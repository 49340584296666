.anchorScrollWrapper {
  .anchorList {
    text-align: right;
    cursor: pointer;
    .anchorItemItem {
      border-right: 1px solid #aeaeae;
    }
    :global {
      .ant-anchor-link-active > .ant-anchor-link-title {
        color: #13c26b !important;
      }
      .ant-anchor-ink {
        height: 0;
      }
      .ant-anchor-ink-ball {
        border: 0;
      }
      .ant-anchor-link {
        padding: 7px 8px 7px 0px;
        line-height: 1;
      }
      .ant-anchor-link-title {
        transition: none;
        color: rgba(0, 0, 0, 0.65) !important;
      }
    }
  }
}
