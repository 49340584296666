.candidateCardTertiaryContent {
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  align-items: center;
  :global {
    .ant-btn-link {
      font-size: 14px;
    }
  }
}

.undoModalContent {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}

.linkButton {
  span {
    text-decoration: underline;
  }
}
