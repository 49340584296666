.custom-modal {
  .ant-modal-body {
    padding: 32px;

    .custom-modal-content-wrapper {
      display: flex;

      .custom-modal-icon {
        font-size: 16px;
        margin-right: 16px;

        .custom-modal-success-icon,
        .custom-modal-pending-icon {
          height: 22px;
          width: 22px;
        }

        .custom-modal-success-icon {
          color: #13c26b;
        }

        .custom-modal-pending-icon {
          color: #f5222d;
        }
      }

      .custom-modal-content {
        .custom-modal-header {
          height: 24px;
          color: rgba(7, 16, 26, 0.9);
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
        }

        .custom-modal-header-false {
          margin-bottom: 16px;
        }

        .custom-modal-message {
          color: rgba(7, 16, 26, 0.7);
          font-size: 14px;
          letter-spacing: 0;
          line-height: 22px;
          margin-bottom: 16px;
        }

        .custom-modal-footer {
          .custom-modal-footer-content {
            display: flex;
            justify-content: space-between;

            .custom-modal-buttons {
              height: 32px;
              display: flex;
              justify-content: space-between;
              position: relative;
              bottom: 0px;
              right: 0px;

              .custom-modal-yes-button,
              .custom-modal-no-button {
                display: flex;
                align-items: center;
                height: 32px;
                font-size: 14px;
                line-height: 20px;
              }

              .custom-modal-no-button {
                margin-right: 8px;
              }

              .custom-modal-yes-button {
                background-color: rgba(7, 16, 26, 0.05);

                .yes-button-text {
                  color: #ff0000;
                  height: 20px;
                }

                .anticon-loading {
                  margin-right: 4px;
                  color: #ff0000;
                }
              }

              .custom-modal-yes-button:hover,
              .custom-modal-yes-button:focus {
                border-color: #ff0000;
              }
            }
          }
        }
      }
    }
  }
}
