.userItem {
  padding: 10px;
  border-bottom: thin solid lightgrey;
  display: flex;
  align-items: center;
  :global {
    .ant-avatar {
      margin: 0 12px 0 24px;
    }
  }
}
