.extended-bot-modal-wrapper {
  .bot-question {
    border: 1px solid #dadbdd;
    border-radius: 4px;
    padding: 6px 24px;
    margin-bottom: 20px;
    background-color: transparent;

    .question {
      textarea {
        font-weight: 600;
        border: none;
      }
    }
  }
  .extended-bot-question-wrapper {
    height: 53vh;
    overflow-x: auto;
    border-bottom: 1px solid #dadada;
  }
  .extended-bot-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .extended-bot-add-question-button {
    justify-content: space-between !important;
    align-items: flex-start;
  }
  .extended-bot-buttons {
    margin-top: 14px;
  }
  .cancel-button,
  .cancel-button:hover {
    background-color: #fff;
    color: rgba(7, 16, 26, 0.7) !important;
    border: 1px solid #dadbdd !important;
    height: 32px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .save-button {
    border-radius: 16px;
    margin-left: 15px;
  }
  .ant-input-disabled {
    background-color: transparent;
  }
  .button-group {
    display: flex;
    justify-content: center;
    margin: 0px 0px 10px 0px;
  }
}
