.contentContainer {
  width: 70%;
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background-color: #fcfcfc !important;
  .searchUserContainer {
    :global {
      .ant-input {
        border: none;
        border-bottom: 1px solid #d9d9d9;
        border-radius: 0px;
      }
    }
    .scoutingAgentContent {
      width: 100%;
    }
  }
  .userWithPermissionContainer {
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #d9d9d9;
    .usersColumn,
    .permissionColumn {
      width: 50%;
      height: 100%;
    }
    .usersColumn {
      border-right: 1px solid #d9d9d9;
    }
    .userWithCheckbox,
    .permissionSelect {
      display: flex;
      gap: 16px;
      border-bottom: 1px solid #d9d9d9;
      padding: 10px;
      padding-left: 20px;
    }
    .userTableContainer {
      display: flex;
      margin-right: 7px;
    }
    .userOptionsWithPermission {
      display: flex;
      width: 100%;
      .userWithCheckbox,
      .permissionSelect {
        width: 50%;
      }
      .userWithCheckbox {
        border-right: 1px solid #d9d9d9;
        .userNameWithAdminLabel {
          flex: 1;
          display: flex;
          justify-content: space-between;
          .adminText {
            color: #1890ff;
            border: 1px solid #91d5ff;
            background-color: #e6f7ff;
            border-radius: 12px;
            padding: 4px 8px;
            line-height: 1;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  .footerContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 15px;
    background-color: white !important;
  }
  .scoutingAgentUserList {
  }
}
.scoutingAgentContent {
  width: 100%;

  :global {
    .ant-select-selection {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
