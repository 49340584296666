.content {
  .title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #374251;
  }
  .sources {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 15px 0px;
    .sourceItemWrapper {
      display: flex;
      gap: 5px;
      padding: 10px;
      border-radius: 9px;
      border: 1px solid #d9d9d9;
      width: 300px;
      justify-content: space-between;
      .sourceItem {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 130px;
        .sourceName {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .divider {
      border: 1px solid #979797;
    }
    .timeWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 120px;
      justify-content: flex-end;
      .errorMessageWrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        .closeIcon {
          font-size: 12px;
          color: red;
        }
        .errorMessageText {
          font-size: 13px;
        }
      }
      .checkCircle {
        > svg {
          color: #01cd75;
          font-size: 18px;
        }
      }
      .secondsText {
        width: 72px;
        color: rgba(7, 16, 26, 0.7);
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
  .clockIcon {
    > svg {
      font-size: 18px;
    }
  }
}
