.suggestionDropdownItemViolet {
  color: #7d18d9 !important;
}

.suggestionDropdownItemGreen {
  color: #00c900 !important;
}

.suggestionDropdownItemBlue {
  color: #2ca2ff !important;
}

.suggestionDropdownDotted {
  background-image: linear-gradient(to right, #979797 40%, #ffffff 20%);
  background-position: bottom;
  background-size: 7px 1px;
  background-repeat: repeat-x;
}

.suggestionDropdownDotted:last-of-type {
  background-image: none;
}
