.manual-add-reject-reasons-wrapper {
  padding: 12px 20px 4px 20px;

  .manual-add-reject-reason-text {
    height: 22px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
  }

  .manual-add-reject-reason-input {
    margin-top: 5px;

    .ant-input-wrapper {
      > input {
        font-size: 14px;
      }
    }

    .ant-input-search-button {
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .anticon {
      > svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  .manual-added-reject-reasons {
    margin-top: 10px;

    .selected-reject-reasons {
      max-height: 120px;
      overflow: auto;
    }

    .selected-reject-reason-tag {
      height: 22px;
      border: 1px solid #dadbdd;
      border-radius: 11px;
      background-color: #f2f3f3;
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;
    }
  }
}
