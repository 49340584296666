.alertWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 32px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(7, 16, 26, 0.15);
  padding: 4px 10px 4px 16px;
  line-height: 14px;
  align-items: center;
  border: 1px solid #ecf0f1;
}

.alertTextWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 12px;
}

.warningIcon {
  color: #faad14;
  font-size: 14px;
}

.successIcon {
  color: #13c26b;
  font-size: 14px;
}

.loadingIcon {
  font-size: 14px;
}
