.payment-error-modal {
  text-align: center;
  padding: 15px;
}

.payment-error-modal-title {
  color: rgba(7, 16, 26, 0.9);
  font-size: 36px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
  margin-top: 40px;
}

.payment-error-modal-description {
  color: rgba(7, 16, 26, 0.7);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  > p {
    margin-bottom: 0em;
  }
}

.payment-error-modal-ok-button {
  font-size: 16px;
  height: 40px;
  min-width: 87px;
  border-radius: 20px;
}
