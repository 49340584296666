.password-reset-description {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 10px;
}

.password-reset-wrapper {
  display: flex;
  .ant-input {
    height: 40px;
  }
}

.reset-password-input {
  width: 420px;
}

.reset-password-info {
  position: absolute;
  font-size: 26px;
  color: #b4b7ba;
  right: -35px;
  top: 39px;
  cursor: pointer;
}

.reset-password-tooltip {
  z-index: 2400;
}
