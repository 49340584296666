.filter {
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: 40px;
}
.filterTitle {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filterApplyClear {
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filterClearButton,
.filterApplyButton {
  border: 1px solid #dadbdd;
  margin-left: 10px;
  line-height: 22px;
  font-size: 14px;
}

.filterClearButton {
  background-color: #ffffff;
}
.content {
  display: flex;
  width: 737px;
  height: 284px;
  overflow-y: auto;
}
.allFilter {
  color: #1f2730;
  font-size: 14px;
  line-height: 22px;
  padding-left: 16px;
  padding-top: 18px;
}
.filters {
  width: 42%;
  border-right: 1px solid #dadbdd;
  padding-left: 4px;
  .menu {
    border-right: 0px;
    color: #1f2730;
    font-size: 14px;
    line-height: 22px;
    padding-top: 12px;
    .menuItem:hover {
      color: #1f2730;
    }
    .menuItem,.menuItem:not(:last-child){
      margin-top: 0px;
      margin-bottom: 0px;
        background-color: #f5f5f5;
        color: #1f2730;
    }
  }
}
.subFilters {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-left: 30px;
  padding-top: 21px;
  font-size: 14px;
  line-height: 22px;
  color: #51575e;

}

.filterIcon{
  cursor: pointer;
  :global .ant-badge {
    .ant-badge-dot {
      background-color: #13c26b;
    }
  }
}
.menuItemAndCollapseIcon{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collapseIcon{
      color:grey
}
