.upgradeScreenContainer {
  position: relative;
  .upgradeModalWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .modalContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 480px;
      height: 252px;
      position: relative;
      border-radius: 18px;
      background-color: #ffffff;
      box-shadow: 0 4px 12px 0 rgba(7, 16, 26, 0.2);
      .lockedFeature {
        margin-bottom: 8px;
        position: absolute;
        top: -60px;
        .lockMiddleCircle,
        .lockOuterCircle {
          padding: 10px;
          border-radius: 80px;
          background-color: #13c26b;
        }

        .lockOuterCircle {
          width: 120px;
          background-color: rgba(19, 194, 107, 0.55);
        }

        .lockInnerCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 80px;
          border-radius: 80px;
          border: 2px solid white;

          .lockedImg {
            height: 38px;
          }
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 65px;
        .heading {
          margin-bottom: 14px;
          color: rgba(7, 16, 26, 0.9);
          font-size: 22px;
          font-weight: bold;
          width: 376px;
          text-align: center;
          line-height: 29px;
        }
        .description {
          margin-bottom: 18px;
          text-align: center;
          font-size: 16px;
          width: 407px;
          line-height: 23px;
        }
        .upgradeButton {
          background-color: #13c26b;
          color: #ffffff;
        }
      }
    }
  }
  .blurImage {
    width: 100%;
    height: 572px;
  }
}
