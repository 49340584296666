.closeModal {
  top: 200px;
}
.actionButtons {
  display: flex;
  justify-content: flex-end;
}
.modalContent {
  display: flex;
  align-items: center;
  .iconDiv {
    margin-right: 16px;
    font-size: 22px;
    color: #ffcc00;
  }
  .message {
    font-size: 16px;
    line-height: 24px;
    color: #07101a;
  }
}
