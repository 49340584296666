.advertiseJobTitleWrapper {
  color: #374251;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.advertiseDescriptionWrapper {
  margin-bottom: 10px;
}

.activeTag {
  margin-left: 6px;
  border-radius: 11px;
}

.editBtn {
  margin-right: 10px;
}

.advertiseActionsWrapper {
  display: flex;
}
