#activate-modal-id {
  height: 405px;
}

.activation-modal-wrapper .activate-modal-id {
  position: relative !important;
  top: 50px;
}

.activate-modal-id .ant-modal-body {
  padding: 0px;
}

.activate-portal-details,
.activate-portal-details-disable {
  padding-top: 8px;
  padding-bottom: 24px;
}

.activate-portal-details-disable {
  opacity: 0.5;
  pointer-events: none;
}

.retain {
  font-size: 16px;
  line-height: 22px;
  color: #374251;
  font-family: Roboto, sans-serif;
  margin-bottom: 9px;
}

// .retain-div {
//    margin-bottom: 36px;
// }

.source-title {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #374251;
}

.activate-modal-title {
  font-size: 16px;
  font-weight: 500;
  color: #1F2730;
  line-height: 24px;
}

.portals {
  padding-top: 20px;
  // height: 200px;
  margin-top: 10;
  margin-bottom: 10;

  .portal-activation-buttons {
    height: inherit;
    overflow-y: scroll;
  }
}

.radio-select {
  font-family: Roboto, sans-serif;
  color: #5f697a;
  font-size: 14;
  line-height: 22px;
}

.activate-btn {
  height: 22px;
  width: 52px;
  color: #ffffff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.cancel-btn {
  height: 22px;
  width: 52px;
  color: #ffffff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.deactivate.ant-btn,
.deactivate:focus.ant-btn:focus {
  float: left;
}

.activation-modal-wrapper {
  display: flex;
  align-items: center;

  .ant-modal {
    position: static;
    margin-top: 140px;
    color: #1F2730 !important;
  }

  .ant-modal-body {
    padding: 0px 24px;
  }
  
  .ant-modal-footer {
    padding: 10px 24px;

    .ant-btn {
      border-radius: 40px;
      line-height: 22px;
      padding: 5px 16px;

      // &.deactivate {
      //   background-color: #f2f3f3;
      //   border-color: #dadbdd;

      //   &:hover {
      //     background-color: #f5222d;
      //     border-color: #f5222d;
      //     color: #ffffff;
      //   }
      // }
    }
  }
}

.userAlertWrapper {
  height: 218px;
  position: fixed;
  z-index: 2000;
  .alert-message-body {
    width: 424px;
  }
  .lock-outer-circle {
    left: 145px;
  }
  .user-alert {
    width: 424px;
    height: 218px;
  }
  left: calc(50% - 212px);
  top: calc(50% - 65px);
}

.score-rank,
.score-rank-applied {
  font-weight: 400;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  margin-top:16px;
  margin-bottom: 8px;
  color: #1F2730;
}

.warning-message{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 8px 0px;
  .ant-alert-message{
    padding-left: 8px;
  }
}

.persist-candidate-type-question{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 8px;
}

.persist-candidate-type-options{
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
