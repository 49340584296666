.reportsTabMenu {
  padding-top: 16px;
  height: 100%;
}

.reportsTabLayout {
  background-color: #fff;
}

.reportsTabSider {
  background-color: #fff;
}

.reportsTabContent {
  min-height: 200px;
}
