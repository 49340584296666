.exportNotificationTitle {
  width: 100%;
  display: flex;
  font-weight: 400;

  .exportNotificationTitleBold {
    font-weight: 500;
    color: black;
  }
}

.reportExportDownloadButton {
  height: 20px;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  border: 1px solid #dadbdd;
  color: rgba(7, 16, 26, 0.7);
  background-color: #ffffff;
  padding: 2px 10px;
}

.reportExportSuccessIconWrapper {
  height: 40px;
  width: 40px;
  font-size: 20px;
  padding: 10px;
  background-color: rgba(19, 145, 107, 0.15);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .reportExportSuccessIcon {
    color: #13c26b;
  }
}

.reportExportErrorIconWrapper {
  height: 40px;
  width: 40px;
  font-size: 20px;
  padding: 10px;
  background-color: rgba(245, 34, 45, 0.15);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .reportExportErrorIcon {
    color: #f5222d;
  }
}

.reportExportNotification {
  display: flex;
  width: 360px;
  justify-content: space-around;
  border-bottom: 1px solid #e6eaee;
  padding: 12px;
  cursor: pointer;

  .content {
    width: 229px;
    color: #81878e;
    font-family: Roboto, sans-serif;
    font-size: 13px;

    .title {
      line-height: 18px;
      width: 100%;
      margin-bottom: 8px;
    }

    .message {
      line-height: 18px;
      margin-bottom: 8px;
    }

    .since {
      color: #7f8fa5;
      font-size: 12px;
      line-height: 14px;
      padding: 4px 0px;
      display: flex;
      align-content: center;

      .sinceTime {
        margin-right: 5px;
      }
    }
  }

  .isViewed {
    :global .ant-badge-status-dot {
      width: 8px;
      height: 8px;
    }

    :global .ant-badge-dot {
      background-color: #13c26b;
    }
  }
}

.reportExportNotification:hover {
  background-color: #f3f3f3;
}
