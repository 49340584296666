.modalContainer > * {
  color: #1f2730;

  #agent_name {
    border-radius: 2px;
  }
}

.frequencyContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  padding-left: 24px;
  .selectDay {
    width: 54px;
  }
  .frequencyText {
    padding-right: 10px;
    margin: 4px 0;
  }
  .repeatFrequency {
    max-width: 36px;
    :global {
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
  .selectFrequent {
    min-width: 76px;
  }
}

.scoutingModal {
  :global {
    .ant-modal-header {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e8e8e87d;
    }
  }
  .notifyUser {
    margin: 30px 0;
    padding-left: 24px;
    h3 {
      font-size: 16px;
      display: inline-block;
      margin-right: 13px;
      padding: 0;
    }
  }
}
.maxCandidate {
  padding-left: 24px;
}
.heading {
  margin: 20px 0 4px 0;
  font-size: 16px;
  font-weight: 500;
}

.formItemFullWidth {
  width: 100%;
  padding-left: 24px;
  padding-right: 20px;
}
.endContainer {
  display: flex;
  gap: 8px;
  align-items: baseline;
  .endDateText {
    min-width: 90px;
  }
}

.divider {
  border-top: 1px solid #dadbdd;
  margin-top: 24px;
}

.actionButtons {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  gap: 8px;
}
.header {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.heading {
  margin: 20px 0 4px 0;
  font-size: 16px;
  font-weight: 500;
}

.formItemFullWidth {
  width: 100%;
}
.endContainer {
  display: flex;
  gap: 8px;
  align-items: baseline;
  .endDateText {
    min-width: 90px;
  }
}

.segmentScoutedContainer {
  padding-left: 24px;
  padding-top: 8px;
  font-size: 16px;
  font-weight: 500;
}

.segmentScoutedChild {
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .segmentScoutedFirstChild {
    padding-top: 8px;
    width: fit-content;
  }
}
