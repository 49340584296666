.titleSelection {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px;
  :global .ant-form-item-control {
    line-height: 0px;
  }
}

.minimizedTitleSeclection {
  :global .ant-form-item-control {
    line-height: 0px;
  }
}

.titleTagsContainer {
  margin-top: 20px;
   display:flex;
  flex-wrap: wrap;
  align-items: center;
}

.titleTag {
  font-size: 12px;
  font-style: normal;
  line-height: 22px;
  border-radius: 15px;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 2px 10px;
  max-width: 300px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  &.selected {

    border-radius: 16px;
    border: 1px solid var(--gray-gray-5-dadbdd, #DADBDD);
    background: var(--gray-gray-1-ffffff, #FFF);

  }
  &.unselected {
    border-radius: 16px;
    border: 1px solid var(--gray-gray-5-dadbdd, #DADBDD);
    background: var(--gray-gray-1-ffffff, #FFF);
  }
}

.titleLabel {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.titleClearAll {
  display: flex;
  .clearAll {
    text-decoration: underline;
    margin-left: auto;
  }
}

.manualSearchPopoverFooter {
  border-top: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  height: 50px;
  button.clearButton {
    border-radius: 15px;
    padding: 2px 15px;
    color: #51575e;
    font-weight: 400;
    font-size: 15px;
  }
}

.maunalSearchAutoCompleteInput {
  :global {
    .ant-input-group > .ant-input:first-child,
    .ant-input-wrapper > .ant-input:focus {
      border: none !important;
      border-bottom: 1px solid gainsboro !important;
      border-radius: 0px !important;
    }
  }
}

.popoverTitleSectionContainer {
  padding: 20px 20px 10px 20px;
  max-width: 380px;
  min-width: 380px;
  display: flex;
  flex-direction: column;
}

.exactTitleMatchWrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding-top: 10px;
  width: fit-content;
  .exactTitleMatchContent {
    font-weight: 400;
    font-size: 14px;
  }
}
