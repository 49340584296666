#candidate-multi-select-mail-window {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 10px;
}

.mail-editor.composer {
  .ql-editor {
    height: 108px;
    overflow: auto;
  }
}

.mail-editor-composer360 {
  .ql-editor {
    min-height: 39.2vh;
    max-height: 39.2vh;
    overflow: auto;
  }
}

#mail-input-box-wrap-360 {
  .rich-text-editor {
    .ql-toolbar {
      .ql-mergetags {
        width: 20px;
        color: var(--arya-accent-color);
      }
    }
  }
}

.richEditor-multi-select-email {
  height: 230px;
  width: 470px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: auto;
  padding: 10px;
}

.bulkEmailModalBody {
  :global {
    .ant-modal-body {
      padding: 24px 24px 0px 24px;
    }
  }
}

.bulkEmail360ModalBody {
  :global {
    .ant-modal-body {
      padding: 24px 24px 0px 24px;
    }
  }
  .ant-modal-header {
    padding: 0px;
  }
}

.candidate-multi-select-mail-window-flex-items {
  margin-bottom: 10px;
  display: flex;

  .candidate-multi-select-mail-template-select {
    width: 400px;
    height: 32px;
  }
}

.candidate360-multi-select-mail-window-flex-items {
  margin-bottom: 10px;
  .candidate-multi-select-mail-template-select {
    width: calc(100% - 3vh);
    height: 32px;
    .ant-select-selection,
    .ant-select-selection:hover,
    .ant-select-selection:focus {
      border-left: none !important;
      border-top: none !important;
      border-right: none !important;
      border-color: #cfd5db;
      border-radius: 0px;
      box-shadow: none;
      overflow: auto;
    }
    .ant-select-arrow {
      color: #1f2730;
      right: 0px;
    }
    .ant-select-selection__clear {
      right: 0px;
    }
  }
}

.candidate-multi-select-email-subject {
  width: 100%;
  margin-bottom: 10px;

  .ql-editor {
    height: 36px;
    overflow: auto;
    white-space: nowrap;
    padding: 8px;
  }
}

#candidateView360AddPreHeaderButton {
  margin-top: 27px;
}

.candidate360-multi-select-email-subject {
  margin-top: 10px;
  width: 100%;

  .ql-editor {
    height: 50px;
    overflow: auto;
    white-space: nowrap;
    padding: 15px;
    padding-left: 10px;
    width: 91%;
  }
}

.emails-dropdown-select-wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;

  .emails-dropdown-text {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    text-align: center;
    align-self: center;
    padding-top: 5px;
    width: 100px;
    height: 32px;
    border: 1px solid #d9d9d9;
  }

  .ant-select-selection {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-left: 0px;
  }
}

.connect-email-dropdown-wrapper {
  width: 400px;
  height: 32px;
  margin-bottom: 5px;

  .connect-email-dropdown {
    width: 340px;

    .ant-select-selection {
      border-left-width: 1px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.bulk-connect-email-dropdown-wrapper {
  width: calc(100% - 3vh);
  margin-bottom: 5px;
  .dropdown-with-label {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-color: #cfd5db;
    border-radius: 0px;
    color: #51575e;
  }

  .connect-email-dropdown {
    .ant-select-selection,
    .ant-select-selection:hover,
    .ant-select-selection:focus {
      border-left: none !important;
      border-top: none !important;
      border-right: none !important;
      border-color: #cfd5db;
      border-radius: 0px;
      box-shadow: none;
    }
    .ant-select-arrow {
      color: #1f2730;
      right: 0px;
    }
    .ant-select-selection__clear {
      right: 0px;
    }
  }
}

.email-composer-merge-tags {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;
  color: var(--arya-accent-color);
}

.emailpopuphead {
  padding: 5px 0px;

  .rich-text-editor {
    width: 100%;
  }
}

.candidate360EmailPopUpHead {
  padding: 0px;
  .rich-text-editor {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    .rich-text-editor-true {
      width: 100%;
    }
  }
}

.candidate-multi-select-email-subject {
  .ql-container {
    border-radius: 4px;
  }
}

.candidate360-subject-editor {
  width: 88%;
  .ql-container {
    border: none;
  }
  .ql-container .ql-snow {
    border: none;
  }
}

.credit-deduction-alert.ant-alert {
  margin-bottom: 16px;
  margin-top: -8px;
}

.bulk-email-input-label-section {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 7px;

  #mergeTagSelect {
    color: #13c26b;
    text-decoration: underline;
  }
}

.candidate360-preheader-view {
  margin-bottom: 10px;
  .ql-container.ql-snow {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-color: #cfd5db;
    border-radius: 0px;
    box-shadow: none;
  }
}

.mail-window-merge-tag-360 {
  cursor: pointer;
  margin: 5px 0px 0px 13px;
  color: var(--arya-accent-color);
  width: 90px;
  position: absolute;
}

#email-input-label-name {
  font-weight: 600;
}

.bulk-email-preheader {
  width: 100%;

  .ql-editor {
    height: 36px;
    overflow: auto;
    white-space: nowrap;
    padding: 8px;
  }
}

#bulkEmailAddPreHeaderText {
  color: #13c26b;
  text-decoration: underline;
}

#bulkEmailAddPreHeaderButton {
  padding: 5px 0px 5px 0px;
}

#bulkEmailPreHeaderRemoveSection {
  position: relative;
  left: 540px;
  bottom: 28px;
}

#bulkEmailPreHeaderRemoveIcon {
  font-size: 18px;
}

.candidate-360-compose-modal-title {
  display: flex;
  justify-content: space-between;
  background: #1f2730;
  color: white;
  padding: 10px;
  height: 40px;
}

.candidate-compose-modal-title {
  display: flex;
  justify-content: space-between;
  height: 20px;
  align-items: center;
  .expand-shrink-icons {
    color: rgba(0, 0, 0, 0.45);
    &:hover {
      color: #1f2730;
    }
  }
}
