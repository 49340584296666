.advanceOptionsPopover {
  width: 327px;
  z-index: 100;
  :global .ant-popover-title {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  :global .ant-popover-inner-content {
    height: 500px;
    overflow: scroll;
    padding-top: 20px;
  }
}
