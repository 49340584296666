.enhancedConnectMediaWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  .connectMediaIconGroup {
    display: flex;
    align-items: center;
    gap: 15px;
    .connectMedia {
      line-height: 1;
      cursor: pointer;
      .mediaIcon {
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .disabledMediaIcon {
        color: #d9dbdc;
        cursor: not-allowed;
        opacity: 0.5;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .largeMediaIcon {
        svg {
          width: 30px;
          height: 27px;
        }
      }
      .unsubscribedIcon {
        svg {
          color: red;
        }
      }
    }
  }
  .contactText {
    cursor: auto;
    border: 1px solid #dadbdd;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    padding: 7px 10px;
    border-radius: 100px;
    font-size: 14px;
    line-height: 16px;
  }
}
.emailIcon {
  display: flex;
  align-items: center;
  .chatGptIcon {
    margin-right: -2px;
    width: 16px;
    margin-top: 0.45px;
    padding-bottom: 0.2px;
  }
}
