.sample-candidate-not-found-content {
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  line-height: 30px;
}

.sample-candidate-not-found-content > p {
  font-size: 16px;
}

.sample-list-candidate-status {
  display: flex;
  width: 100%;
}

.sample-candidate-empty-list > svg {
  width: 100px;
  height: 135px;
}

.sample-candidate-empty-list-benefit-card {
  margin-top: 10px;
  margin-bottom: 30px;
}
