.employmentSection {
  margin-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e8e8e8;
  .employmentSectionClearAll {
    display: flex;
    .employmentSectionLabelWrapper {
      color: rgba(7, 16, 26, 0.7);
      font-size: 16px;
      font-weight: 500;
    }
    .clearAll {
      margin-left: auto;
    }
  }
  .employmentCriteriaWrapper {
    margin-top: 15px;
    .inputLabels {
      color: #484b4f;
      font-size: 12px;
      font-weight: 500;
    }
    .empolymentColumn {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .empolymentRow {
        display: flex;
        align-items: center;
      }
    }
  }
  :global {
    .ant-select-selection,
    .ant-select-focused,
    .ant-input {
      border: none !important;
      border-bottom: 1px solid gainsboro !important;
      border-radius: 0px;
      box-shadow: none !important;
    }
  }
}
