.segmentsAndCampaignsTable {
  font-weight: 400;
  margin-top: 28px;
  height: calc(100vh - 275px) !important;
  overflow-y: auto;
  .tableHeading {
    padding: 11px 20px 13px 20px;
    background-color: #f3f3f4;
    color: #51575e;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1px;
  }
  .segmentName {
    color: #07101a;
  }
  .segmentEmpty > svg {
    width: 275px;
    height: 60px;
  }
}
.loader {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.loadingIcon {
  font-size: 20px;
}
.segmentNotFound {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.emptyScreen {
  :global {
    .ant-empty {
      height: calc(100vh - 380px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      :global {
        .ant-empty-image {
          height: 60px;
        }
      }
    }
  }
}
.extendView {
  height: calc(100vh - 105px) !important;
}
