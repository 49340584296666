.locationSection {
  margin-top: 25px;
  .locationTabs {
    margin-top: 15px;
  }
  .locationLabel {
    font-size: 16px;
    font-weight: 500;
  }
}
.selectedLocation {
  font-weight: 500;
  color: var(--secondary-black-color);
}
