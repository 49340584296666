.contactDetails {
  display: flex;
  padding: 0 0 6px 16px;
  max-height: 100px;
  margin-right: 5px;
  overflow-y: auto;
  border-bottom: 1px solid #e8e8e8;
  align-items: baseline;
  justify-content: space-between;
  .contactDetailsContent {
    width: fit-content;
    &:hover {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
.availableContactText {
  font-weight: 500;
  color: #1f2730;
  margin: 0 0 7px;
}
.externalContact {
  border: 1px solid #d3adf7;
  border-radius: 2px;
  background-color: #f9f0ff;
  margin-left: 5px;
  color: #722ed1;
  padding: 4px 2px 2px;
  height: 16px;
  display: flex;
  align-items: center;
}
.forwardSlash {
  color: #13c26b;
}

.seeMoreButton,
.addOption {
  color: #13c26b;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  text-decoration-line: underline;
}

.title {
  font-weight: 500;
  font-size: 14px;
  color: #1f2730;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  .invalidPhone,
  .invalidEmailIcon,
  .copyIconAndText,
  .smsIconAndText,
  .callIconAndText {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .emailTooltip {
    max-width: 750px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.disabledRefundRequestButton,
.actionButtons {
  color: #13c26b;
  :global {
    .ant-btn {
      padding: 0;
    }
    .ant-btn-link {
      color: #13c26b;
    }
  }
  span {
    text-decoration: underline;
  }
}
.disabledButton {
  .linkButton {
    color: rgba(0, 0, 0, 0.25);
  }
}
.exclamationIconAndText {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
  .exclamationIcon {
    color: #faad14;
  }
}
.refundedCredit {
  font-size: 15px;
  margin-left: 30px;
  color: #1dc572;
  opacity: 1;
}
.pullRequestConfirmationContainer {
  margin-top: 15px;
  margin-left: 10px;
  display: flex;
  align-items: baseline;
  .invalidInformationConfirmation {
    font-size: 14px;
    line-height: 19px;
    color: #202632;
    margin-right: 10px;
  }
  .refundRequestButtonContainer {
    display: flex;
    gap: 10px;
    .refundRequestButton {
      text-align: center;
      height: 25px;
      padding: 0px 10px;
      &.last {
        margin-left: 10px;
      }
    }
  }
}

.refundRequestAlert {
  margin: 10px 0px;
}
.email,
.phone {
  display: flex;
  align-items: flex-start;
  .emailContainer,
  .phoneContainer {
    display: flex;
    flex-wrap: wrap;
    margin-left: 2px;
    align-items: center;
    .phoneActionButtons {
      display: flex;
      align-items: center;
    }
  }
}
.creditsRefundedHeader {
  display: flex;
  gap: 8px;
  align-items: center;
}
.candidateConnectContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  .getContactsText {
    color: #1f2730;
  }
  .candidateConnect {
    margin: 0px;
    :global .connect-get-contact {
      margin: 0px;
    }
  }
}

.emailContent,
.phoneContent {
  display: flex;
  align-items: center;
  &:hover {
    color: #51575e;
  }
}

.expand {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 30px;
  .expandIcon {
    cursor: pointer;
    width: 15px;
    height: 15px;
    .seeMore {
      padding: 0;
      color: #13c26b;
      span {
        text-decoration: underline;
      }
    }
  }
}

.checkbox {
  :global .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0px;
  }
}

.invalidNumber {
  color: var(--color-invalid-spam);
}

.invalidPopover {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  gap: 3px;
  .invalidPopoverAttribute {
    font-weight: bolder;
  }
}

.remarkForInvalidEmail {
  padding: 10px 20px;
  .remarkMandate {
    font-size: 14px;
    font-weight: 450;
    span {
      color: #f5222d;
    }
  }
}
.remarkSubmitButton {
  margin-top: 10px;
}

.reportInvalidEmailButton {
  margin-left: 4px;
  margin-top: -11px;
  padding-bottom: 8px;
}

.exclamationIcon{
  color:gray;
  padding-right: 4px;
}
.manuallyInvalidEmailContent {
  display: flex;
  flex-direction: column;
}

.reportInvalidTextInEmail {
  text-decoration: underline
}
.invalidEmail {
  color: var(--color-invalid-spam);
}
.lineForInvalidContact {
  text-decoration: line-through;
}
