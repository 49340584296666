.candidateDownloadedPortalText {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  align-items: center;
  font-weight: 400;
  display: flex;
  gap: 8px;
  padding-top: 10px;
  font-style: italic;
  color: #83878c;
  width: max-content;
}

.candidateDownloadedPortalTextLatest {
  padding-top: 0px;
}
