.job-tags {
  overflow: hidden;
  .ant-tag,
  .ant-btn {
    margin-left: 0;
    margin-right: 8px;
    margin-top: 12px;
    box-sizing: border-box;
    height: 22px;
    border: 1px solid #d4d4d4;
    border-radius: 11px;
    background-color: #f2f3f3;
    padding: 0px 7px;
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 20px;
  }

  .selected-job-tag {
    border: 1px solid #00c75f;
    background-color: rgba(19, 194, 107, 0.2);
  }
}

.hidden-tags > p {
  color: rgba(7, 16, 26, 0.7);
  // height: 14px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin: 0px;
  padding: 0px;
}

.job-tag-popover {
  .ant-popover-content > .ant-popover-inner {
    max-width: 240px;
    max-height: 440px;
    padding: 5px 5px 0px 5px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
    overflow: scroll;
  }
  p {
    margin: 5px;
  }
}
