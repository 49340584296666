.dropdownWrapper {
  .dropDownContainer {
    background-color: #ffffff;
    padding: 12px;
    max-width: 449px;
    border-radius: 4px;
    box-shadow: 0 1px 6px 0 rgba(7, 16, 26, 0.2);

    .suggestionsContainer {
      display: flex;
      width: 100%;
      .loaderContainer {
        > * {
          padding: 7px;
          background-color: rgba(19, 194, 107, 0.2);
          border-radius: 50%;
          color: rgb(19, 194, 107);
        }
        min-width: 20px;
        line-height: 22px;
        margin: 5px 10px 5px 0px;
      }
      .suggestionsWrapper {
        width: 100%;
        .suggestionsTitle {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: rgba(6, 7, 40, 0.9);
          margin: 5px 0px;
        }
      }
    }
    .suggestions {
      max-height: 110px;
      overflow-y: scroll;
    }
  }

  .hideDropDownContainer {
    padding: 0px;
  }

  .jobTitleDropdown {
    z-index: 1070;
    max-width: 450px;

    :global .ant-dropdown-menu {
      max-height: 264px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .selectedTagsDisplay {
    padding: 12px 0px 0px 0px;
  }

  .jobTitleTags {
    height: 22px;
    margin: 5px;
    border-radius: 11px;
    line-height: 22px;
    background-color: #ffffff;
    cursor: pointer;
  }

  .tagContent {
    display: inline-flex;

    .controlOverflow {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .selectedJobTitles {
    background-color: #f2f3f3;
    cursor: default;
  }
}
