.EdgeAddButton {
  // cursor: default;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TooltipEdge {
  // right: 54px !important;
  // top: 22.5px !important;
  width: max-content;
  .ant-tooltip-inner {
    cursor: pointer;
  }
}
