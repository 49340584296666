.not-authorized-wrapper {
  display: flex;
  height: 90vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -150px;

  .not-authorized-logo {
    box-shadow: 0 0 8px 0 rgba(7, 16, 26, 0.2);

    .not-authorized {
      padding: 30px 120px 15px;
      text-align: center;
      .title {
        color: rgba(7, 16, 26, 0.9);
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 10px;
        width: 100%;
      }

      .subtitle {
        color: #505860;
        margin-bottom: 10px;
      }
      .footer {
        margin-top: 20px;
      }
    }

    .logo {
      padding: 10px;
      text-align: center;
      background-color: #f7f7f7;
    }
  }

  .unauthorized-logout-button {
    margin-top: 10px;
  }
}
