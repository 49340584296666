.contact-settings-admin {
  display: flex;
}

.contact-settings-header {
  margin-bottom: 32px;
}

.contact-settings-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 8px;
}

.contact-settings-description {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.contact-type-options {
  display: flex;
  margin-bottom: 55px;
}

.contact-options-heading {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 10px;
  margin-right: 33px;
}

.radio-contact-group {
  display: flex;
}

.radio-contact-option {
  display: block;
  margin-bottom: 16px;
}

.creditsDisplay {
  margin-bottom: 16px;
}

.creditsDisplayTitle {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 8px;
}

.creditsDisplayAmount {
  color: #01cd75;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
}

.creditsRequest {
  margin-bottom: 16px;
}

.creditsRequestAmount {
  height: 32px;
  width: 160px;
}

.otherAmountRequest {
  margin-bottom: 16px;
}

.otherAmountInput {
  width: 165px;
}

.sendRequestButton,
.sendRequestButton:hover,
.sendRequestButton:focus {
  border-radius: 25px;
  color: white;
  background-color: var(--arya-accent-color);
  margin-bottom: 24px;
}

.creditsSubmitDisclaimer {
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.contact-type-credits-wrapper {
  width: 650px;
}

.contact-info-provider-settings {
  margin-bottom: 30px;
}

.contact-info-provider-settings-title {
  width: 550px;
  color: rgba(32, 38, 50, 0.6);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 20px;
}

.contact-provider-checkbox-option {
  line-height: 40px;
  display: block;
  background-color: rgba(19, 194, 107, 0.1);
  margin-bottom: 8px;
  padding-left: 23px;
}

.contact-provider-checkbox-option.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

.contact-provider-options {
  width: 100%;
}
.hideCreditsForm {
  display: none;
}

.availableCreditsInfo {
  display: flex; flex-direction: column; gap: 20px;
}
