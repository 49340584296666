.contactfetched-image {
  width: 20px;
  height: 20px;
}

.contactfetched-image-wrapper {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: rgba(19, 194, 107, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactpull-icon {
  font-size: 20px;
  color: var(--arya-accent-color);
}
