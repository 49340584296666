.completeHistory {
  display: flex;
  padding: 0 20px 0 20px;
  gap: 30px;
}

.sectionWrapper {
  padding: 10px 14px 30px 20px;
}

.extendView {
  height: calc(100vh - 115px) !important;
}

.extendView > div {
  min-height: calc(100vh - 110px) !important;
}

.scoreDetailsContainer {
  height: calc(100vh - 112px) !important;
  overflow-y: auto;
  width: 91%;

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  .scoreDetailsContent {
    margin-bottom: 20px;

    .sideMenu {
      height: fit-content;
      text-align: right;
      padding-right: 10px;
      border-right: 1.3px solid #dadbdd;
      color: #1f2730;
      position: fixed;

      p {
        span {
          text-transform: capitalize;
        }
        cursor: pointer;
        margin-bottom: 0;
        button {
          border: none;
          color: inherit;
          box-shadow: none;
          padding: 0;
        }
      }
      .selected {
        color: #13c26b;
        font-weight: 500;
      }
    }
    .contentContainer {
      width: 90%;
      margin-left: auto;
      .title {
        color: black;
        font-weight: 600;
        font-size: 16px;
      }
      .heading-1 {
        color: #1f2730;
        font-weight: 700;
        font-size: 15px;
      }
      .heading-2 {
        color: #1f2730;
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
  .bigHeading {
    font-weight: 500;
    font-size: 20px;
    color: rgba(7, 16, 26, 0.900222);
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    i {
      margin: 5px 4px 0 4px;
    }
    span {
      color: #13c26b;
      margin-right: 2px;
    }
  }

  .summaryHeader {
    display: flex;
    justify-content: space-between;
  }

  .sectionHeading {
    font-weight: 600;
    font-size: 16px;
    color: black;
    margin-bottom: 14px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      color: #83878c;
    }
    i {
      font-size: 14px;
    }
  }
  .sectionContentHeading {
    font-weight: 700;
    font-size: 15px;
    color: #1f2730;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      color: #83878c;
    }
    i {
      font-size: 14px;
    }
  }
  .sectionContent {
    font-weight: 400;
    font-size: 14px;
    color: #51575e;
  }
  .sectionCard {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 16px 22px;
  }
  .skillSectionContent {
    color: #51575e;
  }
  .skillArray {
    margin-bottom: 18px;
    text-transform: capitalize;
  }
  .sectionLine {
    border-bottom: 0.5px solid rgb(218, 219, 221);
    margin: 18px 0;
  }
  .bulletPointContainer {
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    gap: 4px;
    .bulletLine {
      margin-left: 8px;
      width: 9px;
      margin-top: 10px;
      border-bottom: 2px solid #b4b7ba;
      margin-right: 5px;
      b {
        color: rgba(31, 39, 48, 1);
      }
    }
  }
  .bulletPointContainer:last-child {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
  }
}

.candidateIntelV2Tooltip {
  white-space: pre;
  * {
    width: fit-content;
  }
}

.extendedCandidate360AtsNotesView {
  height: calc(100vh - 118px) !important;
}

.extendedCandidate360AtsNotesView > div {
  min-height: calc(100vh - 115px) !important;
}

.summaryWrapper {
  display: flex;
  flex-direction: row;
}

.intelLabels {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 300px;
}
.intelLabelGroup {
  display: flex;
  flex-wrap: wrap;
  margin-right: 16px;
  margin-bottom: 16px;
}

.intelLabelColor {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin: 2px 8px 0px 0px;
}

.candidateLabel {
  background-color: #5b8ff9;
}

.jobLabel {
  background-color: var(--arya-accent-color);
}

.intelLabelText {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.radarChartContainer {
  display: block;
  margin-left: 8%;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 103px;
    margin-right: 10px;
  }
}
