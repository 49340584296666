.advance-options-footer {
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 -1px 5px 0 rgba(7, 16, 26, 0.15);
  padding: 15px 20px;
  position: absolute;
  bottom: 1px;
  background-color: white;
  z-index: 2300;

  button {
    width: 82px;
    border-radius: 20px;
  }
}
