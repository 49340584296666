.supplySearch-form {
  .ant-form-item-label {
    line-height: normal !important;
  }
  .ant-form-item-control {
    line-height: normal !important;
  }
}
.advance-filter-menu {
  animation-duration: 0s !important;
}
.experience-list {
  width: 90%;
  margin-left: 9px;
}

.assessment-content {
  display: flex;
}

.role-comparision {
  min-width: 50%;
  max-width: 50%;
  margin-left: 5%;
}

.Global-insight-supply-button {
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-left: 700px;
}
