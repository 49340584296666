.card-item {
  border: '1px solid gray';
  padding: '0.5rem 1rem';
  margin-bottom: '.5rem';
  background-color: 'red';
  cursor: 'move';
}

.configure-button {
  border-radius: 3px;
}

.header-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data {
  display: flex;
  align-items: center;
  font-weight: 412;
}

.check-box {
  margin-right: 15px;
  cursor: pointer;
}

.information {
  display: flex;
}

.information-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.icon-box {
  display: flex;
  align-items: center;
}

.icon-check {
  color: #01cd75;
  margin-left: 10px;
}

.expand-arrow-icon {
  cursor: pointer;
  margin-left: 10px;
}
