.careerPortalContainer {
  display: grid;
  margin: auto;
  height: 100%;
  width: 100%;
  grid-template-columns: 25% 75%;
}

.candidateListContainer {
  display: grid;
  overflow: auto;
  height: 80vh;
}

.candidateListMenu,
.chatInterface {
  border: 1px solid #cfd5db;
}

.candidateListHeader,
.chatHeader {
  display: flex;
  padding: 15px;
  max-height: 60px;
  border-bottom: 1px solid #cfd5db;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  letter-spacing: 0.727273px;
  color: #374251;
  font-weight: 400;
}

.chatSection {
  overflow: scroll;
  height: 80vh;
}

.filterCheckboxGroup {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.careerportalFilterHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  padding: 5px 0px 5px 0px;

  .filterTitle {
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
  }

  .filterOptions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 9px;
  }
}

.careerPortalFilterDisplay {
  display: none;
}
