.user-client-list-controls {
  display: flex;
  align-content: space-around;
  line-height: 40px;
}

.user-client-search {
  height: 32px;
  width: 240px;
  background-color: #ffffff;
  margin-right: 16px;
}

.user-client-search > .ant-input {
  border-radius: 16px;
}

.filter-users-clients {
  img {
    height: 18px;
  }

  line-height: 40px;
  border: none;
}
