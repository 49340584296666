.enhancedCandidateInformationCard {
  padding: 15px 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .informationCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    .candidateSourceAndNameDiv {
      flex: 1;
      span {
        max-width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
      }
    }
    .informationHeaderIcons {
      display: flex;
      justify-content: center;
      gap: 8px;
      align-items: center;
      .candidateViewIcon {
        cursor: pointer;
        color: #b4b7ba;
        line-height: 1;
        svg {
          font-size: 18px;
        }
      }
    }
  }
  .highlightedText {
    font-weight: 600;
    color: #1f2730;
  }
  .candidateDesignationInformation,
  .candidateLocation {
    line-height: 1;
    font-size: 16px;
    max-width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #1f2730;
  }
  .candidateLocation {
    font-weight: 600;
    color: #51575e;
  }
  .displayedProfessionalInfo {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    color: #51575e;
    .workExperienceWithIcon {
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 2px;
      span {
        max-width: 170px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .workExperienceIcon {
        font-size: 15px;
        color: white;
      }
    }
    .candidateScoreSection {
      display: flex;
      gap: 4px;
      color: #13c26b;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      justify-content: center;
      align-items: center;
      i {
        font-size: 18px;
      }
      .candidateScore {
        display: flex;
        gap: 2px;
      }
      .slashIcon,
      .maxCandidateScore {
        font-size: 12px;
        color: #51575e;
      }
      .maxCandidateScore {
        font-weight: 400;
      }
    }
  }
  .jobMatchingAndFetchContact {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    div {
      margin: 0px !important;
    }
  }
  .latestMarkupDate {
    font-size: 12px;
    font-style: italic;
    color: #83878c;
  }
  .candidateInformationCardHeadline {
    color: grey;
  }
  .truncatedCandidateHeadlineText {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
mustHaveMark {
  color: black !important;
  background-color: pink !important;
  padding: 0;
}

.candidateStatusWithIcon {
  padding: 2px;
  color: #007bff;
  background-color: #e6f7ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 6px;
}
.candidateStatus {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
