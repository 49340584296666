.enhanced360SecondaryInfo {
  .candidate360SecondaryTabs {
    :global {
      .ant-tabs-nav-container {
        color: #51575e;
        font-size: 12px;
      }
      .ant-tabs-tab-active {
        color: #51575e;
        font-weight: 600;
      }
    }
  }
  .emptyTabScreenMessage {
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
    color: #1f2730;
    font-weight: 600;
  }
  mark {
    color: #1f2730 !important;
    font-weight: 700;
    background-color: inherit !important;
  }
}
