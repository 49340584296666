.create-template.aryabot.drawer {
  .template-name {
    width: 400px;
    margin-bottom: 20px;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .questions {
    margin-top: 20px;
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;
  }
}
