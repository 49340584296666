.call-icon-wrapper {
  width: 3em;
  height: 3em;
  border: 0px;

  &:hover {
    border: 0px;
  }
}

.call-dialog {
  text-align: center;
  width: 300px;
  cursor: grab;
  position: fixed;
  bottom: 0px;
  right: 48px;
  box-shadow: 0 4px 12px 0 rgba(7, 16, 26, 0.2);
  background: #ffffff;
  z-index: 2500;
  .rich-text-editor-true {
    max-width: 213px;
  }
}

.call-dialog-header,
.call-dialog-body,
.call-dialog-footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.call-dialog-header {
  .call-dialog-header-content {
    width: inherit;
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .call-dialog-header-text {
      background: transparent;
    }

    button {
      color: black;
      font-size: 20px;
    }
  }
}

.call-dialog-header.call-notes {
  align-items: flex-start;
  justify-content: flex-start;
}

.call-dialog-footer {
  margin-bottom: 12px;
}

.call-dialog-button {
  margin: auto;
  width: 100px;
  padding: 12px;
}

.call-dialog-button.active {
  color: var(--arya-accent-color);
}

.call-dialog-button > .anticon {
  width: 100%;
}

.call-dialog-header {
  background: #f8f8f8;
  border-top: 6px solid #bcbcbc;
  border-radius: 4px 4px 0 0;
  color: #83878c;
}

.call-dialog-header.inprogress {
  border-color: var(--arya-accent-color);
}

.call-dialog-header.completed {
  border-color: #f5222d;
}

.call-status-icon {
  margin-right: 10px;
  font-size: 18px;
}

.dialpad-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.dialpad-header.dialpad-text-input {
  flex-direction: row;
}

.dialpad-header .close-dialog-icon {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 15px;
}

.dialpad-header .candidate-name {
  height: 24px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #374251;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}

.dialpad-header .candidate-number {
  font-size: 14px;
  line-height: 22px;
  color: #374251;
}

.dialpad-header .call-status {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #374251;
}

.dialpad-header .call-status.call-status-completed {
  color: #f5222d;
}

.dialpad-header .call-status.call-status-answered {
  color: #13c26b;
}

.dialpad-input {
  border-width: 0;
  background: #f8f8f8;
}

.dialpad-input:hover {
  border-color: transparent;
}

.dialpad-input:focus {
  background: #f8f8f8;
}

#call-dialog-notes {
  width: 100%;
  max-height: 300px;
  overflow: auto;
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 15px;
}

.dialpad-close-icon {
  font-size: 24px;
}

.see-notes-btn {
  margin-right: 10px;
}
