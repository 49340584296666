.emptyReportList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 60px;
}

.emptyReportListIcon {
  font-size: 72px;
  color: #c8c8c8;
}

.emptyReportListTitle {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

.emptyReportListSubtitle {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
