.template-header {
  margin-bottom: 10px;
}

.template-header > .ant-input-search {
  width: 240px;
  height: 40px;
  margin-right: 25px;
}

.template-header > .ant-input-search > input {
  border-radius: 20px;
}

.template-heading {
  display: inline-block;
  height: 40px;
  width: 214px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 40px;
}

.template-search {
  border-radius: 20px;
  background-color: #ffffff;
  float: right;
}

.create.create-template {
  float: right;
  margin-top: 3.5px;
}

.create-workflow {
  float: right;
  margin-top: 2px;
  background: #13c26b;
  border-radius: 20px;
  height: 35px;
}
