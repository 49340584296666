.resumeWrapper {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 5px;
  .durationTypeSelect {
    width: 142px;
    min-height: 32px;
    border-radius: 4px;
  }
  .durationTypeQuantity {
    width: 80px;
    margin: 0px 10px;
  }
  .durationLabel {
    color: #484b4f;
    font-size: 13px;
    font-weight: 500;
  }
}

.resumeLastUpdatedLabelText {
  font-size: 16px;
  color: rgba(7, 16, 26, 0.7);
  font-weight: 500;
  height: 24px;
}
