.recommendationModalWrapper {
  :global .ant-modal-content {
    border-radius: 12px;
    padding: 24px 24px 20px;
  }

  :global .ant-modal-body {
    padding: 0px;
  }

  :global .ant-modal-footer {
    border-top: 0px;
  }
}

.jdModalWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 18px;
}

.jdModalLeft {
  display: flex;
  flex-direction: column;
}

.jdModalTitle {
  color: #000000;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 9px;
}

.jdModalText {
  color: #7d7e7e;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 13px;
}

.jobTitleHeader {
  color: #1e262f;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 8px;
}

.jobTitleInput {
  background-color: #ffffff !important;
  color: #7d7e7e !important;
}

.jdModalRight {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.jdModalSuggestions {
  border: 1px solid #dadbdd;
  border-radius: 4.3px;
  padding: 21px 23px 12px;
  width: 534px;
}

.partition {
  height: 1px;
  border: 1px solid #dadbdd;
  margin: 16px 0px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.addButton,
.cancelButton {
  height: 32px;
}

.addButton {
  width: 154px;
}

.cancelButton {
  width: 85px;
}

.headingText {
  padding-bottom: 17px;
  color: #1e262f;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.optionsCheckboxWrapper {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 15px;
  height: 162px;
  overflow: auto;
  padding-left: 10px;

  :global .ant-checkbox-group {
    line-height: 28px;
  }

  :global .ant-checkbox-group-item {
    display: flex;
    flex-direction: row;
  }

  :global .ant-checkbox {
    top: 6px;
    height: 16px;
  }
}

.optionsCheckboxWrapper::-webkit-scrollbar {
  width: 2px !important;
}

.optionsCheckboxWrapper::-webkit-scrollbar-track {
  background: #dadbdd !important;
  border-radius: 10px !important;
}

.optionsCheckboxWrapper::-webkit-scrollbar-thumb {
  background: #2adb2a !important;
  border-radius: 10px !important;
}
