.modal-payment-card-form {
  width: 656px;

  .ant-modal-content {
    border-radius: 16px;
    background-color: #f4f6f8;

    .ant-modal-body {
      padding: 50px;
    }

    .ant-modal-footer {
      padding: 6px 50px 30px 50px;
      border: none;

      .modal-payment-card-ok-btn {
        margin-left: 16px;
      }
    }
  }

  .payment-card-form-header {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 20px;
  }
}
