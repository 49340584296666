.moreSkillsSection {
  border: 1px solid #e8e8e8;
  padding: 8px;
  margin-top: 10px;
  :global .boolean-string-display-component-wrapper {
    width: auto;
    :global .boolean-string-display-component {
      width: 88%;
      margin-right: 5px;
    }
  }
  .label {
    color: #484b4f;
    font-size: 12px;
    font-weight: 500;
  }

  .showMoreLessButton {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    gap: 12px;
    i > svg {
      font-size: 10px;
    }
    span {
      color: rgba(7, 16, 26, 0.7);
      font-size: 13px;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  .addSkills {
    flex: 1;
    .addSkillsInput {
      width: 300px;
      display: flex;
      margin-top: 8px;
    }
    .displayedExludedSkills {
      margin-top: 12px;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .excludedSkillTag {
        display: flex;
        gap: 4px;
        font-size: 14px;
        border-radius: 24px;
        background-color: white;
        margin: 0px;
        height: 24px;
        padding: 1px 9px;
        color: #51575e;
        font-weight: 400;
        justify-content: center;
        align-items: center;
        width: fit-content;
      }
      .excludedSkillTagText {
        text-overflow: ellipsis;
        flex-wrap: wrap;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .mustsInput {
    margin-top: 8px;
    width: 300px;
  }

  .skillTag {
    display: inline-flex;
    font-weight: bold;
    border-radius: 3px;
    margin-bottom: 8px;
    margin-right: 8px;
    font-size: 12px;
    padding: 2px 4px;
    cursor: pointer;
    &.selected {
      color: #1890ff;
      border: 1px solid #1890ff;
      background-color: rgba(33, 197, 116, 0.11);
    }
    &.unselected {
      color: #83878c;
      border: 1px solid #83878c;
      background-color: #ffffff;
    }
  }
  .mustHavesDisplay {
    margin-top: 10px;
  }
  .moreSkillsWrapperSection {
    display: flex;
    align-items: baseline;
    .mustHavesContainer {
      flex: 1;
    }
    .disabledSkillsButton {
      :global {
        .ant-btn-primary {
          color: #d9d9d9 !important;
          background-color: hsl(0, 0%, 97.5%);
          box-shadow: none;
          width: 40px;
          border-radius: 0px;
        }
      }
    }
    .enabledSkillsButton {
      :global {
        .ant-btn-primary {
          color: #13c26b !important;
          background-color: hsl(0, 0%, 97.5%);
          box-shadow: none;
          width: 40px;
          border-radius: 0px;
        }
      }
    }
  }
}

.displayNone {
  display: none;
}

.displayBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.displayInlineBlock {
  display: inline-block;
}

.excludeSkillTagsContainer {
  margin-top: 10px;
}
