.status {
  align-self: center;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 6px;
  &.success {
    background-color: #52c41a;
  }
  &.pending {
    background-color: #faad14;
  }
  &.failed {
    background-color: var(--arya-error-color);
  }
}

.deleteButton {
  color: var(--arya-error-color);
}

.signaturePopUpButton {
  > svg {
    font-size: 12px;
  }
}

.emailTable {
  :global {
    .ant-table-thead {
      background-color: #f4f6f8;
    }
    .ant-table-row ant-table-row-level-0 {
      background-color: #f4f6f8;
    }
  }
}

.smtpTableColumnTitle {
  font-size: 12px;
  font-weight: 500;
  color: rgba(7, 16, 26, 0.7);
}

.smtpTableRow {
  padding: 0px 5px;
}

.smtpTableColumn {
  padding: 10px !important;
}

.pendingStatus {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
