.endNodeContent {
  line-height: 28px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
}

.endDrawerFooter {
  position: absolute;
  bottom: 0;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  right: 0;
  background: #fff;
  text-align: right;
  width: 100%;
}
