.summaryFilterFormItems {
  display: flex;
  gap: 16px;
}

.formTimeRangeSelect {
  width: 160px !important;

  :global .ant-select-selection {
    border-radius: 16px;
  }
}

.reportUserSelect {
  width: 240px !important;

  :global .ant-select-selection {
    border-radius: 16px;
  }
}

.dateRangePicker {
  width: 280px;

  :global .ant-input {
    border-radius: 16px;
  }
}
.timeRangeTextInfo {
  display: flex;
  flex-direction: row;
}
.infoButton {
  min-width: fit-content;
}
