.candidateTabs {
  :global {
    .ant-tabs-nav-wrap {
      background-color: rgba(217, 217, 217, 0.3) !important;
    }
  }
}

#candidate-tabs-id {
  .ant-tabs-tab {
    margin: 0 18px 0 0;
  }
}
