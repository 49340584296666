.spin-loader-container {
  position: relative;
  padding-bottom: 5px;
}

.spin-loader {
  position: relative;
  left: 12px;
}

.spin-loader-text {
  position: relative;
  left: 20px;
  color: #13c26b;
}

.empty-image-in-select-dropdown-content {
  margin: 0px;
}
