.shareSearchBox {
  margin-bottom: 12px;
  :global {
    .ant-input-search > input {
      border-radius: 32px;
      padding: 12px;
    }
  }
}

.selectAllCheckbox {
  padding: 10px;
  border-bottom: thin solid lightgrey;
  display: flex;
  align-items: center;
}
