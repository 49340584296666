.jobCreationWrapper,
.firstJobWrapper {
  width: 784px !important;
  border-radius: 12.8px;
  background-color: #f4f6f8;
  box-shadow: 0 2px 3px 0 #ccc8c8;
  margin-top: 120px !important;
  text-align: center;
  padding: 50px 0;
}
.firstJobWrapper {
  padding: unset !important;
}
.icon {
  width: 172px;
  height: 124px;
  margin: auto;
  margin-bottom: 45px;
  .iconSize {
    font-size: 160px;
  }
}

.title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19.2px;
  display: block;
  padding-top: 20px;
}

.content {
  padding: 10px 100px 30px;
  span {
    margin: 5px 0;
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    display: block;
  }
}

.spanStyle {
  color: rgba(7, 16, 26, 0.9);
  font-size: 12px;
}

.skipLink {
  border-bottom: 0.5px solid #979797;
  cursor: pointer;
  color: rgba(7, 16, 26, 0.9);
  font-weight: 500;
  font-size: 12px;
}
