.select-plan-item {
  display: flex;
  justify-content: space-between;
}

.bundlePlanModalFooterContent {
  height: 51px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: -0.2px;
  line-height: 17px;
  padding-left: 19px;
}

.container {
  display: flex;
  flex-direction: column;
}

.primaryHeaderContent {
  display: flex;
  justify-content: space-around;
  width: 481px;
  margin-left: 30px;
  margin-right: 30px;
}

.offerTag10Percent {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;

  p {
    color: #52c41a;
  }
}

.offerTag20Percent {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;

  p {
    color: #1890ff;
  }
}

.offerTag {
  box-sizing: border-box;
  height: 23px;
  width: 58px;
  border-radius: 11px;

  p {
    height: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    padding-top: 1px;
    padding-left: 6.5px;
  }
}

.unitPrice {
  height: 16px;
  width: 80px;
  color: rgba(7, 16, 26, 0.7);
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 16px;
}

.offerBanner10PercentColor {
  background-color: #13c26b;
}

.offerBanner20PercentColor {
  background-color: #1890ff;
}

.offerBanner {
  height: 83px;
  width: 181px;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    height: 28px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 0px;
  }
}

.secondaryHeaderContent {
  padding-left: 19px;

  p {
    color: rgba(7, 16, 26, 0.9);
    height: 22px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: bold;
  }
}

.bodyContent {
  padding-bottom: 33px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.innerBody {
  display: flex;
  justify-content: space-around;
  width: 47%;
}

.amount {
  display: inline-block;
}

.planContent {
  display: inline-block;
  flex: 1;

  p {
    margin-bottom: 0px;
  }
}

.nonDiscountContent {
  width: 50%;
  display: flex;
  justify-content: flex-end;

  p {
    height: 24px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.discountContent {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.border {
  border-top: 1.5px solid #dadbdd;
  margin-bottom: 30px;
}

.promotionalOfferContent {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  font-weight: 450;
  letter-spacing: 0;
  line-height: 21px;
  padding-left: 19px;
  margin-bottom: 30px;
}

.nonDiscountPromotionalOfferContent {
  width: 40%;
  display: flex;
  justify-content: center;

  p {
    height: 24px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.contactUsContent {
  height: 22px;
  width: 190px;
  color: #13c26b;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  padding-left: 19px;
  margin-bottom: 20px;
}

.discountedAmount {
  text-decoration: line-through;
  color: red;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 1px;
}

.actualAmount {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.planText {
  height: 24px;
  width: 84px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.bundleInput {
  flex: 1;
}
