.more-reject-reasons-content {
  padding: 12px 24px 20px 24px;
  border-bottom: 1px solid #dadbdd;
  max-height: 200px;
  overflow: auto;

  .ant-checkbox-wrapper {
    margin-bottom: 5px;
    margin-top: 10px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 14px;
  }

  .ant-form-item {
    margin-left: 25px;
  }

  .ant-form-item-children {
    > input {
      font-size: 14px;
    }
  }

  .ant-form-item-label {
    line-height: 0px;

    > label {
      font-size: 14px;
      font-weight: normal;
      color: rgba(7, 16, 26, 0.9);
    }
  }

  .education-mismatch-wrapper {
    .education-mismatch-radio-buttons {
      height: 52px;
      width: 112px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .ant-radio-wrapper {
        color: rgba(7, 16, 26, 0.9);
        font-size: 14px;
      }
    }
  }

  .more-reject-reasons-checkbox {
    margin-left: 0px;

    .ant-form-item-control {
      line-height: 0px;
    }
  }
}
