.educationSection {
  color: #51575e;
  overflow: hidden;
  text-overflow: ellipsis;
  .candidateCardEducation {
    width: fit-content;
    .candidateEducation {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.educationTooltip {
  max-width: 550px;
  max-height: 200px;
}
