.advanceOptionsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .advanceOptionsTitle {
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-left: 8px;
  }

  i {
    cursor: pointer;
    font-size: 16px;
  }
}

.advancedOptionsTitleicon {
  border-radius: 20px;
  height: 20px;
  width: 20px;
  padding-left: 0px;
  padding-right: 0px;
}
