.actionButtons {
  display: flex;
  justify-content: flex-end;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 5px;
  padding-bottom: 24px;
  :global {
    .ant-tag-checkable:not(:global .ant-tag-checkable-checked):hover {
      color: #51575e;
    }
    .ant-tag:hover {
      opacity: 1;
    }
  }
}

.checkableTag {
  font-size: 14px;
  height: 24px;
  display: flex;
  align-items: center;
  border: 1px solid #dadbdd;
  padding: 0px 8px;
  border-radius: 16px;
  cursor: pointer;
  &.selectedTags {
    background-color: #dcf6e9;
    color: #13c26b;
  }
  &.unselectedTag {
    color: #51575e;
    background-color: #ffffff;
  }
}

.commentSection {
  :global {
    .ant-form-item-control-wrapper {
      height: 70px;
    }
  }
}

.commentArea {
  height: 50px !important;
  resize: none;
}
