.contentHeaderBox {
  display: flex;
}

.infoAndSettings {
  width: 100%;
  padding: 10px 10px 10px 32px;
}

.personInfo {
  display: flex;
  align-items: center;
}

.personName {
  font-size: 17px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  letter-spacing: 0.77px;
  line-height: 20px;
  color: #374251;
}

.primaryInfo {
  height: 20px;
  color: #647182;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0.64px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notesIcon {
  margin-left: auto;
}

.profileButton {
  margin-left: 10px;
}

.shortlistButton {
  margin-left: 20px;
  border: 1px solid #647182;
}
