.retrieveCriteriaModal {
  .retrieveCriteriaModalContent {
    margin-top: 19px;
    padding: 0px 33px;
    display: flex;
    flex-direction: column;
    gap: 19px;
    color: rgba(7, 16, 26, 0.9);
    line-height: 24px;
    overflow-y: scroll;
    max-height: 320px;
  }
  .text {
    font-weight: bold;
  }
  .retrieveCriteriaModalFooter {
    display: flex;
    margin-top: 46px;
    height: 88px;
    border-radius: 0 0 5px 0;
    background-color: #f7f7f8;
    align-items: center;
    justify-content: space-between;
    padding-left: 33px;
    padding-right: 59px;
  }
  .note {
    font-size: 11px;
  }
}

.retrieveCriteriaModalTitle {
  margin-top: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-left: 9px;
}
