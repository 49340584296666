.connect-fetch-contact-info,
.connect-fetch-contact-info-360 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.connect-fetch-contact-info-360 {
  margin-top: 10px;
  height: auto;
}
.connect-fetch-contact-info {
  background-color: #fafafb;
}

.connect-contact-fetch-item {
  margin-bottom: 30px;
}

.connect-no-phone-text {
  width: 205px;
  font-size: 16px;
  text-align: center;

  &.contacts-refunded {
    width: 90%;
  }
  &.candidate-360-email {
    width: 295px;
    margin-right: 70px;
  }
}
.contact-info-message {
  font-size: 24px;
  line-height: 28px;
  color: #51575e;
}
.contact-info-text {
  line-height: 16px;
  color: #51575e;
}
.contact-info-message-and-text {
  width: 390px !important;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
