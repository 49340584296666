.displayCardContainer {
  width: 760px;
  border-radius: 10px;
  box-shadow: 0 4px 12px 0px rgba(7, 16, 26, 0.2);
  .displayCardContent {
    display: flex;
    padding: 38px 64px 38px 46px;
    justify-content: space-between;
    align-items: center;
    .logoContainer {
      width: 120px;
      margin-right: 46px;
      min-width: 120px;
    }
    .messageContainer {
      .messageTitle {
        color: rgba(7, 16, 26, 0.9);
        font-weight: 500;
        font-size: 20px;
      }
      .messageContent {
        color: rgba(7, 16, 26, 0.7);
        font-size: 16px;
        line-height: 24px;
      }
      .supportContent {
        color: rgba(7, 16, 26, 0.7);
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .displayCardFooter {
    background-color: #f7f7f7;
    padding: 13px;
    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    gap: 24px;
  }
}
