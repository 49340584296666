.reportListWrapper {
  overflow-x: auto;
}

.reportTable {
  :global .ant-table-small {
    border-radius: 0px;
    border-right: 0px;
    border-left: 0px;
  }

  :global .ant-table-thead {
    background-color: #f4f6f8;
  }

  :global .ant-table-header-column {
    color: rgba(7, 16, 26, 0.5);
  }
}

.columnHeaderTitle {
  max-width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.columnTitle {
  display: flex;
  gap: 4px;
  align-items: center;
}
