.modal-container {
  overflow-y: auto;
}
.candidate-360-compose-modal-title {
  display: flex;
  justify-content: space-between;
  background: #1f2730;
  color: white;
  padding: 10px;
  height: 40px;
}

.expand-shrink-icons {
  display: flex;
  gap: 20px;
  align-items: center;
}
.candidate-360-compose-modal {
  width: 60vw !important;
  position: absolute;
  height: 100vh;
  right: 0;
  top: auto;
  padding-bottom: 0;
  bottom: 0;

  .ant-modal-content {
    height: 100%;
    border-radius: 0;
  }
  .ant-modal-header {
    padding: 0;
    border-bottom: 0;
  }
  .ant-modal-footer {
    background-color: #fafafa;
    border: none;
  }
  .footer-container {
    display: flex;
    justify-content: right;
    align-items: baseline;
    .more-emails {
      margin-right: auto;
      p {
        text-decoration: underline;
        cursor: pointer;
        color: #51575e;
      }
    }
  }
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    padding-right: 28px;
  }
  .candidate-360-view-modal-content {
    .mail-editor {
      .ql-editor {
        min-height: 30vh;
      }
    }
  }
}

.maximized-modal {
  width: 70vw !important;
  height: 100vh;
  .maximized-modal-content {
    .mail-editor {
      .ql-editor {
        min-height: 30vh !important;
      }
    }

    .modal-content-360 {
      max-height: calc(100vh - 92px) !important;
      overflow-y: auto;
      padding-left: 20px;
    }
    .workflow-modal-content-360 {
      max-height: calc(100vh - 92px) !important;
      overflow-y: auto;
      padding: 0;
    }
    .candidate-360-message-window {
      height: inherit;
      overflow-y: auto;
      .message-textarea {
        .ql-editor {
          min-height: 20vh !important;
        }
      }
      .message-textarea-with-spamalert {
        .ql-editor {
          min-height: 14vh !important;
        }
      }
    }
  }
  .candidate-360-view-maximized-modal-content {
    height: inherit;
    .mail-editor {
      .ql-editor {
        min-height: 38vh !important;
      }
    }
    .inner-messages-wrapper {
      overflow: auto;
      height: calc(100vh - 400px);
      &.composeMessageDisabled {
        overflow: auto;
        height: calc(100vh - 50px);
      }
    }
  }
}
email-panel-email-info {
  color: #51575e;
}

.mobile-container {
  width: 15vw;
  height: 70vh;
  max-width: 400px;
  max-height: 600px;
  background-color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 50px;
  position: fixed;
  bottom: 70px;
}

@media (min-width: 768px) {
  .mobile-container {
    width: 18vw;
    height: 80vh;
    max-width: 600px;
    max-height: 800px;
  }
}

@media (min-width: 1024px) {
  .mobile-container {
    width: 23vw;
    height: 88vh;
    max-width: 800px;
    max-height: 1000px;
  }
}

.mobile-screen-container {
  width: 12.5vw;
  height: 60vh;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  font-family: 'Arial', sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  .connect-message-bubble.rightText {
    position: relative;
    max-width: 255px;
    margin-bottom: 5px;
    padding: 10px 20px;
    line-height: 24px;
    word-wrap: break-word;
    border-radius: 25px;
    align-self: flex-end;
    color: white;
    background: #0b93f6;

    &:before {
      width: 20px;
      right: -7px;
      background-color: #0b93f6;
      border-bottom-left-radius: 16px 14px;
    }

    &:after {
      width: 26px;
      background-color: #fff;
      right: -26px;
      border-bottom-left-radius: 10px;
    }

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      height: 25px;
      content: '';
    }
  }
  .connect-message-bubble.leftText {
    position: relative;
    max-width: 255px;
    margin-bottom: 5px;
    padding: 10px 20px;
    line-height: 24px;
    word-wrap: break-word;
    border-radius: 25px;
    align-self: flex-start;
    color: black;
    background: #e5e5ea;
    &:before {
      width: 20px;
      left: -7px;
      background-color: #e5e5ea;
      border-bottom-right-radius: 16px 14px;
    }

    &:after {
      width: 26px;
      background-color: #fff;
      left: -26px;
      border-bottom-right-radius: 10px;
    }

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      height: 25px;
      content: '';
    }
  }
}

@media (min-width: 768px) {
  .mobile-screen-container {
    width: 15.5vw;
    height: 70vh;
    max-width: 500px;
    max-height: 700px;
  }
}

@media (min-width: 1024px) {
  .mobile-screen-container {
    width: 20.5vw;
    height: 78vh;
    max-width: 700px;
    max-height: 900px;
  }
}

.ant-popover.tooltip-class.ant-popover-placement-topLeft {
  left: 150px !important;
  top: 2950.4px !important;
}

.tooltip-class {
  position: absolute;
  .ant-popover-inner {
    background-color: transparent;
    box-shadow: none;
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 10px 10px 5px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  align-self: flex-start;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  .left-icon {
    color: #0b93f6;
    font-size: 28px;
    font-weight: bold;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: #d0d0d0;
  border-radius: 50%;
  margin-right: 10px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-right: 11px;
}

.phone-number {
  font-size: 12px;
  color: #333;
  padding-top: 5px;
}

.message-container {
  padding: 3px 0px 10px 0px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffff;
}

.timestamp {
  font-size: 12px;
  color: #888;
  margin-bottom: 5px;
}

.message-box {
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  background-color: #fff;
  padding: 0px 5px 5px 5px;
  border-radius: 8px;
  width: 95%;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  background-color: #ffff;
  align-self: flex-end;
  width: 100%;
  justify-content: center;
}

.input {
  flex: 1;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  outline: none;
  box-shadow: none;
}
.input-button-wrapper {
  .ant-input:focus {
    box-shadow: none;
    border: 0 !important;
  }
}

.send-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}
