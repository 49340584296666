.create-template.drawer > .ant-drawer-title {
  height: 24px;
  width: 111px;
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.create-template.drawer > .ant-drawer-body > div {
  margin-top: 20px;
}

button.create-template {
  height: 32px;
  border-radius: 20px;
}

.create-template.template-name {
  height: 40px;
  width: 400px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  border: 0px;
  border-bottom: 2px dashed #a8b7c7;
}

button.create-template.test {
  border: 1px solid #dadbdd;
}

.create-template.subject-line {
  height: 40px;
  width: 100%;
}

.create-template.body {
  margin-top: 25px;
}

.create-template.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: '#fff';
  border-radius: 0px 0px 4px 4px;
}

button.create-template.cancel,
button.create-template.save {
  margin-right: 8px;
  border: 1px solid #dadbdd;
}
