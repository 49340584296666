.reactivateArya {
  position: relative;
  z-index: 1111;
  .reactivateAryaButton {
    border-color: #1890ff;
    border-radius: 20px;
    background-color: #e6f7ff;
    cursor: pointer;

    .reactivateAryaButtonText {
      color: #1890ff;
      font-size: 14px;
      font-weight: 500;
    }

    &.disabled {
      border-color: #d9d9d9;
      cursor: not-allowed;
      background-color: rgba(7, 16, 26, 0.05);
      .reactivateAryaButtonText {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .draggableReactivateButton {
    background-color: #007bff;
    height: 45px;
    display: flex;
    gap: 4px;
    align-items: center;
    flex-direction: row;
    .reactivateAryaButtonText {
      color: #ffff;
    }
    &.disabled {
      display: flex !important;
      gap: 4px;
      border-color: #b4b7ba;
      background-color: #d9d9d9;
      cursor: not-allowed;
      .reactivateAryaButtonText {
        .draggableReactivateButtonText {
          padding-top: 3px;
          font-size: 14px;
          color: #ffff;
        }
      }
      :global .ant-btn[disabled] {
        background-color: #d9d9d9;
      }
      .infoIcon {
        padding-left: 8px;
      }
    }
  }
}
