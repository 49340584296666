.candidateCardContainer {
  position: relative;
  .blurCandidateCardWrapper {
    opacity: 0.3;
    background-color: #fafafa;
    border-radius: 24px !important;
  }
  .initialBlurCandidateCardWrapper {
    opacity: 0.6;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.98);
    background-color: rgb(250, 250, 250);
    border-radius: 24px !important;
  }
  .candidateCardWrapper {
    border: 1px solid rgba(7, 16, 26, 0.15);
    border-radius: 16px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.18);
    cursor: pointer;
    .candidateCheckboxAndInfoWrapper {
      display: flex;
      gap: 16px;
      padding: 8px 24px 0px;
      position: relative;
    }
  }
  .duplicateCandidateCard {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.18);
    background: #eceff5;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .collapsedCandidateCardWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    display: flex;
    margin-top: 0px;
    justify-content: center;
    align-items: center;
    color: #51575e;
    font-size: 16px;
    opacity: 1;
    font-weight: 500;
    border-radius: 24px !important;
  }
}
