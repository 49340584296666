.template-types {
  margin-top: 10px;
}

.template-type {
  height: 40px;
  width: 208px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.eamiltemplate-leftmenu {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: #13c26b;
    font-size: 14px;
    font-weight: 500;
  }
}
