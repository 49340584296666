.connect-notification {
  display: flex;
  width: 360px;
  justify-content: space-around;
  border-bottom: 1px solid #e6eaee;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }

  .icon {
    margin-right: 10px;
  }

  .content {
    width: 280px;
    color: #81878e;
    font-family: Roboto, sans-serif;
    font-size: 13px;

    .ant-badge {
      width: 100%;

      .ant-badge-dot {
        background-color: #13c26b;
      }
    }

    .sender-name,
    .consent-type {
      color: rgba(7, 16, 26, 0.9);
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    .message {
      color: rgba(7, 16, 26, 0.7);
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .since {
      color: rgba(7, 16, 26, 0.5);
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.candidate-opt-image {
  width: 24px;
  height: 24px;
  color: #13c26b;
}

.candidate-opt-image-wrapper {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: rgba(19, 194, 107, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}
