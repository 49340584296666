.header {
  margin-top: 50px;
  align-items: center;
  margin-bottom: 18px;
}

.headerInfo {
  display: flex;
  align-items: center;
}

.headerTitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(7, 16, 26, 0.9);
}

.headerDescription {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  line-height: 24px;
}

.treeMap {
  margin-bottom: 50px;
}

.empty {
  text-align: left;
  margin-left: 20px;
}

.selectComponent {
  z-index: 20;
}
