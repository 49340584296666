.searchCriteriaSelect {
  :global .ant-select-selection {
    border-radius: 16px;
  }
  :global .ant-select-selection__rendered {
    margin-right: 0px;
  }
}

.searchCriteriaSelectPlaceholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
