.remoteJobInput {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.remoteJobInfo {
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.locationWrapper {
  display: flex;
  gap: 15px;
  .country {
    width: 160px;
  }
  .location {
    width: 259px;
  }
  .zipcode {
    width: 259px;
  }
  .remoteJob {
    width: 76px;
    height: 22px;
  }
}
