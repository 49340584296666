.enhancedFetchContactButton {
  border: 1px solid #dadbdd;
  border-radius: 100px;
  font-size: 14px;
  line-height: 16px;
  color: #13c26b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 7px 10px;
  gap: 6px;
  font-weight: 400;
}
.disabledFetchContact {
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}
