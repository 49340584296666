.drawer-tooltip {
  z-index: 2700;
}

.candidateAssignedStatusWrapper {
  max-width: 100%;
  .candidate360AssignedStatusText {
    margin-top: 0px !important;
    color: #ffffff;
    font-size: 12px;
    background-color: #51575e;
    line-height: normal;
    padding: 6px 24px;
    border-radius: 0;
    border-bottom-right-radius: 10px;
  }
}

.candidateAssignedStatusText {
  border: 1px solid #dadbdd;
  border-radius: 11px;
  background-color: #f2f3f3;
  padding: 0px 8px;
  color: rgba(7, 16, 26, 0.65);
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-right: 4px;
  margin-top: 6px;
}
