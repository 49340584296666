.NodeWrapper {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
}

.NodeInnerWrapper {
  min-width: 200px;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px 20px 5px 20px;
  text-align: center;
  border: 1px solid #dadbdd;
  border-radius: 6px;
  cursor: grab;

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  > .closeIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    > svg {
      color: #83878c;
    }
  }
  &.highlight {
    border: 3px solid #13c26b;
  }
}

.EmptyNodeInnerWrapper {
  width: 1px;
  height: 1px;
  background-color: var(--edges-stroke-color);
}

.NodeContent {
  display: flex;
  flex-direction: column;
}

.NodeIcon {
  font-size: 18px;
  padding: 4px;
}

.NodeTitle {
  font-size: 14px;
  font-weight: 500;
}

.NodeDesc {
  color: #51575e;
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  font-weight: 400;
}

.NodePort {
  &.react-flow__handle {
    border: 0;
    background-color: var(--edges-stroke-color);
  }

  // &[data-handlepos='bottom'] {
  //   width: 1px;
  //   height: 4px;
  //   background-color: var(--edges-stroke-color);
  // }
}

.TooltipNode {
  .ant-tooltip-inner {
    cursor: pointer;
    white-space: nowrap;
  }
}
