.experienceSection {
  flex: 1;
  padding-top: 25px;
  padding-bottom: 25px;

  :global {
    .ant-input-number {
      border: none !important;
      border-radius: 0px;
      box-shadow: none !important;
      border-bottom: 1px solid gainsboro !important;
    }
    .ant-select-focused {
      border-bottom: none !important;
    }
  }

  .experienceLabel {
    color: #1f2730;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .experienceRangeWrapper {
    display: flex;
    gap: 15px;
    margin-top: 5px;

    .experienceRangeLabel {
      color: #484b4f;
      font-size: 13px;
      font-weight: 500;
      margin-top: 5px;
    }

    .minMaxInput {
      width: 120px;
    }
  }
}
