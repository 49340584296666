.sourceFilter,
.sourceFilter:active {
  height: 32px;
  width: 32px;
}

.sourceFilter {
  &.unselected {
    opacity: 0.6;
    background: #fff;
  }
}