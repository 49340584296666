.close-icon {
  position: absolute;
  color: #919191;
  cursor: pointer;
  font-size: 16px;
  right: 10px;
  top: 30px;
}

.isPaidJob {
  margin-top: calc(var(--navbar-height) + 10px);

  .ant-select-selection__choice__remove {
    color: white;
  }

  .ant-select-selection__choice__content {
    color: white;
  }

  .ant-select-search__field__wrap {
    position: inherit;
    background-color: white;
  }

  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    border-radius: 11px;
    background-color: #13c26b;
  }

  .ant-select-selection__choice__remove:hover {
    color: white;
  }

  .form-div-class {
    padding-top: 40px !important;
  }
}

.dragger-class {
  height: 182px;
  width: 260px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  float: right;
}

.form-body {
  padding-right: 10%;
  padding-bottom: 25px;
  position: relative;

  .ant-radio-group {
    display: flex !important;
  }
  .ant-radio-group-outline {
    display: flex !important;
  }
}

.upload-text {
  height: 24px;
  width: 112px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.upload-format {
  height: 14px;
  width: 232px;
  color: #919191;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  line-height: 11px;
}

.job-resume-col {
  max-width: 250px;
}

#body-content .user-alert-wrapper-jobform {
  width: 76%;
  margin: 0 auto;
  padding-top: 76px;

  .ant-alert {
    margin-right: 15%;
  }
}

.job-form-upload-button {
  width: 193px;
  height: 104px;
  display: flex;
  gap: 9.5px;
  cursor: pointer;
  flex-direction: column;
  border: 1px #d9d9d9;
  text-align: center;
  padding: 20px 10px 20px 10px;
  border-radius: 4px;
  margin-top: 3px;
  background-color: #f3f3f4;

  .job-form-upload-icon {
    font-size: 35px;
    color: var(--arya-accent-color);
  }

  .txtcolor {
    color: #83878c;
  }
  .job-form-upload-hint-textcolor {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }
  .job-form-upload-text {
    color: var(--grey-70-color);
    font-size: 14px;
  }

  .job-form-upload-hint {
    color: #919191;
    font-size: 10px;
    line-height: 13px;
  }
}

.div-class {
  margin: 0 auto;
  width: 50%;
}

.tags-class,
.tags-class:focus,
.tags-class:hover,
.tags-class:active {
  box-shadow: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: text;
  position: relative;
}

#JobTitle,
#JobTitle:focus {
  color: #374251;
  font-family: Roboto, sans-serif;
  padding-top: 15px;
  padding-bottom: 15px;
  letter-spacing: 0.44px;
  line-height: 40px;
  overflow: auto;
  border-radius: 4px;
}

.first-image {
  width: 36px;
  height: 33px;
  border-radius: 50%;
  position: relative;
  padding: 0.5%;
  background-color: white;
  z-index: 20;
}

.next-images {
  width: 36px;
  height: 33px;
  border-radius: 50%;
  margin-left: -3%;
  position: relative;
  padding: 0.5%;
  background-color: white;
  z-index: 10;
}

.plus-icon {
  position: relative;
  font-size: 30px;
  float: right;
  color: #aaaaaa;
  bottom: -5px;
}

.plus-icon-div {
  position: relative;
  box-sizing: border-box;
  border: 1px dashed #cfd5db;
  border-radius: 50%;
  text-align: center;
  width: 35px;
  height: 35px;
  float: right;
}

.jobindussec {
  margin-bottom: 29px;
}

.synonyms,
.synonyms:focus {
  position: relative;

  outline-color: #ffffff;
}

.industry,
.nice-skills,
.must-skills {
  width: 793px;
  min-height: 40px;
}

.nice-skills > .ant-select-selection,
.nice-skills > .ant-select-selection:active {
  border: none;
  box-shadow: none;
}

.must-skills > .ant-select-selection,
.must-skills > .ant-select-selection:active {
  border: none;
  box-shadow: none;
}

.ant-input:focus {
  box-shadow: none;
}

#Description {
  min-height: 170px;
  border: 1px solid #cfd5db;
  border-radius: 4px;
  background-color: #ffffff;
  color: #4a4a4a;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 21px;
}

.form-div-class {
  width: 76%;
  margin: 0 auto;
  padding-top: 76px;
}

.zipcode-dropdown {
  z-index: 1070;

  .ant-dropdown-menu {
    max-height: 264px;
    overflow-y: auto;
  }
}

.dropdown-class-hidden {
  display: none;
}

.dropdown-class,
.dropdown-class-hidden {
  position: absolute;
  max-height: 169px;
  width: 595px;
  overflow: auto;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 2% 0% 2% 2%;
  z-index: 30;
}

.heading-class {
  height: 25px;
  color: #4a4a4a;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  padding-bottom: 4%;
}

.select-tag {
  background-color: #4a90e2;
  color: white;
  font-family: Roboto, sans-serif;
}

.unselect-tag {
  font-family: Roboto, sans-serif;
}

.wrapper {
  position: relative;
}

.wrapper:focus {
  outline: none;
}

// .submit-button {
//   position: relative;
//   float: right;
// }

.tags-component {
  padding-left: 10%;
}

.collaborators-jobstatus {
  margin-top: 35px;
}

.job-form-job-description {
  margin-bottom: 43px;
}

.jobcollaborators {
  .ant-form-item {
    margin-bottom: 15px;
  }
}

.job-form-job-description > .ant-row > .ant-form-item-control-wrapper > .ant-form-item-control {
  line-height: 25px; // to tremove ant default height
}

.job-form-job-Summary {
  overflow: auto;
  overflow-y: hidden;
  margin-bottom: 25px;

  :global {
    .ql-editor {
      min-height: calc((30vh - var(--navbar-height)) * 0.4);
      max-height: calc(30vh - var(--navbar-height));
      border-radius: 0 0 4px 4px;
    }
    .ql-picker-label {
      display: flex;
    }
  }
}

.job-form-job-description .ql-editor {
  min-height: calc((100vh - var(--navbar-height)) * 0.5);
  max-height: calc(100vh - var(--navbar-height));
  border-radius: 0px 0px 4px 4px;

  // border: 1px solid #ccc;
}

// .richEditor-job-description {
// min-height: 360px;
// max-height: calc(100vh - var(--navbar-height));
// border: 1px solid #ccc;
// cursor: 'text';
// padding: 10px;
// overflow-y: auto;
// }
.country-dropdown,
.location-dropdown {
  z-index: 1061;
}

.job-form-status {
  background-color: white;
  font-weight: 500;

  .ant-select-selection,
  .ant-select-selection:hover,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    display: flex;
    align-items: center;
    border: 1px solid #cfd5d8;
    background-color: white;
    height: 32px;
    width: 175px;
  }
}

.job-createfillter {
  .ant-input-number {
    width: 97%;
  }
}

.job-form-source-limit-slider {
  margin-top: 40px;
  width: 50%;
}

.job-form-sourcelimit-info {
  margin-left: 5px;
  color: rgba(7, 16, 26, 0.3);
  font-size: 20px;
}

.source-limit-slider {
  z-index: 100;
}

.job-config-container {
  margin-top: 36px;

  .connect-configuration {
    .ant-form-item-label label {
      font-size: 20px;
    }
  }
  .connect-config-container {
    display: flex;
    align-items: center;

    .connect-config {
      display: flex;
      align-items: center;
      margin-right: 24px;

      .connect-config-email-label,
      .connect-config-message-label {
        font-size: 14px;
        line-height: 22px;
        margin-right: 10px;
      }

      .connect-config-email-label {
        width: 116px;
      }

      .connect-config-message-label {
        widows: 104px;
      }

      .automation-status-tag {
        margin: 0px;
      }
    }

    .edit-config-button {
      height: 32px;
      vertical-align: middle;
      padding: 0px 12px;

      .edit-config-button-text {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
      }

      .job-setting-icon {
        font-size: 16px;
        margin-right: 8px;
      }
    }

    .edit-config-button:hover {
      .job-setting-icon {
        font-size: 16px;
        margin-right: 8px;
        color: var(--arya-accent-color);
      }
    }
  }
}

.job-synonyms-header {
  margin-top: 30px;
}

.job-form-label-header {
  margin-top: 24px;
}

.job-form-section-header {
  margin-top: 36px;
}
.job-form-section-header-div {
  display: flex;
  gap: 96px;
}
.tag-info-circle {
  margin-left: 4px;
  color: #919191;
}

.tag-warning-tooltip {
  .ant-tooltip-inner {
    width: 280px;
  }
}

.job-form-component-must-have,
.job-form-component-nice-have,
.job-form-component-qualification {
  margin-bottom: 20px;
}

.job-create-submit-draft-button {
  margin-right: 26%;
  display: flex;
  justify-content: flex-end;
  margin-left: 26%;
}

.job-create-draft {
  margin-right: 10px;
}

.job-create-draft-button {
  background-color: #ffffff;
  color: rgba(7, 16, 26, 0.7) !important;
  border: 1px solid #dadbdd !important;
  height: 32px;
  border-radius: 16px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.plan-selection-back-wrapper {
  margin-left: 6%;
  margin-top: 10px;
}

.plan-slection-back {
  color: #13c26b;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}

.job-create-component-create-job {
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.form-label-mandatory-mark {
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}

.apply-url-label-icon {
  margin-left: 4px;
  color: #919191;
}

.job-form-progress-step {
  width: 750px;
  margin-left: 6%;
  margin-top: 90px;
  padding: 20px 0px;
}

.job-form-location {
  display: flex;
  gap: 12px;
}

.remote-job-checkbox {
  margin-top: auto;
  margin-bottom: 8px;
}

.diversity-ack-info {
  .ant-tooltip-inner {
    width: 600px;
    display: flex;
    justify-content: center;
  }
}

.diversity-tooltip-text {
  .ant-tooltip-inner {
    color: rgba(0, 0, 0, 0.65);
    background: white;
    width: 500px;
    display: flex;
    justify-content: center;
  }
}
