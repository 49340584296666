.modalContainer {
  display: flex;

  :global {
    .ant-btn:first-child {
      line-height: 1;
    }
  }
}

.iconDiv {
  margin-right: 16px;
  font-size: 22px;
  color: #ffcc00;
}

.message {
  margin-bottom: 12px;
  margin-top: 10px;
}

.actionButton {
  float: right;
  margin-right: 5px;
}

.portalsList {
  padding: 12px;
  background-color: #f8f8f8;
  margin-bottom: 15px;
  margin-right: 10px;
}

.portalsListItem {
  padding: 12px;

  :global {
    .ant-btn {
      float: right;
      line-height: 1;
    }
  }
}
