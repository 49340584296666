// .portal-button {

// }

.ant-btn.portal,
.ant-btn.portal-tick,
.ant-btn.portal:hover,
.ant-btn.portal-tick:hover {
  width: 90px;
  height: 74px;
  display: inline-block;
  position: relative;
  margin: 8px 15px 8px 0px;
  border-radius: 4px;
  padding-top: 1%;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  border: 1px solid #e6eaee;
  vertical-align: middle;
}

// .portal,
// .portal:focus {
//   border-color: white;
// }
.ant-btn.portal:hover {
  box-shadow: 0 2px 6px 0 rgba(7, 16, 26, 0.1);
}

.ant-btn.portal-tick,
.ant-btn.portal-tick:hover,
.ant-btn.portal-tick:focus {
  border: 2px solid #01cd75;
}

.portal-name {
  text-align: center;
  font-size: 11px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  color: #647182;
  line-height: 14px;
}

.portal-image {
  margin-bottom: 6px;

  i > svg {
    font-size: 30px;
  }
}

.ant-btn.check-circle {
  visibility: hidden;
}

.ant-btn.check-circle,
.ant-btn.check-circle-tick {
  top: -5px;
  right: -5px;
  color: #01cd75;
  // background-color: white;
  position: absolute;
}

.ant-btn.portal:hover,
.ant-btn.portal-tick:hover,
.ant-btn.check-circle:hover .ant-btn.check-circle-tick:hover {
  cursor: pointer;
}

.ant-btn.portal-tick > .anticon {
  line-height: 0;
  font-size: 16px;
}
