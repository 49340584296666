.candidateFilterButton {
  height: 32px;
  width: 79px;
  border-radius: 20px;
  margin-left: 10px;
  line-height: 22px;
  font-size: 14px;
}

.candidateFilterApplyButton {
  background-color: #13c26b;
  border: none;
}

.candidateFilterClearButton {
  border: 1px solid #dadbdd;
  background-color: #ffffff;
}

.candidateFilterApplyClear {
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidateFilterAggregation {
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: 54px;
  padding: 0 16px;
}

.candidateFilterAggregationTitle {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidate-icon-filter-button {
  cursor: pointer;
  margin-left: 16px;
}

.candidateFilterPopoverContentHeader {
  display: inline;
  height: 22px;
  width: 90px;
  //justify-content : flex-start;
  color: black;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
}

.candidateFilterPopover {
  height: 435px;
  width: 340px;
  z-index: 100;
}

.candidateFilterPopoverContent {
  width: 293px;
  height: 250px;
  padding-bottom: 16px;
}

.tagFilterPopoverCheckboxes {
  padding: 0px 2px 3px 0px;
  height: 260px;
  overflow: scroll;
  font-weight: 500;
  .ant-checkbox-input {
    box-sizing: border-box;
    height: 16px;
    width: 16px;
    border: 1px solid #dadbdd;
    border-radius: 2px;
    background-color: #ffffff;
  }
}

.aryaSearchCheckboxGroup {
  :global .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .ant-checkbox-wrapper {
      margin-left: 0px !important;
    }
  }
}

.aryaFilterFormItem {
  font-weight: 500;
}
