.jobPublishContainer {
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 30px;
  :global {
    .billing-info-card {
      margin-top: -30px;
      margin-left: -90px;
    }
  }
}

.jobPublishComponent {
  margin-bottom: 10px;
}

.backToJobWrapper {
  display: flex;
}

.backToJob {
  color: var(--arya-accent-color);
  cursor: pointer;
  height: 22px;
}

.selectPortalHeading {
  font-size: 20px;
  padding-top: 6px;
}

.advertisePortalHeading {
  color: rgba(7, 16, 26, 0.9);
  font-size: 24px;
}

.selectPortal {
  width: 100%;
}

.jobCredits {
  min-width: 132px;
  border-left: 1px solid #dadbdd;
  height: 22px;
  margin-left: 8px;
  padding-left: 8px;
  align-content: center;
}

.advertiseHeadingBtn {
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  text-align: center;
}

.advertiseHeadingCols {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
