.offerBannerWrapper {
  background-color: #e5f7ff;
  width: 100%;
  height: 34px;
  .offerBannerContents {
    display: flex;
    justify-content: center;
    .offerBannerLeftIcons {
      width: 100px;
      .offerBannerRedStarOne {
        position: relative;
        bottom: 15px;
      }
      .offerBannerRedTreeOne {
        color: #b50000;
        width: 22px;
        height: 32px;
        position: relative;
        top: 24px;
      }
      .offerBannerGreenStarOne {
        position: relative;
        top: 7px;
      }
      .offerBannerOrangeCircleOne {
        color: #ff7e00;
        width: 4px;
        height: 4px;
        position: relative;
        bottom: 23px;
      }
      .offerBannerGreenGiftOne {
        color: #13c26b;
        width: 17px;
        height: 24px;
        position: relative;
        top: 5px;
      }
      .offerBannerGreenCircleOne {
        position: relative;
        bottom: 20px;
        left: 5px;
        color: #13c26b;
        width: 4px;
        height: 4px;
      }
      .offerBannerOrangeStarOne {
        position: relative;
        left: 10px;
      }
      .offerBannerRedStarSecond {
        position: relative;
        left: 5px;
        bottom: 8px;
      }
      .offerBannerRedLineOne {
        height: 26px;
        width: 2px;
        color: #b50000;
        position: relative;
        right: 15px;
        bottom: 7px;
      }
      .offerBannerGreenLineOne {
        position: relative;
        height: 17px;
        width: 2px;
        color: #13c26b;
        right: 10px;
        bottom: 7px;
      }
      .offerBannerRedLineTwo {
        position: relative;
        height: 15px;
        width: 2px;
        color: #b50000;
        bottom: 12px;
        right: 5.4px;
      }
    }
    .offerBannerText {
      height: 40px;
      color: #b50000;
      font-family: Roboto, sans-serif;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 2.5;
    }
    .offerBannerRightIcons {
      width: 100px;
      .offerBannerGreenTreeOne {
        color: #13c26b;
        width: 22px;
        height: 32px;
        position: relative;
        top: 5px;
      }
      .offerBannerGreenCircleTwo {
        color: #13c26b;
        height: 4px;
        width: 4px;
        position: relative;
        right: 7px;
        bottom: 23px;
      }
      .offerBannerOrangeStarTwo {
        position: relative;
        right: 5px;
        bottom: 4px;
      }
      .offerBannerRedGiftTwo {
        color: #b50000;
        width: 17px;
        height: 24px;
        position: relative;
        top: 16px;
        left: 3px;
      }
      .offerBannerGreenStarTwo {
        position: relative;
        bottom: 23px;
        right: 2px;
      }
      .offerBannerRedStarThree {
        position: relative;
        top: 7px;
        left: 2px;
      }
      .offerBannerGreenStarThree {
        position: relative;
        bottom: 7px;
      }
      .offerBannerOrangeCircleTwo {
        height: 3px;
        width: 3px;
        color: #ff7e00;
        position: relative;
        left: 5px;
        bottom: 22px;
      }
      .offerBannerGreenLineTwo {
        height: 10px;
        width: 2px;
        color: #13c26b;
        position: relative;
        right: 16px;
        bottom: 7px;
      }
      .offerBannerRedLineThree {
        height: 29px;
        width: 2px;
        color: #b50000;
        position: relative;
        right: 7px;
        bottom: 6px;
      }
      .offerBannerGreenLineThree {
        height: 17px;
        width: 2px;
        color: #13c26b;
        position: relative;
        right: 10px;
        bottom: 8px;
      }
    }
  }
}
