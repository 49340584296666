.AutomationCanvas {
  --edges-stroke-color: #dadbdd;
  width: 100%;
  border-bottom: 1px solid #dadbdd;
  cursor: grab;
  height: calc(100vh - 116px);
  &.atsAutomationCanvas {
    height: calc(100vh - 62px);
  }
}

.Controls {
  top: 100px;
  right: 20px;
  left: auto;
  bottom: auto;
}
