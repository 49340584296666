.order-cancellation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 66px;

  .order-cancellation-candidate-count {
    height: 24px;
    color: rgba(7, 16, 26, 0.9);
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: 4px;
  }

  .order-cancellation-message,
  .order-cancellation-warning {
    color: rgba(7, 16, 26, 0.7);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    width: 446px;
  }

  .order-cancellation-message {
    margin-bottom: 24px;
  }

  .cancel-view-order-icon-wrapper {
    margin: 64px 0px 64px 0px;
  }

  .order-cancellation-buttons {
    width: 316px;
    height: 40px;
    display: flex;
    justify-content: space-between;

    .order-cancellation-cancel-button,
    .order-cancellation-view-button {
      width: 146px;
      height: 40px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
