@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

:root {
  --app-width: 90%;
  --app-max-width: 1366px;
  --navbar-height: 64px;
  --footer-height: 64px;
  --body-content-padding: 50px 50px 0px 50px;
  --arya-accent-color: #13c26b;
  --arya-error-color: #f5222d;
  --unactivated-pipeline-color: #e6eaee;
  --color-sourced: #cce5ff;
  --color-shortlisted: #e1dcf9;
  --color-connected: #d0f3e1;
  --color-applied: #ffdec2;
  --color-engaged: #feefd0;
  --color-sourced-activated: #007bff;
  --color-shortlisted-activated: #6f50dd;
  --color-connected-activated: #13c26b;
  --color-applied-activated: #ff7300;
  --color-engaged-activated: #faad14;
  --color-invalid-spam: #f5222d;
  --secondary-black-color: #1f2730;
  --primary-black-color: #07101a;
  --primary-blue-color: #4293f4;
  --secondary-orange-color: #FAAD14​;
  --default-font-weight: 400;
  --default-font-size: 14px;
  --grey-70-color: #51575e;
  --arya-pipeline-color: #51575e;
  font-size: 1.33vw;
}

*,
*::after,
*::before {
  outline: none;
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
}

html,
body {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto', sans-serif;
  display: flow-root;
}

#root {
  width: 100%;
  background-color: #ffffff;
}

#body-content {
  width: 100%;
  margin: auto;
  margin-top: var(--navbar-height);
  min-height: calc(100vh - var(--navbar-height) - var(--footer-height));

  > * {
    width: var(--app-width);
    max-width: var(--app-max-width);
    margin: auto;
  }

  > .body-content-error {
    width: 100%;
    max-width: 100%;
  }
}
#body-content.hide-body-top {
  margin-top: 0;
}
#body-content.show-body-top {
  margin-top: var(--navbar-height);
}

#body-content.downtime-announcement-top {
  margin-top: calc(var(--navbar-height) + 46px);
}

#body-content.impersonate-banner {
  margin-top: calc(var(--navbar-height) + 28px);
}

#body-content-loading {
  max-width: var(--app-width);
  margin: auto;
  margin-top: var(--navbar-height);
  padding: var(--body-content-padding);
  min-height: calc(100vh - var(--navbar-height) - var(--footer-height));
}

#messages-footer {
  // width: 100%;
  position: fixed;
  bottom: 0;
  right: 4%;
  z-index: 2000;

  #message-popup-container {
    width: 100%;
    margin: auto;
  }
}

.popup-message-dropdown {
  z-index: 2000;
}

a,
a:focus,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 20px;
}

body {
  ::-webkit-scrollbar {
    width: 6px;
  }

  // ::-webkit-scrollbar-track {
  //   padding-right: 2px;
  //   border-radius: 10px;
  // }

  *::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 20px;
  }
}

.arya-logo {
  text-align: center;
  padding-top: 20px;
  margin-bottom: 20px;
  margin-left: -50px;
}

.fixed-popover-menu {
  position: fixed;
  z-index: 2010;

  .ant-popover-content > .ant-popover-arrow {
    margin-right: 16px;
  }
}

.ant-message {
  z-index: 5000;
}

// .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
//   color: rgba(7, 16, 26, 50) !important;
// }
// this will highlight the text on hover for dropdown items
