.emailAdditionComponent {
  border: 1px solid #dadbdd;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 16px;
  padding: 22px 24px;
}

.clientEmailconfiguration {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: bold;
}

.lineBorder {
  border: 1px solid #dadbdd;
  margin-top: 12px;
}

.addEmailAddress {
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  font-weight: bold;
  margin-top: 15px;
}

.enterTheNameAndEmail {
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
}

.addEmailSectionForm {
  margin-top: 12px;
  display: inline-flex;
  gap: 10px;
}

.addEmailButton {
  margin-top: 25px;
}
