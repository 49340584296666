.shared-avatars {
  display: flex;
  // flex-direction: row-reverse;
  margin-left: 12px;
}

.shared-user-avatar {
  margin-left: -12px;
  cursor: default;

  &:last-child {
    margin-right: 15px;
  }
}

.user-bubbles {
  display: flex;
  align-items: center;
  .ant-avatar {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 10px;
  }
}

.share-button {
  cursor: pointer;
  i > svg {
    width: 15.65px;
    height: 14.96px;
  }
}
