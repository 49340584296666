.candidateSourceAndNameWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  .candidateSourceAndName {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #07101a;
    display: flex;
    gap: 5px;
    align-items: center;
    .overlappingSourceIcons {
      display: flex;
      .sourceImageContainer {
        z-index: 2;
        background-color: white;
        border-radius: 50px;
      }
      .overlappingSourceImage {
        position: relative;
        right: 4px;
      }
    }
  }
}
