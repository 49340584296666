.wrapper {
  padding: 40px 100px 120px 100px;
}

.jobSummary {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  padding-bottom: 20px;
}

.container {
  padding: 40px 80px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  .title {
    color: #36cf7d;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-weight: 500;
  }
  .subTitle {
    display: block;
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dde3e9;
  }
  .editInsights {
    display: flex;
    justify-content: space-between;
    .editInsightsBtn {
      border: 1px solid rgba(7, 16, 26, 0.15);
      border-radius: 4px;
      background-color: rgba(7, 16, 26, 0.05);
      margin-top: 12px;
      color: black;
    }
  }

  .editTitle {
    display: flex;
    justify-content: space-between;
    .editTitleBtn {
      border: 1px solid rgba(7, 16, 26, 0.15);
      border-radius: 4px;
      background-color: rgba(7, 16, 26, 0.05);
      color: black;
    }
  }
}

.item {
  padding: 12px 0px;
  max-width: 100%;
  word-break: break-word;
  .itemTitle {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  .itemSubTitle {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    display: block;
  }
}
