.emailDropdown {
  display: flex;

  .clearEmailText {
    text-decoration: underline;
    color: #b4b7ba;
  }
}

.selectedText {
  text-decoration: underline;
}

.previewText {
  text-decoration: underline;
  color: #bcbdbe;
}

.workflowEmailDrawerContainer {
  width: 100%;
}

.editTemplateFooter {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
  max-width: 100%;
  justify-content: flex-end;
}

.emailFooterPlacement {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}
