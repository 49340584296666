.connect-get-contact {
  border: 1px solid #dadbdd;
  border-radius: 100px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
  width: 116px;
  height: 28px;
  color: rgba(7, 16, 26, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px auto;
}

.connect-get-contact.disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}
