.create-template.email.drawer {
  .row-one {
    display: flex;
    justify-content: space-between;
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;

    .ant-form-item {
      margin: 0px;
    }
  }
}

.test-email-wrapper {
  display: flex;
}

.test-email-text {
  margin-left: 10px;
}

.success-icon {
  color: #13c26b;
}

.failed-icon {
  color: #ff1a1a;
}

.subject-merge-tag-wrapper {
  display: flex;
  margin-top: 10px;
  margin-bottom: 4px;
  line-height: normal;
  color: var(--secondary-black-color);
}
#preheader-container-wrapper {
  margin-bottom: 0px;
}
.create-template-merge-tag-button {
  cursor: pointer;
  padding: 0px 0px 10px 12px;
  color: var(--arya-accent-color);
}
.create-email-template-merge-tag-button {
  line-height: normal !important;
  padding: 0px 0px 0px 12px !important;
}
.create-template-merge-tag {
  margin-left: auto;
}

.candidate-360-view {
  .rich-text-editor {
    .ql-toolbar {
      .ql-mergetags {
        width: 20px;
        color: var(--arya-accent-color);
      }
    }
  }
}
.email-template-editor {
  .ql-editor {
    height: 240px;
    overflow: auto;
    // border: 1px solid #ccc;
  }

  .email-template-description-tag {
    color: var(--secondary-black-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 22px;
  }

  .email-template-preview {
    overflow: auto;
    border: 1px solid rgba(7, 16, 26, 0.15);
  }
}

.emailsubject {
  .ql-editor {
    height: 36px;
    overflow: hidden;
    white-space: nowrap;
    padding: 8px;
  }
}

.email-pre-header {
  .ql-editor {
    height: 36px;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    padding: 8px;
  }
}

.workflowEmailPreHeaderContainer {
  width: 100%;
}

#workflowEmailAddPreHeaderText {
  color: #13c26b;
  text-decoration: underline;
}

#workflowEmailAddPreHeaderButton {
  padding: 10px 0px 40px 0px;
}

.removeIconSectionConnectEmail {
  position: relative;
}

#connectEmailPreHeaderRemoveInAdvanceEditor {
  position: absolute;
  bottom: 1px;
  left: calc(100% + 4px);
}

#connectEmailPreHeaderRemoveInEditor {
  position: absolute;
  bottom: 1px;
  left: calc(100% + 4px);
}

#workflowEmailPreHeaderRemoveInAdvanceEditor {
  position: absolute;
  left: calc(100% + 4px);
  bottom: 1px;
}

#workflowEmailPreHeaderRemoveInEditor {
  position: absolute;
  bottom: 1px;
  left: calc(100% + 4px);
}
#workflowEmailPreHeaderRemoveIcon {
  font-size: 18px;
}

#workflowPreHeaderTooltipIcon {
  padding: 2px;
}
