.agentSearch {
  width: 148px;
  margin-bottom: 16px;
  input {
    border-radius: 20px;
    height: 26px;
  }
}

.checkboxItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-height: 300px;
  overflow-y: scroll;
  .checkboxDisplay {
    display: flex;
    flex-direction: column;
  }
  .checkboxItem {
    margin-left: 0 !important;
    display: flex;
    :global {
      .ant-checkbox {
        margin-top: 4px;
        height: 17px;
      }
    }
  }
}

.checkboxItemsContainer > * {
  color: #1f2730;
}
