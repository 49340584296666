.listSearch {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  .listsInputSearch {
    width: 232px;
    input {
      border-radius: 30px;
    }
  }
  .ant-input-suffix .listsInputSearch {
    z-index: 0;
  }
}
