.bulkAddition {
  display: flex;
  color: #647182;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-family: 'Roboto', sans-serif;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  .bulkIndicatorWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 13px;
  }
  .bulkStatusText {
    color: rgba(7, 16, 26, 0.5);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .info-icon{
      padding-left:2px;
    }
  }
}

.progress {
  :global {
  .ant-progress-inner {
    background-color: #b5f5cc;
  }}
}


.review {
  :global {
  .ant-progress-inner {
    background-color: #faad14 !important;
    opacity: 0.4;
  }
}
}
