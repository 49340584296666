.addTagContainer {
  width: 650px;

  :global {
    .ant-tag {
      border-radius: 10px;
    }
  }

  .tagList {
    position: relative;
    display: flex;
    margin-top: 15px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 7px;
  }

  .addTagDrawerFooter {
    text-align: right;
    position: absolute;
    border-top: 1px solid #e9e9e9;
    right: 0;
    bottom: 0;
    padding: 10px 16px;
    width: 100%;
    background: #fff;
  }
}
