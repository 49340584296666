.naukri-advanced-options {
  .salary-form-item-label {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    margin-bottom: 8px;
  }

  .salary-form-item {
    width: 280px;
    display: flex;
    justify-content: space-between;
  }

  .naukri-maximum-salary,
  .naukri-minimum-salary {
    width: 136px;
  }

  .popover-select {
    z-index: 2200;
  }

  .naukri-checkbox {
    font-size: 13px;
    display: flex;
    align-items: center;
    color: #1f2730;
  }
}
