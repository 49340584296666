mark {
  background-color: #ffff5a !important;
}
.scoreErrorIcon {
  font-size: 20px;
  color: #e22d12;
}

.scoreErrorPendingIcon {
  height: 24px;
}

.candidateTags {
  display: flex;
  position: absolute;
  right: 0;

  :global .publish-icon-wrapper {
    display: flex;
  }
}

.applicationReviewBtn {
  font-size: 12px;
  width: fit-content;
  height: fit-content;
  padding: 0px;
  border: 0px;

  > span {
    text-decoration: underline;
  }
}

.jobCardInfo {
  flex: 1;
  min-width: 0;
  position: relative;
  border-radius: 4px 0px 0px 4px;
}

.experience {
  font-size: 13px;
  padding-left: 14px;
  font-weight: 500;
  color: #1f2730;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 11px;
  border-right: 2px solid #dadbdd;
  width: 217px;
  > div {
    display: flex;
    width: 97%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.features {
  display: flex;
  flex-wrap: wrap;
  gap: 29px;
  padding-bottom: 15px;
  .jobMatchingLocation {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.jobCardWrapper {
  width: 100%;
  border-radius: 4px;
}

.jobCard {
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  position: relative;
  .jobCardActions {
    width: 48px;
    flex-shrink: 0;
    border-left: 1px solid rgba(7, 16, 26, 0.15);
    cursor: pointer;
  }
  .disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
}

.jobCardContent {
  padding: 16px 14px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.jobCardPrimaryContent {
  min-width: 0;
  flex: 3 3 0;
  cursor: pointer;
}

.jobCardPersonalInfo {
  display: flex;
  align-items: center;
}

.jobCardCandidateName {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  max-width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  gap: 4px;
  .matchingJobTitle {
    text-decoration: underline;
  }
}

.jobCardTitle {
  font-size: 14px;
  line-height: 22px;
  color: rgba(7, 16, 26, 0.7);
  max-width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  & > span {
    display: inline-block;
    max-width: 330px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.jobCardSecondaryContent {
  flex: 3 3 0;
  margin-right: 5px;
  margin-top: -2px;
  min-width: 0;
  margin-left: -18px;
  height: fit-content;
}

.jobCardLastUpdatedinfo {
  font-size: 12px;
  font-weight: 400;
  color: #83878c;
}

.jobCardSkills {
  font-size: 12px;
  line-height: 16px;
  margin: 4px 0;
  width: fit-content;
  max-height: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 0;
  font-weight: 500;
  .jobCardSkillsContent {
    display: inline-block;
    border-radius: 7px;
  }
  .jobMatchingSkillsEllipsedText {
    font-size: 20px;
    font-weight: bolder;
    margin-left: 5px;
  }
}

.jobCardSkills :after {
  animation: 0s; // to remove ant default animation
}

.jobCardSkillsTooltip {
  max-width: 340px;

  .ant-tooltip-inner {
    max-height: 300px;
    overflow-y: auto;
  }
}

.jobCardSkillTag {
  border-radius: 20px;
  background-color: rgba(7, 16, 26, 0.05);
  margin: 4px 1px 4px 5px;
  border: none;
  line-height: 20px;
  mark {
    color: rgba(0, 0, 0, 0.65);
  }
}
.highlightJobCardSkillTag {
  border-radius: 20px;
  background-color: rgba(255, 164, 41, 0.19);
  margin: 4px 1px 4px 5px;
  border: none;
  line-height: 20px;
}

.candidateShortListRejectTooltip {
  background-color: #ffffff;
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: #ffffff !important;
    }
  }
}

.jobCardSelectJob {
  border-bottom: 1px solid rgba(7, 16, 26, 0.15);
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jobCardCandidateSelect {
  width: 100%;
  flex-direction: column;
  display: flex;
  height: 56%;
  align-items: center;
  margin-top: 20px;
  color: #13c26b;
  &.disabled {
    color: grey;
    opacity: 0.5;
  }
 
}

.addButtonLoaderIconDiv {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.addButtonLoaderIcon {
  font-size: 20px;
}

.shortlistedAppliedLinks{
  display: flex;
  position: absolute;
  top: 0px;
  right: 20px;
  gap: 10px;
}

