.candidate360AtsNotesWrapper {
  height: calc(100vh - 288px) !important;
  overflow-y: auto;
}

.extendedCandidate360AtsNotesView {
  height: calc(100vh - 118px) !important;
}

.extendedCandidate360AtsNotesView > div {
  min-height: calc(100vh - 115px) !important;
}
