.creditsExhaustedImageWrapper {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: #fff1f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeIcon {
  font-size: 20px;
  color: red;
}
