.searchCriteriaWrapper {
  margin-top: 20px;
  :global .ant-form-explain {
    margin-top: 5px;
  }
  .experienceLastUpdatedWrapper {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid #e8e8e8;
  }
}

.inputLabels {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.selectDropdown {
  width: 100%;
  border-radius: 4px;

  :global .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    border-radius: 11px;

    .supported-by-portals {
      display: none;
    }
  }

  :global .ant-select-selection {
    overflow-x: hidden;
    max-height: 95px;
  }
}

.workStatusSection {
  flex: 1;
  padding-bottom: 25px;

  .experienceRangeLabel {
    color: #484b4f;
    font-size: 13px;
    font-weight: 500;
  }
}

.workStatusDivider {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin-top: 25px;
}
