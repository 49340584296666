.segregate {
  display: flex;
  flex-direction: row;
}

.segregate:hover .segregateButton {
  opacity: 0.5;
}

.segregate:hover .segregateButton:hover {
  opacity: 1;
}
