.addButton {
  border: none;

  i {
    font-size: 24px;
  }
}

.multiLocationLabelsWrapper {
  margin-bottom: 4px;
  font-weight: var(--default-font-weight);

  :global .ant-col {
    padding-left: 4px;
  }
  .label {
    font-size: var(--default-font-size);
    color: var(--secondary-black-color);
    line-height: 22px;
  }
}

.primaryLocation{
  min-width: fit-content;
  margin-left: 17px;
  align-items: center !important;
  margin-top: 0.15rem;
}
