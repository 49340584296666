.addEmailAddressWrapper {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  .addEmailAddressIcon {
    color: #13c26b;
  }
}
