.zipcode-dropdown {
  z-index: 3000;
  // .ant-dropdown-menu {
  //   max-height: 264px;
  //   overflow-y: auto;
  // }
}

.dropdown-class-hidden {
  display: none;
}

.dropdown-class,
.dropdown-class-hidden {
  position: absolute;
  max-height: 169px;
  width: 595px;
  overflow: auto;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 2% 0% 2% 2%;
  z-index: 30;
}
