.call-history-item {
  width: 100%;
  background-color: #ffffff;
  height: 90px;
  & ~ .call-history-item {
    border-top: 1px solid #e6eaee;
  }

  &:last-of-type {
    border-bottom: 1px solid #e6eaee;
  }
  & [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .call-history-content-top {
    display: flex;
    width: 100%;
    height: 80px;
    left: 358px;
    font-size: 14px;
    font-weight: 400;
    color: #07101a;
  }
  .conversation-status {
    padding-top: 10px;
    margin-bottom: 10px;
  }
  .duration {
    position: relative;
    display: flex;
    left: 24px;
    width: fit-content;
    background-color: #f2f3f3;
    border-radius: 20px;
    height: 24px;
    align-items: center;
    justify-content: center;
  }
  .called-by {
    height: 80px !important;
    padding-top: 15px;
  }
  .phone-number {
    min-width: 150px;
    max-width: 200px;
  }
}

.call-history-item:hover {
  box-shadow: 0 2px 8px -2px rgba(32, 38, 50, 0.3);
}

.call-history-item ~ .call-history-item:hover {
  border-top: 1px solid transparent;
}
