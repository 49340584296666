.reportQueriesListItem {
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & ~ .reportQueriesListItem {
    border-top: 1px solid #e6eaee;
  }

  &:last-of-type {
    border-bottom: 1px solid #e6eaee;
  }
}

.reportQueriesListItem:hover {
  box-shadow: 0 2px 8px -2px rgba(32, 38, 50, 0.3);
}

.reportQueriesListItem ~ .reportQueriesListItem:hover {
  border-top: 1px solid transparent;
}

.queryName {
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.queryType {
  width: 30%;
}

.listItemActions {
  width: 20%;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
}
