.subSegmentDropdown {
  :global {
    .ant-select-selection,
    .ant-select-selection:hover,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      width: 180px;
      border-radius: 20px;
    }
  }
}
.deleteCandidateTextContainer {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: flex-start;
}
.deleteOptionContainer {
  padding-left: 24px;
}
.deleteIconContainer {
  padding-top: 1px;
}

.noteStyle {
  position: absolute;
  bottom: 10px;
  left: 18px;
  width: 285px;
  display: flex;
  gap: 2px;
}

.subSegmentList {
  .listsInputSearch {
    input {
      border-radius: 30px;
    }
  }

  .filterContainer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    align-items: center;
    margin: 16px 0;
  }

  .subSegmentTable {
    display: block;
    th {
      color: #51575ed8 !important;
      background: #f3f3f4 !important;
      padding: 10px 16px !important;
    }
  }

  .tableItemCreatedBy {
    b {
      font-size: 16px;
    }
    div {
      color: #83878c;
    }
  }
}

.subsegmentRow {
  cursor: pointer;
  background-color: white;
}
.subsegmentRow:hover {
  :global {
    .ant-table-row-cell-break-word {
      background: unset !important;
    }
  }
}

.matchingCandidateCheckboxContainer {
  margin-bottom: 20px;
  span {
    color: rgb(31, 39, 48);
    font-size: 15px;
  }
}
.ownerSelect {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  label {
    font-weight: 400;
    color: #000000;
  }
  :global {
    .ant-form-item-label {
      margin-right: 8px;
    }
    .ant-select-selection-selected-value {
      color: #1f2730;
    }
  }
}

.paginationContainer {
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-bottom: 20px;
}

.backTolist {
  span {
    text-decoration: underline;
  }
}

.listNameWrapper {
  display: flex;
  gap: 10px;
  margin-left: 15px;
  .listName {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    max-width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .edit {
    text-decoration: underline;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 2px;
    font-size: 24px;
    cursor: pointer;
  }
}

.emptyScreen {
  :global {
    .ant-empty {
      height: calc(100vh - 380px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      :global {
        .ant-empty-image {
          height: 60px;
        }
      }
    }
  }
}

.addContainer {
  .includedCheckbox {
    display: flex;
    margin-bottom: 20px;
    margin-left: 16px;
    * {
      color: #1f2730;
    }
  }
}

.addNewSubsegment {
  padding: 20px;
  margin-bottom: 20px;
  span {
    text-decoration: underline;
  }
}
.subsegmentOption {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #dadbdd;
  padding: 10px 10px;
  .subsegmentCandidateCount {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
.subsegmentOption:last-child {
  border-bottom: none;
}
.subsegmentSelectContainer {
  display: block;
  :global {
    .ant-select-selection-selected-value {
      display: flex !important;
      opacity: 1;
      justify-content: space-between;
      width: 100%;
    }
  }
}
.emptySubsegment > svg {
  height: 40px;
}

.emptySubsegmentText {
  margin: 30px;
}
.createSubDescription {
  resize: none;
}

.textAreaValidation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  span {
    color: #f5222d;
    margin-right: auto;
  }
  p {
    margin-bottom: 0;
  }
}

.alertMessageContainer {
  :global {
    .ant-alert-warning {
      border: 0;
      background-color: transparent;
    }
  }
}
