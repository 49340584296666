.companyTableContainer {
  margin-bottom: 24px;
  th:nth-child(1) {
    min-width: 360px;
  }
  th {
    background: #eff4ff !important;
    text-transform: capitalize;
  }
  tr:hover > td {
    background: unset !important;
  }
  tr > td {
    background: unset;
  }
}

.greyColumn {
  background-color: #f8f8f8;
}

.companyInfoContainer {
  position: relative;
  display: flex;
  width: 81%;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  .blueBox {
    background-color: #bae7ff;
    padding: 8px 21px 8px 0;
    border-radius: 12px;
    border: 1px solid #69c0ff;
    margin-left: auto;
    height: 100%;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    p {
      width: 66%;
      margin-left: auto;
      margin-bottom: 0;
      text-align: right;
      line-height: 23px;
    }
  }
  .greenBox {
    background-color: #f6ffed;
    padding: 8px 0 8px 25px;
    border-radius: 12px;
    border: 1px solid #bae637;
    height: 100%;
    margin-left: -8px;
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    p {
      width: 66%;
      margin-right: auto;
      margin-bottom: 0;
      line-height: 23px;
      padding-right: 6px;
    }
  }
  .centerBox {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2509803922);
    width: 388px;
    min-height: 26px;
    display: grid;
    place-content: center;
    z-index: 2;
  }
  .blueBoxWrapper,
  .greenBoxWrapper {
    width: 100%;
    text-transform: capitalize;
  }
  .blueBoxWrapper {
    margin-right: -7px;
  }
}
.companyNames {
  display: flex;
  justify-content: center;
  width: 81%;
  margin: 0 auto;
  h3 {
    text-transform: capitalize;
  }
  h3:nth-child(1) {
    width: 38%;
    text-align: center;
  }
  h3:nth-child(2) {
    width: 24%;
    text-align: center;
  }
  h3:last-child {
    width: 38%;
    text-align: center;
  }
}
