.aodTitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--secondary-black-color);
}

.aodContent {
  font-size: 14px;
  color: var(--grey-70-color);
}

.highlightContent {
  color: var(--arya-accent-color);
}

.infoTooltip {
  margin-left: 5px;
}
