.selectedAggregationsWrapper {
  display: flex;
  align-items: center;
  flex: 5;

  .displayedAggregations {
    overflow: hidden;
    width: 90%;
    height: 32px;
  }

  .aggregationTag {
    background-color: #ffffff;
    display: inline-flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    font-size: 14px;
    border-radius: 32px;
  }

  .overflownAggregationsCount {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    border: 1px solid #dadbdd;
    background-color: rgba(7, 16, 26, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.overflownAggregation {
  min-width: 157px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    cursor: pointer;

    > svg {
      font-size: 12px;
      color: rgba(7, 16, 26, 0.5);
    }
  }
}

.overflownAggregationsContent {
  padding: 8px 10px 8px 16px;
}
