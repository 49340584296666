.experience-mismatch-wrapper {
  .experience-mismatch-reason {
    padding: 12px 0px 12px 20px;

    .experience-mismatch-header {
      color: rgba(7, 16, 26, 0.9);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
      margin-bottom: 8px;
    }

    .experience-mismatch-radio-buttons {
      height: 52px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .ant-radio-wrapper {
        color: rgba(7, 16, 26, 0.9);
        font-size: 14px;
      }
    }
  }

  .experience-mismatch-divider {
    height: 1px;
    border: 1px solid #dadbdd;
  }

  .years-of-experience-range {
    padding: 12px 0px 0px 20px;

    .years-of-experience-range-header {
      color: rgba(7, 16, 26, 0.9);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
      margin-bottom: 8px;
    }

    .min-max-experience-wrapper {
      width: 188px;
      display: flex;
      justify-content: space-between;

      .ant-form-item-label {
        line-height: 0px;

        > label {
          font-size: 14px;
        }
      }

      .ant-form-explain {
        font-size: 12px;
      }
    }
  }
}
