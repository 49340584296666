.regionPopover {
  :global {
    .ant-popover-inner-content {
      padding: 10px;
    }
  }
}

.regionName {
  display: block;
}
