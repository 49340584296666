
.horizontalLine {
  margin: 0px;
}

.replyItems {
  margin-top: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.seeRepliesButton {
  cursor: pointer;
  color: var(--arya-accent-color);
  max-width: fit-content;
  color:  #7f7d83;
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
  height: 40px;
  padding-left: 53px;
}

.infiniteScrollingreplyItems{
  // padding-top: 20px;
  margin-top: 8px;
  max-height: 200px;
  overflow-y: scroll;
}
