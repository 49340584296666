.tooltipDetails {
  display: flex;
  margin: 0px 12px 0px 12px;
}

.labelColor {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.labelName {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.legendHeader {
  color: rgba(7, 16, 26, 0.9);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.tooltipHeading {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.tooltipContent {
  margin: 12px 4px 4px 4px;
}

.legendList {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  font-family: Roberto, sans-serif;
  font-size: 14px;
}

.legendWrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  padding: 16px;
  font-size: 14px;
  gap: 4px;
}

.legendListItem {
  display: flex;
  flex-direction: row;
  // width:fit-content;
  height: fit-content;
  padding: 2px;
  align-items: center;
  justify-content: space-between;
}

.legendLabelKey {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: Roberto, sans-serif;
  font-size: 14px;
}

.lineChartTooltip {
  position: absolute;
  font-size: 12px;
  pointer-events: none;
  height: fit-content;
  width: fit-content;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  opacity: 0;
  z-index: 2500;
}
