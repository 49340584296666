.payment-add-card {
  .ant-input {
    height: 40px;
  }

  .payment-card-form-label {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .ant-form-item-label {
    line-height: 24px;
    margin-top: 20px;
  }
}

.payment-date-cvv {
  .ant-form-item {
    width: 40%;
    margin-bottom: 5px;
  }
}

.payment-default-add {
  display: flex;
  flex-direction: column;
}

.paymnet-credit-card-submit {
  .ant-form-item-control-wrapper {
    display: flex !important;
    justify-content: flex-end;

    .ant-form-item-control {
      width: 10%;
    }
  }
}

.payment-card-form-item {
  display: flex;
  align-items: center;

  .payment-card-form-item-offset {
    width: 28px;
  }

  .payment-card-info-icon {
    margin-left: 4px;
    font-size: 24px;
    color: rgba(7, 16, 26, 0.3);
  }

  .ant-input-group-addon {
    padding: 0px;
    color: rgba(7, 16, 26, 0.3);

    .card-detail-card-icon-wrapper {
      height: 38px;
      width: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .card-detail-card-icon > svg {
        width: 38px;
        height: 38px;
      }

      .card-detail-card-default-icon > svg {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.supported-payment-cards-tooltip {
  color: #ffffff;

  .ant-tooltip-arrow::before {
    color: #ffffff;
    background-color: #ffffff;
  }

  .ant-tooltip-inner {
    background-color: #ffffff;
    padding: 12px 16px 18px 16px;
  }

  .supported-payment-cards {
    display: flex;
    flex-direction: column;
    align-items: center;

    .supported-payment-card-text {
      color: rgba(7, 16, 26, 0.7);
      font-family: Roboto, sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
    }

    .supported-payment-card-icons {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .payment-card-icon > svg {
        width: 33px;
        height: 33px;
      }
    }
  }
}
