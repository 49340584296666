.login-form {
  display: flex;
  margin-top: 12px;
  .disconnectButton,
  .disconnectButton:hover,
  .disconnectButton:focus {
    font-size: 14px;
    color: #f5222d;
    border: 1px solid #dadbdd;
    background-color: rgba(7, 16, 26, 0.05);
  }
  .contact-provider-username-input,
  .contact-provider-password-input {
    width: 210px;
    padding: 4px 11px !important;
  }
}
