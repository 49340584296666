.suggestionsDropdown {
  min-width: 300px;
  max-width: 600px;
  .liSelected {
    background-color: #e6ffee !important;
  }
  :global .ant-dropdown-menu-item:hover {
    background-color: #ffffff;
  }
}

.suggestionsDropdownFooter {
  height: 30px;
  color: #121212;
  background-color: #c8cacc;
  font-size: 11px;
  padding: 0px 12px;
  align-items: center;
}

.suggestionDropdownItem {
  font-size: 11px !important;
  font-weight: normal !important;
  white-space: normal;
}
