.publishedStatusReasons {
  padding: 10px;
  p {
    margin: 0;
    padding: 5px 0px;
  }
}
.candidate-card {
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  width: 100%;

  .candidate-card-info {
    // flex-grow: 1;
    flex: 1;
    min-width: 0;
    position: relative;
  }

  .candidate-card-content {
    padding: 16px 24px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .candidate-card-footer {
    padding: 0 4px 8px 24px;
  }

  .candidate-card-primary-content,
  .candidate-card-secondary-content,
  .candidate-card-tertiary-content {
    min-width: 0;
  }

  .candidate-card-primary-content {
    flex: 3 3 0;
  }

  .candidate-card-secondary-content {
    flex: 3 3 0;
    margin-right: 5px;
    margin-top: 10px;
  }

  .candidate-card-tertiary-content {
    flex: 2 2 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    margin-top: 10px;
  }

  .candidate-card-connect-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .candidate-card-push-button {
    div > .push-status-icon {
      font-size: 15px;
      color: white;
      background-color: var(--arya-accent-color);
      padding: 3px;
      margin-left: 10px;
    }
  }

  .candidate-card-personal-info {
    display: flex;
    align-items: center;
  }

  .candidate-card-candidate-name,
  .candidate-card-candidate-location,
  .candidate-card-title {
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .candidate-card-title {
    & > span {
      display: inline-block;
      max-width: 330px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .candidate-card-candidate-name {
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .candidate-card-candidate-location {
    font-size: 12px;
    line-height: 16px;
    color: rgba(7, 16, 26, 0.5);
    margin-left: 6px;
  }

  .candidate-card-title {
    max-width: 85%;
    font-size: 14px;
    line-height: 22px;
    color: rgba(7, 16, 26, 0.7);
  }

  .candidate-card-primary-content {
    cursor: pointer;
  }

  .candidate-card-experience-info,
  .candidate-card-education-info,
  .candidate-card-occupation,
  .candidate-card-industry,
  .candidate-card-skills {
    font-size: 12px;
    line-height: 16px;
    margin: 4px 0;
  }

  .candidate-card-skills {
    margin-top: 0;

    .candidate-card-skills-content {
      display: inline-block;
    }
  }

  .candidate-card-experience-info,
  .candidate-card-education-info,
  .candidate-card-occupation,
  .candidate-card-industry {
    max-width: 85%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .candidate-card-info-tag {
    height: 14px;
    width: 40px;
    border-radius: 7px;
    background-color: rgba(7, 16, 26, 0.09);
    display: inline-block;
    text-align: center;
    line-height: 16px;
    margin-right: 8px;
    flex: 0 0 40px;
    color: #51575e;
    font-size: 11px;
    font-weight: 400;
  }

  .candidate-card-skill-tag {
    border-radius: 12px;
    background-color: rgba(7, 16, 26, 0.05);
    margin: 2px 4px 2px 0;
    border: none;
    line-height: 20px;
    color: #2d3845;
    font-weight: 500;
  }

  .candidate-card-occupation-title,
  .candidate-card-industry-title {
    color: rgba(7, 16, 26, 0.5);
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .candidate-card-occupation-details,
  .candidate-card-industry-details {
    color: rgba(7, 16, 26, 0.7);
    max-width: 75%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .candidate-card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .candidate-card-footer-primary {
      display: flex;
      align-items: center;
    }

    .candidate-card-footer-secondary {
      display: flex;
      align-items: flex-start;
      flex: 3 3 0;
      justify-content: flex-start;
      max-height: 22px;
      overflow: hidden;
      padding-left: 8px;

      .candidate-card-notes {
        display: flex;
        align-items: center;
        flex-direction: row;
        cursor: pointer;
        color: #83878c;
      }

      .candidate-card-notes-icon-wrapper {
        margin-right: 6px;

        .candidate-card-notes-icon {
          font-size: 16px;
        }
      }

      .candidate-card-notes-count {
        font-size: 12px;
      }
    }

    .candidate-card-footer-tertiary {
      display: flex;
      flex: 2 2 1.75%;
      justify-content: space-between;
    }

    .candidate-card-footer-applicationReview {
      display: flex;
      justify-content: flex-end;
    }

    .resume-date-info {
      font-size: 12px;
      color: rgba(7, 16, 26, 0.7);
      margin-left: 2px;
      line-height: 16px;
      display: inline-flex;
      align-items: center;
      font-weight: 500;

      .resume-date-icon {
        font-size: 15px;
        margin-right: 6px;
      }
    }
  }

  .candidate-card-info-icon {
    margin-right: 12px;
  }

  .candidate-info-portal {
    font-size: 12px;
    line-height: 16px;
    color: rgba(7, 16, 26, 0.7);
  }

  .candidate-card-actions {
    width: 48px;
    flex-shrink: 0;
    border-left: 1px solid rgba(7, 16, 26, 0.15);
  }
}

.candidate-card-new {
  position: relative;
}

.candidate-card-new::before {
  content: ' ';
  top: 0;
  left: 0;
  position: absolute;
  border-color: transparent;
  border-left-color: #007bff;
  border-top-color: #007bff;
  border-style: solid;
  border-width: 6px 8px;
}

.candidate-card-candidate-reject-select {
  width: 100%;
  flex-direction: column;
  display: flex;
  height: 100%;
}
.minimized-candidate-card-candidate-reject-select {
  display: flex;
  gap: 20px;
  align-items: center;
  height: 22px;
}
.candidate-list-candidate-info {
  margin-right: 40px;
}

.candidate-list-candidate-info :hover {
  cursor: pointer;
}

.candidate-card-skills {
  width: 100%;
  max-height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.candidate-card-skills :after {
  animation: 0s; // to remove ant default animation
}

.candidate-card-industry :hover {
  cursor: pointer;
}

.candidate-card-occupation :hover {
  cursor: pointer;
}

.candidate-card-select-candidate {
  border-bottom: 1px solid rgba(7, 16, 26, 0.15);
}
.candidate-card-transfer-candidate {
  border-top: 1px solid rgba(7, 16, 26, 0.15);
}
.minimized-candidate-card-reject-candidate {
  :global {
    i {
      font-size: 23px !important;
    }
  }
}
.candidate-card-select-candidate,
.candidate-card-transfer-candidate,
.candidate-card-reject-candidate,
.minimized-candidate-card-select-candidate,
.minimized-candidate-card-reject-candidate,
.minimized-candidate-card-transfer-candidate {
  cursor: pointer;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.candidate-like-icon.anticon {
  color: #01cd75;
}

.candidate-dislike-icon.anticon {
  color: #ff1a1a;
}

.shortlist-row {
  background-color: #01cd75;
}

.reject-row {
  background-color: red;
}

.connect-media-icon-group {
  display: flex;
  text-align: center;
  // justify-content: center;
  align-items: center;
  gap: 24px;
}

.candidate-list-diversity-icon {
  font-size: 20px;
}

.rating-tooltip {
  max-height: 110px;
  max-width: 1000px;
}

.candidate-card-score {
  height: 21px;
  width: 33px;
  border-radius: 4px;
  background-color: #ffffff;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  justify-content: center;
  justify-items: center;
  display: flex;
  align-items: center;
}

.candidate-card-score-best {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.candidate-card-score-better {
  border: 1px solid #faad14;
  color: #faad14;
}

.candidate-card-score-good {
  border: 1px solid #f5222d;
  color: #f5222d;
}

.candidate-card-past-info {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 500;
  color: #2d3845;
}
candidate-card-education {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 400;
  color: #2d3845;
}
.font-size-13px {
  font-size: 13px;
}

.candidate-card-experience-info,
.candidate-card-education-info {
  display: flex;
}

.candidate-card-candidate-status,
.candidate-card-candidate-status-360 {
  width: max-content;
  padding: 0px 8px;
  background-color: rgba(7, 16, 26, 0.05);
  border-radius: 0 0 4px 4px;
  color: rgba(7, 16, 26, 0.9);
  font-weight: 500;
  margin-left: 5px;
  display: inline-block;
}

.candidate-card-candidate-status-360 {
  font-size: 13px;
}

.candidate-card-skills-tooltip {
  max-width: 340px;

  .ant-tooltip-inner {
    max-height: 300px;
    overflow-y: auto;
  }
  mark {
    color: rgba(0, 0, 0, 0.75) !important;
    background-color: #ffff5a !important;
    padding: 0;
  }
  mustHaveMark {
    color: rgba(0, 0, 0, 0.75) !important;
    background-color: pink !important;
    padding: 0;
  }
}

.candidate-rejected-status-tag {
  height: 22px;
  width: 200px;
  margin-right: 70px;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
  background-color: #ffcccc;
}

.candidate-publish-tag {
  color: white;
  display: flex;
  height: 100%;
  padding: 0 10px;
  justify-content: space-around;
  align-items: center;
}

.default-publish-tag,
.vetted-publish-tag {
  background-color: var(--color-shortlisted-activated);
}

.engaged-publish-tag {
  background-color: var(--color-engaged-activated);
}
.candidate-rejected-status {
  color: #000000;
  display: flex;
  flex-direction: row;
  font-weight: 200;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .candidate-rejected-status-text {
    font-weight: 500;
    padding-left: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.candidate-publish-tag-text {
  color: #ffffff;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.shortlisted-like-icon {
  height: 22px;
  width: 22px;
  border-radius: 0 0 0 4px;
  background-color: #13c26b;
}

.rejected-dislike-icon {
  height: 22px;
  width: 22px;
  border-radius: 0 0 0 4px;
  background-color: #ff1a1a;
}

.icon-like {
  height: 14px;
  width: 14px;
  color: #ffffff;
  margin-left: 4px;
  margin-top: 3px;
}

.candidate-status-push-button-wrapper {
  display: flex;
  margin-right: 10px;
}

.candidate-card-push-button {
  display: flex;
}

.candidate-card {
  .candidate-view-icon-wrapper {
    .candidate-view-icon {
      visibility: hidden;
      font-size: 16px;
      margin-left: 10px;
      position: relative;
      top: -2px;
    }
  }
}

.candidate-card:hover {
  .candidate-view-icon-wrapper {
    .candidate-view-icon {
      visibility: visible;
    }
  }
}

.candidate-card-footer-button,
.candidate-card-footer-button-360 {
  padding: 0px 10px;
  cursor: pointer;
  margin-left: 31px;
}

.candidate-card-footer-button-open,
.candidate-card-footer-button-open-360 {
  border-radius: 4px;
  margin-left: 31px;
  background-color: rgba(7, 16, 26, 0.05);
}

.candidate-card-footer-button-360,
.candidate-card-footer-button-open-360 {
  margin-left: 72px;
}

.candidate-card-footer-button-text {
  font-size: 12px;
  line-height: 16px;
  margin-right: 9px;
}

.candidate-card-info-icon {
  margin-right: 12px;
}

.candidate-card-footer-button-icon > i > svg {
  height: 12px;
  width: 12px;
}

.candidate-card-wrapper {
  width: 100%;
  border-radius: 4px;
}

.candidate-metadata-card-transition-enter,
.candidate-metadata-card-transition-appear {
  max-height: 0px;
  width: 100%;
}

.candidate-metadata-card-transition-enter-active,
.candidate-metadata-card-transition-appear-active {
  width: 100%;
  max-height: 200px;
  transition: all 300ms;
}

.candidate-metadata-card-transition-exit {
  width: 100%;
  max-height: 200px;
}

.candidate-metadata-card-transition-exit-active {
  max-height: 0px;
  width: 100%;
  transition: all 300ms;
}

.candidate-personal-experience-info {
  margin-left: 8px;
  margin-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  min-width: 46px;
  height: 16px;
  border-radius: 8px;
  background-color: rgba(7, 16, 26, 0.05);
}

.candidate-personal-experience-info-text {
  font-weight: 500;
  line-height: 10px;
  font-size: 11px;
  color: #2d3845;
}

.last-activity-tooltip {
  .ant-tooltip-inner {
    width: 300px;
    display: flex;
    justify-content: center;
  }
}
