.payment-plan-container {
  height: 100%;
  display: grid;
  grid-template-columns: auto auto;
  padding-top: 16px;
  padding-bottom: 50px;
  .payment-method {
    font-size: 16px;
    font-weight: 500;
    padding: 5px;
  }
  .payment-billing-panel {
    width: 690px;
    .ant-collapse {
      background-color: white;
    }
  }
  .payment-billing-information-container {
    .billing-container {
      .billing-container-profilesettings {
        .payment-default-add {
          margin-bottom: 0px;
        }
        .billing-address-item {
          width: 100%;
          margin-top: 0px;
        }
      }
    }
  }
}

.place-order-title {
  color: rgba(7, 16, 26, 0.9);
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 40px;
  margin-top: 20px;
  display: inline-flex;
}

.back-link-stepper-wrapper {
  display: flex;
}

.job-creation-back {
  padding-top: 38px;
  margin-right: 20px;
  display: flex;
  align-items: center;

  .ant-btn {
    padding: 0;
  }
}

.payment-agreement-container {
  display: grid;
}

.payment-billing-information-container {
  display: grid;
  .payment-information-container {
    margin-bottom: 20px;
  }
}

.payment-component-progress-step {
  width: 750px;
  padding: 50px 0px 10px;
}
