.candidate-intel-score {
  width: 130px;
  height: auto;
  border: 1px solid #dadbdd;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 2px 8px 8px;
  margin-bottom: 12px;
}

.candidate-score {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
}

.candidate-score-secondary-text {
  font-size: 12px;
}

.candidate-score-container {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  background-color: rgba(7, 16, 26, 0.1);
  margin-top: 4px;
}

.candidate-score-container-filled {
  height: 4px;
  background-color: var(--arya-accent-color);
  border-radius: 3px;
}
