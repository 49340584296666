.cardItem {
  border: 1px solid #dadbdd;
  margin-top: 10px;
  padding: 12px;
  border-radius: 8px;
  max-height: 260px;
  overflow-y: auto;
  .noteHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .userIcon {
      display: flex;
      align-items: center;
    }
  }
  .editButton {
    cursor: pointer;
    color: var(--arya-accent-color);
  }
  .candidateInfo {
    display: flex;
    .name {
      margin-left: 10px;
    }
  }
  .actions {
    margin-top: 10px;
  }
  .noteTagsSection {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    :global {
      .ant-tag {
        font-size: 12px;
        border-radius: 20px;
        background-color: #fefefe;
        margin-top: 5px;
        height: 24px;
      }
    }
  }
  .contentTitle {
    font-weight: 500;
    color: rgba(31, 39, 48, 1);
  }
  .content {
    color: rgba(31, 39, 48, 1);
  }
}
.noteSubject {
  margin-bottom: 8px;
}
.partialClass {
  max-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px !important;
  color: rgba(31, 39, 48, 1);
  > p,
  ol,
  ul,
  dl {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.cardItem {
  :global .text-preview-total {
    color: rgba(31, 39, 48, 1);
    line-height: 16px !important;
    margin-right: 12px;
    > p,
    ol,
    ul,
    dl {
      font-size: 14px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}
.addTag {
  margin-top: 5px;
}
.moreIcon {
  margin-top: 4px;
  .moreIconWrapper {
    height: 30px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.contentPop {
  width: 125px;
  font-size: 14px;
  line-height: 16px;
  color: #1f2730;
  padding: 11px 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
}
