.manualSearchFormWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid rgba(7, 16, 26, 0.15);
  .manualSearchForm {
    flex: 1;
    display: flex;
    &.minimize {
      max-height: 180px;
      overflow: hidden;
      opacity: 0.7;
      pointer-events: none;
    }
  }
}
.manualSearchFormMinimizedView {
  display: none;
  cursor: pointer;
}
.manualSearchSources {
  border-right: 1px solid rgba(7, 16, 26, 0.15);
}

.candidateCollapsedFilters {
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  .savedCriteria {
    position: absolute;
    top: 60px;
    right: 0;
    color: rgba(7, 16, 26, 0.5);
    font-size: 12px;
    font-style: italic;
    margin-right: 15px;
  }
  .manualSearchTabs {
    flex: 1;
    position: relative;
  }
}

.seeMoreLessWrapper {
  padding-left: 100px;
  flex: 1;
  height: 27px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #83878c;
  border-top: 0px;
  border-radius: 0px 0px 4px 4px;
}

.seeMoreButton,
.seeLessButton {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}
