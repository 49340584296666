.client-form-body {
  padding-top: 20px;
  padding-bottom: 40px;

  .client-name-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    .client-name-input,
    .client-name-input:focus {
      width: 350px;
      border: none;
      border-bottom: 1px dashed #a8b7c7;
      font-size: 20px;
      padding-left: 0px;
    }

    .client-status-label {
      color: var(--secondary-black-color);
      font-size: 14px;
      line-height: 22px;
      margin-right: 8px;
    }
  }

  .ats-id-input {
    width: 375px;
  }
  .source-count-input {
    width: 170px;
  }

  .emails,
  .contact-numbers,
  .clientid {
    display: flex;
  }

  .company-email,
  .source-count-item {
    margin-right: 20px;
  }

  .primary-email,
  .company-email,
  .phone-number-input,
  .mobile-number-input,
  .source-count-item {
    width: 285px;
  }

  .company-url {
    width: 375px;
  }

  .has-error {
    .company-url {
      .ant-input:focus {
        border-color: #f5222d !important;
        box-shadow: none;
      }
    }
  }

  .http-select {
    width: 80px;

    .ant-select-selection {
      border: 1px solid transparent;
      border-color: transparent;
    }
  }

  .plus-circle {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
  }
}

.client-form-footer {
  width: 650px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 12px 30px;
  background-color: white;
  border-top: 1px solid #d9d9d9;

  .buttons {
    float: right;

    button {
      border-radius: 25px;
    }

    .cancel,
    .cancel:hover {
      margin-right: 12px;
      border: 1px solid #dadbdd;
      color: var(--secondary-black-color);
    }

    .create-client,
    .create-client:hover {
      background-color: var(--arya-accent-color);
      color: white;
    }
  }
}

.drawer-select-dropdown {
  z-index: 3000;
}
