.stackedBarContainer {
  position: relative;
  width: 100%;
  .divOne {
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px;
    width: inherit;
    border: 1px solid #dadbdd;
    min-height: 148px;
    background: white;
    z-index: 3;
    border-radius: 4px;
    text-decoration: none;
    color: inherit;
    .IconContainer {
      position: absolute;
      right: 14px;
      top: 60px;
    }
    h3 {
      display: inline-block;
      color: #51575e;
      font-size: 14px;
      margin-bottom: 9px;
    }
    .tag {
      margin-left: 10px;
      font-weight: 400;
      font-size: 12px;
      border: 1px solid #dadbdd;
      padding: 8px;
      border-radius: 16px;
      color: rgba(0, 0, 0, 0.65);
    }
    .toEmailContainer {
      display: flex;
      justify-content: space-between;
      min-width: 40%;
      span {
        margin: 0 10px 0 0;
      }
      .startTime {
        min-width: 25%;
      }
    }
    .fromEmailContainer {
      display: flex;
      justify-content: space-between;
      min-width: 40%;
      span {
        margin: 0 10px 0 0;
      }
      .startTime {
        margin-bottom: 5px;
        min-width: 25%;
      }
    }
    .subjectContainer {
      display: flex;
      justify-content: space-between;
      min-width: 40%;
      span {
        margin: 0 10px 0 0;
      }
      .startedBy {
        min-width: 25%;
      }
    }
    p {
      margin-bottom: 4px;
      color: rgba(0, 0, 0, 0.65);
    }
    .footerContent {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .status {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .dateContainer {
        color: #b4b7ba;
        margin-top: 4px;
      }
      .footerButtons {
        display: flex;
        gap: 10px;
      }
    }
  }
}
