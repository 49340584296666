.rulesContainer {
  span {
    flex-grow: 1;
    border-bottom: 2px solid rgba(0, 123, 255, 0.5019607843);
  }
}
.delayContainer {
  span {
    flex-grow: 1;
    border-bottom: 2px solid #faad1480;
  }
}
.delayContainer,
.rulesContainer {
  display: flex;
  align-items: center;
  margin: 10px;
  p {
    color: #83878c;
    margin-bottom: 0;
    width: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
  }
}

.minimizedContainer {
  background: white;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  color: #51575e;
  font-weight: 500;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .mailStatus {
    color: #83878c;
    font-weight: 400;
    font-size: 10px;
  }
}

.workflowEmailEditor {
  height: 300px;
  a {
    pointer-events: none;
  }
}

.maxmizedContainer {
  margin-left: auto;
  display: block;
  position: relative;
  width: 98.6%;
  .templateSelectContainer {
    width: fit-content;
    position: absolute;
    top: 3px;
    right: 63px;
    z-index: 1;
  }
  [data-testid='down-arrow-icon'],
  [data-testid='font-size-icon'],
  button {
    pointer-events: none;
  }
}

.space {
  margin: 0 20px;
  border: none !important;
}
