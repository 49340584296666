.linkedInContainer {
  .disabledLinkedIn {
    pointer-events: none;
    > * {
      opacity: 0.2;
    }
  }
  .socialIcon {
    line-height: 1;
  }
}
