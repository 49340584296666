.verificationContainer {
  background: linear-gradient(329.21deg, #4293f4 0%, #1bbd78 100%);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .userVerificationCard {
    width: 400px;
    height: 500px;
    border-radius: 10px;
    background-color: white;
    text-align: center;
    padding: 30px 20px;
  }
  .userVerificationIcon {
    padding: 20px 0px;
  }
}

.outerCircle {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  padding: 22px;
  margin: auto;
  background: linear-gradient(180deg, #f68265 0%, #e94b35 100%);
}

.innerCircle {
  height: 105px;
  width: 105px;
  border-radius: 50%;
  padding: 24px;
  background: linear-gradient(180deg, #e65f3e 0%, #c8311d 100%);
}

.verificationFailedheading {
  margin-top: 48px;
}

.linkExpiredHeading {
  margin-top: 24px;
}

.linkExpiredButton {
  margin-top: 72px;
}

@media (max-width: 800px) {
  .verificationContainer {
    background: none;
  }
  .userVerificationCard {
    width: 347px;
    box-shadow: 0 2px 6px 0 rgba(7, 16, 26, 0.15);
  }
}
