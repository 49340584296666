#overviewRadarChart {
  margin-top: -50px;
  margin-bottom: -100px;
  position: relative;
}

.overview-tooltip {
  position: absolute;
  pointer-events: none;
  height: auto;
  width: auto;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  opacity: 0;
  padding-bottom: 12px;
  z-index: 2500;
}

.overview-tooltip-score-label {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 12px 16px 4px 12px;
}

.overview-tooltip-details {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 16px 0px 12px;
}

.overview-tooltip-label-color {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-top: 4px;
  margin-right: 8px;
}
