.authenticate-button,
.authenticate-button:hover,
.authenticate-button:focus {
  font-size: 14px;
  color: rgba(7, 16, 26, 0.7);
  border: 1px solid #dadbdd;
}

.disconnect-button,
.disconnect-button:hover,
.disconnect-button:focus {
  font-size: 14px;
  color: #f5222d;
  border: 1px solid #dadbdd;
  background-color: rgba(7, 16, 26, 0.05);
}

.career-builder-credentials {
  line-height: 32px;
}

.authenticate-career {
  height: 22px;
  width: 265px;
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-top: -5px;
}

.authentication {
  height: 22px;
  width: 515px;
  color: #83878c;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}

.cb-edge-form .error-message {
  color: var(--arya-error-color);
  padding-top: 7px;
}
