.treeNodeTooltipWrapper {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.treeSelectDropdown {
  max-height: 200px !important;
  overflow: auto;
  overflow-x: hidden;
  white-space: nowrap;
  z-index: 1500;
}
