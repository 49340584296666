.infoGatheringQuestionItem {
  margin-bottom: 10px;
  cursor: move;
}

.infoGatheringCategory {
  border: none;
  width: 40%;
}

.infoGatheringQuestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dadbdd;
  padding-right: 10px;

  :global .ant-form-item {
    width: 100%;
  }
}
.questionInput {
  border: none;
}
