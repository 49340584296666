.settingsLayoutItem {
  width: 875px;
  border-bottom: 1px solid #e6e7e8;
  padding: 40px 28px 51px 26px;
}

.settingsLayoutItemHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
}

.description {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
}
