.userAlertWrapper {
  width: 100%;
  position: relative;
  top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  .alertContentClass {
    text-align: justify;
  }

  .alertBody {
    width: 422px;
  }

  .iconWrapper {
    position: relative;
    left: 152px;
  }
}
