#skillsProximityScatterPlot {
  position: relative;
}

.skill-proximity-tooltip {
  position: absolute;
  font-size: 12px;
  pointer-events: none;
  height: auto;
  width: auto;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  opacity: 0;
  z-index: 2500;
}

.skill-tooltip-content {
  margin-bottom: 12px;
}

.skill-tooltip-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0px 12px 0px 12px;
}

.skill-tooltip-label-color {
  height: 8px;
  width: 8px;
  margin: 4px 8px 0px 0px;
}

.skill-tooltip-skill-name {
  margin-right: 8px;
}

.skill-proximity-circle:hover {
  stroke: #000000;
}
