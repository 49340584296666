.locationTag {
  border-radius: 11px;
  margin-left: 0px;
  margin-right: 6px;
  margin-bottom: 5px;
}
.regionContainer {
  display: flex;
  flex-wrap: wrap;
}
