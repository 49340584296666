.wrapper {
  border-radius: 4px;
  background-color: rgba(7, 16, 26, 0.02);
  padding: 20px;
  margin-top: 120px;
  margin-bottom: 50px;
}

.header {
  font-size: 20px;
}

.subHeader {
  font-size: 16px;
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  letter-spacing: 0;
  line-height: 24px;
}

.subSection {
  margin-top: 25px;
  margin-bottom: 25px;
}

.empty {
  text-align: left;
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-size: 14px;
  color: rgba(7, 16, 26, 0.3);
  margin: 0;
  :global {
    .ant-empty-image {
      display: none;
    }
  }
}

.matchSkillTag {
  background-color: rgba(19, 194, 107, 0.25);
  border-radius: 11px;
  border: 0px;
  margin-top: 8px;
}

.missingSkillTag {
  background-color: #ffffff;
  border-radius: 11px;
  border: solid 1px #dadbdd;
  margin-top: 8px;
}

.skillSet {
  [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  text-align: left;
}
