.notification {
  display: flex;
  width: max-content;
  border-bottom: 1px solid rgb(230, 234, 238);
  padding: 12px;
  cursor: pointer;
  gap: 20px;
}

.AcceptedIcon {
  width: 40px;
  height: 40px;
  background-color: #13c26b33;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 229px;
}

.viewJobButton {
  padding: 0px 30px;
  margin-top: 10px;
}

.time {
    color: #7f8fa5;
    font-size: 12px;
    line-height: 14px;
    padding: 4px 0px;
}
