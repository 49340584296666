.configure-bot {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.configure-bot-button {
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.configure-bot-button:nth-of-type(2){
  display: none;
}



.chat-empty {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  .chat-empty-text {
    margin: 15px 0px;
    font-size: 16px;
    text-align: center;
  }

  .ant-empty-image {
    height: auto;
  }
}
