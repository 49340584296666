.sourceFilter,
.sourceFilter:active {
  border-radius: 20px;
  height: 32px;
  width: 32px;
  padding-left: 0px;
  padding-right: 0px;
  border: transparent;
}

.sourceFilter {
  &.unselected {
    opacity: 0.6;
    background: #fff;
    border: 3px solid #d3d3d3;
  }
}

.sourceFilter {
  &.selected {
    background: #fff;
    border: 3px solid #13c26b;
  }
}
