.jobshared-image {
  width: 24px;
  height: 24px;
}

.jobshared-image-wrapper {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: rgba(19, 194, 107, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-user-name {
  z-index: 2700;
}
