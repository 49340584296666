.connectGrid {
  display: grid;
  padding: 0px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 50% 50%;
  grid-template-areas:
    'emailResponse textResponse'
    'callResponse aryabotResponse';
}

.connectStats {
  display: flex;
  flex-direction: column;
}

.emailResponse {
  grid-area: emailResponse;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  border-right: 1px solid #e6eaee;
  border-bottom: 1px solid #e6eaee;
}

.textResponse {
  grid-area: textResponse;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  border-left: 1px solid #e6eaee;
  border-bottom: 1px solid #e6eaee;
  padding-left: 24px;
}

.callResponse {
  grid-area: callResponse;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  border-right: 1px solid #e6eaee;
  border-top: 1px solid #e6eaee;
}

.aryabotResponse {
  grid-area: aryabotResponse;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  border-left: 1px solid #e6eaee;
  border-top: 1px solid #e6eaee;
  padding-left: 24px;
}
