.moreSkillsSection {
  border: 1px solid #e8e8e8;
  padding: 8px;
  margin-top: 10px;
  :global .boolean-string-display-component-wrapper {
    width: auto;
    :global .boolean-string-display-component {
      width: 88%;
      margin-right: 5px;
    }
  }
  .label {
    color: #484b4f;
    font-size: 12px;
    font-weight: 500;
  }

  .showMoreLessButton {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    i > svg {
      font-size: 10px;
    }
    span {
      font-size: 13px;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .addSkills {
    margin-top: 10px;
    .addSkillsInput {
      width: 300px;
      display: flex;
      margin-top: 8px;
    }
  }

  .mustsInput {
    margin-top: 8px;
    width: 300px;
  }

  .skillTag {
    display: inline-flex;
    font-weight: bold;
    border-radius: 3px;
    margin-bottom: 8px;
    margin-right: 8px;
    font-size: 12px;
    padding: 2px 4px;
    cursor: pointer;
    &.selected {
      color: #1890ff;
      border: 1px solid #1890ff;
      background-color: rgba(33, 197, 116, 0.11);
    }
    &.unselected {
      color: #83878c;
      border: 1px solid #83878c;
      background-color: #ffffff;
    }
  }
  .mustHavesDisplay {
    margin-top: 10px;
  }
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

.displayInlineBlock {
  display: inline-block;
}

.excludeSkillTagsContainer {
  margin-top: 10px;
}
