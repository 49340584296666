.scoreErrorIcon {
  font-size: 20px;
  color: #e22d12;
}

.scoreErrorPendingIcon {
  height: 24px;
}

.candidateTags {
  display: flex;
  position: absolute;
  right: 0;

  :global .publish-icon-wrapper {
    display: flex;
  }
}

.applicationReviewBtn {
  font-size: 12px;
  width: fit-content;
  height: fit-content;
  padding: 0px;
  border: 0px;

  > span {
    text-decoration: underline;
  }
}
.sourceImage {
  z-index: 2;
  background-color: white;
  border-radius: 50px;
}
.overlappingSourceImage {
  position: relative;
  right: 4px;
  overflow: hidden;
}
.overlappedIcons {
  display: flex;
  margin-right: 6px;
  align-items: flex-start;
}
.candidateCardTertiaryContent {
  align-items: center;
  .candidateCardConnectContentWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .candidateCardConnectContent {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.enhancedShortlistContainer {
  flex-direction: column !important;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 0px;
}
.enhancedShortlistContainer > div:not(:first-child) {
  border-top: 1px solid #dadbdd;
}
