.minimizedCandidateInformation {
  display: flex;
  gap: 5px;
  .portalIcon {
    display: inline-flex;
    margin-right: 5px;
    .sourceImage {
      background-color: white;
      border-radius: 50px;
    }
    .overlappingSourceImage {
      position: relative;
      right: 4px;
      overflow: hidden;
    }
  }
  .candidateNameAndLocation {
    display: flex;
  }
  .candidateName {
    font-size: 18px;
    font-weight: 400;
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .candidateLocation {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #51575e;
  }
  .candidateStatusWithIcon {
    padding: 2px;
    color: #007bff;
    background-color: #e6f7ff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 6px;
    font-size: 14px;
  }
}
