.selectAndButtonWrapper {
  display: flex;
  gap: 14px;
  margin-bottom: 6px;
  :global {
    .ant-select-selection-selected-value {
      span:nth-child(2) {
        display: none;
      }
      span:nth-child(3) {
        display: none;
      }
    }
  }
}

.workflowDropdownSelect {
  width: 424px !important;
  :global {
    .ant-select-dropdown-menu-item-selected {
      font-weight: 400 !important;
      color: rgba(0, 0, 0, 0.65) !important;
      background: transparent !important;
    }
  }
}

.disclaimerMessage {
  padding-bottom: 19px;
}

.workflowSelect {
  width: 384px;
}
.linkButtons {
  color: #13c26b;
  align-items: center;

  :global {
    .ant-btn {
      padding: 0 5px;
    }
    .ant-btn-link {
      color: #13c26b;
    }
  }

  span {
    text-decoration: underline;
  }
}
.startEndDate {
  display: flex;
  gap: 17px;
  margin-bottom: 28px;
}
.triggeredEndTimeSelect {
  display: flex;
  align-items: center;
  gap: 17px;
}
.datePicker {
  width: 200px;
}
.datePickerDropdown {
  width: 200px;
  z-index: 2200;
}
.sendToContainer {
  display: flex;
  margin-top: 14px;
  margin-bottom: 4px;

  .sendToLabel {
    font-weight: 500;
    margin-right: 10px;
  }
}

.workflowOptionContainer {
  display: flex;
  justify-content: space-between;
}

.templateIdentifer {
  background-color: #f2f3f3;
  padding: 0 8px;
  border-radius: 12px;
  border: 1px solid #dadbdd;
  font-size: 12px;
  line-height: 20px;
}
.templateIdentifer:nth-child(2) {
  margin-left: 10px;
}
