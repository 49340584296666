.company-info-settings-input {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}
.company-info-location {
  width: 100%;
}
.company-info-settings-section {
  margin-bottom: 20px;
}

.company-info-zipcode-country {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;

  .ant-form-item {
    width: 48%;
  }
}
