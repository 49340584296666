.labelClassName {
  label {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.editorClassName {
  margin-bottom: 28px;

  :global {
    .ql-editor {
      min-height: calc((100vh - var(--navbar-height)) * 0.5);
      max-height: calc(100vh - var(--navbar-height));
      border-radius: 0 0 4px 4px;
    }
  }
}

.alertsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
