.arya-review-wrapper > .ant-modal-content > .ant-modal-body {
  padding: 0;
}

.review-data {
  height: 375px;
  width: 517px;
  border: 1px solid #dadbdd;
  border-radius: 4px;
  background-color: #ffffff;
  margin: auto;
  margin-top: 16px;
}

.review-comment-edit-job,
.review-comment-refine-arya-intel {
  border: 1px solid #dadbdd;
  background-color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  a:link {
    color: #13c26b;
  }
}

.review-comment-edit-job > .edit-job,
.review-comment-refine-arya-intel > .refine-arya-intel {
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.review-comment-review-job-info-div,
.review-comment-try-quick-search-div {
  display: flex;
  padding-left: 30px;
}

.review-comment-review-job-info-div {
  padding-top: 20px;
}

.review-comment-review-job-info,
.review-comment-try-quick-search {
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-right: 10px;
}

.review-comment-reason-list {
  padding-left: 30px;
  margin-top: 15px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 13px;
  line-height: 22px;
}

.review-comment-support-div {
  display: flex;
  padding-left: 30px;
  align-items: baseline;
}

.review-comment-support {
  font-size: 14px;
  line-height: 16px;
  margin-right: 10px;
}

.review-comment-support-link {
  display: flex;
}

.review-comment-close-div {
  margin: auto;
  display: flex;
  width: 517px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.review-comment-divider {
  padding: 0px 30px;
}

.review-comment-divider > .ant-divider > .ant-divider-inner-text {
  font-size: 10px;
}

.review-comment-close {
  cursor: pointer;
}

.review-comment-tick-icon {
  color: #ffe58f;
  margin-right: 10px;
}
