.activeButtonWrapper,
.inactiveButtonWrapper {
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 15px;
  align-items: center;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0;
}

.activeButtonWrapper {
  border: 1px solid #b7eb8f;
  background-color: #f6ffed;
  color: #63c600;
}

.inactiveButtonWrapper {
  border: 1px solid rgba(180, 183, 186, 0.44);
  background-color: #fafafa;
  color: #b4b7ba;
}

.recommendationBtnActive,
.recommendationBtnInActive {
  background-color: #fafafa;
  height: 32px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

.recommendationBtnActive {
  color: #70c800;
  border: 1px solid #70c800;
}

.recommendationBtnInActive {
  color: #b5b7ba;
  border: 1px solid #b5b7ba;
}

.tooltipStyle {
  max-width: 320px;

  :global .ant-tooltip-inner {
    text-align: center;
  }
}
