.chat-window-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 0px 20px;
}

.question-progress {
  // display: inline-block;
  display: none;
  margin: 10px 0px;

  > div {
    padding: 4px 10px;
    display: inline-block;
    font-size: 14px;
    line-height: 22px;

    &:nth-child(1) {
      border: 1px solid #cccccc;
      border-radius: 4px 0 0 4px;
      background-color: #ffffff;
    }

    &:nth-child(2) {
      color: #ffffff;
      font-weight: bold;
      border-radius: 0 2px 2px 0;
      border: 1px solid #4da9ff;
      background-color: #4da9ff;
    }
  }
}

.chat-input-box {
  .ant-affix {
    background-color: white;
    height: 53px !important;
  }
}

#chat-input-box-wrap {
  display: flex;
  flex-shrink: 0;
  margin-bottom: 15px;
  margin-top: 10px;
}

.chat-textarea {
  flex-grow: 1;

  > textarea {
    border: 1px solid #cccccc !important;
  }
}

#connect-chat-send-button {
  margin-left: 20px;
}

.interrupt-chat {
  border: 1px solid #dadbdd;
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;

  > span {
    margin-right: 10px;
  }

  .interrupt-button {
    padding: 4px 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--arya-accent-color);
    border: 1px solid var(--arya-accent-color);
  }
}

.connect-message-bubble.bot-message {
  background-color: var(--arya-accent-color);
}
