.pull-jobs-modal {
  .ant-modal-body {
    color: rgba(7, 16, 26, 0.7);
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 0px;
  }

  .pull-jobs-select {
    margin-bottom: 35px;

    .pull-jobs-radio-option {
      display: block;
      margin-bottom: 12px;
    }

    .pull-all-radio-option {
      display: block;
      margin-bottom: 24px;
    }
  }

  .pull-jobs-user-info {
    .info-icon {
      font-size: 14px;
      margin-right: 5px;
    }

    margin-bottom: 10px;
  }

  .confirm-button,
  .cancel-button {
    border-radius: 20px;
  }
}
