.order-cancel-failed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-cancel-modal-ok-button {
  font-size: 16px;
  height: 40px;
  width: 87px;
  border-radius: 20px;
}

.order-cancel-failed-text {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: 300px;
}

.order-cancel-failed-content-wrapper {
  display: flex;
  justify-content: center;
}
