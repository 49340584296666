.locked-candidate-list {
  width: 1120px;
}

.sample-job-intel-wrapper-blur,
.sample-job-info-wrapper-blur {
  filter: blur(3px);
  margin-top: 20px;
}

#job-details {
  .user-alert-wrapper {
    width: 92%;
    position: absolute;
    top: 19%;
    display: grid;
    place-items: center;
  }
  .select-your-plan > span {
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
  }

  .sample-job-info {
    .lock-outer-circle {
      left: 300px;
    }
    .alert-message-body {
      width: 720px;
    }
  }

  .select-plan-locked-text {
    font-size: 16px;
    line-height: 23px;
    color: rgba(7, 16, 26, 0.9);
  }
  .alert-message-header {
    font-size: 22px;
  }
  .select-plan-button {
    padding: 0 24px;
    margin: 20px;
  }
}

.select-plan-locked-text {
  color: rgba(36, 48, 61, 0.5);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}

.select-plan-button {
  border-radius: 20px;
  background-color: #13c26b;
  margin: 10px;
}

.select-plan-button-text {
  height: 34px;
  color: #ffffff;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
}
