.sourceNameWrapper {
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
  .sourceNamePrimary,
  .sourceNameSecondary {
    color: rgba(7, 16, 26, 0.7);
    font-size: 14px;
    line-height: 22px;
  }
}
