.consentManagerSettings {
  display: inline-flex;
  flex-direction: column;
  gap: 15px;
}

.diversityAdminSettingsHeaderLabel {
  font-size: 20px;
  color: rgba(7, 16, 26, 0.9);
  line-height: 24px;
  align-self: center;
  font-weight: 500;
  padding-bottom: 12px;
}

.diversityAdminSettingsHeaderText {
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 12px;
  color: rgba(7, 16, 26, 0.7);
}

.adminDiversitySettingHeader {
  display: flex;
}

.maskingUserListItem {
  display: flex;
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  margin: 5px;
}

.maskingUserOptions {
  display: flex;
}

.userList {
  padding: 10px;
}

.maskingAddUserBtn {
  display: flex;
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
}

.maskingAddUserBtnItem {
  padding-right: 10px;
}
