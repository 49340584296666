.notes {
  width: 100%;
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  line-height: 22px;
  .html-resume-viewer {
    > p,
    ol,
    ul,
    dl {
      margin: 0px;
    }
  }
}
.html-resume-viewer {
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-justify {
    text-align: justify;
  }
  blockquote {
    border-left: 4px solid #ccc;
    padding-left: 16px;
  }
}
.notes-info {
  height: 16px;
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 20px;
}

.activity-type {
  color: rgba(7, 16, 26, 0.9);
  font-size: 12px;
  line-height: 16px;
  background-color: #f2f3f3;
  border: 1px solid #dadbdd;
  border-radius: 9px;
  padding: 0 2px 0 2px;
}
