.ql-snow button.ql-mergetags {
  width: 100px;

  // &::after {
  //   content: '+ Merge Tags';
  // }
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px;
}

.tags-popover-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 520px;
  padding: 10px;
  z-index: 2100;

  .merge-tag {
    cursor: pointer;
    width: 150px;
    padding: 4px;
    margin: 6px;
    border: 1px dashed #dadbdd;
  }
}

.rich-text-editor {
  .rich-text-editor-false,
  .source-input-false {
    visibility: hidden;
    height: 0px;
    overflow: hidden;
  }

  .rich-text-editor-true {
    visibility: visible;
  }

  .source-input-true {
    visibility: visible;
    min-height: 240px;
    width: 596px;
    margin-bottom: 16px;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid rgb(224, 224, 224);
    padding: 12px 15px;
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
  }

  .ql-tooltip {
    left: 0px !important;
  }

  .ql-editor {
    white-space: pre-line !important;
    background-color: #ffffff;
    border-radius: 4px;
  }

  &.disabled {
    .ql-editor {
      background-color: rgba(7, 16, 26, 0.05);
    }
    .custom-toolbar {
      display: none;
    }
  }

  &.disabled * {
    cursor: not-allowed;
  }

  .ql-toolbar.ql-snow {
    font-family: 'Roboto', sans-serif;
    padding: 6px;
    .ql-mergetags {
      width: 98px;
      color: var(--arya-accent-color);
    }
  }

  .ql-formats {
    margin: 1px;
  }
}

.ql-snow .ql-picker.ql-size {
  width: 85px;
  color: #83878c;
}

.ql-snow .ql-stroke {
  stroke: #83878c;
}

.ql-snow .ql-fill {
  fill: #83878c;
}

.ql-toolbar.ql-snow .ql-picker-label {
  color: #83878c;
}

.ql-snow.ql-toolbar button {
  width: 25px;
}

.ql-container {
  border-radius: 0px 0px 4px 4px;
  border: 1px solid rgb(224, 224, 224);
}

.ql-snow .ql-editor {
  blockquote.ql-indent {
    border-left: 0px;
    padding-left: 0px;
    margin: 0px;
  }
}
.highlight {
  background-color: yellow;
  font-weight: bold;
}

.popupEditor {
  display: flex;
  flex-direction: column-reverse;
}
