.contactTabs {
  height: 400px;
  :global {
    .ant-tabs {
      .ant-tabs-left-bar {
        .ant-tabs-tab {
          text-align: right;
          margin: 0;
          height: 28px;
          padding-top: 4px;
          padding-right: 0 !important;
          padding-bottom: 8px !important;
          padding-left: 16px !important;
        }
      }
      .ant-tabs-left-content {
        border-left: 0;
      }
    }
  }
}
.candidateProfileContactTabs {
  :global {
    .ant-tabs-tab-active {
      color: #07101ae5;
      font-weight: 700;
    }
    .ant-tabs-ink-bar {
      background-color: inherit;
    }
  }
}
.emailTabs,
.smsTabs {
  :global {
    .ant-tabs-tab-active {
      color: #51575e;
      font-weight: 600;
    }
    .ant-tabs-ink-bar {
      background-color: #13c26b !important;
    }
  }
}
.phoneTabs {
  :global {
    .ant-tabs-tab-active {
      color: #51575e;
      font-weight: 600;
    }
    .ant-tabs-ink-bar {
      background-color: #13c26b !important;
    }
  }
}
.emptyChat {
  margin-top: 126px;
}

.infiniteScrollDiv {
  height: 100vh;
  overflow-y: auto;
  width: 95%;
}

.loader {
  width: 100%;
  text-align: center;
  padding-bottom: 100px;
}
.loadingIcon {
  font-size: 20px;
}
