.bot-question {
  border: 1px solid #dadbdd;
  border-radius: 4px;
  padding: 6px 24px;
  background-color: #f3f6f7;
  margin-bottom: 20px;
  cursor: move;

  .question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    margin-bottom: 10px;

    textarea {
      border: none;
      border-bottom: 1px dashed;
      background: transparent;
      width: 400px;
      resize: none;
      padding-left: 0px;
    }
  }

  .answer {
    display: flex;
    align-items: center;

    .ant-form-item-children,
    .ant-form-item-control {
      line-height: 21px;
    }
  }
}

.ant-form-item.ant-row {
  margin-bottom: 0px;
}

.questions {
  margin-top: 20px;
}
