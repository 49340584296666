.quick-search-container {
  display: flex;
  margin: auto;
  justify-content: space-between;
  padding-bottom: 5px;
  position: relative;
  bottom: 48px;
  overflow: hidden;

  .quick-search-form,
  .quick-search-candidates {
    background-color: white;
  }

  .quick-search-form {
    z-index: 1000;
    box-shadow: 0 0 5px 0 rgba(7, 16, 26, 0.15);
    // align-self: flex-start;
    // margin-right: 16px;
  }

  .quick-search-candidates {
    box-shadow: 0 2px 6px 0 rgba(7, 16, 26, 0.15);
  }

  .quick-search-candidate-filter {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    margin-top: 12px;

    .selected-aggregation-filter-wrapper {
      display: flex;
      align-items: center;
      flex: auto;

      .filtered-candidates-search-result {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #dadbdd;
      }
    }
  }

  .clear-aggregation-filter-button {
    color: #13c26b;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    margin-top: 10px;
  }
}

.quick-search-checkbox {
  position: absolute;
  left: -3.5%;
  visibility: visible;
  display: flex;
  align-items: center;
  height: 150px;
  width: 10%;
  justify-content: flex-start;
  cursor: pointer;
}

.quick-search-drawer {
  .ant-drawer-content-wrapper {
    /* NOTE: This is a hack because 'antd' is adding inline width to drawer*/
    width: 60% !important;
    min-width: 650px !important;
  }
}

.quick-search-form {
  width: 25%;
  position: relative;
  border-right: 1px solid #e9e9ea;
  margin-left: 2px;

  .pipeline-remaining-count {
    position: absolute;
    top: -22px;
    right: -70px;
    width: 36px;
    height: 22px;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pipeline-remaining-count.active {
    background-color: var(--arya-accent-color);
  }

  .pipeline-remaining-count.inactive {
    background-color: var(--arya-error-color);
  }

  h1 {
    width: 83%;
    margin: auto;
  }
}

.quick-search-form > .ant-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quick-search-form > .ant-form > div {
  width: 100%;
}

.quick-search-candidates {
  width: calc(75% - 20px);
  margin-right: 2px;

  > div {
    width: 95%;
    margin: auto;
  }

  .ant-tabs {
    overflow: visible;
  }

  .ant-empty {
    height: calc(100vh - 270px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-empty-image {
      height: 40px;
    }
  }
}

.quick-serach-empty-list > svg {
  width: 275px;
  font-size: 40px;
  height: 40px;
}

.quick-search-no-candidates-found,
.quick-search-no-option-selected {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
}

.quick-search-no-candidates-found-message {
  height: 24px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
}

.quick-search-header {
  display: flex;
  // align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: 48px;
  height: 148px;
  margin-top: var(--navbar-height);
  background-color: rgba(7, 16, 26, 0.04);

  .quick-search-title-container {
    margin-top: 16px;
  }

  .back-to-job {
    color: rgba(7, 16, 26, 0.7);
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;

    i {
      margin-right: 8px;
    }
  }

  .quick-search-title {
    color: rgba(7, 16, 26, 0.9);
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.34px;
    line-height: 40px;
  }

  button {
    border-radius: 20px;
  }

  .anticon-close-circle {
    font-size: 32px;
    margin-left: 16px;
    color: rgba(7, 16, 26, 0.3);
  }

  .update-intel-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 32px;

    .auto-email-tag-container {
      margin-top: 16px;
    }

    .update-intel-section {
      display: flex;
      align-items: center;

      .update-intelligence {
        background-color: #13c26b;
        color: #ffffff;
        margin-left: 16px;
      }

      .anticon-info-circle {
        font-size: 24px;
        color: rgba(7, 16, 26, 0.3);
      }
    }
  }
}

.quickSearchPagination {
  padding-top: 20px;
  padding-bottom: 20px;
  float: right;
}
