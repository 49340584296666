.candidateExperiencesContainer {
  color: #51575e;
  overflow: hidden;
  text-overflow: ellipsis;
  .candidateCardExperinces {
    width: fit-content;
    .candidateExperiences {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.pastExperienceTooltip {
  max-width: 550px;
  :global .ant-tooltip-inner {
    max-height: 250px;
    overflow-y: auto;
  }
  mark {
    color: #1f2730 !important;
    background-color: #ffff5a !important;
    padding: 0;
  }
  mustHaveMark {
    color: #1f2730 !important;
    background-color: pink !important;
    padding: 0;
  }
}
