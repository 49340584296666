.recommendationTags {
  height: 22px;
  border: 1px solid #dadbdd;
  border-radius: 11px;
  background-color: white;
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
}

.recommendationContainer {
  margin-bottom: 20px;
}

.recommendationHeader {
  color: rgba(7, 16, 26, 0.9);
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

.recommedationTagGroup {
  max-height: 50px;
  overflow: auto;
}
