.candidatesTab {
  position: static;
  margin-top: 5px;
}
.scoutingAgentTabCandidatesTab {
  :global {
    .ant-tabs-nav-scroll {
      padding-top: 16px;
    }
  }
}
.cadidateSearchTab {
  :global .ant-tabs {
    position: static !important;
  }
}
.loadingIcon {
  display: flex;
  align-items: center;
  gap: 4px;
  i {
    color: grey;
  }
}
.scoutingAgentWithCount,
.smartAgentWithCount {
  display: flex;
  cursor: pointer;
  gap: 4px;
}
.manualSearchTabs {
  :global {
    .ant-tabs-extra-content {
      line-height: 55px;
    }
  }
  .extraTabContent {
    border: 0px;
    padding: 0px 12px;
  }

  .extraTabActiveDiv {
    border-radius: 0px;
    border-bottom: 2px solid #13c26b !important;
    padding-bottom: 3px;
  }

  .smartAgentCandidateCountDiv {
    padding: 2px 8px;
    background-color: #13c26b;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    border-radius: 15px;
    line-height: 16px;
    text-align: center;
    height: 20px;
  }
}
