.supplyFilterContainer {
  width: 300px;
  position: absolute;
  left: 105%;
}
.supplyFilterAffix {
  :global {
    .ant-affix {
      box-shadow: 0px 0px 5px #d9d9d9;
    }
  }
}
.supplyApplyFilterRow {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 10px;
  text-align: center;
  margin-top: -1px;
}
.supplyCollapseView {
  width: 100%;
  :global {
    .ant-card-body {
      padding: 0px;
    }
    .ant-collapse-content-box {
      padding: 0px !important;
    }
    .ant-list-item {
      padding: 10px !important;
    }
    .ant-card-extra {
      padding-right: 6px !important;
    }
    .ant-collapse-header {
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.switchElement {
  font-size: 12px;
  color: rgba(7, 16, 26, 0.5);
}
