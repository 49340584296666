.viewSampleCandidatesContainer {
  .footerWrapper {
    background-color: white;
    padding: 20px 60px;
    display: flex;
    box-shadow: 0 -2px 3px 0 rgba(7, 16, 26, 0.15);
    .draftBtn {
      margin: 0px 10px;
      padding: 0 25px;
    }
    .saveBtn {
      padding: 0 25px;
    }
  }

  .footerAlignment {
    justify-content: space-around;
  }

  .paidPlanDescription {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    padding: 15px 0;
  }

  .stepsWrapper {
    padding-top: 30px;
    margin-left: 10.8% !important;
    width: 760px !important;
    display: inline-block;

    .backToJob {
      position: relative;
      top: 22px;
      left: -90px;
      display: flex;
    }
  }
}
