#job-details {
  padding: var(--body-content-padding);
  padding-top: 36px;
  position: relative;
  margin: auto;

  .auto-email-tag-container {
    margin-top: 24px;
  }

  .ant-tabs {
    position: static;
    overflow: visible;
  }
}

.engaged-button {
  border-radius: 20px;
  background-color: #13c26b;
}

.engaged-button-text {
  height: 34px;
  width: 160px;
  color: #ffffff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
}
