.skillSelection {
  position: relative;
  padding-top: 20px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e8e8e8;
  .clearAllButton {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 20px;
    gap: 5px;
    align-items: center;
  }
  .skillsSectionLabel {
    font-size: 13px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
  .aryaAssistedSkills {
    margin-top: 5px;
    &.minimize {
      margin-top: 0px;
    }
  }
  .booleanSkills {
    margin-top: 5px;
  }
  &.minimize {
    padding-top: 0px;
  }
}

.skillsLabel {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.skillTag {
  display: inline-flex;
  border-radius: 3px;
  margin-bottom: 8px;
  margin-right: 8px;
  cursor: pointer;
  &.selected {
    color: #21c574;
    border: 1px solid #21c574;
    background-color: #ffffff;
    font-weight: 500;
    .skillTagItem:not(:last-child) {
      border-right: 1px solid #21c574;
    }
  }

  &.unselected {
    color: #808489;
    border: 1px solid #83878c;
    background-color: #ffffff;
    font-weight: 500;
    .skillTagItem:not(:last-child) {
      border-right: 1px solid #83878c;
    }
  }

  &.prominent {
    background-color: rgba(33, 197, 116, 0.11);
    font-weight: bold;
  }

  .skillTagItem {
    display: flex;
    align-items: center;
    padding: 2px 4px;
  }
}

.booleanSkillsText {
  font-size: 13px;
  font-weight: 500;
}

.suggestedSkills {
  margin-top: 15px;
}

.suggestedSkill {
  cursor: pointer;
  border: 1px solid #83878c;
  display: inline-block;
  border-radius: 3px;
  color: #808489;
  padding: 2px 4px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.booleanOperators {
  display: flex;
  gap: 10px;
}

.booleanOperatorTag {
  cursor: pointer;
  display: inline-block;
  border: 1px solid #91d5ff;
  border-radius: 2px;
  background-color: #e6f7ff;
  padding: 2px 8px;
  color: #1890ff;
}

.queryString {
  margin-top: 15px;
  .booleanStringTextArea {
    min-height: 150px;
  }
}

.additionalSkillsContent {
  padding: 10px;
  width: 400px;

  .additionalSkillsHeader {
    font-size: 11px;
    font-weight: 500;
  }

  .skillSuggestionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .aliasSkillsHeader {
      display: flex;
      justify-content: space-between;
      .selectAllCheckbox {
        font-size: 11px;
        :global .ant-checkbox-inner {
          height: 14px;
          width: 14px;
        }
      }
    }
    .skillsBox {
      border: 0.5px solid #979797;
      border-radius: 4px;
      padding: 5px;
      .skillsBoxHeader {
        color: rgba(7, 16, 26, 0.7);
        font-size: 11px;
        font-weight: 500;
      }
      .skillsList {
        display: flex;
        flex-wrap: wrap;
        max-height: 100px;
        overflow-y: scroll;
        margin-top: 5px;
        .additionalSkillTag {
          cursor: pointer;
          display: inline-flex;
          color: #808489;
          border: 1px solid #83878c;
          border-radius: 3px;
          font-size: 11px;
          align-items: center;
          padding: 2px;
          margin-right: 5px;
          margin-bottom: 5px;
          &.selected {
            border: 1px solid #13c26b;
            color: #13c26b;
          }
          .selectedSkill {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }
}
