.grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(0, 5fr) minmax(0, 2fr);
  grid-template-columns: minmax(0, 5fr) minmax(0, 2fr);

  .job-detail {
    overflow-x: auto;
    grid-row: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
}

.job-info-blur {
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  margin: 15px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #dadbdd;
}

.job-title-synonyms,
.job-description,
.job-special-notes,
.job-summary {
  font-size: 14px;
  margin-bottom: 24px;
}

.job-description-body,
.job-special-notes-body,
.job-summary-body {
  overflow: auto;
  word-wrap: break-word;
}

.html-resume-viewer {
  > p,
  ol,
  ul,
  dl {
    margin: 0px;
  }
}

.job-description-body .html-resume-viewer > p.MsoListParagraphCxSpMiddle,
.job-description-body .html-resume-viewer > p.MsoListParagraphCxSpFirst,
.job-description-body .html-resume-viewer > p.MsoListParagraphCxSpLast {
  text-indent: 0 !important;
}

.job-info-label {
  color: #374251;
  font-size: 14px;
  font-weight: 500;
}

.job-detail-content {
  color: #374251;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.job-detail-resume-content {
  cursor: pointer;
}

.jobdetail-created-date {
  color: #83878c;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 20px;
}

.right-col-info-row {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 32px;
  }
  .job-location-info {
    margin-top: 5px;
  }
}

.right-col-info-row-salary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 32px;
  }
  .job-location-info {
    margin-top: 5px;
  }
}

.right-col-info-row-salary-info {
  display: flex;
  margin-bottom: 20px;
  gap: 62px;
  &:last-of-type {
    margin-bottom: 32px;
  }
  .job-location-info {
    margin-top: 5px;
  }
}

.job-synonym {
  width: 100%;
  margin-bottom: 24px;
}

.job-description {
  width: 100%;
}

.job-resume > .job-info-label {
  margin-bottom: 5px;
}

.job-resume .job-detail-content {
  margin-bottom: 5px;
}

.resume-format {
  color: #83878c;
}

.right-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  -ms-grid-row: 1;
  -ms-grid-column: 2;

  .job-detail-content {
    color: #374251;
    font-family: Roboto, sans-serif;
    font-size: 14px;
  }

  .job-given-info {
    border-bottom: 1px solid #dadbdd;
  }

  .job-derived-info {
    margin-top: 32px;

    .job-must-skills {
      .boolean-string-display-component-wrapper {
        width: auto;
        justify-content: stretch;

        .boolean-string-display-component {
          width: auto;
          margin-right: 5px;
        }

        .musttohaveskill-tag-style {
          background-color: #e6eff7;
          white-space: normal;
        }

        .musttohaveskill-tag-style-paid {
          background-color: var(--arya-accent-color);
          color: #ffffff;
        }
      }
    }
  }

  .openings-and-experience {
    display: flex;
    justify-content: space-between;
  }
}

.tag-style {
  border-radius: 11px;
  margin-left: 0px;
  margin-right: 6px;
  margin-bottom: 5px;
}

.tag-section-label {
  margin-bottom: 5px;
}

.industry-tag-style {
  background-color: #fff4e3;
  white-space: normal;
}

.job-tag-style {
  border: 1px solid #dadbdd;
  background-color: #f2f3f3;
  color: rgba(7, 16, 26, 0.7);
}

.nicetohave-skill-style,
.edu-qualification-tag-style {
  background-color: #dcf3e6;
  white-space: normal;
}

.nicetohave-skill-style-paid,
.musttohaveskill-tag-style-paid,
.industry-tag-style-paid,
.edu-qualification-tag-style-paid {
  background-color: #13c26b;
  color: #ffffff;
}

.job-apply-url {
  margin-bottom: 16px;

  a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    font-size: 14px;
  }
}

.share-job-text {
  color: #374251;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.social-media-icons-wrapper {
  display: flex;
  align-items: center;

  svg {
    width: 27px;
    height: 27px;
    margin-right: 15px;
    border-radius: 20px;
    cursor: pointer;
  }
}

.job-diversity-info {
  width: 257px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

.job-publish-button {
  width: 155px;
  margin-right: 12px;
  padding-left: 12px;
  padding-right: 11px;
}

.highlighted-card {
  border: 1px solid #52c41a;
}

.aod-request-quote {
  position: relative;
  > i:after {
    content: 'NEW';
    position: absolute;
    font-size: 12px;
    top: 26px;
    right: 14px;
    font-weight: 900;
    color: white;
  }
}
