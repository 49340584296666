.job-status-change-filter-dropdown {
  width: 200px !important;

  .ant-select-dropdown-menu {
    max-height: 30vh;
    padding: 10px 0px;

    .ant-select-dropdown-menu-item {
      padding: 6px 24px;
    }
  }
}
.job-status-disabled-select {
  .ant-select-selection {
    border-radius: 16px !important;
  }
}

.job-status-change-filter > .ant-select-selection .ant-select-selection-selected-value,
.job-status-change-filter > .ant-select-selection:hover .ant-select-selection-selected-value,
.job-status-change-filter > .ant-select-selection:active .ant-select-selection-selected-value,
.job-status-change-filter > .ant-select-selection:focus .ant-select-selection-selected-value {
  max-width: 75px;
}
