.candidateStatus {
  margin: 12px 0px 28px 0px;
  display: flex;
  width: 100%;
}

.pipelineWrapper {
  width: 100%;
  margin-right: 10px;
  cursor: pointer;
}

.rejectButton {
  padding: 4px 0px 0px 4px !important;
  width: 2.3em;
  cursor: pointer;

  .rejectButtonCount {
    display: none;
  }

  .icon {
    font-size: 25px;
  }
}

.rejectButton:hover .rejectButtonCount {
  display: inline;
}

.rejectButton:hover .icon {
  display: none;
}

.rejectedCount {
  display: flex !important;
  justify-content: center;
}
