.connectTabs {
  .master-profile-tab-content {
    height: calc(90vh - 106px);
    overflow-y: auto;

    .chat-window-container {
      height: 87%;
    }

    #connect-mail-window {
      height: 85%;
    }

    #connect-message-window {
      height: 87%;
    }
  }

  .ant-badge-dot {
    background-color: #13c26b;
  }
}
.connectTabs360View {
  #connect-mail-window-360 {
    height: 85%;
    padding-right: 10px;
    margin-right: 10px;
    overflow-y: auto;
    padding-bottom: 55px;
    margin-top: 10px;
  }
  .connect-message-form-360 {
    height: 72%;
    padding-right: 10px;
    margin-right: 10px;
    padding-left: 10px;
  }
  height: calc(70vh + 2px);
}
.tab-media-icon-connect.anticon {
  font-size: 24px;
  // color: #c8d1db;
}

.tab-media-icon.active {
  color: var(--arya-accent-color);
}

.coonect-iconstab {
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0px;
    width: 100px;
  }

  .anticon {
    margin: 0px 19px;
  }
}
