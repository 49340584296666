.candidateSupplyBanner {
  display: flex;
  padding: 30px 15px 20px 30px;
  .item:not(:last-child) {
    border-right: 2px solid #e3e3e3;
  }
  .item:is(:first-child) {
    padding-left: 0;
  }
}

.item {
  height: 70px;
  width: 250px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
  display: flex;
  padding: 0 14px;
  span {
    font-weight: 900;
  }
  .icon {
    width: 44px;
    .iconSize {
      font-size: 44px;
    }
  }
  .text {
    padding-left: 10px;
    text-align: left;
  }
}
