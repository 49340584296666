.reject-reasons-suggestions-wrapper {
  .reject-reasons-suggestions {
    max-height: 120px;
    overflow: auto;
  }
}

.see-more-less-suggestions-button {
  height: 22px;
  width: 22px;
  border: 1px solid #dadbdd;
  border-radius: 11px;
  background-color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .anticon {
    > svg {
      width: 11px;
      height: 7px;
      display: flex;
      align-items: center;
    }
  }
}
