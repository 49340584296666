.page-not-allowed {
  .background-dummy-image {
    width: var(--app-width);
    margin-left: 70px;
  }

  .user-alert-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-150px, -60px);
    z-index: 100;
  }
}
