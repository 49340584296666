.locationInsightWrapper {
  padding: 15px 15px 30px 55px;
  :global {
    .ant-collapse {
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
      border: none;
    }
    .ant-collapse-content {
      border-top: none;
    }
  }
}

.infoWrapper {
  display: flex;

  .infoText {
    padding-left: 5px;
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    span {
      color: #13c26b;
    }
  }

  .infoIcon {
    color: #1890ff;
    font-size: 46px;
  }
}
