.multiLocationInputGroup {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  .primaryLocationRadio {
    display: flex;
    justify-content: center;
  }
  .modifyLocationActionContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}
