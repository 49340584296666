.candidateCardMetaData {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding: 0px;
  .candidateMetadataTag {
    font-size: 14px;
    border-radius: 24px;
    background-color: white;
    color: #07101a;
    margin: 0px;
    overflow: hidden;
    max-width: 171px;
    padding: 1px 9px;
    text-overflow: ellipsis;
    flex-wrap: wrap;
    white-space: nowrap;
    .highlightMetdataText {
      font-weight: 500;
    }
  }
}
