.formWrapper {
  display: block;
  margin: auto;
  padding-bottom: 50px;
  width: 620px !important;
  border-radius: 12.8px;
  background: linear-gradient(180deg, #fafbfc 0%, #f4f6f8 100%);
  box-shadow: 0 2px 3px 0 #ccc8c8;
  margin-top: 50px !important;
}

.formHeader {
  padding: 15px 0px 15px 100px;
  background: linear-gradient(-58.11deg, #004eac 0%, #00bc7a 100%);
  border-top-left-radius: 12.8px;
  border-top-right-radius: 12.8px;
  span {
    color: #ffffff;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    display: block;
    padding-bottom: 5px;
  }
  a {
    color: white;
    border-bottom: 0.5px solid #ffffff;
    font-size: 12px;
    cursor: pointer;
  }
  a:hover {
    color: white !important;
  }
  .link {
    font-size: 12px;
    padding: 0;
    border-bottom: 0.5px solid #ffffff;
    display: inline;
    cursor: pointer;
  }
}

.formAlignment {
  padding-left: 100px;
  padding-top: 20px;
  width: 490px;
  :global {
    .ant-select-selection {
      overflow-x: hidden;
      max-height: 95px;
      overflow-y: scroll;
    }
  }
}

.saveBtn {
  padding-top: 25px;
}

.milesTooltip {
  .ant-tooltip {
    z-index: 0;
  }
}

.remoteHiringText {
  color: rgba(7, 16, 26, 0.9);
  font-weight: 500;
  line-height: 22px;
}

.warningTextWrapper {
  margin-bottom: 15px;
  .titleEditWarningText {
    margin-left: 5px;
    color: rgba(7, 16, 26, 0.5);
    font-size: 12px;
  }
}

.locationInputLabelWrapper {
  display: flex;
  justify-content: space-between;
  .locationLabelSecondaryContent {
    height: 22px;
    color: rgba(7, 16, 26, 0.3);
    font-size: 12px;
    font-style: italic;
    line-height: 35px;
  }
}

.formFieldAlignment {
  margin: 10px 0px;
}

.infoIcon {
  color: #1890ff;
  font-size: 22px;
  margin-left: 3px;
  vertical-align: middle;
  cursor: pointer;
}

.zipCodeMilesWrapper {
  display: flex;
  margin-top: 25px;
  .milesSlider {
    width: 100%;
    margin-left: 15px;
    :global {
      .ant-slider-step {
        background: rgba(7, 16, 26, 0.05);
      }
    }
  }
}
