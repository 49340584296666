.filterCheckbox {
  margin-bottom: 8;
  width: 100%;
}

.candidateFilterDisplayContentSection {
  width: 500px;
  .candidateFilterItemWraper {
    display: 'flex';
  }
}

.candidateFilterPopover {
  z-index: 1900;
}

.candidateFilterAggregation {
  display: flex;
  align-items: baseline;
  width: 500px;
  justify-content: center;
  height: 40px;
}

.candidateFilterSource {
  width: 47%;
  padding: 17px 0px 17px 17px;
}

.candidateFilterApplyClear {
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidateFilterClearButton,
.candidateFilterApplyButton {
  height: 32px;
  width: 79px;
  border: 1px solid #dadbdd;
  border-radius: 20px;
  margin-left: 10px;
  line-height: 22px;
  font-size: 14px;
}

.candidateFilterClearButton {
  background-color: #ffffff;
}

.candidateFilterTitle {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
