.supplyItemsUl {
  list-style: none;
  border-right: 1px solid #f0f0f0;
  text-align: right;
  cursor: pointer;

  .itemName {
    color: rgba(7, 16, 26, 0.9);
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: right;
    padding-right: 12px;
    border-right: 1px solid transparent;
  }
  :global {
    li:not(:last-child) {
      padding-bottom: 20px;
    }
    .ant-anchor-link-active > .ant-anchor-link-title {
      color: #13c26b;
      font-weight: 430;
    }
    .is-active {
      color: #13c26b;
      border-color: #13c26b;
    }
    .ant-anchor-ink {
      height: 0;
    }
    .ant-anchor-ink-ball {
      border: 0;
    }
  }
}
