.resumeWrapper {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 5px;
  .durationTypeSelect {
    width: 90px;
    min-height: 32px;
    border-radius: 4px;
  }
  .durationTypeQuantity {
    width: 80px;
    margin: 0px 10px;
  }
  .durationLabel {
    color: #51575e;
    font-size: 14px;
    font-weight: 400;
  }
}

.resumeLastUpdatedLabelText {
  font-size: 16px;
  color: #1f2730;
  font-weight: 500;
  height: 24px;
}
