.listItemMenuOption {
  display: flex;
  padding: 3px 4px;
  width: 64px;
  justify-content: space-between;
}

.listItemMenuOptionText {
  height: 16px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}
