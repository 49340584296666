.form-panel-item {
  cursor: pointer;
  color: rgba(7, 16, 26, 0.9);
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  box-shadow: inset 0 -1px 0 0 rgba(7, 16, 26, 0.09);
  i > svg {
    height: 10px;
    width: 6px;
  }
}

.selected-form-panel-item {
  background-color: rgba(19, 194, 107, 0.2);
  font-weight: 500;
}

.form-panel-item-popover {
  max-width: 281px;
  z-index: 100;
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  .ant-popover-inner-content {
    padding: 12px 10px 16px 12px;
    .ant-form-item-label {
      line-height: 22px;
      > label {
        font-size: 14px;
        color: rgba(7, 16, 26, 0.9);
      }
    }
    .ant-form-item-control-wrapper {
      margin-top: 3px;
      max-height: 316px;
      overflow: auto;
    }
  }
  .ant-checkbox-group-item {
    margin-top: 3px;
    margin-right: 0px;
  }
}
