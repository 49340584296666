.network-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--navbar-height);

  .network-error-img {
    height: 100px;
    padding: 10px;
  }

  .network-error-title {
    color: rgba(7, 16, 26, 0.9);
    margin-bottom: 10px;
    font-size: 20px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }

  .network-error-message {
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
}
