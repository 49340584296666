.job-settings-container {
  padding-bottom: 56px;

  #slider {
    width: 50%;
  }

  #activation-setting {
    display: flex;
    justify-content: space-between;
  }

  .template-from-description-icon {
    font-size: 16px;
    color: #83878c;
    vertical-align: middle;
  }

  .template-from-icon-container {
    margin-left: 15px;
  }
}

#confirmation-buttons {
  position: fixed;
  width: 924px;
  display: flex;
  justify-content: flex-end;
  bottom: 0px;
  right: 0px;
  padding: 12px 30px;
  background-color: white;
  border-top: 1px solid #d9d9d9;
  z-index: 3000;

  #cancel-btn,
  #save-btn {
    border-radius: 30px;
  }

  #cancel-btn {
    margin-right: 16px;
  }
}

.slider-settings-tooltip,
.source-limit-info-tooltip {
  z-index: 2701;
}

.job-settings-panel-content {
  padding-left: 24px;
  padding-top: 4px;
  .auto-email-from-container {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    line-height: 22px;
    height: 32px;
    gap: 15px;
  }
}

.send-to-filter-container {
  display: flex;
  margin-top: 14px;
  margin-bottom: 4px;

  .send-to-filter-label {
    font-weight: 500;
    margin-right: 10px;
  }
}

.job-settings-panel-description {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 22px;
  color: #83878c;
}

.job-settings-template-selector {
  margin-bottom: 6px;
  height: 32px;
  width: 384px;
}

.template-selector-with-label-wrapper {
  display: flex;
  height: 32px;
  margin-bottom: 6px;
}

.job-settings-template-from {
  border: #dadbdd 1px solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 32px;
  width: 60px;
  color: #83878c;
  padding: 4px 12px;
  margin-bottom: 6px;
}

.job-settings-template-from-selector {
  margin-bottom: 0px;
  height: 32px;
  width: 324px;

  .ant-select-selection {
    border-left-width: 1px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.job-settings-drips-info {
  padding: 0px 10px 0px 10px;
  margin-top: 8px;

  & > span {
    border-radius: 12px;
    border: 1px solid #dadbdd;
    background-color: #f2f3f3;
    padding: 5px;
    font-size: 12px;
    line-height: 20px;
  }
}

.connect-template-body {
  // margin-bottom: 20px;

  .ql-container {
    border-radius: 4px;

    .ql-editor {
      min-height: 80px;
      max-height: 176px;
      padding: 4px 12px;
      overflow: auto;
      border-radius: 4px;
    }
  }
}

.connect-template-subject {
  margin-bottom: 6px;

  .ql-container {
    .ql-editor {
      line-height: 22px;
      height: 32px;
      overflow: auto;
      white-space: nowrap;
      padding: 4px 12px;
    }
  }
}

.connect-template-send-to {
  margin-right: 8px;
  font-weight: 500;
}

.connect-templates-dropdown [role='option'] {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .connect-template-name {
    margin-right: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.message-window-template-consent-text {
  border: 1px solid #dadbdd;
  border-radius: 0 0 4px 4px;
  background-color: rgba(7, 16, 26, 0.03);
  padding: 0px 15px;
}

.message-window-template-consent-note-wrapper {
  padding: 15px;
  background-color: #ffffff;
}

.message-window-template-consent-note {
  font-weight: 500;
}

.templateLabel {
  font-size: 14px;
  font-weight: 400;
  color: #005ce6;
  margin-bottom: 5px;
}

.panel-content-disable {
  opacity: 0.5;
  cursor: not-allowed;
}
