.order-status-icon {
  font-size: 120px;
  padding: 10px;
}
.order-failed-status-icon {
  font-size: 161px;
  padding: 10px;
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#overlay div {
  color: white;
  position: relative;
  font-size: 20px;
  height: 202px;
  width: 414px;
}

.loading-logo {
  margin-left: 165px;
  margin-right: 165px;
  margin-bottom: 50px;
  height: 100px;
  width: 100px;
}
.order-error-modal {
  text-align: center;
  padding: 15px;
}

.order-error-modal-title {
  color: rgba(7, 16, 26, 0.9);
  font-size: 36px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
  margin-top: 40px;
}

.order-error-modal-description {
  color: rgba(7, 16, 26, 0.7);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}

.order-error-modal-try-again-button {
  font-size: 16px;
  height: 40px;
  min-width: 87px;
  border-radius: 20px;
}
