.candidateIntelWrapper {
  height: calc(100vh - 275px) !important;
  overflow: auto;
}

.extendView {
  height: calc(100vh - 105px) !important;
}

.extendView > * {
  min-height: calc(100vh - 100px) !important;
}
