.ClientFormContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sourcingParameterHeader {
  color: var(--secondary-black-color);
  font-size: 20px;
}
.sourcingParameterDescription {
  color: #83878c;
  font-size: 16px;
}

.subsidaryCandidatesInfo {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.addressSelectWidth {
  width: 375px;
}
