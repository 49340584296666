#message-popup-wrapper {
  display: flex;
  overflow-x: auto;
  max-width: 90vw;

  .chat-message-popup {
    margin-left: 12px;
  }

  #horizontal-scroll {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
}
