.jobboard-integration {
  border: 1px solid #e6e7e8;
  border-radius: 10px;
  margin-bottom: 16px;
}

.jobboard-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 16px 15px;
}

.jobboard-integration-login-form {
  width: 100%;
}

.jobboard-country-code {
  width: 80px;
  height: 32px;
  line-height: 32px;
}

.portal-login-form {
  display: flex;
  border-top: 1px solid #e6e7e8;
  padding: 12px 0px 12px 15px;
}

[class~='jobboard-integration']:last-of-type {
  margin-bottom: 0px;
}

.authentication-check-circle {
  color: var(--arya-accent-color);
  font-size: 20px;
}

.authentication-tick {
  margin-right: 12px;
  margin-top: 6px;
}

.authentication-untick {
  margin-right: 12px;
  width: 20px;
}

.jobboard-image {
  min-width: 32px;
  max-width: 80px;
  margin-right: 9px;
  min-height: 30px;

  img {
    max-width: 100%;
    max-height: 32px;
  }
}

.email-limits {
  margin-top: 60px;
}

.email-limits-heading {
  font-size: 16px;
  margin-bottom: 20px;
}

.max-total-emails,
.max-daily-emails,
.email-batch-count,
.applicant-count {
  display: inline-block;
}

.max-total-emails-heading,
.max-daily-emails-heading,
.email-batch-count-heading,
.applicant-count-heading {
  font-weight: 500;
}

.email-limits-input-number {
  width: 160px;
  margin-right: 19px;
}

.add-account-btn {
  font-size: 14px;
  color: rgba(7, 16, 26, 0.7);
  border: 1px solid #dadbdd;
}

.btn-container {
  width: 122px;
  line-height: 32px;
}

.jobboard-credentials-container {
  display: flex;
  // line-height: 32px;
}

.jobboard-input-container {
  margin-right: 16px;
}

.jobboard-image-wrapper {
  display: flex;
  align-items: center;

  .job-board-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .ant-tag {
    border-radius: 11px;
  }
}

.job-board-text {
  margin: auto;
  font-weight: 500;
  color: rgba(7, 16, 26, 0.9);
}
