.supplyLocationFilter {
  width: 100%;
  :global {
    .ant-form-item-control {
      line-height: normal;
    }
    .location-filter-button {
      display: flex;
    }
    .last-button {
      margin-left: 20px;
    }
  }
}

.selectDropdown {
  border-radius: 4px;
  :global {
    .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
      border-radius: 11px;
    }
    .ant-select-selection {
      overflow-x: hidden;
      max-height: 95px;
      overflow-y: auto;
    }
  }
}

.inputDistance {
  :global {
    .ant-input-number-handler-wrap {
      visibility: hidden;
    }
    .ant-input-number {
      width: 100%;
    }
    .ant-input-number-input {
      width: 70%;
    }
  }
}

.inputDistanceSuffix {
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 25%;
  color: #bfbfbf;
  position: absolute;
  float: right;
  right: 15px;
  top: 53%;
}
