.ordersContainer {
  border-radius: 5px;
  min-height: 350px;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  margin: 50px 0px;
  padding: 24px 32px;
}

.orderItemContainer {
  color: rgba(7, 16, 26, 0.9);
  margin-bottom: 20px;
}

.orderHeader {
  display: flex;
  align-items: baseline;

  h2 {
    margin: 0;
  }
}

.verticalLine {
  border-left: 1.5px solid #dadbdd;
  margin: 0 10px;
}

.orderItem {
  border: 1px solid #dadbdd;
  border-radius: 4px;
  padding: 15px 20px;
  border-left: 4px solid var(--arya-accent-color);
}

.orderItemWrapper {
  display: flex;
  align-items: center;
}

.orderInfo {
  display: flex;
  align-items: center;
}

.centerDot {
  height: 5px;
  width: 5px;
  background-color: #929292;
  border-radius: 50%;
  margin: 0px 5px;
}

.orderInfo > p {
  margin: 0;
}

.orderSideContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 12px;
    margin: 3px;
  }
}

.orderDetails {
  margin-left: 20px;
}

.orderDetailsWrapper {
  display: flex;
  width: 100%;
}

.orderDetailsHeadings {
  display: inline-block;

  p {
    font-size: 16px;
    font-weight: bold;
  }
}

.orderDetailsValues {
  display: inline-block;
  margin-left: 30px;
  width: 70%;

  p {
    font-size: 16px;
  }
}

.orderDetailsTitle {
  font-size: 24px;
  margin-left: 20px;
}

.orderCreatedTime {
  color: #00cc71;
  font-size: 16px;
  margin-bottom: 20px;
}

.invoiceNumber {
  opacity: 0.76;
}

.paymentMethod {
  width: max-content;
}

.textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.paginationContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.orderSortIcon {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
}

.controlsButton {
  border: 0px;
  color: #c7d1db;
  padding: 0px 10px;
  box-shadow: none;
}

.orderSortingRadio {
  display: block;
  height: 30px;
  line-height: 30px;
}

.orderSortingRadioDiv {
  border-top: 1px solid #dadbdd;
}

.sortingFilterSortbyClear {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.sortingFilterSortby {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
}

.sortingFilter {
  padding: 10px;
}

.orderHistoryEmpty > svg {
  width: 275px;
  height: 60px;
}

.orderHistoryNoOrderFound {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
}

.orderTypeWrapper {
  margin-left: 15px;
}

.freeOrderType {
  color: #fa541c;
  height: 23px;
  border: 1px solid #ffbb96;
  border-radius: 11px;
  background-color: #fff2e8;
  padding: 0px 10px 0px 10px;
}

.basicOrderType {
  color: #52c41a;
  height: 23px;
  border: 1px solid #b7eb8f;
  border-radius: 11px;
  background-color: #f6ffed;
  padding: 0px 10px 0px 10px;
}

.premiumOrderType {
  color: #1890ff;
  height: 23px;
  border: 1px solid #afe1ff;
  border-radius: 11px;
  background-color: #e5f7ff;
  padding: 0px 10px 0px 10px;
}

.fullServiceCreditWrapper {
  margin-left: 15px;
}

.jobTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.jobClient {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.jobLocation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
