.candidateTags {
  padding: 0 10px 0 10px;
  display: flex;
  flex-wrap: wrap;
}

.candidateTag {
  background-color: #ffffff;
  border: 1px solid #b4b7ba;
  border-radius: 11px;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
  height: 24px;
}

.addTag {
  margin-top: 5px;
}
