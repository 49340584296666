.displayContainer {
  display: block;
  margin: 100px 100px;
  padding: 10px;
  text-align: center;

  .templateSelection {
    display: inline-flex;
    flex-direction: column;
  }

  .headingSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .displayCards {
    display: flex;
    align-items: center;
    gap: 60px;

    .cardTitle {
      font-size: 16px;
      line-height: 23px;
    }

    .cardDescription {
      color: #51575e;
      display: inline-block;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 80px;
      max-height: 80px;
    }

    :global {
      .ant-card-meta-detail {
        text-align: left;
      }

      .ant-card-meta-title {
        font-size: 16px;
      }

      .ant-card-body {
        padding: 15px 15px;
      }
    }
  }
}

.displayCard {
  padding: 0px 0px 5px 0px;
  width: 240px;
  height: 382px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px #00000040;

  .cardContent {
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: space-around;
    flex-direction: column;
  }

  &:hover {
    background: #f6ffed;
    border: 1px #13c26b solid;
  }
}
