.locationSectionWrapper {
  flex: 1;
  padding-top: 25px;

  .locationLabel {
    color: rgba(7, 16, 26, 0.7);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .locationSection {
    display: flex;
    flex-direction: row;

    .enhancedLocationWrapper {
      display: flex;
      flex-direction: row;
      gap: 2px;
      width: 73%;
      .locationTabs {
        margin-top: 15px;
      }
    }
    :global {
      .ant-select-selection,
      .ant-select-focused,
      .ant-input,
      .ant-input-number {
        border: none !important;
        border-bottom: 1px solid gainsboro !important;
        border-radius: 0px;
        box-shadow: none !important;
      }
      .ant-select-selection__rendered {
        margin-left: 4px !important;
      }
      .ant-input,
      .ant-input-number-input {
        padding-left: 4px;
      }
    }
  }

  .minimizedLocationSection {
    width: 780px !important ;
  }
}
