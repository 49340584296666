.sectionWrapper {
  padding-left: 55px;
  padding-bottom: 10px;
  :global {
    .ant-alert {
      margin-bottom: 15px;
      margin-right: 15px;
    }
  }
}

.title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

.subTitleWrapper {
  display: flex;
  margin-right: 15px;
  margin-bottom: 15px;
}

.subTitle {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  span {
    color: rgba(7, 16, 26, 0.7);
    font-size: 11px;
    font-style: italic;
  }
}

.checkboxAlignment {
  margin-left: auto;
  line-height: initial;
  .mustHave {
    color: #13c26b;
    font-weight: 500;
  }
  :global {
    .ant-checkbox-inner {
      border: 1px solid #13c26b !important;
      width: 18px !important;
      height: 18px !important;
    }
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.itemWrapper {
  width: 25%;
  display: flex;
  align-items: center;
}

.item {
  padding: 5px 10px;
  justify-content: space-between;
  display: flex;
  border-radius: 5px;
  width: 100%;
  margin: 10px 15px 10px 5px;
  cursor: pointer;
}

.active {
  border: 2px solid #13c26b;
}

.inActive {
  border: 1px solid grey;
}

.activeCheckbox {
  visibility: visible;
  :global {
    .ant-checkbox-inner {
      border: 1px solid #13c26b !important;
      width: 18px !important;
      height: 18px !important;
    }
  }
}

.inactiveCheckbox {
  visibility: hidden;
}

.text {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.addMore {
  margin-right: 5px;
  padding: 5px 10px;
  justify-content: space-between;
  display: flex;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  :global {
    .select-dropdown {
      width: 100%;
    }
    .ant-input:placeholder-shown {
      border: 1px solid black;
    }
    .ant-input::placeholder {
      color: black;
    }
  }
}

.withCheckbox {
  margin-left: 10px;
}

.withoutCheckbox {
  margin-left: 0;
  padding-left: 5px;
}

.experienceWrapper {
  display: flex;
  margin-left: 5px;
  .minExp {
    margin-right: 20px;
  }
}

.securityClearanceWrapper {
  margin-top: 25px;
}
