.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.jobTitleTag {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 240px;
}

