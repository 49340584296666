.supplyContentDivider {
  width: 100%;
}
.supplyContent {
  width: 100%;
  padding: 10px 0px 20px 10px;
  min-height: 240px;
  .headerSupplyInfo {
    align-items: center;
    margin-bottom: 18px;
  }
  .headerInfo {
    display: flex;
    align-items: center;
  }
  .headerTitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(7, 16, 26, 0.9);
  }
  .headerButton {
    margin-left: auto;
  }
  .headerDescription {
    color: rgba(7, 16, 26, 0.7);
    font-size: 16px;
    line-height: 24px;
  }
  .subInfo {
    font-weight: 300 !important;
    font-size: 14px;
  }
  .listTitle {
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .supplyTopListView {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    .supplyName {
      width: 425px;
      display: inline-block;
    }
    :global {
      .ant-progress-line {
        width: 68%;
        margin-left: 12px;
      }
      .ant-progress-bg {
        background-color: #13c26b;
      }
    }
  }
  .insightListView {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 50%;
    }
  }
  .supplyListView {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    .supplyName {
      width: 425px;
      font-size: 16px;
    }
    :global {
      .ant-progress-line {
        width: 68%;
      }
    }
  }
  .educationLabel {
    color: rgba(7, 16, 26, 0.9);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
  }
  .bubbleCharts {
    margin-left: -94px;
    margin-top: -65px;
  }
  .emptyIcon {
    transform: translate(0, 40px);
    font-size: xxx-large;
    width: 48px;
    height: 47px;
  }
}

.supplyListItem {
  display: flex;
  align-items: baseline;
  .percent {
    color: #13c26b;
    line-height: 20px;
    display: inline-block;
  }
  .displayName {
    color: rgba(7, 16, 26, 0.7);
    margin-left: 16px;
    display: inline-block;
    position: relative;
    max-width: 350px;
  }
}
.BarChartView {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.experienceColorMapView {
  margin-bottom: 48px;
  :global {
    div {
      height: 48px;
      text-align: center;
      color: #fff;
    }
    span {
      color: #fff;
      line-height: 3.5;
    }
  }
}

.skillsMapList {
  margin-right: 32px;
  margin-bottom: 8px;
  :global {
    .ant-progress-inner {
      border-radius: 0px;
      background-color: #e6e7e8;
    }
    .ant-progress-bg {
      border-radius: 0px;
      height: 36px !important;
      background-color: rgba(19, 147, 194, 0.2);
    }
    .ant-progress-text {
      float: right;
      margin-top: -24px;
      position: relative;
      color: rgba(7, 16, 26, 0.7);
      font-size: 12px;
      margin-right: 18px;
    }
    .ant-progress-outer {
      margin-right: 0px;
      padding-right: 0px;
    }
    .ant-progress-line:before {
      content: attr(data-label);
      position: absolute;
      top: 6px;
      left: 10px;
      z-index: 2;
      color: rgba(7, 16, 26, 0.7);
      font-size: 16px;
      max-width: 77%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.colorMapView {
  margin-bottom: 48px;
  :global {
    div {
      height: 48px;
    }
  }
}
.skillsListView {
  display: flex;
  align-items: baseline;
  :global {
    .ant-badge-status-text {
      color: rgba(7, 16, 26, 0.7);
      letter-spacing: 0;
      line-height: 24px;
      display: inline-block;
      position: relative;
      width: 370px;
    }
    .ant-badge-status-dot {
      width: 8px;
      height: 8px;
    }
  }
}

.educationChatView {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.educationRelevanceChartLabels {
  margin-left: 30px;
  .educationDegreeLabel {
    position: relative;
    display: flex;
    align-items: baseline;
    max-width: 250px;
  }
  .educationDegreeLabelColor {
    margin: 0px 8px 0 0;
    height: 10px;
    width: 10px;
    border-radius: 5px;
  }
}
.jobLabels {
  margin-left: 26px;
  .educationJobRequirementLabelContainer {
    height: auto;
    border: 1px solid #dadbdd;
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 8px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
  }
  .jobRequirementColor {
    height: 12px;
    width: 5px;
    border-radius: 2.5px;
    margin: 3px 8px 0 4px;
    background-color: var(--arya-accent-color);
  }
  .jobRequirementHeading {
    color: rgba(7, 16, 26, 0.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
  .jobRequirementName {
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    display: inline-block;
    max-width: 170px;
  }
}

.BarChartContainer {
  margin-left: 28px;
}

.bubbleChartDataValue {
  font-size: 13px;
  fill: #83878c;
}
