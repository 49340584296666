.settingsLayout {
  display: flex;
}

.affixWrapper {
  margin: 34px 15px 0px 0px;
  width: 200px;
  display: flex;
  justify-content: flex-end;
}

.sider {
  margin-right: 16px;
  width: 140px;
}

.siderWrapper {
  display: flex;
  justify-content: flex-end;
}

.verticalLine {
  display: inline-block;
  vertical-align: middle;
  min-height: 112px;
  width: 1px;
  transform: scaleX(-1);
  background-color: #d8d8d8;
}

.content {
  margin: 0px 34px 50px 0px;
}

.affix {
  width: 160px;
}
