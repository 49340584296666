.candidateDetailPushedCandidate {
  line-height: 1;
}
.candidateDetailPushCandidate {
  border: 1px solid #dadbdd;
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 14px !important;
  line-height: 1;
}
