.container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 103px;
  right: 282px;
  min-width: 492px;
  justify-content: space-between;
}
.containerWithoutBasicPlan {
  right: 155px !important;
}
.offerTag10Percent {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;

  p {
    color: #52c41a;
  }
}

.offerTag20Percent {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;

  p {
    color: #1890ff;
  }
}

.offerTag {
  box-sizing: border-box;
  height: 23px;
  width: 58px;
  border-radius: 11px;

  p {
    height: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    padding-top: 1px;
    padding-left: 6.5px;
  }
}

.unitPrice {
  height: 16px;
  width: 80px;
  color: rgba(7, 16, 26, 0.7);
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 16px;
}

.bodyContent {
  display: flex;
  flex-direction: column;
  width: 240px;
}

.innerBody {
  display: flex;
  height: 40px;
  flex-direction: row-reverse;
  justify-content: initial;
}

.planContent {
  display: inline-block;
  flex: 1;

  p {
    margin-bottom: 0px;
  }
}

.nonDiscountContent,
.amount {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  padding-left: 80px;

  p {
    height: 24px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
    margin: auto;
  }
}

.discountContent {
  display: flex;
  justify-content: space-evenly;
  height: 40px;
  width: 32%;
  align-items: center;
}

.border {
  border-top: 1.5px solid #dadbdd;
  margin-bottom: 30px;
}
.discountedAmount {
  text-decoration: line-through;
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: unset;
  margin-right: 8px;
}

.actualAmount {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin: unset;
}
.bundleInput {
  flex: 1;
  max-width: 36.5%;
  margin-right: -3.2px;
  padding-top: -4px;
  border-left: 1px solid #979797;
  font-weight: 800;
  position: relative;
  :global {
    .ant-select {
      width: 100% !important;
      .ant-select-selection {
        border: none !important;
        box-shadow: none !important;
      }
    }
    .ant-input-number-focused {
      border: none !important;
      box-shadow: none !important;
    }
    .ant-form-explain {
      position: absolute;
      width: 340px;
      height: 22px;
      left: -460%;
      top: 95px;
    }
    .ant-input-number-handler-wrap {
      opacity: 1 !important;
    }
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
      color: #83878c !important;
    }
  }
  .jobsCount {
    margin-top: 4px;
    padding-left: 40px;
  }
}
.offerText {
  width: 75%;
  display: flex;
  margin-left: 30px;
  align-items: center;
  color: rgba(7, 16, 26, 0.3);
}
.selectJobCreditTotalOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  margin: auto;
  padding: 30px 36px;
  border: 1px solid #979797;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;

  .grandTotal {
    font-size: 16px;
    font-weight: bold;
    color: #080808;
  }

  .selectCreditButtons {
    width: 160px;
    display: flex;
    justify-content: space-between;

    .cancelButton {
      width: 75px;
      border: 1px solid #dadbdd;
      background-color: #ffffff;
      color: rgba(7, 16, 26, 0.7);
    }
    .nextButton {
      width: 75px;
    }
  }
}
.selectJobCreditTotalOptionWithoutBasicPlan {
  width: 690px !important;
}
.inputCount {
  width: 106px;
  height: 34px;
  border-radius: 0px;
  border: 0px;
  z-index: 0;
}
