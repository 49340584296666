.advanced-options-alternate-locations {
  .adv-options-alternate-locations-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .zipcode-location {
    display: flex;
    width: 270px;
  }
  .ant-select-selection {
    width: 150px;
    margin-right: 10px;
  }
  .zipcode-input {
    width: 105px;
    margin-right: 5px;
  }
  .location-label {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }
  .ant-input {
    font-size: 14px;
  }
  .add-more-locations-button {
    border-style: dashed;
    border-radius: 20px;
    margin-bottom: 8px;
  }
  .alternate-location-delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }
}
