.modalWrapper {
  .ant-modal {
    width: 456px;
    height: 170px;
  }
}
.modalContent {
  color: #07101a;
  display: flex;
  gap: 8px;
  font-size: 16px;
}
.iconDiv {
  font-size: 21px;
  color: #ffcc00;
}
