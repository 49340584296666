.wrapper {
  display: flex;
}
.image {
  width: 130px;
  height: 420px;
}
.textWrapper {
  width: 468px;
  margin-left: 52px;
  text-align: left;
  padding: 42px 0;
}
.headingText {
  margin-bottom: 12px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 36px;
  font-weight: bold;
}
.subHeadingText {
  color: rgba(7, 16, 26, 0.9);
  font-size: 15px;
  margin-bottom: 36px;
}
.bodyText {
  color: rgba(7, 16, 26, 0.9);
  font-size: 12px;
  margin-bottom: 16px;
}
.advancedJobButton {
  color: #ffffff;
  text-align: center;
  margin-bottom: 14px;
}
.spanStyle {
  display: block;
  margin-bottom: 46px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 11px;
}
.normalJobButton {
  display: block;
  text-decoration: underline !important;
  margin-bottom: 46px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 11px;
}
.footerText {
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  font-weight: 500;
}
.buyCreditLink {
  text-decoration: underline !important;
  color: #13c26b;
}
.freeText,
.candidateText {
  color: #13c26b;
}
