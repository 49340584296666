.creditInformationTitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #1f2730;
  padding-bottom: 12px;
}

.creditInformationSubtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #51575e;
  padding-bottom: 24px;
}

.jobSearchInput {
  input {
    border-radius: 30px;
  }
}

.creditRequestTable {
  margin-top: 20px;
}

.creditRequestTableHeader {
  font-size: 12px;
  font-weight: 500;
  color: #83878c;
}

.creditRequestTableJob {
  display: flex;
  gap: 5px;
  align-items: center;
}

.creditRequestTableJobTitle {
  font-weight: 500;
  font-size: 14px;
  color: #1f2730;
  width: 98px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.creditRequestTableJobId {
  font-weight: 400;
  font-size: 12px;
  color: #51575e;
  align-items: center;
}

.creditRequestButton {
  width: 76px;
  border-radius: 16px;
  padding: 1px 12px;
  &.creditRequestApproveButton {
    background-color: #13c26b;
    color: #ffffff;
  }
  &.creditRequestDismissButton {
    background-color: #dadbdd;
    color: #51575e;
  }
}

.approvedDismissedInfo {
  font-weight: 400;
  font-size: 14px;
  color: #51575e;
}

.creditRequest {
  display: flex;
  gap: 63px;
}

.creditRequestHeaderWithCount {
  display: flex;
  gap: 6px;
}
.creditRequestCount {
  display: flex;
  justify-content: center;
  width: 17px;
  height: 17px;
  border-radius: 10px;
  background-color: #13c26b;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
}

.jobUsageBudgetPagination {
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.creditFilterPopoverContainer {
  display: flex;
  width: 100%;
  height: 240px;

  .leftTab {
    width: 33%;
    padding: 15px 0;
    border-right: 1px solid #e8e8e8;
  }
  .rightTab {
    padding: 16px 0 16px 0;
    width: 67%;
    .dateContainer {
      margin-left: 22px;
      .date {
        width: 328px;
      }
    }

    .approvalContainer {
      .approvalItem {
        height: 36px;
        padding: 8px 0 0 24px;
      }
    }

    .dateShortcutKeys {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      p {
        cursor: pointer;
        color: #1f2730;
      }
    }
    .approvalContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;

      .selectedApprovaItem {
        background: #f5f5f5;
      }
    }
  }
  .menuItem {
    font-size: 14px;
    height: 37px;
    padding: 0 16px;
    color: #1f2730;
    cursor: pointer;
    padding-top: 8px;
  }

  .selectedMenuItem {
    background: #f5f5f5;
    font-weight: 600;
    display: flex;

    span {
      margin-left: auto;
    }
  }
}

.creditInfoFilter {
  :global {
    .ant-popover-inner {
      box-shadow: 0px 0px 3px 0px #00000027;
      border: 1px solid #dadbdd;
      border-radius: 4px;
    }
  }
}

.creditFilterTitleContainer {
  display: flex;
  width: 552px;
  justify-content: space-between;
  padding: 10px 0;

  .applyButton {
    margin-left: 12px;
  }
}

.creditInformationFilterTitle {
  font-size: 16px;
  font-weight: 400;
}

.hide {
  display: none !important;
}

.creditAccepted {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
}

.creditRejected {
  display: flex;
  align-items: center;
  justify-content: left;
}

.acceptRejectButtons {
  display: flex;
  gap: 24px;
}

.name {
  display: flex;
  gap: 10px;
  align-items: center;
}

.requestLoader {
  display: flex;
  justify-content: center;
}

.dot {
  position: absolute;
  background-color: #13c26b;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  top: -1px;
  right: -3px;
}

.noRequestFoundClearSearch {
  height: 24px;
  color: #13c26b;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-top: 10px;
}

.requestsListEmpty > svg {
  width: 275px;
  height: 60px;
}

.noJobUsageBudgetRequestFound {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
}

.noJobUsageBudgetRequestFoundMessage {
  height: 24px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
}

.filterIcon {
  cursor: pointer;
  :global .ant-badge {
    .ant-badge-dot {
      background-color: #13c26b;
    }
  }
}
