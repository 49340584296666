.candidateJobs {
  padding: 4px 28px;
  height: calc(100vh - 275px) !important;
  overflow-y: auto;
}
.extendView {
  height: calc(100vh - 105px) !important;
}

.extendView > div {
  min-height: calc(100vh - 100px) !important;
}

.matchingJobsButton {
  padding-top: 3px;
}
