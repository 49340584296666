.workflowDetailsFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 100px;
  gap: 5px;

  .workflowFooterNote {
    margin: auto auto auto 0;
    color: #51575e;
  }

  .disclaimerMessage {
    color: #51575e;
    padding-right: 18px;
  }
}

.mailWindowFooterbuttonContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  .updateWorkflow {
    margin-bottom: 0;
    text-decoration: underline;
    color: #51575e;
    cursor: pointer;
  }
}

.saveDropDown {
  min-width: 250px;
  li {
    font-weight: 500;
    color: #1f2730;
  }
}

.iconStyle {
  height: 100%;
  display: grid;
  place-content: center;
  border-left: 1px solid white;
  padding-left: 12px;
  padding-right: 16px;
  i {
    color: white;
    font-size: 16px !important;
  }
}
