.modalContent {
  height: 240px;
}

.candidateTransferIcon {
  i > svg {
    width: 24px !important;
    height: 17px !important;
  }
}

.action {
  border-bottom: 1px solid #dadbdd;
  font-weight: 500;
  color: #1f2730;
}
.actionRadioButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 18px;
  margin-top: 5px;
  font-weight: 400;
}
.destination {
  margin-top: 12px;
  font-weight: 500;
  color: #1f2730;
}
.desinationRadioButtons {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-weight: 400;
}
.select {
  width: 100%;
  z-index: 21001;
  input {
     padding-right: 15px;
    }
}
.ant-select-dropdown-menu {
  z-index: 210000;
}
.candidateTransfer {
  width: 24px;
}
