.candidateCardInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  overflow:hidden;
  .candidateScoreWithWidgets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}
.candidateCardInfo > :last-child {
  margin-bottom: 8px;
}
