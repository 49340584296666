.card {
  height: 82px;
  width: 100%;
  font-family: Roboto, sans-serif;
  border-bottom: 1px solid #cfd5db;
  border-left: 6px solid #ffffff;
  background-color: #ffffff;
  padding: 10px 10px 10px 15px;
  cursor: pointer;
}

.isActive {
  border-left-color: #01cd75;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.titleBox {
  height: 21px;
  width: 100%;
  display: flex;
  align-items: center;

  .extraInfo {
    margin-left: auto;
    text-align: center;

    .countBadge {
      font-size: 10px;
      height: 17px;
      max-width: 25px;
      color: #fff;
      padding: 0.5px 4px;
      border-radius: 5.5px;
      background-color: #01cd75;
    }
  }
}

.title {
  vertical-align: bottom;
  letter-spacing: 0.64px;
  width: 85%;
  font-size: 14px;
  font-weight: 500;
  color: #374251;
}

.countBadge {
  margin-left: auto;
  font-size: 10px;
  height: 17px;
  max-width: 25px;
  color: #fff;
  padding: 0.5px 4px;
  text-align: center;
  border-radius: 5.5px;
  background-color: #01cd75;
}

.primaryInfoBox {
  font-size: 12px;
  color: #647182;
  letter-spacing: 0.55px;
  height: 18px;
}

.secondaryInfoBox {
  height: 18px;
  color: #647182;
  display: flex;
  align-items: center;
}

.secondaryInfo {
  width: 60%;
  font-size: 12px;
}

.timestampBox {
  margin-left: auto;
  font-size: 10px;
  width: 44%;
  text-align: right;
  letter-spacing: 0.45px;
}

.job-external-source {
  box-sizing: border-box;
  height: 18px;
  width: 14px;
  border: 1px solid #d3adf7;
  border-radius: 2px;
  background-color: #f9f0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.job-external-source-text {
  color: #722ed1;
  font-size: 12px;
  line-height: 20px;
}
