.sourceLimitWrapper {
  padding: 10px;
  border: 1px solid #dadbdd;
  border-right: 0px;
  border-radius: 4px 0px 0px 4px;
  height: 46px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
  line-height: 22px;
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-weight: 500;

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(7, 16, 26, 0.1);
  }
}

.sourceLimitPopover {
  :global .ant-popover-arrow {
    display: none;
  }

  :global .ant-popover-title {
    padding-left: 40px;
    font-size: 16px;
    font-weight: 500;
    background: rgba(7, 16, 26, 0.09);
  }
}

.popoverContent {
  padding: 30px 80px 30px 40px;
}

.popoverContentFooter {
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 8px;
}

.sourcingLimitSlider {
  margin: 50px 0px;
}

.boldText {
  color: rgba(7, 16, 26, 1);
}
