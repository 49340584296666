.settings-title {
  padding-top: 30px;
  margin-left: 46px;
  margin-top: 31px;
  margin-bottom: 39px;
  padding-left: 40px;
}

.settings-tab-wrapper {
  padding-left: 46px;
  padding-right: 46px;
}

.navigator-group {
  display: inline-block;
  margin-right: 16px;
  vertical-align: middle;
}

.setting-navigation {
  color: #202632;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  margin-bottom: 8px;
  cursor: pointer;
}

.vertical-line {
  display: inline-block;
  vertical-align: middle;
  height: 111px;
  width: 1px;
  transform: scaleX(-1);
  background-color: #d8d8d8;
}

.settings-container {
  display: flex;
}

.current-settings-wrapper {
  // display: inline-block;
  // vertical-align: top;
  margin: 51px 34px 50px 0px;
}

.settings-navigator {
  margin: 34px 15px 0px 0px;
  width: 200px;
  display: flex;
  justify-content: flex-end;

  .navigator-group-wrapper {
    > div {
      display: flex;
    }
  }
}

.settings-section {
  width: 875px;
  padding-bottom: 51px;
  padding-left: 26px;
  padding-right: 28px;
  border-bottom: 1px solid #e6e7e8;
  padding-top: 40px;
}

.current-settings-wrapper :first-child {
  // padding-top: 0px;
}
