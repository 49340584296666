.distanceForm {
  display: flex;
  gap: 10px;
}

.input {
  width: 104px;
}

.selectDropdown {
  width: 150px !important;
}
