.title {
  background-color: white;
}

.userDetails {
  display: flex;
  align-items: center;
  gap: 12px;
}

.displayName {
  margin-right: 4px;
  font-weight: 500;
  text-transform: capitalize;
}

.postedAt {
  margin-right: 4px;
  font-weight: 400;
  color: #adacb0;
}

.aryaRecruiterTag {
  color: #1751d0;
  background-color: #ebf0fb;
}

.content {
  font-size: 16px;
  font-weight: 400;
  max-height: 350px;
  overflow-y: scroll;
  overflow-wrap: break-word;
}

.userInfo {
  display: flex;
  gap: 4px;
}

.threadMessage {
  padding: 20px;
  border-radius: 9px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  border-radius: 9px;
  border: 1px solid  #e6e6e6;
  background:  #fff;

}
