.quick-search-filters {
  padding-bottom: 32px;
  .ant-form-item {
    margin-bottom: 10px;
    .ant-form-item-label {
      line-height: 22px;
    }
    .ant-form-item-control {
      line-height: 1;
    }
    .ant-form-item-label label {
      font-size: 14px;
    }
  }
  .aggregation-filter-radio-component {
    display: flex;
    &.radio-column {
      flex-direction: column;
    }
  }
}
