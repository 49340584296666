.titleContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .scoutingAgentTitle {
    color: #1f2730;
    font-size: 16px;
    font-weight: 400;
  }
  .createScoutingAgentWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    .createScoutingAgentButton {
      visibility: hidden;
      background-color: #01cd75;
      color: white;
    }
  }
}

.header {
  color: #1f2730 !important;
  p {
    margin-bottom: 2px;
    line-height: 30px;
  }
}

.emptyText {
  color: #1f2730;
  width: 57%;
  margin: auto;
}

.frquencyDay {
  z-index: 3050;
}

.scoutingAgentTableDisplay {
  :global {
    .ant-table-placeholder {
      border-bottom: none;
    }
  }
}
