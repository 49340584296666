.segmentNote {
  margin-top: 30px;
  margin-bottom: 5px;
}

.noteSubject {
  margin-bottom: 10px;
}

.noteEditor {
  :global {
    .ql-editor {
      height: 100px;
    }
  }
}

.noteSave {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
