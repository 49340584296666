.nodeContainer {
  position: relative;
  max-width: fit-content;
  height: auto;
  padding: 5px 10px 5px 10px;
  text-align: center;
  border: 1px solid #dadbdd;
  border-radius: 6px;
  cursor: pointer;

  .sendEmailNode,
  .sendSMSNode,
  .addTagNode,
  .addCustomFieldNode,
  .delayNode,
  .endNode {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 175px;

    .actionTitle {
      font-size: 15px;
      text-decoration: none;
      color: #722ed1;
    }

    .delayTitle {
      font-size: 15px;
      text-decoration: none;
      color: #fa541c;
    }

    .tagContent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-size: 12px;

      .tagItem {
        color: black;
      }
    }

    .customTagContent {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-size: 10px;

      span {
        margin-bottom: 1px;
      }
    }

    .dealyContent {
      font-size: 10px;
    }

    .endTitle {
      font-size: 15px;
      text-decoration: none;
      color: #f5222d;
    }

    .subTitleSpan {
      font-size: 12px;
    }
  }

  .ruleNode {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 340px;

    .ruleTitle {
      font-size: 15px;
      text-decoration: none;
      color: #007bff;
    }
  }
}
