.experienceSection {
  flex: 1;
  padding-top: 25px;
  padding-bottom: 25px;

  .experienceLabel {
    color: rgba(7, 16, 26, 0.7);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .experienceRangeWrapper {
    display: flex;
    gap: 15px;
    margin-top: 5px;

    .experienceRangeLabel {
      color: #484b4f;
      font-size: 13px;
      font-weight: 500;
      margin-top: 5px;
    }

    .minMaxInput {
      width: 120px;
    }
  }
}
