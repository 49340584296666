.paginationContainer {
  margin-top: 20px;
  display: flex;
  justify-content: right;
  position: relative;
  &.onlyPrevNext {
    :global .ant-pagination {
      :global .ant-pagination-item-active ~ .ant-pagination-item {
        display: none;
      }
      :global .ant-pagination-jump-next {
        display: none;
      }
    }
  }
  &.segmentView {
    :global .ant-pagination {
      :global .ant-pagination-item-active ~ .ant-pagination-item {
        display: none;
      }
      :global .ant-pagination-jump-next {
        display: none;
      }
      :global .ant-pagination-item {
        display: none;
      }
      :global .ant-pagination-item-link {
        display: none;
      }
      :global .ant-pagination-jump-prev {
        display: none;
      }
    }
  }
  :global .ant-pagination-total-text {
    position: absolute;
    left: 0;
    border: 1px solid #dadbdd;
    border-radius: 6px;
    padding: 0px 10px;
  }
}

.prevNextButtonCommonStyle {
  color: #13c26b;
  margin: 0px 10px;
  cursor: not-allowed;
  opacity: 0.5;
}
