.subscriptionPlanLabel,
.trynowLabel {
  box-sizing: border-box;
  max-height: 23px;
  max-width: 76px;
  border-radius: 11px;
}

.subscriptionPlanText,
.trynowText {
  text-align: center;
  max-height: 20px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 20px;
}

.basicPlanLabelColor,
.trynowLabel {
  border: 1px solid #b7eb8f;
  background-color: #f6ffed;
}

.premiumPlanLabelColor {
  border: 1px solid #afe1ff;
  background-color: #e5f7ff;
}
.freePlanLabelColor {
  border: 1px solid #ffbb96;
  background-color: #fff2e8;
}
.basicPlanTextColor,
.trynowText {
  color: #52c41a;
}

.premiumPlanTextColor {
  color: #1890ff;
}
.freePlanTextColor {
  color: #fa541c;
}

.searchAgainButtonWrapper {
  display: flex;
  justify-content: flex-end;
}
