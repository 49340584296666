.auto-logout {
  border: none;
}

.consent-settings {
  border: none;
}

.sharing-header,
.auto-logout-header {
  margin-bottom: 32px;
}

.consent-header {
  margin-bottom: 12px;
}

.sharing-title,
.auto-logout-title,
.consent-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 12px;
}

.sharing-description-text,
.auto-logout-description-text,
.consent-description-text {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
}

.consent-body {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 10px;
}

.enable-sharing-title,
.enable-auto-logout-title {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.enable-auto-logout {
  margin-bottom: 26px;
}

.auto-logout-time {
  height: 24px;
  width: 760px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.app-save,
.app-save:hover,
.app-save:focus,
.app-save:active {
  background-color: var(--arya-accent-color);
  color: white;
  border-radius: 15px;
}
