.manualSearchMinimizedContainer {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #dadbdd;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.manualSearchMinimizedInputs {
  display: flex;
  gap: 20px;
}

.jobTitlePopoverContainer,
.keywordPopoverContainer,
.experiencePopoverContainer,
.locationPopoverContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 6px;
  width: fit-content;
}

.jobTitleHeading,
.keywordHeading,
.experienceHeading,
.locationHeader {
  font-weight: 600;
  font-size: 14px;
}

.titlePopoverSection,
.keywordPopoverSection,
.experiencePopoverSection,
.locationPopoverSection {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
  gap: 10px;
  border: 1px solid #83878c;
  border-radius: 15px;
  padding: 2px 6px;
  cursor: pointer;
  min-width: 180px;
  color: #1f2730;
}
.titlePopoverSection {
  min-width: 160px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .titleText {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.booleanPopoverSection {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
  gap: 10px;
  border: 1px solid #83878c;
  border-radius: 15px;
  padding: 2px 10px;
  cursor: pointer;
  min-width: 140px;
  min-width: 140px;
  color: #1f2730;
  .booleanText {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.keywordPopoverSection {
  max-width: 150px;
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .aIKeywordsText {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.experiencePopoverSection {
  min-width: 120px;
  min-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.locationPopoverSection {
  min-width: 180px;
  max-width: 245px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .locationAndCountContainer {
    display: flex;
    gap: 5px;
    .displayedLocationText {
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .displayedMiles {
    padding-left: 10px;
    border-left: 0.4px solid #b4b7ba;
  }
}

.popoverExperienceSectionContainer,
.popoverLocationSectionContainer {
  padding: 10px 20px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;
  max-width: 300px;
}

.popoverLocationSectionContainer {
  padding: 0px 10px 40px 20px;
  max-width: 890px;
}

.sideBar {
  display: flex;
  align-items: center;
  .expandButton {
    span {
      color: #51575e;
      text-decoration: underline;
    }
  }
}

.experiencePopoverFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid gainsboro;
  padding: 10px 0px;
  width: 100%;
  height: 50px;
  button.clearButton {
    border-radius: 15px;
    padding: 2px 15px;
    color: #51575e;
    font-weight: 400;
    font-size: 15px;
  }
}
