.filter {
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: 40px;
}
.filterTitle {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filterApplyClear {
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filterClearButton,
.filterApplyButton {
  border: 1px solid #dadbdd;
  margin-left: 10px;
  line-height: 22px;
  font-size: 14px;
}

.filterClearButton {
  background-color: #ffffff;
}
.popoverContent {
  width: 737px;
}
.subFilter {
  width: 47%;
  color: #1f2730;
  height: 290px;
  overflow-y: auto;
  .menuItem:hover {
    color: #1f2730;
  }
  .menuItem,
  .menuItem:not(:last-child) {
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: inherit !important;
    color: #1f2730;
    &.selected {
      background-color: #f5f5f5 !important;
    }
  }
}
.menu {
  padding-top: 12px;
  border-right: 0px;
  color: #1f2730;
  font-size: 14px;
  line-height: 22px;
}
.noMatchingJob {
  opacity: 0.5;
}
.filterOptions {
  height: 290px;
  overflow: auto;
  width: 54%;
  border-left: 1px solid #dadbdd;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-left: 30px;
  padding-top: 21px;
  font-size: 14px;
  line-height: 22px;
  color: #51575e;
  .filterCheckboxGroup {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .noMatchingFilters {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding-right: 30px;
    padding-bottom: 25px;
    font-weight: 500;
  }
}
.filterIcon {
  cursor: pointer;
  :global .ant-badge {
    .ant-badge-dot {
      background-color: #13c26b;
    }
  }
}
.menuItemAndCollapseIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collapseIcon {
  color: grey;
}
.experienceWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  .experienceRange {
    display: flex;
    gap: 14px;
    .experienceRangeText {
      color: #484b4f;
      font-size: 13px;
      font-weight: 500;
      padding-top: 4px;
    }
    :global .ant-form-explain {
      margin-top: 6px;
      font-size: 12px;
    }
    .experienceWithLabel {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .experienceFiltersLabel {
      line-height: 1;
      font-size: 13px;
      font-weight: 500;
      color: #484b4f;
    }
  }
}
