.export-data {
  position: absolute;
  right: 0px;
  height: 32px;
  width: 124px;
  border: 1px solid #dadbdd;
  border-radius: 20px;
}
.Market-intel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 10px;
  .Market-intel-button {
    border-radius: 20px;
  }
}
