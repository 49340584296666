.candidate-multi-select {
  display: flex;
  width: 100%;
  align-items: center;

  .candidate-card {
    margin: auto;
  }
}

.candidate-list-item-wrapper {
  margin: 12px 0;
  background-color: #ffffff;
  border: 1px solid rgba(7, 16, 26, 0.15);
  border-radius: 4px;
}

.dilated-intel + .candidate-list-item-wrapper {
  margin-top: 8px;
}

.candidate-multi-select-checkbox {
  position: absolute;
  left: 0;
  cursor: pointer;
  &.quick-search-select-checkbox {
    left: -3.75%;
  }
}

.candidate-empty-list > svg {
  width: 275px;
  height: 61px;
  margin-top: 25px;
}

.candidate-list-no-candidates-found {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
  padding: 19px;
  .score-and-rank-deactivated-message{
    font-size: 20;
    font-weight: 150;
  }
}
.candidate-list-free-trial-plan-no-candidates-found {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 12px;
}
.candidate-list-free-trial-plan-no-candidates-found-message {
  color: #000000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.candidate-list-free-trial-finding-candidate-gif {
  margin: 0 auto 12px;
  display: block;
}
.candidate-list-no-candidates-found-message {
  height: 24px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  .candidate-list-no-candidates-found-deactivation-is-on {
    color: rgba(7, 16, 26, 0.9);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 10px;
  }
}
.candidate-list-free-trial-text {
  text-decoration: underline;
}
.candidate-list-remove-filter {
  height: 24px;
  color: #13c26b;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-top: 10px;
}

.container{
  display:flex;
  justify-content: center;
  height: 100px;
}
