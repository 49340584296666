.add-job-icon {
  font-size: 32px;
  color: #01cd75;
}

.add-job-button {
  margin-left: 16px;
}

.add-job-menu {
  padding: 0px 0px;

  .ant-dropdown-menu-item {
    padding: 12px 24px;
  }

  .menu-divider {
    margin: 0px;
  }
}

.job-status {
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
}

.job-status-filter {
  min-width: 180px;
  font-size: 28px;
  color: rgba(7, 16, 26, 0.9);
  box-shadow: 0 0 0 0px;
}

.job-status-filter > .ant-select-selection,
.job-status-filter > .ant-select-selection:hover,
.job-status-filter > .ant-select-selection:active,
.job-status-filter > .ant-select-selection:focus {
  border: 0px;
  box-shadow: 0 0 0 0px;
}

.recruiter-select-positioner {
  margin-right: 15px;
}

.organization-dropdown {
  display: flex;
}

.right-align {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.close-circle-icon {
  margin-left: 70px;
  cursor: pointer;
}
.job-search {
  display: flex;

  .jobs-input-search {
    input {
      border-radius: 30px;
      width: 180px;
    }

    input:focus {
      width: 300px;
      border: 1px solid var(--arya-accent-color);
    }
  }

  .ant-input-suffix .jobs-input-search {
    z-index: 0;
  }
}

.job-list-header {
  display: flex;
  align-items: center;
}

.ant-select-dropdown-menu-item-selected {
  color: rgba(7, 16, 26, 90) !important;
  font-weight: 600 !important;
  background-color: #fafafa;
}

.jobstatus-dropdown {
  width: 250px !important;

  .ant-select-dropdown-menu {
    max-height: 35vh;
    padding: 10px 0px;

    .ant-select-dropdown-menu-item {
      padding: 8px 24px;
      color: rgba(131, 135, 140, 1);
      font-weight: 400;
    }
  }
}

.job-status-text {
  max-width: 256px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-status-text-limited {
  max-width: 180px;
}

.job-status-count {
  margin-left: 5px;
}
