.candidate-reject-card {
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  border: 1px solid rgba(7, 16, 26, 0.15);
  border-left: 4px solid red;
  cursor: auto;
  .select-reject-reasons-text {
    color: rgba(7, 16, 26, 0.9);
  }
}

.candidate-reject-card.candidate-card {
  .candidate-reject-reasons-wrapper {
    width: 72%;
    margin-top: 22px;
    margin-bottom: 15px;
  }
}

.candidate-reject-card.quick-search-list {
  .candidate-reject-card-intel-icon-wrapper {
    width: 25%;
  }

  .candidate-reject-reasons-wrapper {
    width: 75%;
  }
}

.candidate-reject-card.medium {
  border-bottom: 1px solid rgba(7, 16, 26, 0.15);
  justify-content: space-between;

  .candidate-reject-reasons-wrapper {
    margin-top: 0px;

    .select-reject-reasons-text {
      margin-top: 12px;
    }

    .reject-similar-candidate {
      margin-bottom: 16px;

      &.disabled {
        color: rgba(7, 16, 26, 0.3);
      }
    }
  }

  .candidate-reject-card-reject-reasons {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
  }

  .candidate-reject-card-intel-icon-wrapper {
    display: flex;

    .anticon {
      margin-right: 12px;
    }

    .refine-text {
      margin-top: 0px;
    }
  }
}

.candidate-reject-card-reject {
  border-left: 1px solid rgba(7, 16, 26, 0.15);
  width: 7%;
  min-width: 82px;
  .confirm-reject-disable {
    color: rgba(7, 16, 26, 0.2);
    cursor: not-allowed;
  }
}

.candidate-reject-card-reject-reasons {
  padding: 0px 20px 0px 34px;
  display: flex;
  justify-content: space-between;
}

.reject-similar-candidate {
  margin-top: 5px;

  .ant-form-item-control {
    line-height: 0px;
  }
}

.candidate-reject-cancel {
  border-bottom: 1px solid rgba(7, 16, 26, 0.15);
  height: 20%;
}

.candidate-reject-confirm {
  height: 80%;
  flex-direction: column;
}

.candidate-reject-cancel,
.candidate-reject-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidate-reject-cancel:hover,
.candidate-reject-confirm:hover {
  cursor: pointer;
}

.candidate-reject-card-intel-icon-wrapper {
  margin-top: 16px;

  .anticon {
    height: 54px;
    width: 54px;
    background-color: var(--arya-accent-color);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 27px;
  }

  .refine-text {
    margin-top: 10px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 20px;
    font-weight: 500;
  }

  .give-reasons-text {
    color: rgba(7, 16, 26, 0.9);
    font-size: 14px;
  }

  i > svg {
    background-color: var(--arya-accent-color);
    height: 30px;
    width: 30px;
  }
}

.reject-reasons-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.candidate-reject-card-text {
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
}

.reject-reason-tag {
  display: flex;
  align-items: center;
  border-radius: 16px;
  height: 32px;
  border: 1px solid #dadbdd;
  font-size: 14px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 3px 16px;
  background-color: #ffffff;
  cursor: pointer;

  &.unselected-reject-reason,
  &.ant-tag-checkable {
    &.ant-popover-open,
    &.ant-tag:hover:not(.ant-tag-checkable-checked) {
      color: var(--arya-error-color);
      border: 1px solid var(--arya-error-color);
    }

    &.ant-tag-checkable-checked:active {
      background-color: rgba(7, 16, 26, 0.05);
    }
  }

  &.selected-reject-reason,
  &.ant-tag-checkable-checked {
    background-color: rgba(7, 16, 26, 0.05);
    color: var(--arya-error-color);
  }

  &.ant-tag-checkable:active {
    background-color: #ffffff;
  }
}
