
.unsubscribeButtonPopoverContent {
  margin:10px;
.radioGroup {
  display: flex;
  flex-direction: column;
  .radio {
    display: block;
    height: 30px;
    line-height: 30px;
    font-weight: 500;
 }
}
.textArea {
  margin : 10px 0px 0px 5px;
}
.submitButton {
  display: flex;
  justify-content: flex-end;
  .buttonMargin {
    margin: 10px;
  }
}
}

.popoverContent {
  margin-left: 24px;

  .unsubscribeCandidateButton {
    border-color: rgb(250 226 20 / 96%);
    border-width: 2px;
  }
  .unsubscribeCandidateButtonText {
  color: rgba(250, 226, 20, 0.96);
  }

}




