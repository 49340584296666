.segmentCard {
  display: flex;
  align-items: center;
}
.segmentCardDetails {
  padding: 18px;

  & ~ .segmentCardDetails {
    border-top: 1px solid #e6eaee;
  }

  &:last-of-type {
    border-bottom: 1px solid #e6eaee;
  }
  & [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }
}
.segmentCardDetails:hover {
  box-shadow: 0 2px 8px -2px rgba(32, 38, 50, 0.3);
}

.segmentCardDetails ~ .segmentCardDetails:hover {
  border-top: 1px solid transparent;
}
.creatorName,
.segmentName,
.campaigns {
  font-size: 16px;
  line-height: 24px;
  color: #07101a;
}
.status {
  font-size: 14px;
  line-height: 24px;
  color: #07101a;
}
.none {
  font-size: 16px;
  line-height: 24px;
  color: #83878c;
}
.segmentName {
  cursor: pointer;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 25px;
  text-decoration: underline;
}
