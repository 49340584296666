.ant-input-search.user-search.ant-input-affix-wrapper > input {
  border-radius: 20px;
  height: 40px;
  width: 385px;
  border: 1px solid #dadbdd;
  background-color: #ffffff;
}

.checkbox-style {
  border-bottom: 1px solid rgb(233, 233, 233);
  margin-bottom: 11px;
  padding-bottom: 10px;
  width: 385px;
}

.share-template-model > .ant-modal-content > .ant-modal-body {
  padding-bottom: 0px;
}

.select-all-checkbox-style {
  margin-top: 10px;
  border-bottom: 1px solid rgb(233, 233, 233);
  margin-bottom: 11px;
  padding-bottom: 10px;
  width: 385px;
}

.ant-btn.add-collaborator-button {
  background-color: white;
  border: none;
}

.plus-icon {
  position: relative;
  font-size: 30px;
  float: right;
  color: #aaaaaa;
  bottom: -5px;
}

.user-list {
  height: 300px;
  overflow: scroll;
}

.share-with-user {
  padding: 10px;
  border-bottom: thin solid lightgrey;
  display: flex;
  align-items: center;
}

.share-with-user .ant-avatar {
  margin-left: 24px;
  margin-right: 12px;
}

.share-search-box {
  margin-bottom: 12px;

  .ant-input-search > input {
    border-radius: 32px;
    padding: 12px;
  }
}

.share-admin,
.job-admin,
.user-inactive {
  box-sizing: border-box;
  padding: 0 7px;
  border-radius: 11px;
  text-align: center;
  color: rgba(7, 16, 26, 0.65);
  border: 1px solid #dadbdd;
  background-color: #f2f3f3;
}

.job-admin,
.share-admin {
  border-color: #91d5ff;
  color: #1890ff;
  background-color: #e6f7ff;
}

.share-admin {
  margin-left: auto;
}

.user-inactive,
.job-admin {
  margin-right: 10px;
}

.job-admin-share-wrapper {
  margin-left: auto;

  span:last-child {
    margin-right: 0;
  }
}
