.user-settings-content {
  --content-padding: 20px;
  width: 330px;
}

.user-settings-info {
  display: flex;
  background-color: #f8f9fa;
  padding: var(--content-padding) var(--content-padding) 10px;
  border-radius: 4px 4px 0px 0px; // hack to style popup. Need to check why it is happening
}

.user-settings-avatar {
  margin-right: 20px;
}

.user-avatar-icon {
  height: 48px;
  width: 48px;
}

.user-settings-name,
.user-settings-email {
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-settings-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #202632;
  margin-bottom: 2px;
}

.user-settings-email {
  font-size: 14px;
  line-height: 18px;
  color: rgba(32, 38, 50, 0.5);
  margin-bottom: 8px;
}

.user-settings-edit-profile {
  color: #007bff;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.user-settings-options {
  padding: 0px var(--content-padding) var(--content-padding) var(--content-padding);
}

.user-settings-option {
  display: flex;
  padding: 12px 0px;
  border-bottom: 1px solid #e6eaee;
  font-size: 14px;
  line-height: 22px;
  color: rgba(7, 16, 26, 0.7);

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0px;
  }

  span {
    color: rgba(7, 16, 26, 0.7);
  }

  span {
    cursor: pointer;
  }

  .add-new {
    margin-left: auto;
    color: var(--arya-accent-color);
  }

  .logout {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: rgba(32, 38, 50, 0.5);

    .logout-icon {
      margin-right: 5px;
    }
  }

  .about {
    margin-left: auto;
  }
}

.user-settings-option.logout-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-activation-remaining-count-wrapper {
  .anticon {
    font-size: 15px;
    margin-right: 6px;
  }
  .job-activation-remaining-count {
    cursor: default;
  }
}
