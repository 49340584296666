.personalInfoSection {
  margin-top: 20px;
  :global {
    .ant-input {
      border: none !important;
      border-bottom: 1px solid gainsboro !important;
      border-radius: 0px;
      box-shadow: none !important;
    }
  }
}

.personalInfoHeader {
  display: flex;
}

.personalInfoLabel {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  font-weight: 500;
}

.personalInfoClear {
  margin-left: auto;
}

.personalInfoForm {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.personalInformationSectionRow {
  display: flex;
  gap: 50px;
}

.formItem {
  max-width: 400px;
  flex: 1;
}

.formLabel {
  font-size: 14px;
  color: rgba(7, 16, 26, 0.7);
  font-weight: 500;
}

.isDiversityActive  {
  cursor:not-allowed;
}
.diversityForPersonalInformation {
  opacity: 0.5;
   pointer-events: none
}
