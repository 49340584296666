.linkButton {
  cursor: pointer;
  font-weight: var(--default-font-weight);
  font-size: 14px;
  display: inline-block;
  text-decoration-line: underline;
  color: var(--arya-accent-color);
  &.danger {
    color: var(--arya-error-color);
  }
}
