.emptyPanel {
  text-align: center;
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-size: 12px;
  color: rgba(7, 16, 26, 0.3);
  max-height: 46px;
}

.supplySearchInput {
  padding: 10px;
}

.addSupplyFilterButton {
  box-sizing: border-box;
  width: 80px;
  border: 1px solid #dadbdd;
  background-color: #ffffff;
  top: 8px;
}

.supplyCardList {
  border-top-style: none;
  max-height: 27vh;
  overflow-y: auto;
  :global {
    .ant-card-head-title {
      font-weight: 450;
      color: rgba(7, 16, 26, 0.9);
    }
    .ant-card-extra {
      font-weight: 450;
      color: rgba(7, 16, 26, 0.9);
    }
  }
}

.listBordered {
  border: 0px solid #d9d9d9;
  .checkBoxName {
    font-size: 14px;
  }
}

.displaySupplyPercentageView {
  width: 19%;
  text-align: center;
}

.displaySupplyPercentage {
  display: inline-block;
  color: rgba(7, 16, 26, 0.7);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}

​​​​​.searchButtonFilter {
  :global .ant-input-search-button {
    width: 32px;
    padding: 0;
  }
}

.supplyFilterList {
  width: 100%;
  :global {
    .ant-checkbox-wrapper {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      width: 80%;
      position: relative;
    }
  }
}

.disabled {
  color: #dadbdd;
  :global {
    .ant-input-search-button {
      background-color: #dadbdd;
    }
    .ant-card-head-title {
      color: #dadbdd;
    }
    .ant-card-extra {
      color: #dadbdd;
    }
  }
}
