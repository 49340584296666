.notification {
  display: flex;
  width: 360px;
  justify-content: space-around;
  border-bottom: 1px solid #e6eaee;
  padding: 12px;
  cursor: pointer;

  .bold {
    font-weight: 500;
    color: black;
  }

  .content {
    width: 229px;
    color: #81878e;
    font-family: Roboto, sans-serif;
    font-size: 13px;

    .notification-title {
      line-height: 22px;
      padding: 4px 0px;
    }

    .message {
      line-height: 18px;
    }

    .since {
      color: #7f8fa5;
      font-size: 12px;
      line-height: 14px;
      padding: 4px 0px;
    }
  }

  .viewSymbol {
    :global {
      .ant-badge-status-dot {
        width: 8px;
        height: 8px;
      }
      .ant-badge-dot {
        background-color: #13c26b;
      }
    }
  }
}

.notification:hover {
  background-color: #f3f3f3;
}
