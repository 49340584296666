.successModal {
  :global {
    .ant-modal-body {
      padding: 11px 24px;
    }
    .ant-modal-footer {
      border-top: none;
      padding: 0 24px 18px 24px;
    }
    .ant-modal-close {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-modal-header {
      padding: 8px 24px;
    }
  }
}

.modalContent {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  > div {
    flex-grow: 1;
  }
}

.modalDescription {
  width: 310px;
  color: var(--primary-black-color);
}
