
.createPostButton {
  display: flex;
  align-items: center;
}

.souringSupportHeader {
  padding: 0px 20px 0px 20px;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  .souringSupportHeaderRight{
    display: flex;
    flex-direction: row;
    gap: 15px;

  }
}
