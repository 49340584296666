.settingNavigation {
  color: #202632;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  margin-bottom: 8px;
  cursor: pointer;
}

.notificationSettingsTitle {
  width: 304px;
}

.notificationSettingsTitleText {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.notificationSettingsWrapper {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.notificationSettingsItemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
