.drawerFooter {
  background-color: white;
  min-height: 64px;
  border-top: 1px solid #f5f5f5;
  padding: 12px;
  margin-right: 12px;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1em;
}
