.place-order-body {
  padding: 0 24px;
  width: 450px;
}

.profiles-add {
  padding: 12px 16px 12px 25px;
  border-top-left-radius: 3px;
  border-left: 4px solid #13c26b;

  h3,
  p {
    margin: 0;
  }
}

.order-details-payment-plan {
  padding: 12px 16px 0px 25px;
}

.add-candidates-payment-plan {
  border: 1px solid #dadbdd;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fff;
}

.payment-plan-button {
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  border: 1px solid #dadbdd;
  border-top: none;
  border-radius: 4px;
  padding-top: 35px;
}

.place-order-btn {
  width: 200px;
  height: 40px;
}

.order-result-amount-item {
  padding: 10px 0px;
}

.order-last-item {
  padding-bottom: 15px;
}

.order-price-item {
  text-align: right;
  padding-right: 25px;
}

.arya-job-credit-info {
  height: 24px;
  color: #13c26b;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 45px;
}
.arya-job-amount-api-error {
  height: 24px;
  color: #b50000;
  font-family: Roboto, sans-serif;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0;
  padding: 0 0 80px 5px;
  line-height: 24px;
}

.place-order-item {
  display: flex;
  justify-content: space-between;
}

.order-result-update-address,
.order-result-update-address:hover {
  background-color: transparent;
  border: none;
  color: #36cf7d;
  padding-left: 5px;
  height: auto;
  cursor: pointer;
}

.order-sub-item-title {
  padding-left: 25px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  padding-top: 14px;
}
