.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 5px;
  .moreEmails {
    margin-right: auto;
    a {
      color: #51575e;
      text-decoration: underline;
    }
  }
  .actionButtons {
    display: flex;
    justify-content: flex-end;
    padding-right: 28px;
  }
}

.candidate360ComposeModalTitle {
  display: flex;
  justify-content: space-between;
  background: #1f2730;
  color: white;
  padding: 10px;
  height: 40px;
  .expandShrinkIcons {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}

.candidate360ComposeModal {
  position: absolute;
  right: 0;
  top: auto;
  padding-bottom: 0;
  bottom: 0;
}
.maximizedModal {
  width: 70vw !important;
  height: 100vh;
}
.modalContainer {
  overflow-y: auto;
}
