.notes-input-button {
  display: flex;
  width: 100%;
  margin-top: 35px;
  .ql-editor.ql-blank::before {
    font-style: normal;
  }
  .ql-container {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    border-radius: 4px;
  }
}
.ql-editor.ql-blank {
  font-size: 14px;
  line-height: 18px;
  color: #b4b7ba;
}
.ant-row > .ant-form-item-control-wrapper {
  width: 100%;
  > .ant-form-item-control {
    width: 100%;
    > .ant-form-item-children {
      width: 100%;
      > .notes-input {
        width: 98%;
      }
    }
  }
}

.notes-input {
  padding: 8px 11px;
  margin-right: 8px;
}

.notes-submit,
.notes-submit:focus {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background-color: #13c26b;
}

.notes-characters-count {
  line-height: 22px;
  float: right;
  margin-right: 10px;
}

.notes-submit-button {
  margin-left: 5px;
}

.call-notes-richtext-editor {
  .ql-editor {
    min-height: 60px;
    max-height: 90px;
  }
}

.notes-richtext-editor {
  .ql-editor {
    max-height: 100px;
  }
}
