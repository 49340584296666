.Career-builder-edge-filter-and-collapsed-filter {
  display: flex;
  .career-builder-edge-primary-filter {
    padding-left: 16px;
    padding-top: 11px;
    .radio-column {
      .ant-radio-wrapper {
        margin-top: 5px;
      }
    }
    .ant-form-item-label {
      > label {
        color: rgba(7, 16, 26, 0.9);
      }
    }
    .ant-select-selection {
      width: 281px;
    }
  }
  .career-builder-edge-collapsed-filter {
    border-left: 1px solid #dadbdd;
    padding-left: 16px;
    padding-top: 11px;
    .ant-form-item {
      margin-bottom: 0px !important;
      .ant-form-item-control {
        line-height: 32px !important;
      }
    }
  }
}
