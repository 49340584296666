.publishJobTitleWrapper {
  color: #374251;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.publishDescriptionWrapper {
  margin-bottom: 10px;
}

.portalList {
  display: flex;
  justify-content: space-between;
}

.portalLeftSection {
  justify-content: flex-start;
  align-self: center;
  display: flex;
  max-width: 50%;
}

.portalRightSection {
  justify-content: flex-end;
  display: flex;
  max-width: 50%;
}

.activeBadge {
  right: 5px;
  top: -8px;
}

.inactiveBadge {
  width: 14px;
}

.editButton {
  color: #13c26b;
  font-weight: normal;
  margin-right: 5px;
  align-self: center;

  &:hover {
    cursor: pointer;
  }
}

.portalName {
  font-size: 16px;
  margin-left: -7px;
}
