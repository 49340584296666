.dropdown-with-label-wrapper {
  display: flex;
  height: 32px;
}

.dropdown-with-label {
  border: #dadbdd 1px solid;
  border-right: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 32px;
  width: 60px;
  color: #83878c;
  padding: 4px 12px;
}

.select-with-label-dropdown [role='option'] {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .select-with-label-value {
    margin-right: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.dropdown-without-label-select {
  height: 32px;
  width: 384px;
}

.dropdown-with-label-select {
  margin-bottom: 0px;
  height: 32px;
  width: 324px;

  .ant-select-selection {
    border-left-width: #dadbdd 1px solid;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
