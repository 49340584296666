#donutChart {
  position: relative;
  padding: 16px;
}

.tooltip {
  position: absolute;
  font-size: 12px;
  pointer-events: none;
  height: 60px;
  width: 146px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  opacity: 0;
  z-index: 2500;
}

.tooltipHeading {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 12px 12px 4px 12px;
}

.tooltipDetails {
  display: flex;
  margin: 0px 12px 0px 12px;
}

.labelName {
  margin: 0px 16px 0px 8px;
}

.labelColor {
  margin-top: 4px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

#donutChartView {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.legendLabels {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.legendLabelColor {
  margin: 2px 8px 0px 0px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.donutLabels {
  margin-left: 30px;
}

.pieChart {
  text {
    pointer-events: none;
  }
}
