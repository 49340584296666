.connectTemplateSubject {
  margin-bottom: 6px;

  .ql-container {
    .ql-editor {
      line-height: 22px;
      height: 32px;
      overflow: auto;
      white-space: nowrap;
      padding: 4px 12px;
    }
  }
}
.connectTemplateBody {
  .ql-container {
    border-radius: 4px;

    .ql-editor {
      min-height: 80px;
      max-height: 176px;
      padding: 4px 12px;
      overflow: auto;
      border-radius: 4px;
    }
  }
}
.dripInfoWithDisclaimer {
  display: flex;
  align-items: center;
  gap: 10px;
  .disclaimerMessage {
    padding-top: 10px;
    color: #51575e;
  }
}
.dripInfo {
  padding: 0px 10px 0px 10px;
  margin-top: 8px;

  & > span {
    border-radius: 12px;
    border: 1px solid #dadbdd;
    background-color: #f2f3f3;
    padding: 5px;
    font-size: 12px;
    line-height: 20px;
  }
}
.sendToContainer {
  display: flex;
  margin-top: 14px;
  margin-bottom: 4px;

  .sendToLabel {
    font-weight: 500;
    margin-right: 10px;
  }
}
