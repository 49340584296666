.columnFilterMenu {
  width: 275px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15) !important;
}

.dropdownButtonContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdownButton {
  width: 185px;
  padding: 0px 11px;
}

.downIcon {
  color: rgba(0, 0, 0, 0.25);
}

.columnOption {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
