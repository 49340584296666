.connect-message-form {
  height: 100%;
  margin-bottom: 10px;
}
.connect-message-form-360 {
  margin-bottom: 10px;
  margin-right: 10px;
  padding-right: 20px;
  overflow-y: scroll;
}
.extended-connect-message-form-360 {
  margin-bottom: 60px;
}
#connect-message-window {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fafafb;
  padding: 0px 20px;

  .candidate-360-text-history-container {
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 2;
  }

  .candidate-360-text-history-infinite-scroll {
    height: 100vh;
    overflow-y: auto;
    width: 95%;
  }

  .user-alert-wrapper {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-150px, -60px);
  }

  .template-label {
    font-size: 13px;
    font-weight: 400;
    color: #005ce6;
    margin-top: 5px;
  }

  .template-lable-360 {
    text-align: center;
  }
}

#template-dropdown-credits-wrap {
  display: flex;
  padding-right: 72px;
  justify-content: space-between;
  align-items: center;
}

.consent-status-info-message-360-view {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  border-radius: 4px;
  width: fit-content;
  justify-content: center;
  margin: 0 auto;

  .anticon {
    margin: 0 10px;
    color: #1890ff;
  }

  &.consent-pending {
    background-color: #fffbe6;
    border-color: #ffe58f;

    .anticon {
      color: #faad14;
    }
  }

  &.consent-denied {
    background-color: #fff1f0;
    border-color: #ffa39e;

    .anticon {
      color: #f5222d;
    }
  }
}

.consent-status-info-message {
  padding: 8px;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  border-radius: 4px;

  .anticon {
    margin: 0 10px;
    color: #1890ff;
  }

  &.consent-pending {
    background-color: #fffbe6;
    border-color: #ffe58f;

    .anticon {
      color: #faad14;
    }
  }

  &.consent-denied {
    background-color: #fff1f0;
    border-color: #ffa39e;

    .anticon {
      color: #f5222d;
    }
  }
}

#connect-aryacredit-icon {
  margin-left: auto;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

#message-input-box-wrap {
  display: flex;
  // margin-bottom: 30px;
  // min-height: 40px;
  align-items: flex-end;
}

#connect-message-send-button {
  // align-self: flex-end;
  // height: 40px;
  margin-left: 10px;
}

.message-textarea {
  width: 90%;
  background-color: #ffffff;
  position: relative;

  .rich-text-editor {
    margin-right: 50px;
  }

  .message-character-count {
    text-align: right;
    font-size: 11px;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #dadbdd;
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    min-height: 40px;
    max-height: 100px;
    // border: 1px solid #ccc;
  }
}

.connect-message-bubble.bot-message {
  background-color: var(--arya-accent-color);
}

.connect-message-bubble.rightText {
  background: #e2edfa;
  color: #1f2730;
  font-weight: 400;
  border-radius: 7px 0px 7px 7px;
}
.connect-message-bubble.leftText {
  background: #ffffff;
  color: #51575e;
  font-weight: 400;
  border-radius: 0px 7px 7px 7px;
  width: fit-content;
  border: none;
}

.merge-tag-360 {
  .message-window-merge-tag {
    cursor: pointer;
    padding-left: 10px;
    color: #51575e;
    font-weight: 400;
    white-space: nowrap;
    .merge-tag-360-button {
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
    }
    span {
      display: flex;
      align-items: center;
      margin-top: 1px;
      gap: 11px;
    }
    i {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-size: 12px;
    }
  }
}
.merge-tag {
  margin-top: 8px;
  .message-window-merge-tag {
    cursor: pointer;
    padding-left: 10px;
    color: var(--arya-accent-color);
  }
}

.consent-message-button-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message-window-template-consent-text {
  color: #51575e;
  background: white;
  border: none;
  border-radius: 0px;
}

.invalid-spam-phone,
.invalid-spam-email {
  color: var(--color-invalid-spam);
}

.message-window-template-consent-note-wrapper {
  padding: 15px;
  border-top: 1px solid #f2f3f3;
  border-bottom: none;
  background-color: #ffffff;
}

.message-window-template-consent-note {
  font-weight: 700;
  color: #1f2730;
}

.candidate-360-message-window {
  overflow-y: auto;
  #connect-message-window {
    padding: 0 !important;
  }
  .candidate-360-phone-with-label {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
    flex-direction: column;
    color: #374251;
    padding: 0px 22px 12px 22px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(152, 152, 152, 0.25);
    .candidate-360-phone-send-to,
    .candidate-360-phone-send-from {
      display: flex;
    }
    .candidate-360-phone-label {
      font-weight: 400;
      margin-top: 14px;
      width: 59px;
      color: #83878c;
    }
    .connect-message-window-flex-items {
      &.phone-dropdown {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .candidate-360-text-history-container {
    overflow: auto;
  }
  .template-dropdown-credits-message-input-box-consent-wrapper {
    background-color: #fafafb;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 275px;
    &.messageNotSent {
      height: 393px;
    }
    &.messageSentConsentNotApproved {
      height: 293px;
    }
    &.composeMessageDisabled {
      height: 0px;
    }
  }

  .select-to-dropdown-360 {
    font-weight: 500;
    font-size: 14px;
  }
  .select-to-dropdown-360,
  .tempalete-candidate-360 {
    color: #374251;
    width: 100% !important;
    .ant-select-selection,
    .ant-select-selection:hover,
    .ant-select-selection:focus {
      border: none !important;
      box-shadow: none;
      width: auto;
      max-width: 400px;
    }
    .ant-select-selection__clear {
      right: 0px;
    }
    .ant-select-arrow {
      color: #1f2730;
      right: 0px;
    }
  }
  .tempalete-candidate-360 {
    .ant-select-selection-selected-value {
      padding-right: 20px;
      max-width: 356px;
    }
  }
  .smsWindowExtraContentShowLowCreditBanner {
    height: 450px !important;
  }
  .empty-select-template {
    .ant-select-selection,
    .ant-select-selection,
    .ant-select-selection:hover,
    .ant-select-selection:focus {
      min-width: 120px !important;
    }
  }
  #template-dropdown-credits-wrap {
    display: flex;
    gap: 2rem;
    justify-content: left;
    padding: 12px 22px 12px 18px;
    border-bottom: 1px solid #f2f3f3;
    box-shadow: 0px -1px 8px rgba(89, 89, 89, 0.17);
  }

  .action-button {
    margin-left: auto;
    cursor: pointer;
    button {
      border: none;
      background: transparent;
      font-size: 25px;
    }
  }
  .tempalete-candidate-360 {
    .ant-select-selection__placeholder {
      color: #374251;
    }
    .ant-select-selection__rendered {
      margin-left: 0px;
      margin-right: 0px;
    }
    .ant-select-selection,
    .ant-select-selection:hover,
    .ant-select-selection:focus {
      max-width: max-content;
    }
  }
  .message-textarea {
    width: 100%;
    .message-window-merge-tag {
      float: right;
      text-decoration: underline;
      margin: 10px;
    }
    .rich-text-editor {
      margin-right: 0;
    }
    .ql-editor {
      min-height: 120px;
    }
  }
  .message-textarea-with-spamalert {
    .ql-editor {
      min-height: 80px;
      max-height: 90px;
    }
  }
  .message-window-template-consent-note-wrapper {
    padding: 8px 8px 5px 16px;
  }
  .message-window-template-consent-text {
    padding: 0px 24px 16px 18px;
  }
}
.phone-option-container {
  display: flex;
  gap: 10px;
  .add-phone {
    color: #13c26b;
  }
}
.template-dropdown {
  width: 358px !important;
}

.connect-message-consent-banner {
  padding-right: 40px;
  margin-right: 20px;
  margin-left: 10px;
}

.report-chat-button {
  display: flex;
  align-items: center;
}
