.research-card {
  height: 174px;
  width: 284px;
  border: 1px solid #dadbdd;
  border-radius: 4px;
  background-color: #ffffff;
  display: inline-block;
  margin-top: 26px;
}
.name-publication {
  display: inline-block;
  clear: left;
  margin-top: 12px;
  margin-left: 16px;
}
.publication-image {
  display: inline-block;
  vertical-align: top;
  float: right;
  margin-right: 12px;
  margin-top: 12px;
}

.publication-name {
  height: 22px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  max-width: 115px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.publications-count {
  height: 22px;
  width: 195px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  line-height: 22px;
}
.publication-summary {
  height: 32px;
  width: 227px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.publication-summary-icon {
  display: inline-block;
  margin-left: 16px;
}

.i-10-index,
.citations,
.h-index {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: inline-block;
  vertical-align: middle;
}

.citations {
  margin-left: 16px;
}

.i-10-index,
.h-index {
  margin-left: 12px;
}

.confidence,
.confidence-type {
  color: rgba(7, 16, 26, 0.5);
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  margin-top: 8px;
}
.confidence {
  font-weight: 500;
  margin-left: 16px;
}

.go-to-profile {
  height: 16px;
  width: 76px;
  color: #13c26b;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: inline-block;
  float: right;
  margin-left: 12px;
  margin-top: 8px;
  cursor: pointer;
}
