.candidate-sorting-radio {
  display: block;
  height: 30px;
  line-height: 30px;
}

.candidate-sorting-radio-div {
  border-top: 1px solid #dadbdd;
}

.sortingfilter-sortby-clear {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.sortingfilter-sortby {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
}

.sortingfilter-clear-button {
  border: none;
}

.sorting-filter {
  padding: 10px;
}
