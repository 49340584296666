.tickbox,
.tickbox-tick,
.tickbox:hover,
.tickbox-tick:hover {
  height: 42px;
  display: inline-block;
  position: relative;
  margin: 8px 20px 8px 0px;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  text-align: center;
  padding: 5px;
}

.tickbox,
.tickbox:focus {
  border: 1px solid #dadbdd;
}

.tickbox-tick,
.tickbox:hover,
.tickbox-tick:hover,
.tickbox-tick:focus {
  border-color: #01cd75;
}

.check-circle {
  visibility: hidden;
}

.check-circle,
.check-circle-tick {
  top: -5px;
  right: -5px;
  color: #01cd75;
  position: absolute;
}

.tickbox:hover,
.tickbox-tick:hover,
.check-circle:hover .check-circle-tick:hover {
  cursor: pointer;
}

.tickbox-tick > .anticon {
  line-height: 0;
}

.tickbox {
  border: 1px solid #dadbdd;
  background-color: #ffffff;
}
