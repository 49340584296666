.body-pdf .highlight,
.body-pdf .selected {
  background-color: #13c26b !important;
}

.body-pdf .selected ::selection {
  background-color: #13c26b !important;
}
.body-pdf {
  position: relative;
  .textLayer {
    opacity: 0.4 !important;
  }
}
.pdfViewer {
  > div {
    border: none !important;
  }
}

pdfmark {
  background-color: #ffff5a !important;
}
pdfmustHaveMark {
  background-color: pink !important;
}
