.candidateCardHeaderInfo {
  display: flex;
  // justify-content: space-between;
  .candidateHeaderInfoWithPushIcon {
    display: flex;
    .displayedCandidateHeaderInfo {
      padding: 4px 16px;
      width: fit-content;
      background: #51575e;
      color: #f2f3f3;
      font-size: 12px;
      border-top-left-radius: 16px;
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      .shortlistInfo {
        .highlightedText {
          font-weight: 700;
        }
      }
    }
    .collapsedCandidateHeaderInfo {
      border-top-left-radius: 24px !important;
    }
    .scoutingAgentIcon {
      display: grid;
      place-content: center;
      border-top-left-radius: 16px;
      background: #fff2e8;
      padding: 4px 14px;
      border-bottom-right-radius: 0;
    }
    .pushStatusIcon {
      background: #daf0e5;
      padding: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .candidateCardTags {
    display: flex;
    padding-right: 32px;
    .candidateStatusWithIcon {
      color: #007bff;
      background-color: #e6f7ff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 0 10px;
    }
  }
}
.candidateHeaderInfoWithPushIcon > :last-child {
  border-bottom-right-radius: 8px;
}
.candidateHeaderInfoWithPushIcon > :first-child {
  .pushStatusIcon {
    border-top-left-radius: 8px;
  }
}
.candidateStatus{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
