.notesTabWrapper {
  width: 602px;
  position: fixed;
  z-index: 4000;
  bottom: 0;
  right: 0;

  box-shadow: 0px 8px 10px 1px #00000024;

  :global {
    .ql-editor {
      height: 298px;
    }
    .ql-container.ql-snow {
      border: none;
    }
    .ql-editor.ql-blank::before {
      font-style: normal;
      color: #b4b7ba;
    }
  }

  .header {
    background: #1f2730;
    color: white;
    height: 40px;
    padding: 12px 12px 12px 16px;
    display: flex;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    justify-content: space-between;
    cursor: pointer;

    .actionIcons {
      display: flex;
      gap: 8px;

      i:nth-child(1) {
        margin-top: 4px;
      }
    }
  }
  .content {
    background-color: white;
    height: 100%;
    .noteSubject {
      :global {
        .ant-form-explain {
          margin-left: 14px;
        }
      }
    }
    .noteEditor {
      :global {
        .ant-form-explain {
          position: absolute;
          background: white;
          width: 100%;
          border-top: none;
          bottom: 40px;
          left: 14px;
        }
      }
    }
  }
  .saveNoteButton {
    border: 1px solid #dadbdd;
    color: #019939;
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
}

.popUpEditor {
  display: flex;
  flex-direction: column-reverse;
}

.popupEditorSubject {
  border: none !important;
  margin-left: 14px;
  padding-left: 0;
  width: 97%;
}
.popupEditorSubject:hover {
  border-color: transparent;
  border-right-width: 0px !important;
}

.popupEditorSubject:focus {
  border: none !important;
  box-shadow: none !important;
}

.subjectBorderContents {
  width: 100%;
  border-bottom: 1px solid black;
}

.notesMinimizedFooter {
  position: fixed;
  z-index: 4000;
  bottom: 0%;
  right: 0%;
  .notesMinimizedContainer {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 94vw;

    .horizontalScroll {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      flex-grow: 1;
      gap: 8px;
    }
    .header {
      background: #1f2730;
      color: white;
      height: 40px;
      padding: 12px 12px 12px 16px;
      display: flex;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      justify-content: space-between;

      .actionIcons {
        display: flex;
        gap: 8px;

        i:nth-child(1) {
          margin-top: 4px;
        }
      }
    }
  }
}
.longBoxes {
  width: 602px;
}
.smallBoxes {
  width: 361px;
}
