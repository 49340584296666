/* prettier-ignore */

// :root {
//   /* 1. The Contactlab Marketing Cloud color palette */
//   --color-accent: #1890ff;
//   --color-accent100: #e6f7ff;
//   --color-accent200: #bae7ff;
//   --color-accent300: #91d5ff;
//   --color-accent400: #69c0ff;
//   --color-accent500: #40a9ff;
//   --color-accent600: #1890ff;
//   --color-accent700: #096dd9;
//   --color-accent800: #0050b3;
//   --color-accent900: #003a8c;
//   --color-base: #141414;
//   --color-base100: #fafafa;
//   --color-base200: #f5f5f5;
//   --color-base300: #f0f0f0;
//   --color-base400: #d9d9d9;
//   --color-base500: #bfbfbf;
//   --color-base600: #8c8c8c;
//   --color-base700: #595959;
//   --color-base800: #434343;
//   --color-base900: #141414;
//   --color-info: #1890ff;
//   --color-success: #52c41a;
//   --color-warning: #faad14;
//   --color-error: #f5222d;
//   --color-white: #fff;
//   --color-black: #000;
//   --color-fluo-base300: #e9e8ff;
//   --color-fluo-base600: #b4afff;
//   --color-fluo-base900: #7168ff;
//   --color-fluo-accent300: #e1f9ef;
//   --color-fluo-accent600: #9bebd0;
//   --color-fluo-accent900: #00c9bd;

//   /* 2. The Contactlab Marketing Cloud elevations */
//   --elevation-z1: 0 1px 3px 0 rgba(0, 20, 32, 0.12);
//   --elevation-z2: 0 3px 6px 0 rgba(0, 20, 32, 0.04),
//                   0 3px 6px 0 rgba(0, 20, 32, 0.12);
//   --elevation-z3: 0 6px 6px 0 rgba(0, 20, 32, 0.09),
//                   0 10px 20px 0 rgba(0, 20, 32, 0.13);
//   --elevation-z4: 0 10px 10px 0 rgba(0, 20, 32, 0.05),
//                   0 14px 28px 0 rgba(0, 20, 32, 0.16);
//   --elevation-z5: 0 19px 38px 0 rgba(0, 20, 32, 0.16),
//                   0 15px 12px 0 rgba(0, 20, 32, 0.12);
//   --elevation-accent-z1: 0 1px 3px 0 rgba(3, 145, 236, 0.12);
//   --elevation-accent-z2: 0 3px 6px 0 rgba(3, 145, 236, 0.04),
//                          0 3px 6px 0 rgba(3, 145, 236, 0.12);
//   --elevation-accent-z3: 0 6px 6px 0 rgba(3, 145, 236, 0.09),
//                          0 10px 20px 0 rgba(3, 145, 236, 0.13);
//   --elevation-accent-z4: 0 10px 10px 0 rgba(3, 145, 236, 0.05),
//                          0 14px 28px 0 rgba(3, 145, 236, 0.16);
//   --elevation-accent-z5: 0 19px 38px 0 rgba(3, 145, 236, 0.16),
//                          0 15px 12px 0 rgba(3, 145, 236, 0.12);

//   /* 3. The Contactlab Marketing Cloud transitions */
//   --elevation-transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

//   /* 4. Document styles */
//   --mc-body-foreground: var(--color-base);
//   --mc-body-background: var(--color-base100);
//   --mc-scroll-padding: 100px;
//   --mc-scroll-behavior: smooth;

//   /* 5. a11y */
//   --mc-selection-foreground: var(--color-white);
//   --mc-selection-background: rgba(24, 144, 255, 0.8);

//   /* 6. Headings */
//   --mc-heading-foreground: var(--color-base);
//   --mc-subheading-foreground: var(--color-base800);

//   /* 7. Links */
//   --mc-link-foreground: var(--color-accent);
//   --mc-link-active-foreground: var(--color-accent500);

//   /* 8. <code> */
//   --mc-code-border-radius: 2px;
//   --mc-code-background: var(--color-base300);

//   /* 9. <hr> */
//   --mc-hr-background: var(--color-base300);

//   /* 10. <mark> */
//   --mc-mark-foreground: var(--color-white);
//   --mc-mark-background: var(--color-accent);

//   /* 11. <small> */
//   --mc-small-foreground: var(--color-base600);
// }

.workflowLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  height: 100vh;
}

.savingWorkflowSection {
  position: relative;
  padding: 10px 10px 10px 10px;
  width: 67.5rem;
  height: 60px;
  background: white;
  display: flex;
  align-items: center;
  border-top: 2px solid #f5f5f5;
  justify-content: flex-end;
}

.saveButtonSection {
  width: 30rem;
  height: 50px;
  background: white;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.workflowReportNote {
  background: #e6f7ff;
  width: fit-content;
  p {
    color: #1890ff;
    font-size: 12px;
  }
}
