.quicksearch-advanced-options {
  padding: 0px 20px;
  margin-bottom: 70px;
  .ant-form-item {
    margin-bottom: 10px;
    .ant-form-item-label {
      line-height: 22px;
    }
    .ant-form-item-control {
      line-height: 1;
    }
    .ant-form-item-label label {
      font-size: 14px;
    }
  }
  .advanced-options-select-component,
  .advanced-options-input-component {
    width: 281px;
    font-size: 14px;
  }
  .advanced-options-radio-component {
    display: flex;
  }
  .advanced-options-switch-component {
    display: flex;
    align-content: space-between;
    justify-items: center;
    .ant-form-item-control-wrapper {
      width: 44px;
    }
  }
}
.isDiversityActive  {
  opacity: 0.5;
}
