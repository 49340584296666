#donutChart {
  position: relative;
  padding: 16px;
}

.tooltip {
  position: absolute;
  font-size: 12px;
  pointer-events: none;
  height: 60px;
  width: 146px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  opacity: 0;
  z-index: 2500;
}

.tooltip-heading {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 12px 12px 4px 12px;
}

.tooltip-edu-details {
  display: flex;
  margin: 0px 12px 0px 12px;
}

.tooltip-edu-degree {
  margin: 0px 16px 0px 8px;
}

.tooltip-label-color {
  margin-top: 4px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

#education-relevance-chart {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.education-label-container {
  width: 150px;
  height: auto;
  border: 1px solid #dadbdd;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 8px 8px 8px;
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
}

.label-color {
  height: 12px;
  width: 5px;
  border-radius: 2.5px;
  margin: 3px 8px 0px 4px;
}

.label-for-job {
  background-color: var(--arya-accent-color);
}

.label-for-candidate {
  background-color: #1890ff;
}

.label-heading {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.label-subheading {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.candidate-and-job-labels {
  margin-left: 40px;
}

.education-degree-label {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.education-degree-label-color {
  margin: 2px 8px 0px 0px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.education-degree-label-name {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.education-relevance-chart-labels {
  margin-left: 30px;
}

.pie-chart {
  text {
    pointer-events: none;
  }
}
