.jobLandingWrapper {
  padding-top: 70px;
  .joblandingContainer {
    width: max-content;
    padding: 0px 0px 70px 0px;
    margin: auto;
    background-color: #f4f6f8;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 4px 0 #ccc8c8;
  }
  .credits {
    padding: 10px 20px;
    justify-content: flex-end;
    margin-bottom: 60px;
  }
}

.startHiringTitle,
.choosePlanTitle,
.sampleCandidateTitle,
.enterJobDetailTitle {
  text-align: left;
  white-space: normal;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-left: 5px;
  margin-right: 5px;
}
.jobCreationFlowStepperWrapper {
  margin-top: 54px;
  margin-bottom: 43px;
}
