.jobActivity {
  display: flex;
  align-items: center;
  cursor: pointer;
  .clockIcon {
    > svg {
      font-size: 20px;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
}

.jobActivityContentWrapper {
  padding: 20px 20px 20px 20px;
  min-width: 425px;
  .jobActivityContent {
    max-height: 200px;
    overflow-y: scroll;
    padding-right: 10px;
    :global .ant-timeline-item-head-blue {
      color: #1890ff;
      border-color: #1890ff;
    }
    .eventName {
      text-decoration: underline;
    }
  }
  .emptyCriteriaContent {
    color: rgba(7, 16, 26, 0.7);
    font-size: 11px;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }
  .eventTypeButton {
    padding: 0px;
  }
  :global .ant-timeline-item-last {
    padding: 0px;
    .ant-timeline-item-content {
      min-height: 0px;
    }
  }
}
