.jobTitle {
  color: #13c26b;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
}

.timelineItem {
  :global {
    .ant-timeline-item-content {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}

.timelineItem:nth-last-child(2) {
  :global {
    .ant-timeline-item-tail {
      height: calc(100% - 45px);
    }
    .ant-timeline-item-content {
      border-bottom: 0;
    }
  }
}

.accomplishmentsTimeline {
  :global .ant-timeline-item-tail {
    border-left-width: 3px;
  }
}

.companyName {
  color: rgba(7, 16, 26, 0.9);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
}

.hiddenTimeline {
  height: 0;
  :global {
    .ant-timeline-item {
      padding: 0;
    }
  }
}
