.ckeditor-merge-tag-button {
  font-size: 14px;
  border: none;
  cursor: pointer;
  background: #f8f8f8;
  padding: 5px 0px 0px 12px;
  color: var(--arya-accent-color);
}

.email-template-editor {
  .cke {
    border-top: none;
  }
}

.ckeditor-merge-tag-wrapper {
  border: 1px solid #d1d1d1;
  border-bottom: none;
  background: #f8f8f8;
}
