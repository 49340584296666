.candidateSupplyBanner {
  height: 126px;
  width: 1005px;
  background-color: rgba(216, 216, 216, 0.23);
}

.candidateSupplyInsights {
  margin: 50px 0;
  text-align: left;
  .antCol4 {
    position: sticky;
    top: 80px;
    padding-left: 50px !important;
    padding-right: 25px !important;
    div {
      border-right: 1px solid #e3e3e3;
    }
  }
  .antCol18 {
    padding-left: 0 !important;
    padding-right: 60px !important;
    max-height: 800px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 82%;
  }
  .antCol18V1 {
    max-height: 370px;
  }
}

.selectPlanLockedText {
  letter-spacing: 0;
  text-align: center;
  font-size: 16px;
  line-height: 23px;
  color: rgba(7, 16, 26, 0.9);
  width: 510px;
  margin: auto;
}

.selectPlanButton {
  border-radius: 20px;
  background-color: #13c26b;
  padding: 0 24px;
  margin: 20px;
}

.lockOuterCircle {
  left: 445px;
  top: -1305px;
}
.alertMessageBody {
  width: 1005px;
  height: 1246px;
  justify-content: flex-start;
  padding: 80px 0px 40px 0px;
}

.lockOuterCircleV2 {
  top: -860px;
}
.alertMessageBodyV2 {
  height: 800px;
}

.lockOuterCircleV3 {
  top: -415px;
}
.alertMessageBodyV3 {
  height: 355px;
}
