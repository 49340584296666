.manualSearchManualSearchFormHeaderOptions {
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 0;
  margin-top: 7px;
  margin-right: 30px;

  .saveIcon {
    padding: 0 10px;
    display: flex;
    align-items: center;
    text-decoration: underline;
    font-size: 15px;
    cursor: pointer;
  }

  .resetButton {
    cursor: pointer;
    text-decoration: underline;
  }
}
