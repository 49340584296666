.wrapper {
  width: 100% !important;
  background-color: rgba(216, 216, 216, 0.23);
  .link {
    color: #13c26b;
    border-bottom: 0.5px solid #979797;
    cursor: pointer;
  }
}

.stepsWrapper {
  padding-top: 40px;
  margin-left: 9%;
  width: 750px;
}

.container {
  display: flex;
  margin-left: 9%;
  align-items: center;
}

.icon {
  width: 140px;
  .iconSize {
    font-size: 115px;
  }
}

.title {
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  span {
    font-weight: 500;
  }
}

.subTitle {
  display: block;
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.noInsightSubTitle {
  display: block;
  color: #1b1b1b;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
