.ql-toolbar.ql-snow .ql-formats {
  margin: 0px;
}
.tags-popover-content-candidate-360 {
  display: flex;
  overflow-y: scroll;
  max-width: 670px !important;
  width: fit-content !important;
  max-height: 236px;
  .merge-tag {
    width: 145px;
    margin: 5px !important;
    padding: 3px !important;
  }
}
.merge-tags-text {
  text-decoration: underline;
}

.custom-toolbar {
  .mergeTag-popup {
    cursor: not-allowed;
  }
  button.custom-toolbar-html-true {
    cursor: not-allowed;
  }

  .custom-toolbar-html-true {
    .ql-picker-label {
      cursor: not-allowed !important;
    }
  }

  .custom-toolbar-html-true,
  button.custom-toolbar-html-true:hover {
    .ql-picker-label,
    .ql-picker-label:hover {
      color: #c7d1db !important;
      fill: #c7d1db !important;

      .ql-stroke {
        stroke: #c7d1db !important;
      }
    }

    .ql-stroke {
      stroke: #c7d1db !important;
    }

    .ql-fill {
      fill: #c7d1db !important;
    }

    .ql-colour-label {
      fill: #c7d1db !important;
    }

    .ql-picker-label {
      color: #c7d1db !important;
    }

    .ql-expanded {
      .ql-picker-options {
        display: none !important;
      }

      .ql-picker-label {
        border-color: transparent !important;
      }
    }
  }

  .ql-formats {
    margin-right: 0px;
    display: flex;
    flex-direction: column;

    .source-button:hover {
      .html-tool-false {
        color: #06c;
      }
    }

    .source-button {
      width: 30px;

      .html-tool-true {
        color: #13c26b;
      }

      .html-tool-false {
        color: #83878c;
      }
    }
  }
}

.custom-toolbar-candidate360-view {
  max-height: 38.3vh;
  .ql-picker-options {
    right: -1px;
  }
}

.custom-toolbar-chatGpt-view {
  max-height: 31.2vh;
  .ql-picker-options {
    right: -1px;
  }
}

.custom-toolbar-container {
  display: flex;
  border: 1px solid #ccc;
  flex-direction: column;
  min-width: 43px;
  .custom-toolbar {
    border: none !important;
    flex-grow: 1;
    line-height: initial;
  }
}

.custom-toolbar::-webkit-scrollbar {
  display: none;
}
.custom-toolbar-down-icon {
  padding: 0px 0px 0px 12px;
  cursor: pointer;
}

.custom-toolbar-up-icon {
  padding: 2px 0px 0px 12px;
  cursor: pointer;
}
.create-email-template-toolbar {
  flex-direction: row;
  align-items: center;
}

.hide-section {
  display: none;
}
