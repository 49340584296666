.monster-classic {
  .monster-advanced-options {
    padding: 0px 20px;
    margin-bottom: 70px;

    .ant-form-item {
      margin-bottom: 10px;

      .ant-form-item-label {
        line-height: 22px;
      }

      .ant-form-item-control {
        line-height: 1;

        .ant-radio-wrapper {
          span.ant-radio + * {
            padding-left: 2.5px;
            padding-right: 2.5px;
          }
        }
      }

      .ant-form-item-label label {
        font-size: 14px;
      }
    }

    .salary-form-item-label {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 6px;

      .salary-type-select {
        width: 86px;
        height: 20px;
        font-size: 12px;
        margin-left: 10px;

        .ant-select-selection--single {
          height: 20px;
        }

        .ant-select-selection__rendered {
          margin-left: 8px;
          line-height: 20px;
        }
      }
    }

    .salary-form-item {
      display: flex;
      align-items: flex-start;

      .monster-salary-currency {
        width: 82px;
        margin-right: 8px;
      }

      .monster-min-salary {
        width: 96px;
        margin-right: 8px;
      }

      .monster-max-salary {
        width: 96px;
      }
    }

    .monster-advance-options-select,
    .monster-advance-options-input {
      width: 281px;
      min-height: 32px;
      border-radius: 4px;
    }

    .monster-advance-options-tree-select {
      width: 281px;
      min-height: 32px;
      border-radius: 4px;

      .ant-select-selection {
        max-height: 160px;
        overflow: auto;
      }
    }
  }

  .add-more-languages {
    width: 182px;
    border-radius: 22px;
  }

  .language-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 22px;
    color: #999;
    transition: all 0.3s;
  }
  .language-delete-button:hover {
    color: #777;
  }
  .language-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .monster-classic-language-select {
    width: 112px;
    margin-right: 8px;
  }

  .monster-classic-proficiency-select {
    width: 120px;
    margin-right: 8px;
  }

  .switch-form-item {
    display: flex;
    align-items: center;

    .ant-form-item-control-wrapper {
      width: 44px;
    }
  }
}

.popover-select {
  z-index: 2200;
}

.company-catagory-dropdown {
  max-height: 400px;
}
