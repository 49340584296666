.appFooter {
  height: var(--footer-height);
  margin: auto;
  background-color: white;
  max-width: var(--app-max-width);
  text-align: center;
  color: gray;
  padding: 12px 50px;
  a {
    color: gray;
  }
}
