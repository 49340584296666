.listsAndCampaignsTabsHeader {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  .listsAndCampaignsInputSearch {
    width: 139px;
    input {
      border-radius: 16px;
    }
  }
  .ant-input-suffix .listsInputSearch {
    z-index: 0;
  }
}
.searchAndFilter {
  display: flex;
  gap: 12px;
}
