.nexxt-username,
.nexxt-employer-id,
.nexxt-employer-email {
  display: block;
  width: 252px;
}

.nexxt-username,
.nexxt-employer-id {
  margin-bottom: 16px;
}

.nexxt-password {
  margin-bottom: 64px;
}

.nexxt-password,
.nexxt-employer-password {
  display: block;
  width: 252px;
}

.authenticate-button,
.authenticate-button:hover,
.authenticate-button:focus {
  font-size: 14px;
  color: rgba(7, 16, 26, 0.7);
  border: 1px solid #dadbdd;
}

.disconnect-button,
.disconnect-button:hover,
.disconnect-button:focus {
  font-size: 14px;
  color: #f5222d;
  border: 1px solid #dadbdd;
  background-color: rgba(7, 16, 26, 0.05);
}



.error-message-with-form{
  display: flex;
  flex-direction: column;
  gap: 16px;
  .error-message {
    color: var(--arya-error-color);
  }
}
