.magicSignInContainer {
  display: flex;
  justify-content: center;
  height: 100vh;

  .magicSignInContent {
    display: flex;
    align-items: center;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;

  .loaderHeaderContent {
    font-size: 20px;
    padding: 10px;
    font-weight: 600;
    color: rgba(7, 16, 26, 0.9);
  }

  .loaderContent {
    color: rgba(48, 66, 86, 0.7);
    font-size: 16px;
    font-weight: 500;
  }
}
