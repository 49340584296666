.modalContainer {
  display: flex;
}

.modal{
 padding: 24px 24px 0px 24px;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.bulkConfirmation {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  .bulkConfirmationTitle{
    margin: 5px;
    padding-bottom: 7px;
  }
}
