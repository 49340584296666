.saveSearchCriteriaModalContent {
  .saveSearchCriteriaModalDescription {
    color: rgba(7, 16, 26, 0.5);
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
  }
  .saveSearchCriteriaModalFooter {
    display: flex;
    margin-right: 7px;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px;
    .note {
      font-size: 11px;
    }
  }
}
.saveSearchCriteriaModalTitle {
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-left: 8px;
}
.saveSearchCriteriaModalContentTwo {
  .saveSearchCriteriaModalHeader {
    color: rgba(7, 16, 26, 0.9);

    font-size: 20px;
    font-weight: 500;
    margin-top: 28px;
    line-height: 28px;
  }
  .saveSearchCriteriaModalTwoDescription {
    height: 48px;
    width: 438px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 14px;
  }
  .saveSearchCriteriaModalTwoFooter {
    display: flex;
    justify-content: flex-end;
  }
}
.saveSearchCriteriaFailureModalContent {
  .saveSearchCriteriaFailureModalHeader {
    margin-top: 23px;
    line-height: 28px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 20px;
    font-weight: 500;
  }
  .saveSearchCriteriaFailureModalDescription {
    margin-top: 8px;
    font-size: 16px;
  }
  .saveSearchCriteriaFailureModalFooter {
    margin-top: 27px;
    margin-bottom: 6px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }
}
