#jobTable {
  font-weight: 400;
  margin-top: 28px;
  #tableHeading {
    padding: 11px 20px 13px 20px;
    background-color: #f3f3f4;
    display: flex;
    justify-content: space-between;
    color: #51575e;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1px;
  }
  .listName {
    color: #07101a;
  }
}
.paginationContainer {
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.emptyScreen {
  :global {
    .ant-empty {
      height: calc(100vh - 380px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      :global {
        .ant-empty-image {
          height: 60px;
        }
      }
    }
  }
}
.segmentListEmpty > svg {
  width: 275px;
  height: 60px;
}
