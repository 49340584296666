.recentJobsWrapper {
  display: flex;
  color: #13c26b;
  justify-content: space-between;
  width: 97%;
  margin-left: 15px;
  position: sticky;
  margin-top: -16px;
  z-index: 1;
}

.jobListWrapper {
  overflow-y: auto;
  height: calc(100vh - 150px) !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.jobListWrapper::-webkit-scrollbar {
  display: none;
}

.emptyScreen {
  :global {
    .ant-empty {
      height: calc(100vh - 380px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      :global {
        .ant-empty-image {
          height: 75px;
          width: 250px;
          font-size: 100px;
        }
      }
    }
  }
}
.jobListEmpty > svg {
  width: 275px;
  height: 60px;
}
.jobListNoJobFound {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
}
.jobListNoJobFoundMessage {
  height: 24px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
}
.jobListNoJobRemoveSearch {
  height: 24px;
  color: #13c26b;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-top: 10px;
}
.seeRecentJobs {
  text-decoration: underline;
  display: flex;
  gap: 10px;
  font-weight: 400;
  color: #51575e;
  cursor: pointer;
}

.bulkOptionsFooterWrapper {
  width: 98%;
  z-index: 1000;
  margin-top: 25px;
  margin-left: 15px;
  .bulkOptionsFooter {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    height: 52px;
    z-index: 1200;
    border: 1px solid rgba(7, 16, 26, 0.15);
    border-radius: 4px;
    padding-left: 15px;
  }
}

.bulkOptionsSelection {
  display: flex;
  gap: 10px;
  align-items: center;
  .bulkActivityText {
    color: rgba(7, 16, 26, 0.9);
    font-size: 14px;
    font-weight: 500;
  }
}

.jobsInputSearch {
  width: 139px;
  input {
    border-radius: 16px;
  }
}

.jobCardSelectCandidate {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #13c26b;
  border-bottom: 1px solid rgba(7, 16, 26, 0.15);
}

.jobsTabHeader {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 11px;
  .jobsInputSearch {
    width: 139px;
    input {
      border-radius: 16px;
    }
    input:focus {
      width: 200px;
    }
    :global .ant-input {
      float: right;
    }
  }
  .ant-input-suffix .listsInputSearch {
    z-index: 0;
  }
}
.searchAndFilter {
  display: flex;
  gap: 12px;
}

.paginationContainer {
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-bottom: 20px;
}

.jobMultiSelect {
  display: flex;
  width: 100%;
  align-items: center;
  .candidate-card {
    margin: auto;
  }
}

.jobCardInfo {
  margin: 12px 15px;
  background-color: #ffffff;
  width: 98%;
  min-height: calc(100vh - 370px) !important;
}

.extendJobMatchingView {
  min-height: calc(100vh - 175px) !important;
}

.jobListItemWrapper {
  margin: 12px 15px;
  background-color: #ffffff;
  border: 1px solid rgba(7, 16, 26, 0.15);
  border-radius: 4px;
  width: 98%;
}

.textDivider {
  color: #1f2730;
}
