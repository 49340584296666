.text-preview-partial,
.text-preview-total {
  p {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 16px !important;
    text-align: justify;
  }
}
.text-preview-partial {
  max-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-preview-button-wrapper {
  text-align: inherit;

  .ant-btn {
    padding: 0px;
  }
}
