.minimizedCandidateHeader,
.candidateInfoAndContact,
.bookmarkAndCandidateActions {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  .candidateActionIcons {
    display: flex;
    gap: 19px;
    .pushCandidateIcon {
      display: flex;
      font-size: 10px;
      .consent-status-info {
        display: inline-block;
      }
      .candidateDetailPushedCandidate {
        width: 21px;

        .pushStatusIcon {
          background-color: var(--arya-accent-color);
          border-radius: 50%;
          color: white;
          padding: 3px;
          font-size: 16px;
        }
        .pushIconV2 {
          background-color: inherit;
          color: #b4b7ba;
        }
      }
      .candidateDetailPushedCandidateError {
        width: 21px;

        .pushStatusIconError {
          background-color: gray;
          border-radius: 50%;
          color: white;
          padding: 3px;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .candidatePushV2 {
        font-size: 14px !important;
      }
    }
  }
  .candidateTransferIcon {
    svg {
      font-size: 32px !important;
      width: 32px !important;
      height: 24px;
    }
  }
  .enhancedShortlistContainer {
    gap: 16px;
  }
}
.minimizedCandidateHeader {
  flex: 1;
  justify-content: space-between;
}
.candidateConnect {
  margin: 0px;
  :global .connect-get-contact {
    margin: 0px;
  }
}
.bookmark {
  width: 18px;
  height: 18px;
}

.bookmark:hover {
  cursor: pointer;
}

.bookmarkIcon {
  font-size: 20px;
  color: #83878c;
}

.bookmarkMarked {
  color: #13c26b;
}

.linkedInIcon {
  font-size: 20px;
  display: flex;
}

.disabledLinkedIn {
  pointer-events: none;
  > * {
    opacity: 0.2;
  }
}

.socialIcon:hover,
.linkedInIcon:hover {
  cursor: pointer;
}
