.educationSection {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 30px;
  .educationSectionClearAll {
    display: flex;
    .educationSectionLabelWrapper {
      color: rgba(7, 16, 26, 0.7);
      font-size: 16px;
      font-weight: 500;
    }
    .clearAll {
      margin-left: auto;
    }
  }
  :global {
    .ant-select-selection,
    .ant-select-focused,
    .ant-input {
      border: none !important;
      border-bottom: 1px solid gainsboro !important;
      border-radius: 0px;
      box-shadow: none !important;
    }
  }
}

.titleSelection {
  max-width: 400px;
  flex: 1;
}

.titleTag {
  height: 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: 1px solid #21c574;
  border-radius: 3px;
  color: #21c574;
  background-color: rgba(33, 197, 116, 0.11);
  margin-bottom: 8px;
}

.titles {
  margin-top: 10px;
  max-height: 100px;
  overflow: scroll;
  display: inline-block;
}

.educationSectionRow {
  display: flex;
  justify-content: space-between;
}

.educationWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.quickSearchFormItemLabel {
  display: flex;
  align-items: center;
}

.quickSearchFormItemLabelText {
  font-size: 14px;
  color: rgba(7, 16, 26, 0.7);
  font-weight: 500;
}

.isDiversityActive  {
   opacity: 0.5;
}
