.smsNodeContent {
  width: 650px;
  .emailDropdown {
    display: flex;

    .selectedText {
      text-decoration: underline;
    }

    .previewText {
      text-decoration: underline;
      color: #bcbdbe;
    }

    .drawerFooter {
      position: absolute;
      background: #fff;
      right: 0;
      bottom: 0;
      border-top: 1px solid #e9e9e9;
      padding: 10px 16px;
      text-align: right;
      width: 100%;
    }
  }
}
