.modalContainer {
  display: flex;
}

.iconDiv {
  margin-right: 16px;
  font-size: 22px;
  color: #ffcc00;
}
.restartConfirmationContent {
  color: #07101a;
  font-size: 16px;
}
