.form-panel-header {
  height: 46px;
  font-size: 14px;
  color: rgba(7, 16, 26, 0.9);
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 16px;
  background-color: rgba(7, 16, 26, 0.03);
  box-shadow: inset 0 -1px 0 0 rgba(7, 16, 26, 0.09);
}
