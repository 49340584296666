.confirmationWrapper {
  padding: 15px;
  .icon {
    margin-right: 10px;
    margin-top: 5px;
    float: left;
    .warningIcon {
      color: #faad14 !important;
      font-size: 24px;
    }
  }
  .textWrapper {
    display: block;
    .confirmationTitle {
      padding-top: 8px;
      color: rgba(7, 16, 26, 0.9);
      font-size: 16px;
      font-weight: 500;
      margin-left: 44px;
    }
    .confirmationSubTitle {
      margin-left: 44px;
      padding-top: 15px;
      height: 64px;
      width: 370px;
    }
    .buttons {
      display: flex;
      margin-top: 30px;
      justify-content: flex-end;
      gap: 16px;
      .cancelButton {
        background-color: #ffffff;
        border: 1px solid #dadbdd;
      }
    }
  }
}
