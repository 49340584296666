.formItemLabel {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.formItemLabelText {
  line-height: 24px;
  font-size: 14px;
  color: rgba(7, 16, 26, 0.7);
  font-weight: 500;
}

.contactMethod {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
