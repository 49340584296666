.paymentModalContainer {
  width: 540px !important;
  :global {
    .ant-modal-header {
      border-left: 4px solid #13c26b;
    }

    .ant-tabs-topbar {
      margin-bottom: 32px !important;
    }
  }
}

.boldPaymentInfoRow {
  font-weight: 500;
  font-size: large;
}

.boldPaymentInfoRow,
.simplePaymentInfoRow {
  justify-content: space-between;
  display: flex;
  margin: 7px 5px;
}

.notEnoughCredits {
  color: red;
  font-size: small;
  float: right;
}
