.notification {
  display: flex;
  width: 360px;
  border-bottom: 1px solid#e6eaee;
  padding: 12px;
  cursor: pointer;
  gap: 20px;

  .jobLevelExhausationImageWrapper {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background-color: #fff1f0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    width: 229px;
    color: #81878e;
    font-family: Roboto, sans-serif;
    font-size: 13px;

    .bold {
      font-weight: 500;
      color: #000;
    }
  }
}

.closeIcon {
  font-size: 20px;
  color: red;
}

.content {
  width: 229px;
}

.viewJobButton {
  padding: 0px 30px;
  margin-top: 10px;
}

.time {
  color: #7f8fa5;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 0px;
}
