.integration-title,
.social-integration-title,
.distance-title,
.language-title,
.bot-settings-title,
.pay-config-title,
.candidateAutoRejectSettingsTitle {
  color: rgba(7, 16, 26, 0.9);
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  line-height: 28px;
  text-align: justify;
  margin-bottom: 6px;
}

.integration-message,
.language-message,
.distance-message,
.social-integration-message {
  color: rgba(32, 38, 50, 0.6);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
}

.integration-message {
  margin-bottom: 40px;
}

.social-integration-message {
  margin-bottom: 70px;
}

.language-message {
  margin-bottom: 37px;
}

.distance-message {
  margin-bottom: 32px;
}

.bot-description {
  margin-bottom: 32px;
}

.distance-select {
  width: 160px;
}

.linkedin-img {
  height: 19px;
  width: 75px;
  margin-right: 63px;
}

.social-account {
  margin-bottom: 61px;
}

.social-accounts {
  margin-bottom: 60px;
}

.language-select {
  width: 590px;
  margin-bottom: 60px;
}

.distance-input-number {
  width: 280px;
  margin-left: 16px;
}

.setting-navigation {
  color: #202632;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  margin-bottom: 8px;
  cursor: pointer;
}

.navigator-group {
  display: inline-block;
  margin-right: 16px;
  vertical-align: middle;
}

.horizontal-line {
  height: 1px;
  width: 875px;
  background-color: #e6e7e8;
  // margin-bottom: 60px;
}

.vertical-line {
  display: inline-block;
  vertical-align: middle;
  height: 111px;
  width: 1px;
  transform: scaleX(-1);
  background-color: #d8d8d8;
}

.application-settings {
  padding-bottom: 371px;
}

.ant-btn.save-changes-button,
.ant-btn.save-changes-button:hover {
  vertical-align: middle;
  left: 50%;
  height: 32px;
  width: 135px;
  background-color: #13c26b;
  font-size: 14px;
  color: #ffffff;
  border-radius: 20px;
}

// .settings {
//  //  margin: 51px 34px 50px 0px;
// }

.jobboard-settings {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.distance-settings {
  display: flex;
  justify-content: space-between;
}

.bot-settings-app {
  display: flex;
  justify-content: space-between;
}

.arya-bot-settings {
  padding-top: 50px;
}

.app-save,
.app-save:hover,
.app-save:focus,
.app-save:active {
  background-color: var(--arya-accent-color);
  color: white;
  border-radius: 15px;
}

.section-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.add-smtp-settings-button-wrapper {
  margin-top: 16px;
  height: 56px;
  border: 1px solid #dadbdd;
  border-radius: 4px;

  .add-smtp-settings-button:hover,
  .add-smtp-settings-button:focus {
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
  }

  .add-smtp-settings-button {
    margin-top: 12px;
    border-radius: 16px;
    width: 208px;
    margin-left: 16px;
  }
}

.application-settings-email-configuration-title {
  color: rgba(7, 16, 26, 0.9);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: justify;
}

.application-settings-email-configuration-message {
  text-align: justify;
  margin-top: 14px;
  font-size: 16px;
  color: rgba(7, 16, 26, 0.7);
}
