.candidateFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .candidateSourceFilterWrapper {
    display: flex;
    align-items: center;
  }
  .candidateFilterSelectedPortalText{
    padding-left: 5px;
  }

  .rightSection {
    display: flex;
    gap: 12px;

    .searchFilter {
      width: 202px;
      :global .ant-input {
        border-radius: 25px;
        font-weight: 500;
      }
    }
  }
}
