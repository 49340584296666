@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  12.5% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-200px);
  }
  37.5% {
    transform: translateX(-200px);
  }
  50% {
    transform: translateX(-380px);
  }
  62.5% {
    transform: translateX(-380px);
  }
  75% {
    transform: translateX(-600px);
  }
  87.5% {
    transform: translateX(-600px);
  }
  100% {
    transform: translateX(0px);
  }
}
.componentWrapper {
  margin: auto;
  display: flow-root;
  overflow: hidden;
  position: relative;
  width: 800px;
  margin-top: 10px;
}
.logosWrapper {
  animation: scroll 15s linear infinite;
  display: flex;
  width: 800px;
  margin-top: 25px;
  align-items: center;
}
.footerText {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
}
