.locationSelect {
  width: 100%;
  min-width: 250px;
  border-radius: 4px;

  :global .ant-select-selection {
    overflow-x: hidden;
    max-height: 95px;
  }
}

.locationDropdown {
  z-index: 1901;
}
