.icon {
  font-size: 82px;
  display: flex;
}

.wrapper {
  display: flex;
  padding: 10px;
  .textWrapper {
    padding-left: 10px;
    color: black;
  }
  .selectPlanBtn {
    margin-top: 15px;
    display: block;
  }
}
