.resumeIcon {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resumeDownloadButton {
  margin: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resumeInfoText {
    height: 24px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
}
