.warningWrapper {
  display: flex;
  .icon {
    margin-right: 10px;
    margin-top: 5px;
    .iconSize {
      font-size: 24px;
    }
  }
  .textWrapper {
    display: flex;
    flex-direction: column;
    .warningTitle {
      color: #374251;
      font-size: 22px;
      font-weight: bold;
    }
    .warningSubTitle {
      color: rgba(7, 16, 26, 0.9);
      font-size: 16px;
    }
  }
}
