.benefitsContainer {
  width: 350px;
  border-radius: 10px;
  height: 400px;
  color: #ffffff;
  background: linear-gradient(329.21deg, #4293f4 0%, #1bbd78 100%);
  padding: 30px;
  position: relative;
  .benefitsCardHeader {
    margin-bottom: 15px;
    .benefitsTitle {
      font-size: 20px;
      font-weight: bold;
      line-height: 22px;
      margin-bottom: 10px;
    }
  }
  .benefitsList {
    margin-bottom: 15px;
  }
  .diamondIcon {
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    display: inline-block;
    line-height: 14px;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
}

.checkListItem {
  display: flex;
  p {
    text-align: start;
    margin-left: 8px;
    font-size: 14.4px;
    letter-spacing: 0;
    line-height: 19.2px;
  }
}

.horizonatlBenefitCardContainer {
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  padding: 21px 24px 22px;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(329.21deg, #4293f4 0%, #1bbd78 100%);
  .benefitCardIcon {
    margin-right: 20px;
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    display: inline-block;
    line-height: 14px;
  }
  .benefitTitle {
    font-size: 20px;
    font-weight: bold;
  }
  .benefitCardContent {
    margin: 0 20px;
  }
}
