.jobCardDetails {
  padding: 19px;
  height: 80px;
  & ~ .jobCardDetails {
    border-top: 1px solid #e6eaee;
  }

  &:last-of-type {
    border-bottom: 1px solid #e6eaee;
  }
  & [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }
  .noneText {
    font-size: 16px;
    line-height: 24px;
    color: #83878c;
  }
}
.jobCardDetails:hover {
  box-shadow: 0 2px 8px -2px rgba(32, 38, 50, 0.3);
}

.jobCardDetails ~ .jobCardDetails:hover {
  border-top: 1px solid transparent;
}

.jobId,
.candidateStage,
.updatedTime,
.jobStatus,
.jobTitle {
  font-size: 14px;
  line-height: 24px;
  color: #07101a;
}
.jobTitle {
  max-width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 25px;
  cursor: pointer;
  text-decoration: underline;
}
.jobCard {
  display: flex;
  align-items: center;
  line-height: 24px;
  color: #07101a;
}
