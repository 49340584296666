.bulkOptionsFooterWrapper {
  width: 100%;
  z-index: 1000;
  .bulkOptionsFooter {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    height: 52px;
    z-index: 1200;
    border: 1px solid rgba(7, 16, 26, 0.15);
    border-radius: 4px;
    padding-left: 15px;
    align-items: center;
  }
}

.bulkActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.clearSelection {
  cursor: pointer;
}

.selectedCandidatesText {
  color: #000000;
  margin-right: 6px;
}

.bulkOptionIcon,
.bulkOptionIconSegment {
  height: 50px;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 23px;
  padding: 20px;
  cursor: not-allowed !important;
}
.bulkOptionIcon {
  color: #47a6d3;
  background-color: #e6f7ff;
}
.bulkRecommendationStatus{
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  .info-icon-wrapper{
    color: #faad14;
    display: flex;
    gap: 3px;
    align-items: center;
  }
}

.bulkOptionIconSegment {
  color: #01cd75;
  background-color: #f6ffed;
}

.bulkActionIcon {
  height: 50px;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #b7eb8f;
  background-color: #f6ffed;
  padding: 20px;
  cursor: not-allowed !important;
}

.bulkCandidateTransfer {
  svg {
    font-size: 32px !important;
    width: 27px !important;
    height: 24px !important;
  }
}

.activatedBulkOptionIcon {
  cursor: pointer !important;
}

.candidate-multi-select-engaged,
.candidate-multi-select-connected,
.candidate-multi-select-shortlist {
  display: flex;
  margin-right: 10px;
  gap: 5px;
}

.nonContactedCandidateNames {
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.bulkOptionsSelection {
  display: flex;
  gap: 10px;
  align-items: center;
  .bulkActivityText {
    color: rgba(7, 16, 26, 0.9);
    font-size: 14px;
    font-weight: 500;
  }
  .customInputValidation {
    color: red;
  }
}

.noticeSubTitle {
  text-decoration: underline;
}

.bar-progress{
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.progress-bar-count{
  margin: 2px;
}

.favouriteCandidatePublishButton{

  display:flex;
  align-items: center;
  gap: 2px;
  padding-right: 10px;
}
