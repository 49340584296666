.boolean-string-display-component-wrapper {
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  line-height: 0px;

  .boolean-string-display-component {
    max-height: 80px;
    overflow: auto;
    max-width: 352px;
    border: 1px solid rgba(7, 16, 26, 0.15);
    border-radius: 4px;
    padding: 0px 8px 5px 8px;
    line-height: 0px;

    .boolean-display-selected-tag,
    .boolean-display-suggested-tag {
      height: 22px;
      border: 1px solid #dadbdd;
      border-radius: 11px;
      display: inline-flex;
      align-items: center;
      margin-top: 5px;
      margin-right: 0px;
      line-height: 22px;
    }

    .boolean-display-selected-tag {
      background-color: #f2f3f3;
    }

    .boolean-display-suggested-tag {
      background-color: #ffffff;

      .anticon-plus {
        margin-right: 5px;

        > svg {
          height: 8px;
          width: 8px;
        }
      }
    }

    .boolean-string-or-text {
      color: #7c8287;
      font-size: 10px;
      font-style: italic;
      font-weight: 500;
      margin: 5px 4px 0px 4px;
    }

    .add-more-tag {
      border: 1px dashed #dadbdd;
      border-radius: 11px;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      margin-top: 5px;

      i > svg {
        font-size: 8px;
        margin-right: 4px;
      }
    }

    .add-more-input {
      width: 100px;
      border-radius: 12px;
      margin-top: 5px;
      font-size: 14px;
    }
  }

  .boolean-string-component-and-text {
    color: #7c8287;
    font-size: 10px;
    font-style: italic;
    font-weight: 500;
  }
}
