.performanceGrowth {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.decrementIndicator {
  color: #f5222d;
}

.incrementIndicator {
  color: #13c26b;
}
