.ant-modal.distribute-job-modal {
  height: 260px;
}

.distribute-job-error-alert-wrapper {
  margin-bottom: 12px;
}

.distribute-job-info-alert-wrapper {
  margin-bottom: 12px;
}

.distribute-job-portals-text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
  color: rgba(7, 16, 26, 0.9);
}

.source-portal-name {
  margin-left: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: 800;
  color: #647182;
  line-height: 14px;
}

.job-distribution-date {
  height: 30px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 10px 15px 0px;
}

.draw-horizontal-line {
  border: 1px solid #dadbdd;
  height: 1px;
  background-color: #dadbdd;
}

.modal-content {
  padding-bottom: 60px;
}

.portals-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.align-portal-icon {
  display: flex;
  align-items: center;
}

.draw-horizontal-line:last-child {
  display: none;
}

.distribute-job-tooltip-overlay {
  .ant-tooltip-inner {
    min-width: 491px;
  }
}
