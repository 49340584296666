.menuItem {
  padding: 6px 5px;
  max-width: 200px;
  height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdownOverlay {
  min-width: auto !important;
}
.dropdownContainer {
  :global .ant-form-item-control {
    line-height: normal !important;
  }
}
.menuItem:hover {
  cursor: pointer;
}

.liSelected {
  background-color: #e6ffee;
}

.dropdown {
  max-height: 100px;
  overflow-y: scroll;
  color: #51575e;
}

.noContentDropdown {
  height: 40px;
  overflow-y: visible;
  :global .ant-empty-description {
    font-size: 11px;
  }
  :global .ant-empty-normal {
    margin: 13px 0;
  }
}
.newTag {
  color: #1f2730;
}
