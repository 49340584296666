.reActivationAlertModal {
  padding: 20px;
  .reActivationAlertModalContent {
    display: flex;
    .infoIcon {
      margin-right: 12px;

      i {
        font-size: 24px;
        color: #faad14;
      }
    }
    .subTitle {
      color: rgba(7, 16, 26, 0.9);
      font-size: 16px;
    }
  }
  .footerButtons {
    display: flex;
    justify-content: center;
  }
}
