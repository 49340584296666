.workflowTemplateHeader {
  height: 40px;
  background-color: #f4f6f8;
  color: rgba(7, 16, 26, 0.5);
  font-size: 12px;
  padding: 10px 0px 20px 20px;
  letter-spacing: 0.5px;
  border-radius: 5px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 12px;
}

.workflowListItem {
  :global {
    .ant-pagination {
      margin-top: 20px;
      margin-bottom: 20px;
      float: right;
    }
  }
}

.email.template-search {
  margin-left: 384px;
}

.create-email-template {
  margin-left: 10px;
}

.emptyWorkflowsHeading {
  font-size: 20px;
  height: 24px;
  color: rgb(2, 2, 2);
}
