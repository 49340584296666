.spamAlertText {
  width: 510px;
  background-color: '#FFF1F0';
  border-color: '#FFA39E';
  border-radius: 0px;
  margin: 6px 0px;
  :global .ant-alert-message {
    color: #961616;
    font-size: 13px;
    font-weight: 475;
  }
  .messageWithTooltip {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
}
