.email-templates {
  max-width: 990px;
}

.email-templates .ant-pagination {
  margin-top: 20px;
  margin-bottom: 20px;
  float: right;
}

.email.template-search {
  margin-left: 384px;
}

.create-email-template {
  margin-left: 10px;
}
