.contactInfoContainer {
  margin-top: -20px;
  .chatIconAndMessage {
    display: flex;
    flex-direction: column;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  .weChatIcon {
    font-size: 125px;
    color: lightgray;
  }
  .contactInfoMessage {
    font-size: 24px;
    line-height: 24px;
    color: #51575e;
  }
  .contactInfoText {
    line-height: 16px;
    color: #51575e;
  }
}
.extendedContactInfoContainer {
  margin-top: 50px;
}
