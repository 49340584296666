.billing-container {
  padding: 0 40px;
  width: 656px;

  .billing-information-header-text {
    margin-top: 10px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
  .billing-address-country,
  .billing-first-last-name,
  .billing-state-code {
    .ant-input {
      height: 40px;
    }
    .ant-select-selection {
      height: 40px;
    }
    .ant-select-selection__rendered {
      margin: 4px 15px;
    }
  }
  .billing-container-payment {
    .billing-address-country {
      .billing-address-item,
      .billing-address-country-item {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  .billing-container-profilesettings {
    .billing-address-country {
      .billing-address-country-item {
        margin-bottom: 16px;
        margin-top: 16px;
        width: 100%;
      }
    }
    .billing-state-code {
      .city-state-item,
      .zip-postalcode-item {
        margin-top: 0px;
      }
    }
    .ant-select-enabled {
      width: inherit;
    }
  }
}

.billing-info-card {
  width: 1122px !important;
  margin: auto;
}

.billing-first-last-name,
.billing-state-code {
  .ant-form-item {
    width: 48%;
    line-height: 14px;
  }
  .ant-select {
    width: 100%;
  }
  display: flex;
  justify-content: space-between;
  .ant-form-item-control {
    line-height: 14px;
  }
  .ant-form-item:last-child {
    margin-right: 0px;
  }
}
