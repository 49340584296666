.userAlertWrapper {
  height: 218px;
  position: fixed;
  z-index: 2000;
  .alert-message-body {
    width: 424px;
  }
  .lock-outer-circle {
    left: 145px;
  }
  .user-alert {
    width: 424px;
    height: 218px;
  }
  left: calc(50% - 212px);
  top: calc(50% - 65px);
}
