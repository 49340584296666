.connect-widget-media-icon-group {
  display: flex;
  justify-content: space-between;
}

.connect-media-icon.anticon {
  font-size: 22px;
  color: #83878c;
}

.connect-media-icon-contactpull.anticon {
  font-size: 22px;
  color: #83878c;
}

.connect-media-icon-email.anticon {
  font-size: 24px;
  color: #83878c;
}

.connect-media-icon-with-addon {
  font-size: 31.5px;
}

.connect-media-icon-disabled {
  opacity: 0.4;
  cursor: not-allowed !important; // To override ant css
}

.connect-media-icon-small {
  padding: 0px;
  font-size: 15px;
  margin-right: 5px;
}

.connect-media-icon-img {
  height: 35px;
  width: 35px;
}

.connect-media-icon-success.anticon {
  color: #13c26b;
}

.connect-media-icon-failed.anticon {
  color: #f5222d;
}

.contact-found-icon.anticon {
  color: #13c26b;
}

.contact-found {
  margin-top: 5px;
  height: 16px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

// div > .contact-found {
//   text-align: center;
// }

.connect-call:hover,
.connect-message:hover,
.connect-mail:hover {
  cursor: pointer;
}

.connect-media-icon-with-addon-message.anticon {
  font-size: 31.5px;
  margin-top: 10px;
}

.connect-media-icon-with-addon-email.anticon {
  font-size: 29px;
  margin-top: 12px;
}

.email-communication-disabled-icon.anticon {
  position: relative;
  top: -1.8px;
}

.connect-media-icon-with-addon-call.anticon {
  font-size: 31.5px;
  margin-top: 10px;
}

.connect-media-icon-message-disabled.anticon {
  color: #d9dbdc;
  cursor: not-allowed;
  font-size: 24.5px;
}

.connect-media-icon-call-disabled.anticon {
  color: #d9dbdc;
  cursor: not-allowed;
}

.connect-media-icon-mail-disabled.anticon {
  color: #d9dbdc;
  cursor: not-allowed;
  font-size: 27px;
}

.connect-media-icon-message.anticon {
  font-size: 23.5px;
  color: #83878c;
}

.consent-status-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  border-radius: 40px;

  .anticon {
    margin-right: 10px;
    color: #1890ff;
  }

  &.consent-pending {
    background-color: #fffbe6;
    border-color: #ffe58f;

    .anticon {
      color: #faad14;
    }
  }

  &.consent-denied {
    background-color: #fff1f0;
    border-color: #ffa39e;

    .anticon {
      color: #f5222d;
    }
  }
}

.consent-status-message {
  font-size: 12px;
  font-weight: bolder;
  line-height: 16px;
  text-align: center;
  margin-top: 8px;
}
