.jobPublishForm {
  color: rgba(7, 16, 26, 0.9);
  margin-bottom: 23px;
  margin-top: 16px;

  :global {
    .ant-input {
      font-size: 16px;
      line-height: 40px;
      height: 35px;
      margin-bottom: 24px;
    }

    .ant-select {
      font-size: 16px;
      line-height: 40px;
      height: 40px;
      margin-bottom: 24px;
      width: 100%;
    }

    .ant-form-explain {
      margin-top: -24px;
      margin-bottom: 24px;
    }
  }
}

.jobInventories {
  margin-bottom: 28px;
}

.labelHeader {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.jobPublishDescription {
  label {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.inventoryOptions {
  :global {
    .ant-radio-wrapper {
      width: 100%;
    }
  }
}

.richEditorJobPublishDescription {
  margin-bottom: 28px;

  :global {
    .ql-editor {
      min-height: calc((100vh - var(--navbar-height)) * 0.5);
      max-height: calc(100vh - var(--navbar-height));
      border-radius: 0 0 4px 4px;
    }
  }
}

.publishJobTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  margin-top: 32px;
}
.actionBtnsAffix {
  z-index: 1;
  width: 100% !important;
  max-width: 100% !important;
}
.actionBtnsContainerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  padding: 22px 0px;
  bottom: 0;
  width: 100%;
  left: 0;
  box-shadow: 0 -2px 3px 0 rgba(7, 16, 26, 0.15);
  z-index: -10;
  justify-content: center;
}

.actionBtnsWrapper {
  display: flex;
  flex-direction: row;
  width: var(--app-max-width);
  justify-content: space-between;
  padding: 0px 150px;
  align-items: center;
}

.actionBtnsContainer {
  display: flex;
  align-items: center;
  text-align: right;
  margin-left: 16px;

  :global {
    .ant-btn:first-child {
      margin-right: 16px;
    }

    .ant-btn {
      border-radius: 20px;
      font-size: 16px;
      height: 40px;
    }
  }
}

.footerTextContent {
  display: flex;
  align-content: flex-start;

  :global {
    .ant-checkbox-wrapper {
      display: flex;
      flex-direction: row;
      line-height: 1.5;

      .ant-checkbox {
        top: 3px;
        height: 16px;
      }
    }

    .ant-form-explain {
      margin-top: 0px;
      margin-bottom: 0px;
      position: absolute;
    }
  }
}

.contactDetails {
  margin-bottom: 28px;

  :global {
    .ant-collapse-header {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.applyMethod {
  display: flex;
}

.applyRadioSelect {
  margin-left: 20px;
}

.publishModify {
  margin-left: 20px;
  width: 60px;
  float: right;
}

.publishStats {
  font-size: 12px;
  color: #484b4f;
  line-height: 21px;
}

.zipCode {
  :global {
    .ant-input {
      margin-bottom: 0px;
    }
    .ant-form-explain {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}
