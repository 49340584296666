
.verticalDivider {
  color: #e6e6e6;
  flex-grow: 1;
}

.avatarSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

}

.contentSection {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  gap: 4px;
  flex : 1 0 0;
}

.replyItem {
  display: flex;
  padding: 0px 20px 0px 35px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.displayName {
  margin-right: 4px;
  font-weight: 500;
}

.postedAt {
  margin-right: 4px;
  font-weight: 400;
  color: #adacb0;
}

.userDetails {
  display: flex;
  gap:4px;
}

.aryaRecruiterTag {
  color: #1751d0;
  background-color: #EBF0FB;
}

.replyContent{
  overflow-wrap: anywhere;
}

