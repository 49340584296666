.formInputHelperText {
  display: block;
  font-size: 12px;
  color: rgba(7, 16, 26, 0.9);
}

.additional-settings {
  margin-top: 34px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
}

.enableThirdParty {
  display: flex;
  gap: 20px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  margin-top: 15px;
}

.enableEndUsers {
  display: flex;
  margin-top: 15px;
  gap: 20px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
}

.defaultEmailSmtpComponent {
  display: flex;
  padding: 20px;
  width: max-content;
  border: 3px solid #dbdad7;
  border-width: thin;
  border-radius: 5px;
  gap: 50px;
  .label {
    color: rgba(7, 16, 26, 0.9);
    font-weight: 500;
  }
}

.smtpSettings {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
  :global .ant-checkbox-wrapper {
    margin-left: 0px;
  }
}
.additionalSettings {
  color: rgba(7, 16, 26, 0.9);
  font-weight: 500;
}
.emaiSettingBox {
  padding: 20px;
  width: 700px;
  border: 3px solid #dbdad7;
  border-width: thin;
  border-radius: 5px;
}

.emailSettingsLastRow {
  width: 650px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px;
}

.defaultEmailTitle {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.infoIconWithContent {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.defaultEmailConfig {
  width: 875px;
  border-bottom: 1px solid #e6e7e8;
  margin-top: 10px;
  padding-bottom: 30px;
}
