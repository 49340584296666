.sourcesWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .sourcesText {
    font-family: Roboto, sans-serif;
    color: #1f2730;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  .manualSearchSources {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
}
.sourceSelected {
  border-radius: 4px;
  border: 1.4px solid #DADBDD;
  background: var(--Polar-Green-green-1, #F6FFED);

  position: relative;
}

.selectionDisabled {
  cursor: not-allowed !important;
}
.sourceWrapper {
  .sourceItemWrapper {
    display: flex;
    align-items: center;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 5px 8px 5px 8px;
  border-radius: 4px;
  border: 1.4px solid #DADBDD;
  cursor: pointer;
  .advanceSettingsButton {
    color: rgba(7, 16, 26, 0.7);
    cursor: pointer;
    > svg {
      font-size: 16px;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
  > span {
    display: flex;
  }
}
