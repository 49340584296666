.jobPublishForm {
  color: rgba(7, 16, 26, 0.9);
  margin-bottom: 25px;

  :global {
    .ant-input {
      font-size: 16px;
      line-height: 40px;
      height: 35px;
      margin-bottom: 24px;
    }
    .ant-select {
      font-size: 16px;
      line-height: 40px;
      height: 40px;
      margin-bottom: 24px;
      width: 100%;
    }
    .ant-form-explain {
      margin-top: -24px;
      margin-bottom: 24px;
    }
  }
}

.jobInventories {
  margin-bottom: 28px;
}

.labelHeader {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.jobPublishDescription {
  label {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.inventoryOptions {
  :global {
    .ant-radio-wrapper {
      width: 100%;
    }
  }
}

.richEditorJobPublishDescription {
  margin-bottom: 28px;
  :global {
    .ql-editor {
      min-height: calc((100vh - var(--navbar-height)) * 0.5);
      max-height: calc(100vh - var(--navbar-height));
      border-radius: 0 0 4px 4px;
    }
  }
}

.publishJobTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 32px;
}

.actionBtnAffix {
  width: 100% !important;
  max-width: 100% !important;
}

.actionBtnsContainer {
  background: #fff;
  box-shadow: 0 -2px 3px 0 rgba(7, 16, 26, 0.15);
  width: 100%;
}

.actionBtnsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 150px;
  width: var(--app-width);
  max-width: var(--app-max-width);
  margin: auto;
  .cancelBtn {
    margin-right: 16px;
  }
  .cancelBtn,
  .publishBtn {
    border-radius: 20px;
    font-size: 16px;
    height: 40px;
  }
}

.contactDetails {
  margin-bottom: 28px;

  :global {
    .ant-collapse-header {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.applyMethod {
  display: flex;
}

.applyRadioSelect {
  margin-left: 20px;
}

.publishModify {
  margin-left: 20px;
  width: 60px;
  float: right;
}

.publishStats {
  margin-top: -28px;
  margin-bottom: 32px;
  font-size: 12px;
  color: rgba(7, 16, 26, 0.5);
  line-height: 16px;
}
