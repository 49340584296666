.FilterIconToolTip {
  cursor: pointer;
  .dot {
    position: absolute;
    background-color: #13c26b;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    top: 3px;
    left: 14px;
  }
  .icon {
    position: relative;
  }
}
