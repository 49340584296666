.candidateFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0px 10px;
  .rightSection {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .dragged {
    position: fixed;
    z-index: 1000;
    top: calc(100vh - 175px);
    right: 2px;
  }
}
