.reportViewHeader {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.subtitle {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
