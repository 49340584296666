.assistedSourcingIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(235, 240, 251, 1);
  padding: 0px 4px 0px 4px;
  gap: 2px;
  border-radius: 5px;
  .assistedSourcingIndicatorText {
    color: rgba(23, 81, 208, 1);
    font-size: 12px;
    font-weight: 475;
  }
}
