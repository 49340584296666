.scouting-agent-icon {
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #dadbdd;
  position: relative;
  padding: 6px 0;
}

.active-scouting-agent-icon,
.active-scouting-agent-icon:hover,
.active-scouting-agent-icon:focus {
  border: 1px solid var(--arya-accent-color) !important;
  border-radius: 4px;
  position: relative;
  background: #f6ffed !important;
}
