.savedReportQueries {
  padding: 20px 0px 18px 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.savedReportQueriesPagination {
  text-align: end;
}

.reportQueriesListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f4f6f8;
  color: #647182;
  font-size: 12px;
  font-weight: 500;
}

.queryNameHeader {
  width: 50%;
}

.queryTypeHeader {
  width: 30%;
}

.listItemActions {
  width: 20%;
}

.savedReportQueriesHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reportQueriesListSearch {
  :global .ant-input {
    border-radius: 16px;
  }
}
