.summaryReportHeader {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-right: 10px;
}

.summaryReportDescription {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  padding-right: 10px;
}

.summaryReport {
  display: flex;
  flex-direction: row;
  justify-content: inherit;
}

.jobCard,
.candidateCard,
.connectionCard {
  width: 33%;
  padding: 0px 2px;
}

.summaryReportInsights {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.performanceGrowthWrapper,
.performanceGrowth {
  display: flex;
  align-items: center;
  gap: 4px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  flex-wrap: wrap;
}

.performanceGrowthSeparator {
  height: 24px;
  width: 1px;
  background-color: #dadbdd;
  margin: 0px 4px;
}

.performanceLabelSeparator {
  height: 22px;
  width: 1px;
  background-color: #dadbdd;
  margin: 0px 4px;
}

.performanceStatsWrapper,
.performanceStats {
  display: flex;
  align-items: center;
  gap: 4px;
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 40px;
  flex-wrap: wrap;
}

.performanceStatsSeparator {
  height: 40px;
  width: 2px;
  background-color: #83878c;
  margin: 0px 4px;
}

.performanceStats {
  display: flex;
  align-items: center;
}

.performanceDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.dateRange {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  flex-wrap: wrap;
}

.statsWrapper {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.performanceLabel {
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
