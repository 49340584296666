.optOutWidgetWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  .optOutIconWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .optOutIcon {
      font-size: 16px;
      color: rgba(7, 16, 26, 0.2);
    }
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-left: 9px;
      margin-bottom: 0px;
      color: rgba(7, 16, 26, 0.2);
    }
  }
  .optOutReasonContent {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: rgba(7, 16, 26, 0.7);
  }
}
