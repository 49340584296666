.job-not-share {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--navbar-height));
  background-color: rgba(7, 16, 26, 0.3);
}

.job-not-share-wrapper {
  min-width: 370px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(7, 16, 26, 0.15);
  padding: 15px;
}

.job-not-share-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.job-not-share-description {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
}

.job-not-share-button-wrapper {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.job-not-share-button {
  height: 24px;
}
