.card-details-body {
  cursor: pointer;
  width: 180px;
  height: 118px;
  padding: 16px 16px 16px 24px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  border-radius: 4px;
  background-color: white;

  .card-details-card-number,
  .card-details-card-expiry {
    color: #565b63;
    font-family: Roboto, sans-serif;
    letter-spacing: 0;
  }

  .card-details-card-number {
    font-size: 14px;
    line-height: 22px;
  }

  .card-details-card-expiry {
    font-size: 10px;
    line-height: 22px;
  }

  .card-detail-card-icon > svg {
    width: 42px;
    height: 42px;
  }

  .card-detail-card-default-icon > svg {
    width: 42px;
    height: 42px;
  }
}

.card-details-primary-content {
  display: flex;
  justify-content: space-between;

  .card-details-more {
    box-sizing: border-box;
    height: 22px;
    width: 17px;
    border-radius: 4px;
    border: 1px solid transparent;
  }

  .card-details-more:hover,
  .card-details-more:focus {
    border: 1px solid #13c26b;
  }
}

.credit-card-popover-menu {
  width: 90px;
}

.select-card {
  border: '1px solid green';
}
