.remarkForInvalidPhone {
  .textArea {
    margin: 10px 0px 0px 5px;
  }
  .remarkSubmitButton {
    display: flex;
    justify-content: flex-end;
    .buttonMargin {
      margin: 10px;
    }
  }
}

.popoverContent {
  margin-left: 24px;
}

.invalidPhoneButton {
  color: red !important;
}

.invalidPhoneButtonPopover {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-top: 5px;
}
.remarkMandate{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left:10px
}
.textAreaInInvalidPhoneReason{
  margin-left: 10px;
  margin-right:10px;
}
.reportInvalidtext {
  margin-left: 5px;
  color: rgba(7, 16, 26, 0.9);
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
}

