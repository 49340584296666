.salaryWrapper {
  margin-top: 15px;
  width: 70%;
  padding-bottom: 20px;
  :global {
    .ant-col-5 {
      .ant-select {
        width: 80%;
      }
    }
    .ant-col-10 {
      .ant-slider-mark-text {
        width: 100%;
      }
    }
  }
  .resetSalary {
    margin-left: 40px;
    border: 1px solid rgba(7, 16, 26, 0.15);
    border-radius: 4px;
    background-color: rgba(7, 16, 26, 0.05);
    margin-top: 5px;
  }
  .minMaxSalary {
    display: flex;
  }
}
