.pipeline-item {
  height: 32px;
  line-height: 32px;
  margin-left: 4px;
  overflow: hidden;
  font-size: 16px;
  background-color: #d0f3e1;
}

.pipeline-item:hover {
  color: white;
  background-color: #13c26b;
}
.pipeline-item:hover:after {
  border-left-color: #13c26b;
}
.pipeline-item-title::after,
.pipeline-item::after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  border: 16px solid transparent;
  border-left: 16px solid #d0f3e1;
  border-left-width: 12px;
  border-right: 0;
  position: absolute;
  left: 100%;
  top: 0px;
  z-index: 2;
}

.pipeline-item-title {
  display: flex;
  justify-content: center;
  .unlock-icon{
  padding-left: 10px;
  padding-top:1px;
  }
}
.pipeline-item-title::after {
  border-left: 16px solid white;
  border-left-width: 12px;
  transform: translateX(4px);
}

.pipeline {
  display: flex;
}

.pipeline > div:last-child > .pipeline-item::after {
  border: 0px;
}
.pipeline > div:last-child > .pipeline-item {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.pipeline > div:first-child > .pipeline-item {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.pipeline > div:last-child > .pipeline-item > .pipeline-item-title::after {
  border: 0px;
}

// small pipeline item

.pipeline-item-small {
  height: 18px;
  line-height: 18px;
  margin-left: 2px;
  overflow: hidden;
  font-size: 12px;
  background-color: #d0f3e1;
  color: white;
}

.pipeline-item-small::after,
.pipeline-item-small > .pipeline-item-title::after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  border: 9px solid transparent;
  border-left: 9px solid #d0f3e1;
  border-left-width: 6px;
  border-right: 0;
  position: absolute;
  left: 100%;
  top: 0px;
  z-index: 2;
}

.pipeline-item-small > .pipeline-item-title::after {
  border-left: 9px solid white;
  border-left-width: 6px;
  transform: translateX(2px);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

// add these for every bucket
// sourced pipeline
.sourced-btn {
  background-color: var(--unactivated-pipeline-color);
}

.sourced-btn::after {
  border-left-color: var(--unactivated-pipeline-color);
}

.sourced-btn-activated {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}

.sourced-btn-activated::after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.sourced-btn:hover {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}

.sourced-btn:hover:after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.sourced-btn-activated-v2 {
  background-color: var(--arya-pipeline-color);
}

.sourced-btn-activated-v2::after {
  border-left-color: var(--arya-pipeline-color);
  cursor: pointer;
}

.sourced-btn-v2:hover {
  background-color: var(--arya-pipeline-color);
}

.sourced-btn-v2:hover:after {
  border-left-color: var(--arya-pipeline-color);
}
// till here ^

// shortlisted pipeline
.shortlisted-btn {
  background-color: var(--unactivated-pipeline-color);
}

.shortlisted-btn::after {
  border-left-color: var(--unactivated-pipeline-color);
}

.shortlisted-btn-activated {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}
.shortlisted-btn-activated::after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.shortlisted-btn:hover {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}

.shortlisted-btn:hover:after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.shortlisted-btn-activated-v2 {
  background-color: var(--arya-pipeline-color);
}
.shortlisted-btn-activated-v2::after {
  border-left-color: var(--arya-pipeline-color);
}

.shortlisted-btn-v2:hover {
  background-color: var(--arya-pipeline-color);
}

.shortlisted-btn-v2:hover:after {
  border-left-color: var(--arya-pipeline-color);
}

//connected pipeline
.connected-btn {
  background-color: var(--unactivated-pipeline-color);
}

.connected-btn::after {
  border-left-color: var(--unactivated-pipeline-color);
}

.connected-btn-activated {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}
.connected-btn-activated::after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.connected-btn:hover {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}

.connected-btn:hover:after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.connected-btn-activated-v2 {
  background-color: var(--arya-pipeline-color);
}
.connected-btn-activated-v2::after {
  border-left-color: var(--arya-pipeline-color);
}

.connected-btn-v2:hover {
  background-color: var(--arya-pipeline-color);
}

.connected-btn-v2:hover:after {
  border-left-color: var(--arya-pipeline-color);
}
//Applied pipeline

.applied-btn {
  background-color: var(--unactivated-pipeline-color);
}

.applied-btn::after {
  border-left-color: var(--unactivated-pipeline-color);
}

.applied-btn-activated {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}

.applied-btn-activated::after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.applied-btn:hover {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}

.applied-btn:hover:after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.applied-btn-activated-v2 {
  background-color: var(--arya-pipeline-color);
}

.applied-btn-activated-v2::after {
  border-left-color: var(--arya-pipeline-color);
}

.applied-btn-v2:hover {
  background-color: var(--arya-pipeline-color);
}

.applied-btn-v2:hover:after {
  border-left-color: var(--arya-pipeline-color);
}

//Engaged pipeline

.engaged-btn {
  background-color: var(--unactivated-pipeline-color);
}

.engaged-btn::after {
  border-left-color: var(--unactivated-pipeline-color);
}

.engaged-btn-activated {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}
.engaged-btn-activated::after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.engaged-btn:hover {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}

.engaged-btn:hover:after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.engaged-btn-activated-v2 {
  background-color: var(--arya-pipeline-color);
}
.engaged-btn-activated-v2::after {
  border-left-color: var(--arya-pipeline-color);
}

.engaged-btn-v2:hover {
  background-color: var(--arya-pipeline-color);
}

.engaged-btn-v2:hover:after {
  border-left-color: var(--arya-pipeline-color);
}
// disabled pipeline
.pipeline-item-disabled::after {
  border-left-color: #e6eaee;
}

.pipeline-item-disabled {
  color: #B4B7BA;
  background-color: #e6eaee;
}
.pipeline-item-disabled:hover::after {
  border-left-color: #e6eaee;
}

.pipeline-item-disabled:hover {
  color: white;
  background-color: #e6eaee;
}

.custom-tooltip {
  .ant-tooltip-content {
    width: 360px;
  }
  .ant-tooltip-arrow::before,
  .ant-tooltip-inner {
    background-color: white;
  }
}
// aryaranked pipeline
.aryaranked-btn {
  background-color: var(--unactivated-pipeline-color);
}

.aryaranked-btn::after {
  border-left-color: var(--unactivated-pipeline-color);
}

.aryaranked-btn-activated {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}
.aryaranked-btn-activated::after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.aryaranked-btn:hover {
  background-color: var(--arya-accent-color);
  color: white;
  cursor: pointer;
}

.aryaranked-btn:hover:after {
  border-left-color: var(--arya-accent-color);
  cursor: pointer;
}

.aryaranked-btn-activated-v2 {
  background-color: var(--arya-pipeline-color);
}

.aryaranked-btn-activated-v2::after {
  border-left-color: var(--arya-pipeline-color);
}

.aryaranked-btn-v2:hover {
  background-color: var(--arya-pipeline-color);
}

.aryaranked-btn-v2:hover:after {
  border-left-color: var(--arya-pipeline-color);
}

// job page default active pipeline v2
.default-activated-pipeline-btn-v2 {
  background-color: var(--arya-pipeline-color);
}

.default-activated-pipeline-btn-v2::after {
  border-left-color: var(--arya-pipeline-color);
}

.tooltipUnlockWrapper{
  display: flex;
  flex-direction: column;
padding: 16px 24px;
  .tooltipUnlockHeader{
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 10px;
  }
}
