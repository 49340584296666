.segmentCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.segmentCardDetails {
  padding: 18px;

  & ~ .segmentCardDetails {
    border-top: 1px solid #e6eaee;
  }

  &:last-of-type {
    border-bottom: 1px solid #e6eaee;
  }
  & [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }
}
.segmentCardDetails:hover {
  box-shadow: 0 2px 8px -2px rgba(32, 38, 50, 0.3);
}

.segmentCardDetails ~ .segmentCardDetails:hover {
  border-top: 1px solid transparent;
}

.creatorName,
.listName,
.events {
  font-size: 16px;
  line-height: 24px;
  color: #07101a;
}

.creatorName {
  margin-left: 7px;
  max-width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 25px;
}
.listName {
  max-width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 25px;
  padding-left: 16px;
}
.none {
  font-size: 16px;
  line-height: 24px;
  color: #83878c;
  margin-left: 5px;
  padding-left: 16px;
}
.dateTime {
  display: flex;
  gap: 4px;
  margin-left: 7px;
}
.moreIcon {
  display: flex;
  gap: 10px;
  margin-top: 4px;
  width: 60px;
  justify-content: right;
  .scoutingAgentIcon {
    width: 28px;
    display: flex;
    justify-content: center;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    border: 1px solid #13c26b;
  }
  .moreIconWrapper {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.content {
  width: 125px;
  font-size: 14px;
  line-height: 16px;
  color: #1f2730;
  padding: 11px 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
}
.sharedAvatarSegment {
  margin-left: 10px;
}


