.botConfigModal {
  :global {
    .ant-modal-body {
      padding: 20px;
      background-image: var(--bot-bg-image2);
      background-size: cover;
    }
  }
}

.botConfigure {
  display: flex;
  gap: 10px;
}

.botEfficiency {
  font-size: 32px;
  line-height: 38px;
  font-weight: 600;
}

.turnOnBot {
  font-size: 20px;
  font-weight: 500;
  color: #13c26b;
}

.botProsTitle {
  font-weight: 700;
}

.botPros {
  padding-top: 14px;
  font-size: 16px;
}

.botImage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.okButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
}
