.delayNodeContainer {
  width: 650px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;

  .waitInputSection {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .resumeTimeSection,
  .checkboxGroup {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
  }

  .resumeSectionShow {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .resumeSectionHide {
    display: none;
  }

  .delayInput {
    display: flex;
    gap: 20px;
  }

  #waitLabel {
    padding-top: 5px;
  }

  #tooltipIcon {
    padding-top: 5px;
  }

  .footer {
    position: absolute;
    right: 0;
    background: #fff;
    text-align: right;
    width: 100%;
    bottom: 0;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
  }
}
