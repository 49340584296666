.jobFormContainer {
  height: 100%;
  position: relative;
}
.jobFormFooterAffix {
  max-width: 100% !important;
  width: 100% !important;
  position: relative;
  z-index: 1200;
  .jobformBottomAffix {
    margin: 0 !important;
    background: #fff;
    text-align: right;
    padding: 10px 0px 10px 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: 0 -2px 3px 0 rgba(7, 16, 26, 0.15);
  }
}
.cancelButton {
  display: flex;
  align-items: center;
  gap: 19px;
}

.diversityBlock {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.diversityPolicyBlock {
  margin-top: 10px;
}

.diversityHeader {
  height: 24px;
  width: 418px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

.diversityPreference {
  display: flex;
  box-shadow: 0 0 4px 0 rgba(7, 16, 26, 0.2);
  border-radius: 4px;
  width: 542px;
  height: 46px;
  margin-top: 10px;
  flex-direction: column;
}

.diversityMinorityPreference {
  display: flex;
  box-shadow: 0 0 4px 0 rgba(7, 16, 26, 0.2);
  border-radius: 4px;
  width: 542px;
  padding-bottom: 10px;
  margin-top: 10px;
  flex-direction: column;
}

.diversityPreferences {
  padding-top: 10px;
  padding-bottom: 10px;
}

.minorityPreference {
  display: flex;
  flex-direction: column;
}

.locationLabel {
  line-height: 22px;
  margin-bottom: 4px;
  color: #1f2730;
}

.diversityLabel {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.diversityOptions {
  padding-right: 10px;
}

.diversityLabelDescription {
  height: 22px;
  color: var(--secondary-black-color);
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 22px;
}

.diversityClearBtn {
  font-size: 12px;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
}

.diversityClearBtnDisabled {
  font-size: 12px;
  display: flex;
  flex-direction: row-reverse;
  cursor: default;
  pointer-events: none;
  opacity: 0.7;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin-left: 26px;
  padding-top: 6px;
}

.genderRadioOptions {
  display: grid;
  width: 100%;
  grid-template-areas:
    'male'
    'female';
}

.maleOptions {
  grid-area: male;
}
.femaleOptions {
  grid-area: female;
}

.locationWrapper {
  margin-top: 15px;
  .locationTitle {
    font-size: 20px;
    font-weight: var(--default-font-weight);
    color: var(--secondary-black-color);
    line-height: 24px;
  }
  .locationDescription {
    color: var(--grey-70-color);
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .locations {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    color: var(--grey-70-color);
    &.ok {
      font-weight: 500;
    }
  }
  .locationsHide {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    :global {
      .ant-radio {
        display: none;
      }
    }
    span {
      padding-left: 0px;
    }
  }
}
.selectedLocation {
  font-weight: 500;
  color: var(--secondary-black-color);
}
.experienceNote {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: var(--grey-70-color);
}
.tagsAndInfoIcon {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}
.jobFormLabelHeader {
  display: flex;
  gap: 10px;
  margin-top: 24px;
  .experience {
    width: 170px;
  }
  .totalOpenings {
    width: 170px;
  }
}
.jobFormClientDataInfo {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}
.remoteJobInput {
  margin: 0px 0px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.remoteJobInfo {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  .remoteJob {
    width: 76px;
    height: 22px;
  }
}
