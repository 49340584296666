.plan-credit-information {
  height: 32px;
  border-radius: 4px;
  background-color: #f4f6f8;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  padding: 0px 12px;
  .anticon {
    font-size: 15px;
    margin-right: 6px;
  }
}

.plan-credit-information:not(:last-child) {
  margin-right: 16px;
}
