.jobList {
  margin: auto;
  padding: var(--body-content-padding);
}
.paginationContainer {
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-bottom: 20px;
}


