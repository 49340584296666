.notificationSettingsItem {
  max-width: 480px;
  display: flex;
  justify-content: space-between;
}

.title {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
