.donutChart {
  position: relative;
  padding: 16px;
}

.tooltip-donut {
  position: absolute;
  font-size: 12px;
  pointer-events: none;
  min-width: 68%;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  opacity: 0;
  z-index: 2500;
}
.donut-tooltip-content {
  margin-top: -3px;
  padding-bottom: 7px;
}

.donut-tooltip-heading {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 12px 12px 4px 12px;
}

.tooltip-details-info {
  display: flex;
  margin: 0px 12px 0px 12px;
  .label-color {
    height: 10px;
    width: 10px;
    border-radius: 5px;
  }
  .label-heading {
    margin: 0px 9px 0px -2px;
  }
}
