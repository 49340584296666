.candidateScore {
  border: 0.8px solid #b4b7ba;
  border-radius: 24px;
  display: flex;
  align-items: center;
  .candidateScoreSection {
    flex: 1;
    padding: 6px 9px;
    display: flex;
    gap: 8px;
    color: #51575e;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    justify-content: center;
    align-items: center;
    i {
      font-size: 18px;
    }

    .candidateScoreWithSlash {
      display: flex;
      gap: 4px;
      .slashIcon,
      .maxCandidateScore {
        font-size: 12px;
      }
      .maxCandidateScore {
        font-weight: 400;
      }
    }
  }
  .candidateRatingLinkButton {
    border-left: 0.8px solid #b4b7ba;
    border-radius: 0px;
    span {
      text-decoration: underline;
    }
  }
}
