.candidateAutoRejectSwitchWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.autoRejectSwitchWrapper {
  display: flex;
  justify-content: space-between;
}

.autoRejectSwitchTitle {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.autoRejectSwitchInfoWrapper {
  display: flex;
  gap: 8px;
}

.autoRejectSwitchInfoIconWrapper {
  font-size: 16px;
  color: #83878c;
}

.autoRejectSwitchInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.autoRejectSwitchInfoTitle {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

.autoRejectSwitchInfoDescription {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  max-width: 718px;
}
