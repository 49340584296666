.payment-setting-wrapper {
  width: 80%;
  padding: 0px 0px 20px 0px;
  margin-bottom: 50px;
  .card-details-body {
    padding: 20px !important;
  }
  :global {
    .ant-form {
      margin-top: -10px !important;
    }
  }
}
