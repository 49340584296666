.callStats {
  display: flex;
  justify-content: space-between;
  padding: 0px 8px 0px 0px;
}

.callStatsView {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
