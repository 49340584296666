.sourcesWrapper {
  display: flex;
  flex-direction: column;

  .sourceIconWrapper {
    display: flex;
    height: 47px;
    background-color: #f2f3f3;
    border-bottom: 1px solid #e9e9ea;
    .hamburgerIcon {
      flex: 1;
      margin-top: 15px;
      > svg {
        font-size: 22px;
      }
    }
    .sourcesTextWrapper {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-left: 25px;
      .sourcesText {
        color: rgba(7, 16, 26, 0.9);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.sourceWrapper {
  .sourceItemWrapper {
    display: flex;
    align-items: center;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 18px 10px 18px;
  border-bottom: 1px solid #e9e9ea;

  &.advanceOptionsApplied {
    border-left: 4px solid #13c26b;
    padding-left: 14px;
  }

  .advanceSettingsButton {
    color: rgba(7, 16, 26, 0.7);
    cursor: pointer;
    > svg {
      font-size: 16px;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
  > span {
    display: flex;
  }
}
