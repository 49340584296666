.primary-card-header {
  border-bottom: 1px solid #cfd5db;
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 53px;
  align-items: center;
  padding: 10px;
  font-size: 17px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  letter-spacing: 0.77px;
  line-height: 20px;
  color: #374251;

  .search {
    margin-left: auto;
  }
}
