.jobsTabHeader {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  height: 14px;
  .jobsInputSearch {
    width: 139px;
    input {
      border-radius: 16px;
    }
  }
  .ant-input-suffix .listsInputSearch {
    z-index: 0;
  }
}
.searchAndFilter {
  display: flex;
  gap: 12px;
  position: absolute;
  margin-top: 10px;
}

.matchingJobs {
  text-decoration: underline;
  font-weight: 400;
  color: #51575e;
  cursor: pointer;
}

.recentJobs {
  color: #13c26b;
}

.textDivider {
  color: #1f2730;
}

.candidateJobsLoadingState {
  :global {
    .ant-skeleton-title {
      margin-top: 40px;
    }
  }
}

.jobsHeaderForAtsView {
  margin-top: 5px;
  margin-right: 10px;
  height: 22px;
}
