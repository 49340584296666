.create-template.text.drawer {
  .row-one {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;

    .ant-form-item {
      margin: 0px;
    }
  }
}

.create-template-editor {
  .ql-editor {
    min-height: 300px;
    max-height: calc(100vh - 200px);
  }
}

.create-template-merge-tag-button {
  cursor: pointer;
  color: var(--arya-accent-color);
  text-decoration: underline;
  font-weight: 400;
  line-height: 22px;
}

.create-template-consent-text {
  border: 1px solid #dadbdd;
  border-radius: 0 0 4px 4px;
  background-color: rgba(7, 16, 26, 0.03);
  padding: 15px;
}

.create-template-consent-template {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-70-color);
  margin-right: 10px;
}

.create-text-tempate-consent-wrapper {
  display: flex;
  padding: 0 0 10px 0;
}
.switchAndInfoIcon {
  display: flex;
  gap: 5px;
  align-items: center;
}

.consentInfoTooltip {
  font-size: 12px;
}

.message-count-container {
  display: flex;
  margin-top: 2px;
  .message-count-info-container {
    margin-top: 2px;
  }
}
