.drip-display-container-candidate-360-mail {
  overflow: auto;
}
.old-view-bulk-email-composer {
  .preHeaderDeleteIcon {
    left: 390px !important;
    top: 0px !important;
  }
}
.old-mail-window-drip-email {
  .preHeaderDeleteIcon {
    left: 810px !important;
  }
}
#candidate-360-subject-drip-view {
  .rich-text-editor-true {
    width: 100%;
    border: 1px solid #ccc;
    .subject-container {
      .subject-label {
        min-width: fit-content !important;
      }
    }
    .quill {
      width: 100%;
    }
    .ql-container {
      border: none !important;
      margin-bottom: 0px !important;
      .ql-editor {
        overflow: auto !important;
      }
    }
  }
  .rich-text-editor {
    width: 100% !important;
    display: flex;
    flex-direction: row-reverse;
    .ql-mergetags {
      width: auto !important;
    }
    .custom-toolbar-container {
      .custom-toolbar {
        border-top: none !important;
        border-left: none !important;
      }
    }
  }
}
.connect-window-display-drip-template {
  .connect-drip-condition {
    .ant-select {
      width: 120px;
    }
    .ant-select-selection {
      width: 120px;
    }
  }
}

.connect-window-display-drip-template,
.mail-window-display-drip-template,
.bulk-mail-window-display-drip-template {
  position: relative;
  left: -16px;
  margin-top: 20px;
}

.connect-window-display-drip-template,
.mail-window-display-drip-template {
  width: calc(100% - 56px);
}

.drips-display-time-line-item {
  .ant-timeline-item-tail {
    top: 45px;
  }
  .ant-timeline-item-head {
    top: 45px;
  }
  .ant-timeline-item {
    .ant-timeline-item-content {
      .anticon-right {
        top: 33px;
      }
    }
  }
}

.drip-condition {
  .ant-input-number {
    width: 60px;
  }
}

.drip-html-viewer {
  border: 1px solid rgba(7, 16, 26, 0.15);
  border-radius: 4px;
  background-color: #ffffff;
  overflow-x: scroll;
}

.preHeaderDeleteIcon {
  font-size: 18px;
  position: relative;
  z-index: 222;
  left: 420px;
  top: 28px;
}
.candidate-360-drip-view {
  margin-top: 20px;
  .ql-editor {
    min-height: 22vh;
    max-height: 30vh;
  }
  .rich-text-editor {
    .ql-mergetags {
      width: auto !important;
    }
    .custom-toolbar-container {
      .custom-toolbar {
        border-top: none !important;
      }
    }
  }
}
