.user-shared-text-template-consent-box {
  border: 1px solid #ffbb96;
  border-radius: 11px;
  background-color: #fff2e8;
  color: #fa541c;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px 0 10px;
  margin: 0 15px 0 15px;
}

.user-shared-text-template-title-wrapper {
  display: flex;
}
