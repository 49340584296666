.manualSearchEmptyScreenWrapper{
  padding-bottom: 70px;
  padding-top: 70px;
  margin-top: 30px;
  .manualSearchEmptyScreen > svg {
  width: 275px;
  font-size: 40px;
  height: 40px;
}

.messageHeader{
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
}

.messageInfo {
  height: 24px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
}
:global .ant-empty-image{
  height: fit-content;
}
}
