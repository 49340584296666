.popup-container {
  border-radius: 8px 8px 0 0;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  width: 380px;
  height: 410px;
  display: flex;
  flex-direction: column;
  z-index: 1900;

  .popup-header {
    padding: 10px 10px 5px;
    border-bottom: 2px solid #e6eaee;
    position: relative;
    .popup-name-close {
      display: flex;
      margin-bottom: 2px;

      .popup-name-wrapper {
        font-size: 13px;
        color: #81878e;

        .popup-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: rgba(7, 16, 26, 0.9);
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .popup-close {
        margin-left: auto;
        color: rgba(7, 16, 26, 0.3);
      }
    }

    .popup-phones {
      font-size: 14px;
      line-height: 20px;
      display: inline-flex;

      .phone-select {
        & > div {
          border: 0;
          color: #13c26b;
          box-shadow: none;

          .ant-select-arrow {
            color: #13c26b;
          }
        }

        .ant-select-selection__rendered {
          margin-left: 0px;
        }
      }
      .phone-select-invalid {
        & > div {
          border: 0;
          color: red;
          box-shadow: none;

          .ant-select-arrow {
            color: red;
          }
        }

        .ant-select-selection__rendered {
          margin-left: 0px;
        }
      }
    }
  }

  .popup-body {
    // padding: 10px 0 10px 10px;
    overflow: auto;
    background-color: #f8f9fa;
    display: flex;
    flex-grow: 1;
  }

  .popup-footer {
    .popup-chat-textarea {
      padding: 5px 11px 5px 0;
      border-top: 1px solid #e6eaee !important;
      display: flex;
      align-items: center;

      .ant-input:focus {
        box-shadow: none;
        border: 0 !important;
      }

      #popup-chat-send-button {
        font-size: 18px;
        padding: 0px 12px;
        height: 40px;
        background-color: var(--arya-accent-color);
        border-color: var(--arya-accent-color);
      }
      .ql-container {
        border: 0px;
        width: 330px;
      }
      .ql-blank {
        font-family: 'Roboto', sans-serif;
      }
    }
    .popup-chat-textarea-junk-text {
      .ql-container {
        border: 0px;
        width: 360px;
      }
    }
  }
}

.consent-status-info-message {
  padding: 8px;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  border-radius: 4px;
  display: flex;

  .anticon {
    margin: 0 10px;
    color: #1890ff;
  }

  &.consent-pending {
    background-color: #fffbe6;
    border-color: #ffe58f;

    .anticon {
      color: #faad14;
    }
  }

  &.consent-denied {
    background-color: #fff1f0;
    border-color: #ffa39e;

    .anticon {
      color: #f5222d;
    }
  }
}
.lineForInvalidContact {
  text-decoration: line-through;
}
