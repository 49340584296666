.manualSearchFormFooter {
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0 0 5px 0;
  border-top: 1px solid #e8e8e8;
  .manualSearchFormSaveButton {
    margin-right: 12px;
    button {
      display: flex;
      align-items: center;
      gap: 14px;
      padding: 0 0 0 16px;
    }
    .iconStyle {
      height: 100%;
      display: grid;
      place-content: center;
      border-left: 1px solid rgba(218, 219, 221, 1);
      padding-left: 12px;
      padding-right: 16px;
      i {
        color: rgba(131, 135, 140, 1);
        font-size: 16px !important;
      }
    }
  }
  .manualSearchFormFooterButtons {
    display: flex;
    gap: 10px;
    margin-right: 70px;
  }
}

.hideFooter {
  display: none;
}

.saveDropDown {
  min-width: 250px;
  li {
    font-weight: 500;
    color: #1f2730;
  }
}
