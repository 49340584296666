.checkbox-group {
  width: 100%;
}

.ant-btn.candidate-list-filter {
  // margin-right: 10px;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  padding-left: 0px;
  padding-right: 0px;
  border: transparent;
  cursor: pointer;

  i > svg {
    font-size: 33px;
  }

  i.totaljobs-icon > svg {
    font-size: 24px;
  }
}

.ant-btn.candidate-list-filter.unselected {
  opacity: 0.6;
  // filter: grayscale(100%);
  background: #fff;
  border: 3px solid #d3d3d3;
}
.ant-btn.candidate-list-filter:hover,
.ant-btn.candidate-list-filter:focus,
.ant-btn.candidate-list-filter:active {
  color: rgba(0, 0, 0, 0.65);
}

.candidate-list-filter.disconnected {
  opacity: 0.4 !important;
  i > svg {
    filter: grayscale(100%);
  }
}

.ant-btn.candidate-list-filter.selected {
  background: #fff;
  border: 3px solid #13c26b;
}

.candidate-filter-popover-content {
  width: 870px;
}

.candidate-filter-popover {
  z-index: 1900;
}

.industry-filter {
  max-height: 400px;
  overflow-y: auto;
}

.boost-filter {
  max-height: 400px;
  overflow-y: auto;
}

.shortlist-by-name {
  display: inline-block;
  position: relative;
  top: 7px;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

.source-filter {
  max-height: 135px;
  overflow-y: auto;
}

.industry-filter::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
}

.industry-filter::-webkit-scrollbar-thumb {
  border-radius: 3.5px;
  background: #d8d8d8;
}

.source-filter::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
}

.source-filter::-webkit-scrollbar-thumb {
  border-radius: 3.5px;
  background: #d8d8d8;
}

.candidate-filter-aggregation {
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: 40px;
}

.candidate-filter-rating-mover {
  width: 54%;
  padding: 17px 0px 17px 17px;
}

.candidate-filter-apply-clear {
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidate-filter-industry {
  width: 54%;
  border-left: 1px solid #dadbdd;
  padding: 17px;
}

.candidate-filter-source {
  width: 100%;
}

.candidate-filter-source h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.candidate-filter-clear-button,
.candidate-filter-apply-button {
  height: 32px;
  width: 79px;
  border: 1px solid #dadbdd;
  border-radius: 20px;
  margin-left: 10px;
  line-height: 22px;
  font-size: 14px;
}

.candidate-filter-clear-button {
  background-color: #ffffff;
}

.candidate-filter-aggregation-title {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidate-filter-mover-prediction {
  .ant-form-item {
    margin-bottom: 0px;
  }

  h3 {
    margin-bottom: 2px;
    margin-top: 10px;
  }
}

.candidate-filter-match {
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.mover-prediction-match {
  display: flex;
  flex-wrap: wrap;
}

.source-count-label {
  margin-right: 10px;
  top: 3.5px;

  .ant-badge-count {
    top: 0px;
    right: 9px;
    box-shadow: none;
  }

  &.unselected {
    .ant-badge-count {
      background: #f4f5f7;
      color: #767c83;
    }
  }

  &.selected {
    .ant-badge-count {
      background: #007bff;
      p {
        color: #fff;
      }
    }
  }

  p {
    line-height: 22px;
  }
}

.candidate-filter-row {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  .candidate-source-filter-wrapper {
    display: flex;
    align-items: center;
  }

  .candidate-source-filter {
    color: var(--arya-accent-color);
    border: 1px solid #dadbdd;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    padding-left: 15px;
    padding-right: 15px;

    // To rmove ant animation on  onclick of button
    [ant-click-animating-without-extra-node]:after {
      animation: 0s !important;
    }

    .candidate-source-button {
      border-radius: 0px;
      border: none;
      padding-left: 0px;
      padding-right: 0px;
    }

    .candidate-source-button-filters {
      border-radius: 0px;
      border: none;
      border-right: 1px solid var(--arya-accent-color);
      color: var(--arya-accent-color);
      padding-left: 0px;
      padding-right: 10px;
    }

    .clear-source {
      padding-left: 10px;
      border: none;
      cursor: pointer;
      font-size: 12px;
    }
  }

  .candidate-source-filter.selected {
    border: 1px solid var(--arya-accent-color);
    padding-right: 10px;
  }

  .candidate-search-box-wrapper {
    // margin-left: auto;

    .candidate-search-box input {
      border-radius: 25px;
    }
  }

  .candidate-control-icons {
    display: flex;
    align-items: center;
    gap: 12px;

    .controls-button,
    .controls-button-aggregation {
      border: 0px;
      color: #83878c;
      padding: 0px;
      box-shadow: none !important;
    }

    .controls-button-aggregation {
      padding: 0px;
    }

    .ant-badge-dot {
      background-color: #13c26b;
    }
    .draggedReactivateArya {
      position: fixed;
      z-index: 1000;
      top: calc(100vh - 175px);
      right: 2px;
    }
    .submit-candidate-button {
      padding: 0px;
      border: 0px;

      > span {
        text-decoration: underline;
      }
    }
  }
  .candidate-filter-selected-portal-text {
    margin-left: 20px;
    padding-left: 20px;
  }
}

.candidate-sources-wrapper {
  padding: 10px;
}

.portal-image-filter {
  border-radius: 20px;
  height: 32px;
  width: 32px;
  padding-left: 0px;
  padding-right: 0px;
}

.candidate-aggregation-image-filter,
.candidate-sorting-image-filter {
  height: 23px;
  width: 23px;
}

.candidate-filter-tick-box {
  display: flex;
  justify-content: space-between;
  align-content: baseline;

  > div {
    margin: 4px;
  }
}

.shorlist-by-values {
  margin-bottom: 8px;
}
