.freeTrialFindingCandidates {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.candidateListFreeTrialPlanNoCandidatesFound {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 12px;
}
.candidateListFreeTrialFindingCandidateGif {
  margin: 0 auto 12px;
  display: block;
}
.candidateListFreeTrialPlanNoCandidatesFoundMessage {
  color: #000000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  width: 400px;
}
.candidateListFreeTrialText {
  text-decoration: underline;
}
.candidateListNoCandidatesFound {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
}
.candidateListNoCandidatesFoundMessage {
  height: 24px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
}
