.scoutingAgentNotificationContainer {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e6eaee;
  padding: 12px;
  cursor: pointer;
  width: 360px;
  gap: 12px;
  .scoutingAgentNotificationIconWrapper {
    padding-left: 6px;
  }
  .scoutingAgentIcon {
    border-radius: 50%;
    border: 1px solid #dadbdd;
    padding: 3px 6px 2px 6px;
    margin-top: 3px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .content {
    width: 229px;
    margin-right: 26px;
    .notificationTitle {
      span {
        font-weight: bold;
      }
    }
  }
  .actionButton {
    margin-top: 10px;
    button {
      padding: 0 12px;
    }
  }
}
