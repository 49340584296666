.input-search-component {
  &.disabled {
    .ant-btn-primary {
      border: 1px solid #dadbdd;
      background-color: rgba(7, 16, 26, 0.05);
      cursor: not-allowed;
      box-shadow: none;
    }
  }
  .ant-input-search-button {
    padding: 0px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
