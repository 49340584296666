.filter-popover-component-overlay {
  z-index: 100;

  .ant-popover-title {
    padding: 10px 20px 9.5px 16px;
  }

  .filter-popover-component-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filter-popover-component-title {
    font-size: 14px;
  }

  .filter-popover-component-title-buttons-wrapper {
    width: 148px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.filter-popover-applied {
  color: #13c26b;
  border-color: #13c26b;
}

.filter-bage {
  cursor: pointer;
  :global .ant-badge {
    .ant-badge-dot {
      background-color: #13c26b;
    }
  }
}
