.consentInfoBanner360 {
  margin-top: -10px;
  margin-right: 20px;
}

.consentStatusInfoMessage360View {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  border-radius: 4px;
  width: fit-content;
  justify-content: center;
  margin-bottom: 10px;
}

.consentPending {
  background-color: #fffbe6;
  border-color: #ffe58f;
}

.consentDenied {
  background-color: #fff1f0;
  border-color: #ffa39e;
}
