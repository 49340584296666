.candidateQualificationSummary {
  cursor: pointer;
  mark {
    color: #1f2730 !important;
    font-weight: 700;
    background-color: inherit !important;
  }
  .candidatSummaryField {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    align-items: flex-start;
    overflow: hidden;
    .summaryLabelGroup {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .summaryLabel {
        color: #83878c;
      }
    }
    .summaryValueGroup {
      display: flex;
      flex-direction: column;
      gap: 6px;
      overflow: hidden;
    }
  }
}
