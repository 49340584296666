.text-templates {
  max-width: 990px;
}

.text-templates .ant-pagination {
  margin-top: 20px;
  margin-bottom: 20px;
  float: right;
}

.text.template-search {
  margin-left: 384px;
}

.create-text-template {
  margin-left: 10px;
}
