.emailConfigurationInput {
  width: 260px;
}

.emailConfigurationModalContent {
  display: inline-flex;
  gap: 15px;
  margin-bottom: 22px;
}

.radioButtonDesign {
  margin-top: 10px;
}

.oAuthComponentOrientation {
  margin-top: 10px;
}

.emailConfigurationModal {
  width: 881px;
}

.signatureInput {
  height: 120px;
}

.existingEmailProviders {
  margin-top: 15px;
  .emailProvidersGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .header {
    color: rgba(7, 16, 26, 0.9);
    font-size: 14px;
    font-weight: 500;
  }
  .providerWrapper {
    display: inline-flex;
    gap: 5px;
    .providerName {
      width: auto;
      overflow: visible;
    }
  }
}

.addNewEmailProviderWrapper {
  margin-top: 15px;
  .header {
    color: rgba(7, 16, 26, 0.9);
    font-size: 14px;
    font-weight: 500;
  }
  .oAuthButtons {
    margin-top: 10px;
    margin-bottom: 24px;
    display: flex;
    gap: 25px;
  }
}
