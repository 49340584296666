#industryRelevanceSankeyChart {
  position: relative;

  text {
    pointer-events: none;
  }
}

.industry-relevance-tooltip {
  position: absolute;
  height: auto;

  opacity: 0;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  padding: 12px;
  z-index: 2500;
  margin-left: -50px;
}

.industry-relevance-tooltip-content p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.industry-relevance-percentage {
  font-size: 24px;
  font-weight: 600;
}

.industry-relevancy-text {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  max-width: 300px;
}

.industry-relevancy-description {
  display: flex;
}

.industry-relevancy-color {
  height: 10px;
  width: 10px;
  margin: 3px 8px 0px 0px;
}
