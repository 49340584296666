.customFieldContainer {
  width: 650px;
  margin: 0;

  .inputSection {
    display: flex;
    width: auto;
    gap: 15px;
  }

  button > span {
    text-decoration: underline;
  }

  :global {
    .ant-btn-link {
      padding-left: 0px;
      margin-top: 20px;
    }
    .ant-tag {
      border-radius: 10px;
    }
  }

  .customTags {
    position: relative;
    display: flex;
    margin-top: 15px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 7px;
  }

  .customTagDrawerFooter {
    position: absolute;
    width: 100%;
    right: 0;
    border-top: 1px solid #e9e9e9;
    bottom: 0;
    text-align: right;
    background: #fff;
    padding: 10px 16px;
  }
}
