.chartLabels {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.lineChart {
  position: relative;
}

.chartLabelsView {
  display: flex;
  flex-direction: row;
}

.labelColor {
  margin: 2px 8px 0px 0px;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.labelName {
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.ChartFilters {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}
