.dialpad {
  width: 100%;
  cursor: pointer;
}

.dialpad-row {
  display: flex;
  text-align: center;
}

.dial-note {
  padding: 11px 12px;
  font-weight: 500;
  margin: auto;
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  line-height: 19px;
}
