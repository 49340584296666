.candidateStatus {
  margin: 12px 0px 28px 0px;
  display: flex;
  width: 100%;
}

.pipelineWrapper {
  width: 100%;
  margin-right: 10px;
  cursor: pointer;
}

.rejectButton {
  margin-left: auto;
  padding: 4px 8px !important;
  border-radius: 50% !important;
  width: 2.3em;
  height: 2.3em !important;

  .rejectButtonCount {
    display: none;
    border-radius: 50%;
    width: 3em;
  }

  .icon {
    display: inline;
    margin-left: 0px !important;
  }
}

.rejectButton:hover .rejectButtonCount {
  display: inline;
  border-radius: 50%;
}

.rejectButton:hover .icon {
  display: none;
}

.rejectedCount {
  display: flex !important;
  justify-content: center;
}
