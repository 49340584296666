#roleSimilarityScatterPlot {
  position: relative;
}

.role-similarity-tooltip {
  position: absolute;
  font-size: 12px;
  pointer-events: none;
  height: auto;
  width: auto;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  opacity: 0;
  padding-bottom: 12px;
  z-index: 2500;
}

.role-tooltip-details {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 12px 0px 12px;
}

.tooltip-candidate-role-color,
.tooltip-job-role-color {
  height: 10px;
  width: 10px;
  margin: 4px 8px 0px 0px;
}

.tooltip-candidate-role-color {
  background-color: #5b8ff9;
}

.tooltip-job-role-color {
  background-color: #5ad7a6;
}
