.formItemLabelText {
  line-height: 24px;
  font-size: 14px;
  color: rgba(7, 16, 26, 0.7);
  font-weight: 500;
}

.languageDeleteButton {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 22px;
  color: #999;
  transition: all 0.3s;
}

.languageDeleteButton:hover {
  color: #777;
}

.languageDeleteButton[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.languageSelect {
  width: 112px;
  margin-right: 8px;
}

.proficiencySelect {
  width: 120px;
  margin-right: 8px;
}

.languageSelectPopover {
  z-index: 2200;
  min-width: 80%;
  max-height: 180px;
  overflow: hidden;
}

.proficiencySelectPopover {
  z-index: 2200;
  min-width: 55%;
}
