.searchSavedCriteriaModalContent {
  display: flex;
  flex-direction: column;
  gap:15px;
  .saveCriteriaheader {
    height: 28px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 20px;
    font-weight: 500;
  }
  .saveCriteriaDescription {
    margin-top: 14px;
    font-size: 16px;
  }

  .saveCriteriaFooter {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }
}
