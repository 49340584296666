.modalTextArea {
  .ant-input {
    height: 100px;
  }
}
.modalContent {
  line-height: 0.7;
}
:global {
  .ant-modal-centered .ant-modal {
    top: 64px;
  }
}

.ownerSelect {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  label {
    font-weight: 400;
  }
  :global {
    .ant-form-item-label {
      margin-right: 8px;
    }
  }
}
