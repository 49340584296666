.selectPlanContainer {
  padding-top: 48px;
  min-width: var(--app-max-width);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .selectPlanCardsContainer {
    flex-direction: column;
    display: inline-flex;
    position: relative;

    .plancards {
      height: 578px;
      padding: 3px 47px 0px;
      display: flex;
      // justify-content: space-between;
      justify-content: center;
      .planCard {
        margin: 0 5px;
        position: relative;
        overflow: hidden;
        height: 575px;
        width: 300px;
        box-sizing: border-box;
        border: 1px solid #979797;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        .planCardHeaderWrapper {
          height: 109px;
          padding: 43px 12px 13px;
          text-align: center;
          z-index: 100;
          .title {
            height: 24px;
            color: #13c26b;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            .caption {
              height: 16.2px;
              color: rgba(7, 16, 26, 0.9);
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16.2px;
              margin-bottom: 0px;
              margin-top: 5px;
            }
          }
          .basicTitle {
            position: relative;
            left: 1px;
            top: -143px;
            height: 24px;
            color: #13c26b;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;

            .basicCaption {
              height: 16.2px;
              color: rgba(7, 16, 26, 0.9);
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16.2px;
              margin-bottom: 0px;
              margin-top: 5px;
            }
          }
        }
        .planCardPriceInfoWrapper {
          .planPriceInfo {
            height: 43px;
            color: #080808;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            background-color: #f3f3f3;
            .planPriceInfoDescription {
              color: #080808;
              font-size: 12px;
              letter-spacing: 0;
            }
          }
          .discountedAmount {
            text-decoration: line-through;
            text-decoration-color: #b50000;
            color: black;
            position: relative;
            bottom: 10px;
            left: 20px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 1px;
          }

          .actualAmount {
            position: relative;
            color: #b50000;
            font-size: 24px;
            font-weight: 500;
            right: 29px;
            top: 8px;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 1px;
            margin-left: 10px;
          }
        }
        .planCardTurnAroundTimeInfoWrapper {
          .turnAroundTimeInfo {
            height: 53px;
            color: rgba(7, 16, 26, 0.9);
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }

        .planCardDescription {
          box-sizing: border-box;
          height: 40px;
          border: 0.5px solid #979797;
          border-bottom: 0.5px;
          border-right: 0.5px;
          border-left: 0.5px;

          .checkIcon {
            font-size: 140%;
            color: #83878c;
          }
        }

        .planCardDescription:nth-last-child(2) {
          border: 0.5px solid #979797;
          border-right: 0.5px;
          border-left: 0.5px;
        }

        .planCardDescriptionText {
          justify-content: flex-start;
          text-align: left !important;
          padding-left: 8px;
          color: #4a4848;
          font-size: 13px;
          letter-spacing: 0;
        }

        .multipleJobsPlanCard {
          height: 280px;
          .multipleJobsPlanCardDescription {
            height: 200px;
            padding: 8px 15px 78px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .multipleJobsPlanCardContent {
              height: 30px;
              background-color: white;
              display: flex;
              justify-content: center;
              .circle {
                height: 7px;
                width: 7px;
                margin-right: 20px;
                background-color: #13c26b;
                border-radius: 50%;
                margin-top: 4px;
              }
              .information {
                width: 194px;
                color: rgba(7, 16, 26, 0.9);
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
              }
            }
            .multipleJobsPlanCardContent:nth-child(1) {
              align-items: flex-end;
              .circle {
                margin-bottom: 7px;
              }
            }
          }
        }

        .planCardButton {
          height: 88px;

          .getStartedButton {
            width: 70%;
            height: 35.2px;
          }

          .linkButton {
            height: 35.2px;
          }
        }
      }

      .planCard:hover {
        .planCardHeaderWrapper {
          .title {
            font-size: 25px;
          }
        }
      }

      .planCard:hover:not(:nth-child(1))::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        padding: 5px;
        background: linear-gradient(to bottom, #13c26b, #0f66be);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: destination-out;
        mask-composite: exclude;
      }

      .planCard:nth-child(1) {
        width: 380px;
      }
      .basicPlanCard {
        margin: 0 5px;
        position: relative;
        overflow: hidden;
        height: 655px;
        width: 241px;
        box-sizing: border-box;
        border: 1px solid #979797;
        border-radius: 10px;
        z-index: 100;

        display: flex;
        flex-direction: column;
        .planCardHeaderWrapper {
          height: 109px;
          padding: 43px 12px 13px;
          text-align: center;
          .title {
            height: 24px;
            color: #13c26b;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            .caption {
              height: 16.2px;
              color: rgba(7, 16, 26, 0.9);
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16.2px;
              margin-bottom: 0px;
              margin-top: 5px;
            }
          }
        }
        .planCardPriceInfoWrapper {
          .planPriceInfo {
            height: 43px;
            color: #080808;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            background-color: #f3f3f3;
            .planPriceInfoDescription {
              color: #080808;
              font-size: 12px;
              letter-spacing: 0;
            }
          }
          .discountedAmount {
            text-decoration: line-through;
            text-decoration-color: #b50000;
            color: black;
            position: relative;
            bottom: 10px;
            left: 20px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 1px;
          }

          .actualAmount {
            position: relative;
            color: #b50000;
            font-size: 24px;
            font-weight: 500;
            right: 29px;
            top: 8px;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 1px;
            margin-left: 10px;
          }
        }
        .planCardTurnAroundTimeInfoWrapper {
          .turnAroundTimeInfo {
            height: 53px;
            color: rgba(7, 16, 26, 0.9);
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }

        .planCardDescription {
          box-sizing: border-box;
          height: 40px;
          border: 0.5px solid #979797;
          border-bottom: 0.5px;
          border-right: 0.5px;
          border-left: 0.5px;

          .checkIcon {
            font-size: 140%;
            color: #83878c;
          }
        }

        .planCardDescription:nth-last-child(2) {
          border: 0.5px solid #979797;
          border-right: 0.5px;
          border-left: 0.5px;
        }

        .planCardDescriptionText {
          justify-content: flex-end;
          padding-right: 7px;
          color: #4a4848;
          font-size: 13px;
          letter-spacing: 0;
          text-align: center;
        }

        .multipleJobsPlanCard {
          height: 360px;
          .multipleJobsPlanCardDescription {
            height: 200px;
            padding: 8px 15px 78px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .multipleJobsPlanCardContent {
              height: 30px;
              background-color: white;
              display: flex;
              justify-content: space-between;
              .circle {
                height: 7px;
                width: 7px;
                background-color: #13c26b;
                border-radius: 50%;
                margin-top: 4px;
              }
              .information {
                width: 194px;
                color: rgba(7, 16, 26, 0.9);
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
              }
            }
            .multipleJobsPlanCardContent:nth-child(1) {
              align-items: flex-end;
              .circle {
                margin-bottom: 7px;
              }
            }
          }
        }

        .planCardButton {
          height: 88px;
          z-index: 2500;
          .getStartedButton {
            width: 70%;
            height: 35.2px;
          }

          .linkButton {
            height: 35.2px;
          }
        }
      }

      .basicPlanCard:hover {
        .planCardHeaderWrapper {
          .title {
            font-size: 25px;
          }
        }
      }

      .basicPlanCard:hover:not(:nth-child(1))::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        padding: 5px;
        background: #ce0000;
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: destination-out;
        mask-composite: exclude;
      }

      .basicPlanCard:nth-child(1) {
        width: 288px;
      }

      .plancardDescriptionWrapper {
        div:nth-last-child(-n + 2) {
          color: #080808;
          font-weight: bold;
        }
      }
    }

    .orderUpgradePlanCardWrapper {
      padding: 0px 390px;

      .orderUpgradePlanCard {
        box-sizing: border-box;
        border: 1px solid #979797;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .orderUpgradePlanCardHeader {
          height: 115px;
          text-align: center;
          padding: 34px 186px;
          position: relative;
          overflow: hidden;

          .title {
            height: 24px;
            color: #00c760;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;

            .caption {
              height: 16.2px;
              color: rgba(7, 16, 26, 0.9);
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16.2px;
              margin-bottom: 0px;
              margin-top: 5px;
            }
          }
        }
        .orderUpgradePlanCardDescriptionContainer {
          height: 374px;
          // width: 584px;
          // background-color: #00cca4;
          display: flex;
          .orderUpgradePlanCardDescription {
            height: 374px;
            width: 236px;

            .planCardPriceInfoWrapper {
              .planPriceInfo {
                height: 43px;
                color: #080808;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                background-color: #f3f3f3;
                .planPriceInfoDescription {
                  color: #080808;
                  font-size: 12px;
                  letter-spacing: 0;
                }
              }
            }

            .planCardTurnAroundTimeInfoWrapper {
              .turnAroundTimeInfo {
                height: 53px;
                color: rgba(7, 16, 26, 0.9);
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
              }
            }

            .planCardDescription {
              box-sizing: border-box;
              height: 40px;
              border: 0.5px solid #979797;
              border-bottom: 0.5px;
              border-right: 0.5px;
              border-left: 0.5px;

              .checkIcon {
                font-size: 140%;
                color: #83878c;
              }
            }

            .planCardDescription:nth-last-child(1) {
              border: 0.5px solid #979797;
              border-right: 0.5px;
              border-left: 0.5px;
            }

            .planCardDescriptionText {
              justify-content: flex-end;
              padding-right: 7px;
              color: #4a4848;
              font-size: 13px;
              letter-spacing: 0;
              text-align: center;
            }
          }
          .orderUpgradePlanCardDescriptionBorder {
            box-sizing: border-box;
            height: 376px;
            border: 0.5px solid #979797;
            border-left: 0px;
            border-top: 0px;
            border-bottom: 0px;
          }
          .orderUpgradePlanCardDescription:nth-child(1) {
            width: 347px;
          }
        }

        .orderUpgradePlanCardButton {
          height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          padding: 10px 0px;
          .getStartedButton {
            width: 31%;
            height: 35.2px;
          }
          .noThanksButton {
            height: 22px;
            width: 68px;
            color: #565b63;
            font-family: Helvetica, sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
            text-decoration: underline;
            text-underline-position: under;
            text-decoration-color: #979797;
            cursor: pointer;
          }
        }

        .planCardPriceInfoWrapper {
          .planPriceInfo {
            height: 43px;
            color: #080808;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            background-color: #f3f3f3;
            .planPriceInfoDescription {
              color: #080808;
              font-size: 12px;
              letter-spacing: 0;
            }
          }
        }
        .planCardTurnAroundTimeInfoWrapper {
          .turnAroundTimeInfo {
            height: 53px;
            color: rgba(7, 16, 26, 0.9);
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }
  }

  .selectPlanHeaderContainer,
  .selectCreditHeaderContainer {
    padding-left: 117px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .refineCriteriaIconCreditSelection {
      font-size: 86px;
      margin-right: 12px;
    }
    .progressSteps {
      width: 825px;
      display: flex;
      .backBtn {
        margin-right: 10px;
        padding-left: 0px;
        display: flex;
      }
    }

    .header {
      height: 86px;
      width: 989px;
      display: flex;
      justify-content: space-between;
      .refineCriteriaIcon {
        font-size: 86px;
      }
      .headerContent {
        width: 891px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .headerText {
          height: 40px;
          margin-top: 9px;
          color: rgba(7, 16, 26, 0.9);
          font-size: 28px;
          font-weight: 500;
          letter-spacing: 0.4px;
          line-height: 40px;
        }
        .headerDescription {
          height: 24px;
          color: #2a2a2a;
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px;
          margin-bottom: 8px;
        }
      }
    }
  }

  .selectCreditHeaderContainer {
    padding-left: 190px !important;
    flex-direction: row !important;
    justify-content: unset !important;
    margin-bottom: 24px !important;
  }

  .selectPlanFooterContainer {
    height: 200px;
    .footerContent {
      padding-top: 33px;
      padding-bottom: 40px;
      height: 232px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .footerText {
        height: 24px;
        color: #ffffff;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;
      }
      .footerDescription {
        height: 24px;
        color: #ffffff;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
      .footerStats {
        display: flex;
        height: 79px;
        width: 928px;
        justify-content: space-around;
        align-items: center;
        .footerStatInfo {
          display: flex;
          flex-direction: column;
          align-items: center;
          .footerStatValueNumber {
            height: 47px;
            color: #ffffff;
            font-size: 40px;
            font-weight: 500;
            letter-spacing: 0.57px;
            line-height: 47px;

            .footerStatValueText {
              height: 40px;
              font-size: 20px;
              letter-spacing: 0.29px;
              line-height: 40px;
            }
          }
          .footerStatCaption {
            color: #ffffff;
            font-size: 16px;
            letter-spacing: 0;
          }
        }
      }
    }
  }

  .moreDiscount {
    text-align: center;
    margin: auto;
    font-weight: 500;
    padding: 32px 0;
    span {
      color: #00c760;
    }
  }
}
.creditSelectionWrapper {
  width: 320px;
  padding: 10px;
  border: 5px solid #dadbdd;
  margin: 0;
}
.recommendedIcon {
  box-sizing: border-box;
  height: 32px;
  width: 140px;
  border: 1px solid #13c26b;
  border-radius: 4px;
  background-color: #00c760;
  position: absolute;
  top: 18px;
  right: -38px;
  transform: rotate(44deg);
}

.recommendedIcon > p {
  color: #ffffff;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
.ribbonIcon {
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  left: 41px;
  transform: rotate(-8deg);
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressStepsMain {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-top: 50px;
  .progressSteps {
    margin-left: 100px;
    width: 400px;
  }
}

.freeJobSteps {
  max-width: 360px;
}
