.review-comment {
  padding: 10px;
  border: 1px solid #ffe58f;
  border-radius: 0 0 4px 4px;
  background-color: #fffbe6;
}

.review-comment-title {
  display: flex;
  color: rgba(7, 16, 26, 0.85);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 10px;
}

.review-comment-description {
  color: rgba(7, 16, 26, 0.65);
  font-size: 14px;
  line-height: 22px;
  margin-left: 50px;
  margin-top: 10px;
  display: flex;

  .see-details-wrapper {
    margin-left: auto;
    cursor: pointer;

    .see-details {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 22px;
      color: #2466f3;
    }

    .arrow-icon {
      margin-right: 24px;
    }
  }
}

.review-comment-exclamation {
  font-size: 24px;
  color: #ffe58f;
  margin-right: 15px;
}
