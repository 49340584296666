.EdgeButton {
  padding: 2px;
  border: 0;
  background-color: var(--color-base100);
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  color: var(--color-accent);
  transform: color 0.2s ease;

  &:hover {
    color: var(--color-accent700);
  }

  &[disabled] {
    color: var(--color-base600);
    cursor: not-allowed;
  }
}

.condition-edge-text {
  position: absolute;
  top: 0px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  color: rgba(7, 16, 26, 0.7);
  padding-top: 5px;
  &.rightTop {
    right: 20px;
    .ant-tooltip {
      white-space: nowrap;
      font-style: initial;
    }
  }
  &.leftTop {
    left: 20px;
    .ant-tooltip {
      white-space: nowrap;
      font-style: initial;
    }
  }
  .ConditionalTooltipEdge {
    cursor: pointer;
  }
}

.react-flow__edge-path {
  &.highlight {
    stroke: #13c26b;
    stroke-width: 3px;
  }
}
