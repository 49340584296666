.careerbuilder {
  .careerbuilder-advanced-options {
    padding: 0px 20px;
    margin-bottom: 70px;

    .ant-form-item {
      margin-bottom: 10px;

      .ant-form-item-label {
        line-height: 22px;
      }

      .ant-form-item-control {
        line-height: 1;
      }

      .ant-form-item-label label {
        font-size: 14px;
      }
    }
    .switch-form-item {
      .ant-form-item-control-wrapper {
        width: 44px;
        display: inline-block;
      }
    }

    .careerbuilder-advance-options-select,
    .careerbuilder-advance-options-inputnumber {
      min-height: 32px;
      border-radius: 4px;
    }

    .careerbuilder-advance-options-select {
      width: 281px;
    }

    .careerbuilder-advance-options-inputnumber {
      width: 98px;
    }

    .exclude-resumes {
      display: flex;
      align-items: center;

      .ant-form-item-control-wrapper {
        width: 44px;
      }
    }

    .salary-form-item-label {
      font-weight: 500;
      font-size: 14px;
      display: flex;
    }

    .salary-form-item {
      width: 280px;
      display: 'flex';
      justify-content: 'space-between';
    }

    .salary-type-select {
      width: 72px;
      height: 20px;
      font-size: 12px;
      line-height: 16px;
      margin-left: 8px;

      .ant-select-selection,
      .ant-select-selection__rendered,
      .ant-select-selection-selected-value {
        height: 20px;
        line-height: 20px;
      }

      .ant-select-selection__rendered {
        margin-left: 8px;
      }
    }
  }
}

.careerbuilder-maximum-salary,
.careerbuilder-minimum-salary {
  width: 136px;
}

.cb-popover-select,
.popover-select {
  z-index: 2200;
}
