.call-history {
  height: 400px;
  margin-bottom: 25px;
}
.call-history-connect {
  height: 80%;
  position: relative;
}

.list {
  height: calc(100% - 117px);
  overflow-y: scroll;
  width: 100% !important;
}

.title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 5px;
}

.empty-call-icon {
  width: 40px;
  height: 142px;
  font-size: 33px;
}
.heading {
  width: 100%;
  height: 36px;
  top: 702px;
  left: 358px;
  border-radius: 4px;
  display: flex;
  background: #f2f3f3;
  color: #51575e;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  box-sizing: border-box;
}

.unsubscribe-button-candidate-card-call-history {
  display: flex;
  position: fixed;
  bottom: 0px;
  left: 20%;
  min-width: 100%;
  background-color: white;
  border-top: 1px solid #dadbdd;
  padding: 12px 20px 20px 20px;
}

.unsubscribe-button-connect-call-history {
  position: absolute;
  bottom: 0px;
  display: flex;
  background-color: transparent;
  align-items: center;
  margin-bottom: -10px;
}
