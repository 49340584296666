.jobDetails {
  padding: 0 55px;
  padding-top: 40px;
  .title {
    color: rgba(7, 16, 26, 0.9);
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
  .subTitle {
    display: block;
    color: rgba(7, 16, 26, 0.9);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.wrapper {
  display: flex;
  padding-bottom: 135px;
  padding-top: 50px;
  padding-right: 50px;
}

.collapseWrapper {
  :global {
    .ant-collapse {
      position: sticky;
      top: 80px;
      width: max-content;
      margin-left: 50px;
    }
    .ant-collapse,
    .ant-collapse-content {
      border: none;
      width: 180px;
    }
    .ant-collapse > .ant-collapse-item {
      border-bottom: 1px solid white;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background: white;
      padding-bottom: 0px;
      padding-left: 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.specialNotes {
  padding-left: 55px;
  margin-bottom: 35px;
  :global {
    .ql-editor {
      min-height: calc((100vh - var(--navbar-height)) * 0.4);
      max-height: calc(100vh - var(--navbar-height));
      border-radius: 0 0 4px 4px;
    }
    .ql-picker-label {
      display: flex;
    }
  }
}

.jobSummary {
  overflow: auto;
  overflow-y: scroll;
  padding-left: 55px;
  margin-bottom: 25px;

  .summaryTitle {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    font-weight: 400;
  }

  :global {
    .ql-editor {
      height: calc((60vh - var(--navbar-height)) * 0.4);
      border-radius: 0 0 4px 4px;
    }
    .ql-picker-label {
      display: flex;
    }
  }
}

.jobSummaryDivider {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin-top: 35px;
  margin-bottom: 14px;
}

.notesTitle {
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

.notesSubTitle {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 20px;
  font-weight: normal;
}

.affixTitle {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.advanceSearch {
  font-size: 12px;
  font-weight: 500;
  padding-top: 20px;
  span {
    color: #13c26b;
    border-bottom: 0.5px solid #979797;
    cursor: pointer;
  }
}

.locationNotesWrapper {
  width: 100%;
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
}

.employmentWrapper {
  margin-bottom: 25px;
}

.editIcon {
  margin-left: 10px;
  cursor: pointer;
}

.loaderWrapper {
  :global {
    .ant-skeleton-content {
      padding: 0px 60px;
    }
  }
}

.jobDescription {
  overflow: auto;
  overflow-y: hidden;
  padding-left: 55px;
  margin-bottom: 15px;

  .descriptionTitle {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    font-weight: 400;
  }
  :global {
    .ant-form-item {
      padding-bottom: 10px;
    }
    .ql-editor {
      min-height: calc((100vh - var(--navbar-height)) * 0.4);
      max-height: calc(100vh - var(--navbar-height));
      border-radius: 0 0 4px 4px;
    }
    .ql-picker-label {
      display: flex;
    }
  }
}
