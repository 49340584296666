.lock-middle-circle,
.lock-outer-circle {
  padding: 10px;
  border-radius: 80px;
  background-color: #13c26b;
}

.lock-outer-circle {
  width: 120px;
  position: relative;
  left: 102px;
  top: -320px;
  background-color: rgba(19, 194, 107, 0.55);
}

.lock-inner-circle {
  margin-left: auto;
  margin-right: auto;
  background-color: #13c26b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 80px;
  border: 2px solid white;

  .locked-img {
    width: 32px;
    height: 38px;
  }
}

.alert-message-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 260px;
  width: 322px;
  border-radius: 18px;
  padding: 80px 31px 40px 31px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(7, 16, 26, 0.2);

  .alert-message-header {
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
  }

  .alert-message-content {
    color: rgba(7, 16, 26, 0.5);
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
}
