.modalContainer {
  display: flex;
}

.iconDiv {
  margin-right: 16px;
  font-size: 22px;
  color: #ffcc00;
}

.message {
  margin-bottom: 12px;
  margin-top: 10px;
}

.actionButton {
  float: right;
  margin-right: 5px;
}
