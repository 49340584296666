.milesInput {
  :global .ant-form-item-children {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}
.maximumMiles {
  color: var(--grey-70-color);
}
