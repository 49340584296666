.richTextEditors {
  :global .ql-container.ql-snow {
    border: none;
  }
}
.newPost {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 9px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  margin-top: 20px;
}

.userDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.aryaRecruiterTag {
  color: #1751d0;
  background-color: #ebf0fb;
  font-weight: 450;
}

.userName {
  font-weight: 500;
  text-transform: capitalize;
}

.inputSection {
  display: flex;
  padding: 4px 4px 4px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background-color: #f8f8f8;
  box-shadow: 0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);

  .richTextEditor {
    flex-grow: 1;
    resize: none;
    &:focus {
      border-color: transparent !important;
    }
    font-weight: 500;
    background-color: #f8f8f8;
    padding: 0px;
    box-sizing: border-box;
    word-break: break-word;
    visibility: visible;
    :global .ql-container.ql-snow {
      border: none;
    }
    :global .ql-editor{
      padding: 0px;
      font-family: Roboto, sans-serif;
      max-height: 105px;
      min-height: 21px;
      font-size: 14px;
    }
    :global .ql-editor.ql-blank::before {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
    }
  }
}

.createNewThreadButton {
  padding: 0px;
  margin: 0px;
  width: 32px;
  height: 32px;
}
