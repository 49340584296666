.profile-settings {
  display: flex;
  flex-direction: row;
}

.navigation-sidebar {
  cursor: pointer;
}

.settings-wrapper {
  flex-grow: 1;
  padding: 0px 50px;

  div {
    width: 100%;
  }

  .profile-email-signature {
    width: 60%;

    .ql-editor {
      height: 252px;
    }
  }
}

.navigation-item-title-save {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.navigation-item-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
}

.navigation-item-subtitle {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
  width: max-content;
}
.form-item-email {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  padding-bottom: 10px;

  .profile-settings-input {
    width: 230px;
    margin-bottom: 0px;
  }

  .profile-settings-email-input {
    width: 410px;
    margin-bottom: 0px;
  }

  .ant-form-explain {
    position: absolute;
    line-height: 1.2;
  }

  .verify-button {
    box-shadow: 0px 0px 0px 0px;
  }
}

.profile-email-section {
  .profile-settings-email-head-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 7px;

    .profile-settings-email-text-wrapper {
      display: flex;
      flex-direction: column;

      .profile-settings-email {
        color: rgba(7, 16, 26, 0.7);
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
      }

      .profile-settings-email-message {
        display: flex;
        flex-direction: row;
        color: rgba(7, 16, 26, 0.7);
        font-size: 12px;
        line-height: 16px;

        .dot {
          align-self: center;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          margin-right: 6px;

          &.pending {
            background-color: #faad14;
          }

          &.verified {
            background-color: #52c41a;
          }

          &.failed {
            background-color: #f5222d;
          }
        }
      }
    }

    .profile-settings-email-button {
      display: flex;
      flex-direction: row;
      align-items: center;

      .email-reverify-button {
        height: 24px;
        border: 1px solid #dadbdd;
        color: rgba(7, 16, 26, 0.7);
        text-align: center;
        background-color: #ffffff;

        &.verified {
          background-color: rgba(7, 16, 26, 0.05);
          color: rgba(7, 16, 26, 0.3);
        }
      }

      .email-delete-button {
        color: red;
        align-self: center;
      }
    }
  }

  .profile-email-signature {
    padding: 10px 0px 17px;
  }
}

.navigation-item-form-wrapper {
  padding: 0px 0px;
}

.profile-settings-input {
  width: 50%;
  margin-bottom: 10px;
}

.profile-settings-section {
  margin-bottom: 20px;
}

.profile-zipcode-country {
  display: flex;
  width: 50%;
  justify-content: space-between;

  .ant-form-item {
    width: 48%;
  }
}

.settings-section {
  .company-info-settings-input,
  .company-info-location,
  .company-info-zipcode-country {
    width: 50%;
  }

  .billing-container {
    width: 460px;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    box-shadow: none;
  }
}
