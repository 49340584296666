.splashScreenWrapper {
  box-sizing: border-box;
  height: 1154px;
  border: 0.5px solid #979797;
  border-radius: 6px;
  margin-top: 40px;
}

.splashScreenHeader {
  height: 294px;
  background-color: rgba(216, 216, 216, 0.23);
  padding-left: 75px;
}

.splashScreenCloseIcon {
  float: right;
  margin: 15px;
  cursor: pointer;
}

.buttonWrapper {
  padding-top: 65px;
}

.splashScreenBannerTitle {
  height: 28px;
  color: #1b1b1b;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  padding-top: 48px;
  span {
    color: #36cf7d;
  }
}

.splashScreenBody {
  margin: 50px 20px;
  text-align: left;
  .antCol4 {
    position: sticky;
    top: 80px;
    padding-left: 65px !important;
    padding-right: 25px !important;
    div {
      border-right: 1px solid #e3e3e3;
    }
  }
  .antCol18 {
    padding-left: 0 !important;
    padding-right: 60px !important;
    max-height: 780px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 82%;
  }
}

.bannerWrapper {
  padding-top: 20px;
}

.bannerBody {
  padding-left: 0;
}
