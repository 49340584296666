.job-filter-button {
  height: 32px;
  width: 79px;

  border-radius: 20px;
  margin-left: 10px;
  line-height: 22px;
  font-size: 14px;
}

.job-filter-apply-button {
  background-color: #13c26b;
  border: none;
}

.job-filter-clear-button {
  border: 1px solid #dadbdd;
  background-color: #ffffff;
}

.job-filter-apply-clear {
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-filter-aggregation {
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: 54px;
}

.job-filter-aggregation-title {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-icon-filter-button {
  cursor: pointer;
  margin-left: 16px;
}

.job-filter-search-box-wrapper {
  height: 32px;
  width: 293px;
  margin-top: 10px;

  .job-filter-search-box input {
    border-radius: 25px;
  }
}

.job-filter-popover-content-header {
  height: 22px;
  width: 31px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.job-filter-popover {
  height: 435px;
  width: 340px;
  z-index: 100;
}

.job-filter-popover-content {
  margin: 16px 23px 0px 24px;
  width: 293px;
  height: 340px;
  padding-bottom: 16px;
}

// .ant-checkbox-group-item {
//   display: block;
//   margin-right: 0;
// }

.tag-filter-popover-checkboxes {
  padding: 0px 2px 3px 0px;
  height: 260px;
  overflow: scroll;
  margin-top: 16px;
  .ant-checkbox-input {
    box-sizing: border-box;
    height: 16px;
    width: 16px;
    border: 1px solid #dadbdd;
    border-radius: 2px;
    background-color: #ffffff;
  }
}

// .anticon {
//   margin-left: -6px;
// }
