#muti-select-message-window {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 20px;
}

.muti-select-message-window-flex-items {
  width: 100%;

  .message-textarea {
    margin-top: 16px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;

    .ql-container.ql-snow {
      border: none;
    }
  }
}

.multi-select-message-window-flex-items {
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: 10px;

  .candidate-multi-select-message-template-select {
    width: 368px;
    height: 32px;
  }
}

.message-composer-merge-tags {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: var(--arya-accent-color);
}

.bulkMessageModalBody {
  :global {
    .ant-modal-body {
      padding: 24px 24px 0px 24px;
    }
  }
}

.messageTextCreditsNotAvailable {
  pointer-events: none;
  opacity:0.5;
}
