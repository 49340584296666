.jobboardSettings {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}
.integrationTitle {
  color: rgba(7, 16, 26, 0.9);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: justify;
  margin-bottom: 6px;
}
.integrationMessage {
  color: rgba(32, 38, 50, 0.6);
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  margin-bottom: 40px;
}
