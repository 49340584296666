.countryChangeModalContent {
  .countryChangeModalHeader {
    display: flex;
    gap: 10px;
    margin-top: 23px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 20px;
    font-weight: 500;
  }
  .countryChangeModalFooter {
    margin-top: 27px;
    margin-bottom: 6px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }
}
