.listHeader {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .edit {
    text-decoration: underline;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 2px;
    font-size: 24px;
    cursor: pointer;
  }
}
.listInfoContainer {
  display: flex;
  flex-direction: column;
  width: 500px;
}
.listName {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  max-width: 300px;
  white-space: nowrap;
}
.listDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  display: inline;
  max-width: 950px;
}
.seeMoreButton {
  text-decoration: underline;
  color: #13c26b;
  cursor: pointer;
  display: inline-flex;
}
.segmentActions {
  display: flex;
  gap: 8px;
  .scoutingAgentIcon {
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #dadbdd;
    position: relative;
  }
  .activeIcon,
  .activeIcon:hover,
  .activeIcon:focus {
    border: 1px solid var(--arya-accent-color) !important;
    border-radius: 4px;
    position: relative;
    background: #f6ffed !important;
  }
  .greenCircleIcon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    background: var(--arya-accent-color);
    right: -4px;
  }
  .enabledScoutingAgentIcon {
    border: 2px solid #13c26b;
  }
  .toolIcons {
    display: flex;
    align-items: center;
  }
}
.emailAutomation {
  padding-left: 9px !important;
  padding-right: 9px !important;
  font-size: 15px !important;
  background-color: #f6ffed;
}

.listNameWrapper {
  display: flex;
  gap: 10px;
}

.createCampaignIcon {
  display: flex;
  align-items: center;
  width: 32px;
  justify-content: center;
  border: 1px solid #dadbdd;
  border-radius: 4px;
  cursor: pointer;
}

.bulkProgressModel {
  min-width: 85px;
  border: 1px solid #dadbdd;
  padding: 10px 6px 6px 6px;
  border-radius: 4px;
  height: 55px;
}
.headerAndBulkProgressIndicator {
  display: flex;
}
