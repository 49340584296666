.footerWrapper {
  background-color: white;
  padding: 20px 60px;
  display: flex;
  box-shadow: 0 -2px 3px 0 rgba(7, 16, 26, 0.15);
  .draftBtn {
    margin: 0px 10px;
    padding: 0 25px;
  }
  .saveBtn {
    padding: 0 25px;
  }
}
