.aryaBannerModal {
  :global {
    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-content {
      border-radius: 10px;
    }
  }
}
