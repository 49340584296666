.aryaBannerCarousel {
  .aryaPagination {
    height: 16px;
    width: 16px;
    border: 1px solid #13c26b;
    cursor: pointer;
    border-radius: 10px;
    margin-left: 2px;
  }

  :global {
    .slick-slide {
      background: #ffff;
    }
    .slick-list {
      border-radius: 10px;
    }

    .slick-dots {
      bottom: 28px !important;
      text-align: left !important;
      padding-left: 44px !important;

      .slick-active {
        :local {
          .aryaPagination {
            background-color: #01cd75;
          }
        }
      }
    }
  }
}
