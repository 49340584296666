.email-configuration-header {
  width: 650px;
  margin-bottom: 16px;
}

.email-configuration-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 8px;
}

#smtp-server-input,
.smtp-port-input.ant-input-number,
.pop3-server-input,
.pop3-port-input,
#username-input,
#password-input {
  width: 300px;
}

.email-settings-row {
  width: 650px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0px;
}

.email-settings-admin {
  display: flex;
  justify-content: space-between;
}

.clear-btn {
  margin-right: 15px;
  border-radius: 200px;
}

.hide-btn {
  display: none;
}
