.manualSearchCandidateList {
  :global {
    .ant-empty {
      height: calc(100vh - 270px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .ant-empty-image {
      height: 40px;
    }
  }
}
