.radioButton {
  padding: 4px 0px;
  display: flex;
  align-items: flex-start;

  :global .ant-radio {
    margin-top: 2px;
  }
}

.radioButtonText {
  white-space: normal;
}
