.imageSection {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: #fff1f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeIcon {
  font-size: 20px;
  color: red;
}

.errorSystemNotification {
  display: flex;
  width: 360px;
  justify-content: space-around;
  border-bottom: 1px solid #e6eaee;
  padding: 12px;
  cursor: pointer;
}

.notificationContent {
  width: 229px;
  color: #81878e;
  font-family: Roboto, sans-serif;
  font-size: 13px;
}

.notificationTitle {
  line-height: 22px;
  padding: 4px 0px;
  font-weight: 500;
  color: black;
}

.notificationTime {
  color: #7f8fa5;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 0px;
}

.viewStatus {
  :global {
    .ant-badge-status-dot {
      width: 8px;
      height: 8px;
    }
    .ant-badge-dot {
      background-color: #13c26b;
    }
  }
}
