.dropdownMenu {
  :global {
    .ant-dropdown-menu {
      margin-left: 90px;
      padding: 0;
    }
    .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
      width: 144px;
    }
    .ant-dropdown-menu-item {
      font-size: 13px;
      margin-top: 2px;
    }
    .ant-dropdown-menu-submenu-title:hover {
      background: #f2f3f3;
      box-shadow: none;
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      color: #722ed1;
    }
  }
}

.popoverItemWrapper {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.nodeTooltip {
  :global {
    .ant-tooltip-inner {
      width: 260px;
    }
  }
}

.workflowActionName {
  font-size: 16px;
}

.workflowActionLabel {
  color: black;
  font-size: 13px;
}

.workflowActionSubtype {
  height: 45px;
  font-size: 16px;
  color: #722ed1;
}

.workflowActionSvg {
  font-size: 19px;
}

.workflowNodePopoverSubmenu {
  // display: none;
  height: 54px;
  min-width: 200px;
  z-index: 1049;
  :global {
    * {
      height: inherit;
    }
    .ant-dropdown-menu {
      box-shadow: none;
    }
    .ant-dropdown-menu-item {
      padding: 15px 24px;
      box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.09);
      background-color: white;
      margin-top: 2px;
    }
    .ant-dropdown-menu-item:hover {
      background-color: #f2f3f3;
      box-shadow: none;
    }
  }
}

.workflowNodeMenuItem {
  min-width: 256px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.09);
  :global {
    .ant-dropdown-menu-submenu-arrow {
      bottom: 16px;
    }
  }
}

.workflowNodeMenuItem:hover {
  :global {
    .ant-dropdown-menu-submenu-title:hover {
      background-color: #f2f3f3;
    }
  }
  background: #f2f3f3;
  box-shadow: none;
}

.workflowNodeDropdown {
  padding: 0;
}
