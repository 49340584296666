.workflowContextWrapper {
  display: inline-flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 12px;
  margin-left: 50px;
  border: 1px solid #dadbdc;
  border-radius: 10px;
  background-color: white;
  color: #51575e;
}

.workflowReportNote {
  color: #1890ff;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 12px;
  font-size: 12px;
  width: fit-content;
  padding: 1px 7px;
  margin-top: 12px;
  margin-left: 50px;
}
