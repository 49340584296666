.recruiter-select {
  display: inline-block;

  .ant-select-selection,
  .ant-select-selection:hover,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    width: 180px;
    height: 32px;
    border-radius: 20px;

    .ant-select-selection-selected-value {
      color: rgba(7, 16, 26, 0.7);
      font-size: 16px;
    }
  }

  &.inactive {
    .ant-select-selection-selected-value {
      color: rgba(7, 16, 26, 0.5);
    }
  }
}

.recruiter-select-dropdown {
  .inactive {
    color: rgba(7, 16, 26, 0.5);
  }
}
