.aryaTop {
  height: 321px;
  width: 790px;
  background-color: #e1f9f6;

  .aryaVisual {
    height: 300px;
    width: 700px;
    position: relative;
    top: 20px;
    margin: auto;
    margin-bottom: 0;

    .aryaDisplay {
      background-color: #e1f9f6;
      height: 100%;
    }

    .aryaDisplay > img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .aryaVersionNumber {
    position: relative;
    float: right;
    right: 18px;
    height: 17px;
    width: 29px;
    color: #13c26b;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 23px;
  }

  .aryaCloseButton {
    position: relative;
    top: 10px;
    right: 10px;
    float: right;
  }

  .aryaOval {
    margin-left: 2px;
    height: 2.8px;
    width: 2.81px;
    opacity: 0.8;

    .oval1 {
      background-color: #fa5959;
    }

    .oval2 {
      background-color: #8ccf4d;
    }
  }
}

.aryaBottom {
  padding: 24px 57px 67px 44px;

  .aryaSkipTour {
    cursor: pointer;
    color: #a2a2a2;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .aryaSkipTour:hover {
    color: #13c26b;
  }

  .aryaButtons {
    display: flex;
  }

  .aryaFeatureName {
    width: 100%;
    margin-bottom: 0.1em;
    color: #979797;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 33px;
  }

  .aryaFeatureDescription {
    :global .html-resume-viewer {
      margin-bottom: 16px;
      color: #000000a6;
      height: 64px;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 23px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.announcementImage {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: 700px;
}
