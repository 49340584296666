.autoRejectIcon {
  font-size: 24px;
}
.minimizedRejectIcon {
  :global .anticon svg {
    font-size: 20px;
  }
}
.candidateShortListRejectTooltip {
  background-color: #ffffff;
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: #ffffff !important;
    }
  }
}
