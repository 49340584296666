.connectV2Redirect {
  display: flex;
  position: absolute;
  right: 0px;
  align-items: center;
  color: var(--arya-accent-color);
  margin-top: 7px;
  font-weight: 450;
  z-index: 1;
}

.connectDescription {
  margin-right: 15px;
}
