.create-template.drip.drawer {
  .drip-template-with-info-icon {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .drip-template-name {
    margin-bottom: 40px;
  }

  .ant-collapse-header {
    padding: 12px;
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-form-item {
    margin: 0px;
  }

  .select-template-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select-template {
      width: 75%;
    }
  }

  .drip-condition {
    display: flex;

    > div {
      margin-right: 10px;
    }

    .ant-input-number {
      width: 54px;
    }
  }
}
.emailsubject {
  .ql-toolbar {
    border: none;
  }
  .ql-container {
    border-radius: 4px;
    margin-bottom: 10px;
  }
}
