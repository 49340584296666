.creditsUsedWrapper {
  display: flex;
  gap: 12px;
  padding: 9px 13px;
  border-radius: 4px;
  border: 1px solid #dadbdd;
}
.creditsUsedContainer {
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 26px;
  gap: 2px;
  align-items: center;
  justify-content: center;
}
.creditsUsed {
  display: flex;
  width: 140px;
  height: 18px;
  justify-content: space-between;
}

.creditsUsedText,
.creditsUsedCount {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #808489;
}

.creditsRequest {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
}

.creditRequestText {
  margin-top: 4px;
}
