.jobLandigPageStepsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 19px;
  margin-bottom: 18px;
  margin-left: 25px;
  margin-right: 25px;

  .choosePlanStep,
  .sampleCandidateStep,
  .startHiringStep,
  .enterJobDetailStep {
    display: flex;
    align-items: center;
  }

  .enterJobDetailStep::after,
  .choosePlanStep::after,
  .sampleCandidateStep::after {
    content: ' ';
    display: block;
    height: 1px;
    background: #13c26b;
    width: 50px;
    left: 100%;
    top: calc(50% - 2px);
    margin-right: 5px;
  }
}

.labelText {
  margin: 0 5px;
}

.labelIcon {
  font-size: 37px;
}
