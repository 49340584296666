.order-cancelled-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 140px 0px 240px 0px;

  .order-cancelled-header {
    height: 24px;
    width: 146px;
    color: rgba(7, 16, 26, 0.9);
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: 8px;
  }

  .order-cancelled-message {
    color: rgba(7, 16, 26, 0.7);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    width: 420px;
  }

  .order-cancellation-message {
    margin-bottom: 24px;
  }

  .order-cancelled-icon-wrapper {
    margin-bottom: 32px;
  }
}
