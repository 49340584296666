.excludeCandidatesWrapper {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .excludeCandidatesText {
    margin-left: 20px;
    .excludeCandidatesLabel {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
