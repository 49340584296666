.BannerMessageWrapper {
  border: 1px solid #ffe58f;
  margin: 10px 0px 10px 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 9px 16px 9px 16px;
  background-color: #fffbe6;
  .loadingIcon {
    padding-right: 12px;
    font-size: 16px;
    color: #13c26b;
  }
}
