.candidateAggregationIconAndTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.allTabAggregationFilterDrawer {
  position: relative;
  .dot {
    position: absolute;
    background-color: #13c26b;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    top: 0px;
    left: 18px;
    margin-right: 5px;
    font-size: 20px;
  }
}
.aggregationDrawer {
  .aggregationDrawerSectionsContainer {
    margin-bottom: 48px;
    .filterSection {
      padding: 0px 10px 10px 20px;
      margin: 10px;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .filterSectionHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .subsectionTitle {
          flex: none;
          font-size: 16px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
        }
        .clearAllButton {
          cursor: pointer;
          opacity: 0.4;
        }
      }
    }
  }
  .dividerLine {
    margin: 16px 0px;
  }
  .actionButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .searchBox input {
    border-radius: 25px;
  }
  // .highlight {
  //   font-weight: 500;
  //   color: #07101a;
  // }
}
.gridHeight {
  overflow-x: hidden !important;
}

.aggregationFilterFooter {
  position: fixed;
  width: 900px;
  display: flex;
  justify-content: space-between;
  bottom: 0px;
  right: 0px;
  padding: 12px 30px;
  background-color: white;
  border-top: 1px solid #d9d9d9;
  z-index: 3000;
}
