.calendar-iframe {
  width: 100vw;
  height: calc(100vh - var(--navbar-height) - var(--footer-height));
  border: none;
  display: flex;
}
#calendar-component {
  margin: 0;
}

