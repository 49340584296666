.replyInputComponent {
  display: flex;
  padding: 32px 20px 12px 35px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.inputSection {
  display: flex;
  padding: 4px 4px 4px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);
  width: calc(100% - 42px);
}

.inputField {
  width: calc(100% - 42px);
  &:focus {
    border-color: transparent !important;
  }
  font-weight: 500;
  padding: 0px;
  :global .ql-container {
    border: none;
  }
  :global .ql-editor {
    padding: 0px;
    font-family: Roboto, sans-serif;
    max-height: 105px;
    min-height: 21px;
    font-size: 14px;
  }
  :global .ql-editor.ql-blank::before {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
  }
}

.sendReplyButton {
  padding: 0px;
  margin: 0px;
  width: 32px;
  height: 32px;
}
