.reportViewHeader {
  padding-left: 20px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.savedReportDetails {
  border-left: 1px solid #dadbdd;
  padding-left: 8px;
  display: flex;
  gap: 4px;
}

.savedDetailsLabel {
  width: 88px;
  font-size: 14px;
  font-weight: 500;
}

.savedDetailsValue {
  width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.reportViewContent {
  padding: 20px 0px 18px 18px;
}

.reportViewFilter {
  border-radius: 4px;
  background-color: #fafbfc;
  padding: 16px 20px;
}

.reportFilterButtons {
  margin-top: 24px;
  display: flex;
  gap: 16px;
}

.reportViewDivider {
  margin: 0px;
}

.reportViewDisable {
  cursor: not-allowed
}
