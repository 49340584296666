.autocomplete-search-component {
  &.disabled {
    .ant-btn-primary {
      border: 1px solid #dadbdd;
      background-color: rgba(7, 16, 26, 0.05);
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  &.disabledNew {
    .ant-btn-primary {
      border: none;
      border-bottom: 1px solid gainsboro;
      color: #b4b7ba;
      background-color: white;
      cursor: not-allowed;
      pointer-events: none;
      box-shadow: none;
      border-radius: 0px !important;
    }
  }
  &.enabledNew {
    .ant-btn-primary {
      border: none;
      border-bottom: 1px solid gainsboro;
      color: #13c26b;
      background-color: white;
      box-shadow: none;
      border-radius: 0px !important;
    }
  }
  .ant-input-search-button {
    padding: 0px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
