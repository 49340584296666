.countrySelect {
  width: 100%;
  border-radius: 4px;

  :global .ant-select-selection {
    overflow-x: hidden;
    max-height: 95px;
  }
}

.countryDropdown {
  z-index: 1901;
}
