.candidateSkillsSection {
  flex: 1;
  color: #51575e;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  .candidateCardSkills {
    display: flex;
    column-gap: 6px;
    flex-wrap: wrap;
    visibility: hidden;
  }
  .visibleSkills {
    display: flex;
    column-gap: 6px;
    flex-wrap: wrap;
    top: 0;
    position: absolute;
  }
}
