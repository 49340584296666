.multiLocationSettingsTitle {
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 12px;
}

.multiLocationSettingsSubtitle,
.multiLocationSettingsDescription {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  line-height: 24px;
}

.multiLocationSettingsSubtitle {
  padding-bottom: 12px;
}

.multiLocationSettingsDescription {
  display: flex;

  :global .ant-switch {
    margin-left: 12px;
  }
}

.selectDropdown {
  :global .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    border-radius: 16px;
  }
}

.createButton {
  margin: 30px auto 0px auto;
}

.deleteButton {
  border: none;
  font-size: 12px;
  color: var(--arya-error-color);
  cursor: pointer;
  text-decoration-line: underline;
}

.disabledMultilocation {
  pointer-events: none;
}

.locationTagsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.locationTags {
  border-radius: 11px;
  background-color: #f2f3f3;
  margin-right: 0px;
}
