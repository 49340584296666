.basicSystemNotificationWrapper {
  width: 360px;
  padding: 10px 16px;
  border-bottom: 1px solid #e6eaee;
  :global .ant-badge {
    .ant-badge-dot {
      background-color: #13c26b;
    }
  }

  span {
    width: 100%;
  }
  h4,
  p {
    margin: 4px 0px;
  }
  .notificationSubTitle {
    font-size: 12px;
    color: rgba(7, 16, 26, 0.5);
  }
  .since {
    color: #7f8fa5;
    font-size: 12px;
    line-height: 14px;
  }
}

.basicSystemNotificationWrapper:hover {
  cursor: pointer;
  background-color: #f3f3f3;
}
