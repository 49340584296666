.job-card {
  display: flex;
  align-items: center;
}

.premium-service-status-steps {
  width: 495px;
  margin-top: 10px;
}

.basic-service-status-steps {
  width: 1050px;
  margin-top: 10px;
}

.free-service-status-steps {
  width: 1050px;
  margin-top: 10px;
}

.order-status-cancelled-text,
.status-text {
  height: 16px;
  font-family: Roboto, sans-serif;
  color: '#83878C';
  font-size: 12px;
  line-height: 16px;
  margin-left: 40px;
}

.status-text-color {
  color: #13c26b;
}

.order-status-cancelled-text {
  color: #f5222d;
}

.subscription-plan-label {
  box-sizing: border-box;
  max-height: 23px;
  max-width: 82px;
  border-radius: 11px;
}

.subscription-plan-text {
  text-align: center;
  max-height: 20px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 20px;
}

.default-plan-label-color {
  border: 1px solid #d3adf7;
  background-color: #f9f0ff;
}

.basic-plan-label-color {
  border: 1px solid #b7eb8f;
  background-color: #f6ffed;
}

.premium-plan-label-color,
.premium-upgrade-plan-label-color {
  border: 1px solid #afe1ff;
  background-color: #e5f7ff;
}
.freetrial-plan-label-color {
  border: 1px solid #ffbb96;
  background-color: #fff2e8;
}
.default-plan-text-color {
  color: #722ed1;
}

.basic-plan-text-color {
  color: #52c41a;
}

.premium-plan-text-color,
.premium-upgrade-plan-text-color {
  color: #1890ff;
}

.freetrial-plan-text-color {
  color: #fa541c;
}

.job-card-details {
  padding: 18px;

  & ~ .job-card-details {
    border-top: 1px solid #e6eaee;
  }

  &:last-of-type {
    border-bottom: 1px solid #e6eaee;
  }
}

.job-card-details:hover {
  box-shadow: 0 2px 8px -2px rgba(32, 38, 50, 0.3);
}

.job-card-details ~ .job-card-details:hover {
  border-top: 1px solid transparent;
}

.tag-icon-button {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
  border-style: none;
  margin-left: 25px;
  margin-right: 0px;
  padding: 0px;
}

.tag-icon-image {
  height: 14px;
  width: 13px;
  margin-left: 2px;
}

.job-title {
  color: #374251;
  font-size: 16px;
  font-weight: 500;
  max-width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 25px;
}

.job-id {
  color: #647182;
  font-size: 12px;
  line-height: 16px;
  display: inline;
}

.job-time {
  color: #647182;
  font-size: 12px;
  line-height: 16px;
}

.client-name {
  color: #374251;
  font-size: 16px;
  max-height: 25px;

  & > span {
    display: inline-block;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.client-location {
  color: #647182;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// .candidate-col {
//   top: 9px;
// }

.candidate-button,
.candidate-button:hover,
.candidate-button:focus,
.candidate-button:active {
  height: 17px;
  width: 52px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #fff;
  border: none;
}

.status-col .ant-btn {
  color: #a8b7c7;
  background: transparent;
  border: none;
}

.job-status-change-filter {
  // width: 95px;
  font-size: 14px;
  color: #647182;
  background: transparent;
  border: none;
  // margin-top: 8px;
  font-weight: 500;
}

.job-id-time {
  display: flex;
  align-items: center;
}

.job-id-time-dot {
  height: 4px;
  width: 4px;
  margin: 0px 8px;
  border-radius: 50%;
  background-color: #838786;
}

.job-status-change-filter,
.job-status-change-filter:hover {
  background-color: #f4f6f8;
  border-radius: 30px;
}

.job-status-change-filter > .ant-select-selection,
.job-status-change-filter > .ant-select-selection:hover,
.job-status-change-filter > .ant-select-selection:active,
.job-status-change-filter > .ant-select-selection:focus {
  border: 0px;
  box-shadow: 0 0 0 0px;
  padding: 14px 0px 14px 0px;
  display: flex;
  align-items: center;
  max-width: 155px;

  .ant-select-selection-selected-value {
    max-width: 140px;
    overflow: hidden;
  }
}

.job-status-change-filter > .ant-select-selection:hover {
  background-color: #f4f6f8;
  border-radius: 30px;
}

.source-button {
  width: 55px;
  height: 26px;
  margin: inherit;
  cursor: pointer;
  .scouting-content {
    display: flex;
    gap: 10px;
  }
  .scouting-agent-icon {
    width: 28px;
    display: flex;
    justify-content: center;
    height: 24px;
    border-radius: 4px;
    align-items: center;
    border: 1px solid #13c26b;
  }
}

.job-title-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.job-external-source {
  box-sizing: border-box;
  height: 18px;
  width: 14px;
  border: 1px solid #d3adf7;
  border-radius: 2px;
  background-color: #f9f0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.job-external-source-text {
  color: #722ed1;
  font-size: 12px;
  line-height: 20px;
}

.job-card-details {
  & [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
    // border-color: transparent !important;
  }
}

.status-tag {
  .tag-icon-button:hover,
  .tag-icon-button:focus,
  .tag-icon-button:active {
    color: rgba(7, 16, 26, 0.7);
  }

  .tag-icon-button[disabled]:hover {
    color: rgba(7, 16, 26, 0.7);
  }
}

.job-tags-transition-enter,
.job-tags-transition-appear {
  max-height: 0px;
  width: 100%;
}

.job-tags-transition-enter-active,
.job-tags-transition-appear-active {
  width: 100%;
  max-height: 500px;
  transition: all 1300ms;
}

.job-tags-transition-exit {
  width: 100%;
  max-height: 500px;
}

.job-tags-transition-exit-active {
  max-height: 0px;
  width: 100%;
  transition: all 300ms;
}

.draft-job-icon {
  color: #f53333;
  font-size: 12px;
  margin: 0;
}

.job-status-text-value {
  min-width: 93px;
  padding-left: 10px;
  font-size: 14px;
  color: #647182;
  font-weight: 500;
}

.high-confidence-job-tooltip .ant-tooltip-inner {
  background-color: #e7f8ed !important;
  color: #37b15a;
  font-size: 14px;
}
.low-confidence-job-tooltip .ant-tooltip-inner {
  background-color: #fff1b8 !important;
  color: #faad14;
  font-size: 14px;
}

.high-confidence-job-status-box,
.low-confidence-job-status-box {
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: #e7f8ed;
  border-radius: 50%;
}

.low-confidence-job-status-box {
  background-color: #fff1b8 !important;
}
