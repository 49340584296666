.monster-power {
  .monster-advanced-options {
    padding: 0px 20px;
    margin-bottom: 70px;

    .ant-form-item {
      margin-bottom: 10px;

      .ant-form-item-label {
        line-height: 22px;
      }

      .ant-form-item-control {
        line-height: 1;
      }

      .ant-form-item-label label {
        font-size: 14px;
      }
    }

    .salary-form-item-label {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 6px;

      .salary-type-select {
        width: 80px;
        height: 20px;
        font-size: 12px;

        .ant-select-selection--single {
          height: 20px;
        }

        .ant-select-selection__rendered {
          margin-left: 8px;
          line-height: 20px;
        }
      }
    }

    .salary-form-item {
      display: flex;
      align-items: flex-start;

      .monster-salary-currency {
        width: 82px;
        margin-right: 8px;
      }

      .monster-min-salary {
        width: 96px;
        margin-right: 8px;
      }

      .monster-max-salary {
        width: 96px;
      }
    }

    .monster-advance-options-select,
    .monster-advance-options-input {
      width: 281px;
      min-height: 32px;
      border-radius: 4px;
    }

    .switch-form-item {
      display: flex;
      align-items: center;

      .ant-form-item-control-wrapper {
        width: 44px;
      }
    }
  }
}

.ant-select-dropdown.popover-select {
  z-index: 2200;
}

.monster-currency.ant-select-dropdown {
  width: 160px;
  font-size: 12px;
}
