.statsViewTitle {
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.statsViewCount {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.successIndicator {
  color: #13c26b;
}

.errorIndicator {
  color: #f5222d;
}

.defaultIndicator {
  color: rgba(7, 16, 26, 0.9);
}
