.bot-ui-settings-title {
  display: inline-block;
  vertical-align: top;
  color: rgba(32, 38, 50, 0.6);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
}

.bot-ui-settings,
.bot-settings {
  display: inline-block;
  margin-left: 40px;
}

.bot-settings-text {
  display: inline-block;
  vertical-align: top;
  color: rgba(32, 38, 50, 0.6);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
}

.bot-input-title {
  width: 480px;
  border: 1px solid rgba(7, 16, 26, 0.15);
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 27px;
}

.bot-input-name,
.bot-input-location,
.bot-input-description,
.bot-input-greeting,
.bot-input-goodbye {
  width: 480px;
  border: 1px solid rgba(7, 16, 26, 0.15);
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 16px;
}

.bot-input-description,
.bot-input-greeting,
.bot-input-goodbye {
  height: 70px !important;
}

.bot-icon {
  display: inline-block;
  margin-right: 58px;
  margin-bottom: 48px;
}

.bot-icon-text > div > label {
  color: rgba(32, 38, 50, 0.6);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: justify;
  margin-bottom: 14px;
}

.bot-preview-button {
  height: 32px;
  width: 135px;
  background-color: #13c26b;
  font-size: 14px;
  color: #ffffff;
  border-radius: 20px;
}
