.candidateTransferModalContainer {
  .enhancedCandidateTransferIcon {
    svg {
      width: 40px;
      font-size: 32px;
      color: white !important;
    }
  }
}
.errorInfo {
  font-size: 12px;
  color: red;
}
.transferModalFooter {
  display: flex;
  margin-top: 5px;
  font-size: 13px;
}

.enhancedModalContent {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .actionField,
  .destinationField {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #1f2730;
    font-size: 16px;
    font-weight: 500;
  }
  .fieldContainer {
    display: flex;
    justify-content: space-between;
    .actionButtons {
      border: 1px solid #b4b7ba;
      border-radius: 20px;
      width: 186px;
      height: 96px;
      cursor: pointer;
      .actionButtonText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px 24px;
        height: 100%;
        .actionButtonInfoText {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .highlightedActionButtons {
      border-color: #13c26b;
      background-color: #f6ffed;
    }
  }
  .footerButtons {
    display: flex;
    justify-content: space-between;
    .closeButton {
      flex: 1;
    }
  }
}
