.searchCriteriaWrapper {
  margin-top: 20px;
  :global .ant-form-explain {
    margin-top: 5px;
  }
  .experienceLastUpdatedWrapper {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid #e8e8e8;
    .displayedWorkAuth {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      max-height: 70px;
      overflow: auto;
      .workAuthTagText {
        text-overflow: ellipsis;
        flex-wrap: wrap;
        white-space: nowrap;
        overflow: hidden;
      }
      .workAuthTag {
        display: flex;
        gap: 4px;
        font-size: 14px;
        border-radius: 24px;
        background-color: white;
        margin: 0px;
        height: 24px;
        padding: 1px 9px;
        color: #51575e;
        font-weight: 400;
        justify-content: center;
        align-items: center;
      }
    }
    :global {
      .ant-select-selection,
      .ant-select-focused,
      .ant-input-number {
        border: none !important;
        border-radius: 0px;
        box-shadow: none !important;
        border-bottom: 1px solid gainsboro !important;
      }
      .ant-select-focused {
        border-bottom: none !important;
      }
    }
    .clearAllButton {
      color: rgba(7, 16, 26, 0.5);
      margin-top: 15px;
      text-decoration: underline;
    }
  }
  .searchCriteriaManualSearchSources {
    padding-top: 20px;
  }
}

.inputLabels {
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.selectDropdown {
  width: 100%;
  border-radius: 4px;

  :global .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    border-radius: 11px;

    .supported-by-portals {
      display: none;
    }
  }

  :global {
    .ant-select-selection {
      overflow-x: hidden;
      max-height: 55px;
      border: none !important;
      border-bottom: 1px solid #bfbfbf !important;
      border-radius: 0px;
    }
    .ant-select-selection__rendered {
      margin-left: 0px !important;
    }
    .ant-select-arrow {
      display: none;
    }
  }
}

.workStatusSection {
  flex: 1;
  margin-top: 25px;
  font-size: 16px;
  color: #1f2730;
  font-weight: 500;
  height: auto;
  padding-bottom: 16px;
  .selectWorkAuthorization {
    padding-top: 5px;
    width: 350px;
  }
}

.workStatusDivider {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin-top: 25px;
  margin-bottom: 0px;
}
