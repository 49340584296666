.connect-message-window-flex-items {
  width: 100%;

  &.phone-dropdown {
    margin-top: 8px;
  }
}

.messages-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 5px 0 10px;
}
.inner-messages-wrapper {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
}

.connect-message-bubble-wrapper {
  max-width: 70%;
  margin-bottom: 10px;

  &.right {
    align-self: flex-end;
  }

  &.left {
    align-self: flex-start;
  }
}

.connect-message-bubble {
  padding: 10px 15px;
  margin-top: 10px;
  font-size: 14px;

  span {
    word-break: break-word;
  }

  &.right {
    background-color: #1890ff;
    color: #ffffff;
    border-radius: 14px 14px 0 14px;

    &.pending {
      opacity: 0.5;
    }

    > a {
      color: white;
      text-decoration: underline;
    }
  }

  &.left {
    background-color: #ffffff;
    border-radius: 0 14px 14px 14px;
    color: rgba(7, 16, 26, 0.7);
    border: 1px solid #dadbdd;
  }
}

.connect-message-bubble-time {
  margin-left: 10px;
  font-size: 12px;

  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }
}

.chat-pane-title {
  border: 1px solid #dadbdd;
  border-radius: 11px;
  background-color: #ffffff;
  padding: 2px 10px;
}

.chat-pane-title-wrapper {
  display: flex;
  justify-content: center;
}
.warning-icon {
  font-size: 14px;
  color: #faad14;
  margin-right: 8px;
}
