.magiclink-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.magiclink-email-sent-content {
  width: 285px;
  color: #474747;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-bottom: 15px;
}

.magiclink-component-footer {
  font-size: 28px;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 30px;
}
.magiclink-email-sent-title {
  color: rgba(7, 16, 26, 0.9);
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 40px;
}
