.candidateDetailPushCandidateError {
  border-radius: 50%;
  padding: 4px 6px;
  cursor: pointer;
  background-color: gray;
  font-size: 14px !important;
  line-height: 1;
  .pushStatusIcon {
    color: white;
  }
}

.candidateDetailPushCandidate {
  border-radius: 50%;
  padding: 4px 6px;
  cursor: pointer;
  border: 1px solid #dadbdd;
  font-size: 14px !important;
  line-height: 1;
  .pushStatusIcon {
    color: white;
  }
}
.candidateDetailPushedCandidate {
  line-height: 1;
}
