.customAffixMenu {
  a {
    color: rgba(7, 16, 26, 0.9);
    padding: 2px 0;
    display: block;
    cursor: pointer;
  }
  a:hover {
    color: #36cf7d !important;
  }
  .active {
    color: #13c26b !important;
  }
}
