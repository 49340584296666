.smartAgentCandidateEmptyScreenWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  margin-top: 30px;
  .smartAgentCandidateEmptyScreen > svg {
    width: 275px;
    font-size: 40px;
    height: 40px;
  }

  .messageInfo {
    height: 24px;
    color: rgba(7, 16, 26, 0.8);
    font-size: 20px;
    line-height: 24px;
    margin-top: 10px;
    font-weight: 500;
  }
  :global {
    .ant-empty-image {
      height: fit-content;
    }
    .ant-empty {
      height: auto;
    }
  }
}
