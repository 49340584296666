.autoRejectAlertMessageWrapper {
  display: flex;
  justify-content: space-between;
}

.autoRejectAlertMessageText {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

.autoRejectAlertMessageActions {
  display: flex;
  align-items: center;
  gap: 24px;
}

.autoRejectAlertActionButton {
  padding: 0px;
  color: #1890ff;
  background-color: #e6f7ff;
}

.autoRejectAlertActionButton::before {
  background-color: #e6f7ff;
  opacity: 0;
}

.autoRejectAlertActionButton:hover,
.autoRejectAlertActionButton:active,
.autoRejectAlertActionButton:focus {
  color: #40a9ff;
  background-color: #e6f7ff;
}

.autoRejectAlertInfoIcon > svg {
  height: 16px;
  width: 16px;
  fill: #1890ff;
}
