.insightsCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.insightsCard {
  width: 100%;
  padding: 38px 20px 20px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 6px 0 rgba(7, 16, 26, 0.2);
  display: flex;
  gap: 24px;
  height: 100%;
  margin-top: -30px;
  position: relative;
}

.insightsCardDetails {
  width: 100%;
}

.insightsCardHeader {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  margin-bottom: 6px;
}

.insightsCardIndicator {
  padding: 6px;
  border-radius: 26px;
  border: 1px solid #d3d3d3;
  z-index: 100;
  background: #ffffff;
}

.jobsIndicator {
  background-color: #07101a;
}

.candidatesIndicator {
  background-color: var(--color-sourced-activated);
}

.connectionsIndicator {
  background-color: var(--color-connected-activated);
}

.insightsCardTitle {
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

.insightsCardStats {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 40px;
  margin-bottom: 6px;
}

.insightsCardPerformance {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
}

.infoIcon {
  font-size: 16px;
  color: #83878c;
  padding-top: 2px;
}

.jobsIconWrapper,
.candidatesIconWrapper,
.connectionsIconWrapper {
  height: 40px;
  width: 40px;
  font-size: 20px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jobsIconWrapper {
  background-color: rgba(7, 16, 26, 0.1);

  .jobsIcon {
    color: #83878c;
  }
}

.candidatesIconWrapper {
  background-color: rgba(24, 144, 255, 0.1);

  .candidatesIcon {
    color: #1890ff;
  }
}

.connectionsIconWrapper {
  background-color: rgba(19, 194, 107, 0.1);

  .connectionsIcon {
    color: #13c26b;
  }
}

.infoIconTooltipWrapper {
  position: absolute;
  top: 38px;
  right: 20px;
}
