.decisionNodeContainer {
  width: 650px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;

  .addNewRuleSection {
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: flex-start;
    justify-content: flex-start;

    .createRuleHeading {
      b {
        font-size: 14px;
      }
    }
  }

  .ruleDropdownSection {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;

    :global {
      .ant-btn-link {
        color: #a4a6a8;
        text-decoration: underline;
      }
    }
  }

  .emptyText {
    :global {
      .ant-btn-link {
        color: #a4a6a8;
      }
    }

    span {
      text-decoration: underline;
    }
  }

  .filledClearText {
    :global {
      .ant-btn-link {
        color: #13c26b;
      }
    }

    span {
      text-decoration: underline;
    }
  }

  .filledDeleteText {
    :global {
      .ant-btn-link {
        color: #f5222d;
      }
    }

    span {
      text-decoration: underline;
    }
  }

  .ruleInputDropdownSection {
    border: 1px solid rgb(189, 184, 184);
    border-radius: 5px;
    width: 510px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    .inputItem {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  .andInputList {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0px 5px 10px;
  }

  .andFilter {
    padding-left: 10px;
    text-decoration: underline;
  }

  .orFilter {
    text-decoration: underline;
  }

  :global {
    .ant-btn-link {
      padding: 0;
    }
  }

  .orTextDisplay {
    padding: 10px 0px 10px 0px;
  }

  .cancelButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .deleteNodeIcon {
    position: relative;
    padding-top: 5px;
    padding-left: 480px;
    height: 12px;
  }

  .footerPlacememt {
    position: absolute;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    right: 0;
    width: 100%;
    bottom: 0;
    background: #fff;
    text-align: right;
  }
}
