.workflowActionsContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.workflowNodeDrawerFooter {
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}
