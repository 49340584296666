.CandidateWorkflowContainer {
  display: flex;
  .CandidateWorkflowContent {
    width: 96%;
  }
  .IconLineContainer {
    height: auto;
    width: 20px;
    .IconLine {
      height: 100%;
      border-right: 2px dashed #b4b7ba80;
    }
  }
  .mailContainer {
    position: relative;
    .mailIconContainer {
      color: #722ed1;
      position: absolute;
      top: 10px;
      left: -10px;
      border: 1px solid #722ed1;
      border-radius: 50%;
      padding: 0 3px;
      background: #fafafa;
      font-size: 10px;
    }
  }
}

.defaultScreenContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  button {
    width: fit-content;
  }
}
