.job-intel {
  display: flex;
  flex-direction: column;

  .content {
    margin-top: 40px;
  }

  .list-view {
    width: 100%;

    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 40px;

      .list-item {
        width: 33%;

        .percent {
          color: #13c26b;
          font-size: 16px;
          line-height: 20px;
        }

        .identifier {
          color: rgba(7, 16, 26, 0.7);
          font-size: 16px;
          line-height: 24px;
          margin-left: 16px;
        }

        .identifier.must-have {
          color: #1991eb;
        }

        .identifier.relevant {
          color: #13c26b;
        }

        .identifier.nice-to-have {
          color: #dadbdd;
        }
      }
    }
  }

  .title {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
  }

  .description {
    width: 90%;
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;

    .topFive {
      color: rgba(7, 16, 26, 0.9);
      font-weight: 500;
      margin-top: 28px;
    }
  }

  > div {
    padding-bottom: 28px;
    border-bottom: 1px solid #dadbdd;
  }

  .bls > .data {
    display: flex;
    justify-content: space-between;

    .country {
      width: 65%;
      border-right: 1px solid #cfd5db;

      .stats > div {
        width: 10%;
      }
    }

    .state-area {
      width: 30%;

      .stats > div {
        width: 21.66%;
      }
    }

    .heading {
      color: rgba(7, 16, 26, 0.7);
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      margin-top: 13px;
      margin-bottom: 9px;
    }

    .stats {
      display: flex;
      justify-content: space-between;
      width: 95%;

      .stat {
        color: rgba(7, 16, 26, 0.9);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      .label {
        color: #83878c;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .companies {
    .top-company {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
    }

    .ant-progress {
      width: 30%;

      .ant-progress-bg {
        background-color: #13c26e;
      }

      .ant-progress-inner {
        background-color: #e6e7e8;
      }
    }
  }

  .industries {
    .top-industry {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
    }

    .ant-progress {
      width: 30%;

      .ant-progress-bg {
        background-color: #13c26e;
      }

      .ant-progress-inner {
        background-color: #e6e7e8;
      }
    }
  }

  .keywords {
    .description {
      .keyword-list {
        margin-top: 28px;

        div {
          margin-top: 12px;

          svg {
            margin-right: 14px;
          }
        }
      }
    }

    .bubble:not(:last-child) {
      border-right: 0.85px solid #979797;
    }
  }

  .heat-map {
    path {
      stroke: #fff;
      stroke-width: 1px;
    }

    path:hover {
      fill-opacity: 0.7;
      cursor: pointer;
    }
  }

  .keywords,
  .industries,
  .companies,
  .job-functions,
  .heat-map {
    display: flex;
    justify-content: space-between;

    .content {
      width: 40%;
      display: flex;
      flex-direction: column;
    }

    .charts {
      width: 55%;
      display: flex;
      justify-content: space-around;
    }
  }
}
