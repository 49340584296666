.bot-prequal-wrapper {
  display: flex;
  flex-direction: column;

  .bot-prequal-text {
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }

  .button-wapper {
    display: flex;
    justify-content: center;
    padding: 15px;

    .skip-button,
    .skip-button:hover {
      background-color: #fff;
      color: rgba(7, 16, 26, 0.7) !important;
      border: 1px solid #dadbdd !important;
      height: 32px;
      border-radius: 16px;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }

    .continue-button {
      border-radius: 16px;
      margin-left: 15px;
    }
  }
}
