.paymentCompanyInfo {
  padding-bottom: 30px;
  :global {
    .ant-collapse-extra {
      display: flex;
      align-items: center;
    }
    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
    }
    .ant-collapse-content-box {
      padding: 20px 40px;
    }
    .ant-form-item {
      display: block !important;
    }
  }
  .editDetailsBtn {
    border: 1px solid rgba(7, 16, 26, 0.15);
    border-radius: 4px;
    background-color: rgba(7, 16, 26, 0.05);
  }
  .placeOrderAlertWrapper {
    width: 98%;
    margin: auto;
    margin-bottom: 10px;
  }

  .paymentCompanyInfoHeader {
    padding: 5px;
  }

  .paymentCompanyInfoTitle {
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
  }

  .paymentCompanyInfoSubtitle {
    color: rgba(7, 16, 26, 0.7);
    font-size: 12px;
  }
}

.paymentCompanyInfoCollapseIcon {
  :global {
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      right: 16px;
      left: auto;
      height: 90px;
    }
  }
}

.termsOfUseWrapper {
  :global {
    .ant-collapse {
      background: white;
    }
  }
  .termsOfUseHeader {
    padding: 5px;
    color: rgba(7, 16, 26, 0.7);
    cursor: pointer;
  }
  .paymentAgreement > p {
    font-size: 16px;
    line-height: 24px;
    color: rgba(7, 16, 26, 0.5);
  }
}
