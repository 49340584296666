.blsIntel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.blsDataHeader {
  color: rgba(7, 16, 26, 0.9);
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.blsDataContainer {
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
}

.blsDataGroup {
  width: 20%;
}

.locationHeader {
  @extend .blsDataGroup;
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.blsDataValue {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.blsDataLabel {
  color: #83878c;
  font-size: 14px;
  line-height: 18px;
}
