.duplicateCandidateCardContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .duplicateCandidateInfo {
    margin: 8px 24px 0px 56px;
    border-bottom: 1px solid rgba(7, 16, 26, 0.15);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 12px;
  }
  .lastDuplicateCandidate {
    border-bottom: none !important;
  }
}
.blurCandidateCardWrapper {
  opacity: 0.3;
  background-color: #fafafa;
  border-radius: 24px !important;
}
.initialBlurCandidateCardWrapper {
  opacity: 0.6;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.98);
  background-color: rgb(250, 250, 250);
  border-radius: 24px !important;
}
