.menuItems {
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  display: flex;
  gap: 8px;
  align-items: center;
  .textContent {
    color: rgba(19, 194, 107, 1);
  }
  .iconContent {
    margin-left: 2px;
  }
  .textItems {
    line-height: 17px;
    height: 42px;
    padding-top: 2px;
  }
}
.constentMenuItem {
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  background-color: #f2f3f3;
}
.suggestionsDropdown {
  z-index: 3200;
  border: none;
  padding-bottom: 10px;
  :global {
    .ant-menu-item:hover,
    .ant-menu-item-active {
      background-color: #f2f3f3 !important;
      color: rgba(0, 0, 0, 0.65) !important;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.65);
    }
    .ant-menu-vertical .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }
    .ant-menu-vertical,
    .ant-menu-item {
      height: fit-content;
      margin-top: 0px;
      margin-bottom: 0px;
      line-height: 50px;
    }
  }
}
