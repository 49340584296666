.statsDetailsWrapper {
  padding: 16px 0px;
}
.statsDetailsHeader {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.statsDetailsTitle {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.statsDetailsSubtitle {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}
