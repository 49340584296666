.modalContainer {
  display: flex;
}

.iconDiv {
  margin-right: 16px;
  font-size: 22px;
  color: #ffcc00;
}

.deductionMessage {
  margin-bottom: 12px;
  margin-top: 10px;
}

.lowCreditMessage {
  color: red;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.bulkConfirmation {
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  font-weight: 500;
}
