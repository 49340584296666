.diversityFilter {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  height: max-content;
  width: 416px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;

  :global {
    .ant-checkbox-wrapper {
      padding: 5px;
      margin-left: 0px;
    }
    .ant-divider-horizontal {
      margin: 5px;
    }
  }
}

.diversityFilterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.diversityHeaderLabel {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.diversityFilters {
  display: flex;
  flex-direction: row;
}

.genderFilter {
  display: flex;
  flex-direction: column;
}

.diversityIcon {
  border: 0px;
  color: #c7d1db;
  padding: 0px;
  box-shadow: none !important;
}

.diversityGreyIcon {
  border: 0px;
  color: #c7d1db;
  padding: 0px;
  box-shadow: none !important;
  pointer-events: none;
}

.diversityClearButton,
.diversityApplyButton {
  height: 32px;
  width: 79px;
  border: 1px solid #dadbdd;
  border-radius: 20px;
  margin-left: 10px;
  line-height: 22px;
  font-size: 14px;
}

.diversityLabelDescription {
  height: 22px;
  width: 429px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 22px;
}
