.call-configuration-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 8px;
}

.add-number {
  display: flex;
  align-items: flex-end;
}

.country-select,
.phone-number-input {
  margin-right: 16px;
}

.country-select {
  width: 80px;
  height: 32px;
}

.call-settings-header {
  margin-bottom: 32px;
}

.phone-number-input {
  width: 250px;
  height: 32px;
}

.submit-number,
.submit-number:hover,
.submit-number:focus {
  width: 135px;
  font-weight: 400;
  background-color: var(--arya-accent-color);
  color: white;
  border-radius: 25px;
}

.add-number-fields {
  margin-bottom: 32px;
}

.phone-number-list {
  width: 761px;
}

.phone-number-list-header {
  color: rgba(32, 38, 50, 0.6);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding-bottom: 11px;
}

.phone-number-column,
.region-column,
.region,
.phone-number-action-buttons,
.phone-number-heading,
.region-heading {
  display: inline-block;
}

.region-heading {
  float: right;
  margin-right: 170px;
}

.phone-number-item {
  line-height: 32px;
  font-size: 16px;
  border-top: 1px solid #e6e7e8;
}

.phone-number-action-buttons {
  float: right;
  visibility: hidden;
}

.phone-number-item:hover {
  .phone-number-action-buttons {
    visibility: visible;
  }
}

.region-column {
  width: 200px;
  float: right;
}

.edit-phone-number,
.delete-phone-number {
  color: var(--arya-accent-color);
  border: none;
  padding: 0px 7px;
  font-size: 12px;
  height: 20px;
}

.edit-phone-number {
  border-right: 1px solid var(--arya-accent-color);
  border-radius: 0px;
}

.calling-codes-dropdown.ant-select-dropdown {
  width: 200px !important;
}
