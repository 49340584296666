.candidate-list {
  margin: auto;
  width: 100%;
}

.candidate-list > .ant-row {
  margin-bottom: 20px;
}

.candidate-status {
  margin: 12px 0px 28px 0px;
  display: flex;
  width: 100%;
}
.candidate-status-v2 {
  margin: 12px 0px 18px 0px;
}

.visible {
  visibility: visible !important;
}

.candidate-list-checkbox-text {
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  line-height: 22px;
}
