@import '~antd/dist/antd.less';
@primary-color: #13c26b;
@font-family: Roboto, sans-serif;
.ant-form-item-label {
  line-height: 22px;
  margin-bottom: 4px;
  color: #1f2730;
}
.ant-form-item-label label {
  line-height: 22px;
  margin-bottom: 4px;
  color: #1f2730;
}
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  border-radius: 11px;
}
.ant-form-item-control {
  line-height: 32px;
}
.ant-popover-inner-content {
  padding: 0px;
}

.ant-popover {
  z-index: 2100;
}

.ant-drawer {
  z-index: 2001;
}

.ant-notification {
  z-index: 2200;
}

.ant-select-dropdown.connect-drawer,
.ant-select-dropdown.create-template,
.ant-select-dropdown.select-aryabot-template {
  z-index: 2010;
}

.ant-input:focus {
  border: 1px solid #36cf7d !important;
}

.ant-input.no-border:focus {
  border: 0px !important;
}

.ant-tabs-nav-container {
  font-size: 16px;
}

.ant-tooltip {
  z-index: 2100;
}
.ant-message-notice-content{
  background-color: #F6FFED;
}
