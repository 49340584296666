.drip-templates,
.aryabot-templates {
  max-width: 990px;

  .connect-templates-pagination {
    margin-top: 20px;
    margin-bottom: 20px;
    float: right;
  }
}
