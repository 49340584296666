.arya-status {
  display: flex;
  color: #647182;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-family: 'Roboto', sans-serif;
  align-items: center;
  flex-direction: column;
}

.arya-status.sourcing {
  .ant-progress-inner {
    background-color: #b5f5cc;
  }
}

.arya-status.review {
  .ant-progress-inner {
    background-color: #fff1b8;
  }
}

.arya-status.off {
  .ant-progress-inner {
    background-color: #f2f3f3;
  }
}

.sourcing-status-text {
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 10px;
}
