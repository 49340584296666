.showAlertContainer {
  display: inline-block;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  border-radius: 5px;
  //   box-shadow: 0px 0px 6px;
  z-index: 2001;
  background-color: white;
  color: gray;
  text-align: center;
}

.hideAlertContainer {
  display: none;
}

.alertMessage {
  width: 100%;
  background-color: #f5222d;
  border: 1px solid #ffa39e;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  padding: 7px;
}

.warningIcon {
  font-size: 28px;
  font-weight: 100;
}

.messageSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-left: 60px;
}

.messageSection > p {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: medium;
  font-weight: 300;
}

.closeIcon {
  font-size: 18px;
  margin-right: 20px;
}
