.autoEmailFromContainer {
  display: flex;
  align-items: center;
  line-height: 22px;
  height: 32px;
  gap: 15px;
  margin-bottom: 14px;
}
.radioFields {
  margin-bottom: 14px;
}
.emailAutomationDescription {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 22px;
  color: #83878c;
}
.smtpNotConfiguredAlert {
  margin-bottom: 10px;
}

.templateLabel {
  font-size: 14px;
  font-weight: 400;
  color: #005ce6;
  margin-bottom: 5px;
}

.campaignTitle {
  width: 384px;
  margin-bottom: 15px;
}
