.candidateCardPersonalInfo {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 16px;
  cursor: pointer;
  max-width: 60%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  .highlightedText {
    overflow: hidden;
    font-weight: 600;
    color: #1f2730;
  }
  .candidateCompany {
    font-weight: 500;
    color: #1f2730;
  }
  .designationAndLocation {
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;
    .candidateDesignationInformation {
      max-width: 500px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .candidateLocation {
      max-width: 300px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 600;
      color: #51575e;
    }
  }
  .displayedProfessionalInfo {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    color: #51575e;
    .workExperienceWithIcon {
      font-weight: 600;
      .workExperienceIcon {
        font-size: 15px;
        color: white;
      }
      .workExperienceText {
        font-size: 12px;
      }
    }
    .clockCircleIconWithText,
    .workExperienceWithIcon {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }
}
.candidateCardPrimaryData {
  display: flex;
  gap: 10px;
}
.candidateCardHeadline {
  color: grey;
  overflow: hidden;
}
.candidateCardHeadlineText {
  display: flex;
  gap: 10px;
}
.truncatedHeadlineText {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  max-width: 600px;
}


.candidateNotInterestedContent {
  border: 1px solid #ffa39e;
  background: #fff1f0;
  color: #f5222d;
  border-radius: 2px;
  padding: 1px 8px;
  font-size: 12px;
}

