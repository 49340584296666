::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* .connect-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
} */

.connect-container *::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.connect-tab-wrapper {
  position: relative;
}

.connect-container {
  margin: auto;
  display: -ms-grid;
  display: grid;
  height: 80vh;
  width: 100%;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(4, 1fr);
  -ms-grid-rows: 100%;
  grid-template-rows: 100%;

  #connect-message-window {
    height: 85%;
  }

  #grid-1 {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  #grid-2 {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  #grid-3 {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
}

.grid-column {
  min-width: 0;
  min-height: 0;
  position: relative;
  border: 1px solid #cfd5db;
}

.main-column {
  border-right: 0px;
}

.sub-column {
  border-right: 0px;
}

.content-column {
  -ms-grid-column-span: 2;
  grid-column: span 2;
  position: relative;
  .content-pane-and-compose-email-button {
    .compose-email-message-button-360 {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      border-top: 1px solid #dadbdd;
      justify-content: flex-end;
      background-color: white;
      align-items: center;
      flex-wrap: wrap;
    }
    .disclaimer-message {
      padding: 4px 13px 0px 0px;
      display: flex;
      gap: 5px;
    }
    .cancel-drip-email {
      padding-right: 20px;
    }
    .unsubscribe-and-compose-email-button {
      display: flex;
      gap: 22px;
      justify-content: space-between;
      padding-bottom: 5px;
      padding-top: 6px;
      width:100%;
      padding-right: 15px;
    }
    .notes-and-stop-drip-button {
      display: flex;
      border-top: 1px solid #dadbdd;
      align-items: center;
      width: 100%;
      padding-bottom: 2px;
    }
  }
}

.card-list {
  overflow-y: auto;
  padding-top: 53px;
  height: 100%;
}

.connect-candidate-header {
  height: 120px;
  position: relative;
  border-bottom: 1px solid rgba(7, 16, 26, 0.15);

  .candidate-card-candidate-reject-select {
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -ms-flex-align: center;
    align-items: center;

    .candidate-like-icon.anticon {
      color: #01cd75;
    }

    .candidate-dislike-icon.anticon {
      color: #ff1a1a;
    }
  }
}

.connect-candidate-drawer {
  .ant-drawer-content-wrapper {
    /* NOTE: This is a hack because 'antd' is adding inline width to drawer*/
    width: 60% !important;
    min-width: 650px !important;
  }
  .rich-text-editor-true {
    max-width: 677px;
  }
}

.ant-radio-group.candidate-profile-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.connect-candidate-info {
  overflow: auto;
  height: calc(100% - 120px);
}

.connect-container-wrapper {
  margin: auto;
}

.connect-candidate-list-empty {
  margin-top: 148px;
}

.connect-candidate-list-empty-icon > svg {
  width: 275px;
  font-size: 40px;
}

.connect-candidate-list-empty .ant-empty-image {
  height: 50px;
}

.email-stats-filter-title {
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: 44px;
  width: 200px;

  .title {
    color: rgba(7, 16, 26, 0.9);
    font-size: 16px;
    font-weight: 500;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .email-stats-filter-apply-clear {
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
