.payment-information-container {
  padding: 0px 40px;
  width: 656px;

  .ant-tabs {
    &-nav {
      width: 100%;

      div {
        display: flex;

        .ant-tabs-tab {
          flex: 1;
          margin-right: 0px;
          width: 100%;
        }
      }
    }
  }
}

.payment-info-tab-item {
  label {
    margin: 0;
  }

  margin: auto;
  display: flex;
  align-items: center;
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.payment-info-tab-disabled {
  label {
    cursor: not-allowed;
  }
}

.credit-card-collection {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;

  .payment-info-credit-card {
    margin-right: 12px;
  }
}

.payment-creditcard-from-heading-wrapper {
  display: flex;
  align-items: baseline;
}

.add-card-cancel-icon {
  font-size: 24px;
  margin-left: auto;
}

.payment-mode-selection {
  margin-top: 20px;
  display: flex;
  width: 563px;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  height: 38px;
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
}

.payment-mode-arya-job-credit-header {
  height: 24px;
  width: 264px;
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

.credits-remaining-content {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  width: 264px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}

.expiry-info {
  height: 48px;
  width: 238px;
  padding-top: 20px;
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.multiple-jobs-discount-content {
  width: 526px;
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 40px;
}

.no-credits-available {
  font-weight: bold;
  font-size: x-large;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: 75px;
  margin-bottom: 40px;
}
