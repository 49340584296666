.modal-container {
  overflow-y: auto;
}
.candidate-360-compose-modal-title {
  display: flex;
  justify-content: space-between;
  background: #1f2730;
  color: white;
  padding: 10px;
  height: 40px;
}

.expand-shrink-icons {
  display: flex;
  gap: 20px;
  align-items: center;
}
.candidate-360-compose-modal {
  width: 60vw !important;
  position: absolute;
  height: 100vh;
  right: 0;
  top: auto;
  padding-bottom: 0;
  bottom: 0;

  .ant-modal-content {
    height: 100%;
    border-radius: 0;
  }
  .ant-modal-header {
    padding: 0;
    border-bottom: 0;
  }
  .ant-modal-footer {
    background-color: #fafafa;
    border: none;
  }
  .footer-container {
    display: flex;
    justify-content: right;
    align-items: baseline;
    .more-emails {
      margin-right: auto;
      p {
        text-decoration: underline;
        cursor: pointer;
        color: #51575e;
      }
    }
  }
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    padding-right: 28px;
  }
  .candidate-360-view-modal-content {
    .mail-editor {
      .ql-editor {
        min-height: 30vh;
      }
    }
  }
}

.maximized-modal {
  width: 70vw !important;
  height: 100vh;
  .maximized-modal-content {
    .mail-editor {
      .ql-editor {
        min-height: 30vh !important;
      }
    }

    .modal-content-360 {
      max-height: calc(100vh - 92px) !important;
      overflow-y: auto;
      padding-left: 20px;
    }
    .workflow-modal-content-360 {
      max-height: calc(100vh - 92px) !important;
      overflow-y: auto;
      padding: 0;
    }
    .candidate-360-message-window {
      height: inherit;
      overflow-y: auto;
      .message-textarea {
        .ql-editor {
          min-height: 20vh !important;
        }
      }
      .message-textarea-with-spamalert {
        .ql-editor {
          min-height: 14vh !important;
        }
      }
    }
  }
  .candidate-360-view-maximized-modal-content {
    height: inherit;
    .mail-editor {
      .ql-editor {
        min-height: 38vh !important;
      }
    }
    .inner-messages-wrapper {
      overflow: auto;
      height: calc(100vh - 400px);

      &.composeMessageDisabled {
        overflow: auto;
        height: calc(100vh - 50px);
      }
    }
  }
}
email-panel-email-info {
  color: #51575e;
}
